import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '../../environments/environment';
import { Salesman } from '../models/salesman.model';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SalesmanService {
  @Output() refresh = new EventEmitter<null>();

  constructor(private http: HttpClient) { }
  getSalesman() {
    return this.http.get<Salesman[]>(`${environment.baseURL}/Salesman/GetSalesmen`).pipe(
      map(
        (data: Salesman[]) => {
          return data;
        },
        catchError((error) => throwError('Something Went Wrong'))
      )
    );
  }
  addSaleman(saleman: Salesman) {
    return this.http.post(`${environment.baseURL}/Salesman`, saleman);
  }
  editSaleman(salesman: Salesman, SalesmanId: string) {
    return this.http.put(
      `${environment.baseURL}/Salesman/${SalesmanId}/Edit`,
      salesman
    );
  }
  deleteSaleman(SalesmanId: string) {
    return this.http.put(
      `${environment.baseURL}/Salesman/${SalesmanId}/delete`,
      {}
    );
  }
}
