import { Injector, Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesmanService } from '../../../services/salesman.service';
import { Salesman } from '../../../models/salesman.model';
import { BuisnessService } from '../../../services/buisness.service';


@Component({
  selector: 'app-add-business-form',
  templateUrl: './add-business-form.component.html',
  styleUrls: ['./add-business-form.component.scss'],
})
export class AddBusinessFormComponent implements OnInit {
  salesMen: Salesman[] = [];
  id: string = "";
  addBusinessForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl(''),
    joiningType: new FormControl(0),
    salesManId: new FormControl('')
  });
  activeModal!: NgbActiveModal;
  @Input() useCase = 'business';

  constructor(private injector: Injector, private salesmanService: SalesmanService, private businessService: BuisnessService, private nbToaster: NbToastrService) {
    if(this.useCase == 'business') {
      this.activeModal = this.injector.get<NgbActiveModal>(NgbActiveModal);
      console.log(this.activeModal)
    }
  }

  ngOnInit(): void {
    this.getSalesMan();
  }

  onSave() {
    //console.log(this.useCase)
    this.businessService.addBuisness(this.addBusinessForm.value).subscribe((res: any) => {
      //console.log(res);
      this.nbToaster.show(res.message, 'success', {
        status: 'success',
        hasIcon: false,
        icon: 'checkmark-outline'
      });
      if(this.useCase == 'business') this.onClose('success');
      this.addBusinessForm.reset();
    }, err => {
      if (err.status == 0) {
        this.nbToaster.show('please check your connection and try again.', 'no internt connection', {
          status: 'danger',
          hasIcon: false,
          icon: 'info-checkmark'
        });
      } else {
        //validation
        console.log(err.error.errors)
        this.nbToaster.show(err.error?.errors[0], 'error', {
          status: 'danger',
          hasIcon: false,
        });
      }

      if(this.useCase == 'business') this.onClose('err');

    });
  }

  onStep() {
    // console.log(this.useCase)
    this.activeModal.close(this.addBusinessForm.value);
  }

  onClose(msg: string) {
    this.activeModal.close(msg);
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  getSalesMan() {
    this.salesmanService.getSalesman().subscribe(res => {
      //console.log(res);
      this.salesMen = res;
    }, err => console.log(err));
  }

}
