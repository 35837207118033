<div>
  <nb-user size="large"
           name=""
           showInitials=false
           picture="https://via.placeholder.com/50/4479e7/ffffff?Text=IMG" *ngIf="!imgUrl"></nb-user>

  <nb-user size="large"
           name=""
           showInitials=false
           [picture]="imgUrl"  *ngIf="imgUrl"></nb-user>
</div>
