import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { EditBranchItemsImgComponent } from './edit-branch-items-img/edit-branch-items-img.component';

@Component({
  selector: 'app-branch-items-img-manage',
  templateUrl: './branch-items-img-manage.component.html',
  styleUrl: './branch-items-img-manage.component.scss'
})
export class BranchItemsImgManageComponent implements OnInit {
  @Input() items: any[] = [];
  @Output() updateParentEvent = new EventEmitter<void>();


  itemId: any;
  categoryName = 'Category Name';
  subCategoryName = 'Subcategory Name';

  constructor(
    private dialogService: NbDialogService

  ) { }

  ngOnInit(): void {

  }
  edit(item:any)
  {
    console.log(item);
    console.log(item);
    
    const modalRef = this.dialogService.open(EditBranchItemsImgComponent, {
      context: {
        item:item
      },
    });
    modalRef.onClose.subscribe((res: any) => {
      console.log(res);
      if (res?.updated) {
        this.updateParentEvent.emit();
      }  
    });
  }
}
