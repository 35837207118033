<nb-card class="m-0">
    <nb-card-header>Edit SMS</nb-card-header>
    <nb-card-body>
        <form [formGroup]="editSMSForm">
            <div class="row">
                <div class="col-12 mb-3">
                    <input formControlName="text" type="text" placeholder="Enter SMS Text" nbInput fullWidth autofocus>
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer>
        <div class="text-right">
            <button nbButton status="success" hero (click)="onEdit()"> Save</button>
        </div>
    </nb-card-footer>
</nb-card>