<!-- addSMS -->
  <nb-card>
      <nb-card-header>
        <h5 class="modal-title">SMS Notification </h5></nb-card-header>
      <nb-card-body>
        <form [formGroup]="addSmsForm">
         <div class="row form-row">
          <div class="col-12 mb-3">
            <label for="recipient-name" class="col-form-label">Event</label>
            <nb-select placeholder="Select Sms Notification Event" fullWidth formControlName="notificationEventId">
              <nb-option *ngFor="let event of events " value="{{event.notificationEventId}}">{{event.notificationEventName}}</nb-option>
            </nb-select>
          </div>
          <div class="col-12 mb-3">
            <label for="message-text" class="col-form-label">SMS Text</label>
              <textarea  nbInput fullWidth placeholder="Enter Sms Text" formControlName="text">
            </textarea>
          </div>
        </div>
        </form>
    </nb-card-body>
      <nb-card-footer class="text-end">  
        <button nbButton  status="success" (click)="addSms()"><nb-icon icon="save-outline"></nb-icon>Save</button>
        <button nbButton  status="danger" (click)="cancel()"><nb-icon icon="close-outline"></nb-icon>Cancel</button>
      </nb-card-footer>
  </nb-card>
<!--addSMS-->
