<nb-card>
  <nb-card-body class="py-4">
    <nb-tabset fullWidth="false" responsive="true">
      <nb-tab tabTitle="Transactions" tabIcon="layers-outline">
        <div class="mt-4">
          <app-trxs-branch></app-trxs-branch>
        </div>
      </nb-tab>
      <nb-tab tabTitle="Claims" tabIcon="people-outline">
        <div class="mt-4">
          <app-claims-branch></app-claims-branch>
        </div>
      </nb-tab>
      <nb-tab tabTitle="Deleted Transactions" tabIcon="trash-2-outline">
        <div class="mt-4">
          <app-deletedtxrs-branch></app-deletedtxrs-branch>
        </div>
      </nb-tab>
      <nb-tab tabTitle="Orders" tabIcon="cube-outline">
        <div class="mt-4">
          <app-orders></app-orders>
        </div>
      </nb-tab>
    </nb-tabset>
  </nb-card-body>
</nb-card>
