import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  constructor(private http: HttpClient) { }

  getAllFeatures() {
    return this.http.get(`${environment.baseURL}/Feature/GetNotDefaultFeatures`);
  }

  getFeatureGroups() {
    return this.http.get(`${environment.baseURL}/Feature/GetFeatureGroups`);
  }

  getFeatureGroupsWithDetail() {
    return this.http.get(`${environment.baseURL}/Feature/GetFeatureGroupsWithDetail`);
  }

  getPackages() {
    return this.http.get(`${environment.baseURL}/Feature/GetPackages`);
  }

  createFeatureGroup(body: any) {
    return this.http.post(`${environment.baseURL}/Feature/CreateFeatureGroup`, body);
  }

  createFeature(body: any) {
    return this.http.post(`${environment.baseURL}/Feature/CreateFeature`, body);
  }

  createPackage(body: any) {
    return this.http.post(`${environment.baseURL}/Feature/CreatePackage`, body);
  }

}
