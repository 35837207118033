
export interface FilteringOptions {
  filterInfos: FilterInfo[];
  orderInfos: OrderInfo[];
  count: number; // 0 fetches all the data
  page: number;
}

export interface FilterInfo {
  logical: Logical;
  propertyName: string;
  value: string; //input
  operator: Operator; //7 - 0
}
export interface OrderInfo {
  orderType: OrderType;
  property: string; // the name of the property
}

export enum Logical {
  AND,
  OR,
}
export enum Operator {
  Contains,
  GreaterThan,
  GreaterThanOrEqualTo,
  LessThan,
  LessThanOrEqualTo,
  StartsWith,
  EndsWith,
  Equals,
  NotEqual,
}
export enum OrderType {
  ASC = 1,
  DESC = 2,
  // not used
  None = 0,
}
