import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';

import { UserService } from '../../services/user.service';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  constructor(public userService:UserService,
    private nbToastr: NbToastrService,
    protected dialogRef: NbDialogRef<AddUserComponent>) { }
  businessId:any;
  userName:string=''
  email:string=''
  role:string=''
  password:string=''
  confirmpassword:string=''
  phoneNumber: string = ''
  userList:User[]=[]
  addUserForm: FormGroup = new FormGroup({
    userName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    role: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    // confirmpassword: new FormControl(''),
    phoneNumber: new FormControl(''),
    businessId:new FormControl(''),
    // branchId:new FormControl(null)
  });
  roles:any = [];
  showPassword = true;
  showconfirmPassword = true;
  submitted: boolean = false;
  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    else{
      return 'password';
    }
  }
  getInputType1() {
    if (this.showconfirmPassword) {
      return 'text';
    }
    else{
      return 'password';
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  toggleShowPassword1() {
    this.showconfirmPassword = !this.showconfirmPassword;
  }
  ngOnInit(): void {
    console.log(this.businessId)
     this.addUserForm.patchValue({
      userName: this.userName,
      email: this.email,
      role: this.role,
      password: this.password,
      // confirmpassword: this.confirmpassword,
      phoneNumber: this.phoneNumber,
      businessId: this.businessId,
      // branchId: null
    })
    this.getUsers();
    this.userService.refresh.subscribe(res => this.getUsers());
    this.getUserRoles()
  }
  getUsers() {
    this.userService.getUsersBusiness(this.businessId).subscribe((res:any)=>{
      this.userList = res;
    },
    (err) => {
      console.error({ err });
    });
  }
  getUserRoles() {
    this.userService.getUserRoles().subscribe((res:any)=>{
      this.roles = res.filter((role: any) => {
        return role.name == 'business'
      });
      //console.log(this.roles,"roles")
    },
    (err) => {
      console.error({ err });
    });
  }
  saveUser() {
    this.submitted = true;
    if(this.addUserForm.value.password === this.confirmpassword) {
    this.userService.register(this.addUserForm.value).subscribe((res:any)=>{
      //console.log(this.addUserForm.value,"addUser");
      if(res.isSucceeded == true) {
        this.onClose('success');
      } else {
        this.onClose(res.message);
      }
      this.nbToastr.show('User Added successfully.', 'success', {
        status: 'success',
        hasIcon: false,
        icon: 'checkmark-outline'
      });
      this.getUsers()
      this.userService.refresh.emit();
      this.dialogRef.close();
      this.submitted = false;
    },
    (errors) => {
      console.log({errors})
      this.submitted = true;
      if(errors.error.StatusCode==500) {
        this.nbToastr.show(errors.error.Message, 'error', {
          status: 'danger',
          hasIcon: true,
          icon: 'close-outline',
          toastClass: 'toatser-index'
        });
      } else {
        let err  = '';
        if(errors.error?.errors && Array.isArray(errors.error?.errors)) {
            err = errors.error?.errors[0]?.description? errors.error?.errors[0].description : errors.error?.errors[0];
        } else if (errors.error?.errors) {
          for(const key in errors.error?.errors) {
            err = errors.error?.errors[key][0];
          }
        }
        else {
          err = errors.error.message
        }


        this.nbToastr.show(err, 'error', {
          status: 'danger',
          hasIcon: true,
          icon: 'close-outline',
          toastClass: 'toatser-index'
        });
      }

      // if(this.addUserForm.value.userName=="" || errors.error.errors.UserName) {
      //   this.nbToastr.show(errors.error.errors.UserName[0], 'error', {
      //     status: 'danger',
      //     hasIcon: true,
      //     icon: 'close-outline',
      //     toastClass: 'toatser-index'
      //   })
      // }
      // if(this.addUserForm.value.email=="" || errors.error.errors.Email) {
      //   this.nbToastr.show(errors.error.errors.Email[0], 'error', {
      //     status: 'danger',
      //     hasIcon: true,
      //     icon: 'close-outline',
      //     toastClass: 'toatser-index'
      //   });
      // }
      // if( this.addUserForm.value.password.search(/[A-Z]/) || errors.error.errors.Password) {
      //   //console.log(errors)
      //   this.nbToastr.show(errors.error.errors.Password[0], 'error', {
      //     status: 'danger',
      //     hasIcon: true,
      //     icon: 'close-outline',
      //     toastClass: 'toatser-index'
      //   });
      // }
      // if( this.addUserForm.value.password.search(/[0-9]/) < 0 || errors.error.errors.Password) {
      //   //console.log(errors)
      //   this.nbToastr.show(errors.error.errors.Password[0], 'error', {
      //     status: 'danger',
      //     hasIcon: true,
      //     icon: 'close-outline',
      //     toastClass: 'toatser-index'
      //   });
      // }
      // if(this.addUserForm.value.role=="" || errors.error.errors.Role) {
      //   this.nbToastr.show(errors.error.errors.Role[0], 'error', {
      //     status: 'danger',
      //     hasIcon: true,
      //     icon: 'close-outline',
      //     toastClass: 'toatser-index'
      //   });
      // }
    })}
    else if(this.addUserForm.value.password !== this.confirmpassword) {
      this.nbToastr.show("password and confirm password  doesn't match", 'error', {
        status: 'danger',
        hasIcon: true,
        icon: 'close-outline',
        toastClass: 'toatser-index'
      });
    }
  }
  onClose(msg: string) {
    this.dialogRef.close(msg);
  }
  cancel() {
    this.dialogRef.close();
  }

}
