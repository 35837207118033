import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { CashierType } from '../../../models/cashier.model';
import { CashierService } from '../../../services/cashier.service';


@Component({
  selector: 'app-edit-cashier',
  templateUrl: './edit-cashier.component.html',
  styleUrls: ['./edit-cashier.component.scss']
})
export class EditCashierComponent implements OnInit {
  cashierTypes: CashierType[] = []
  PosRoles: any = []
  cashierName: string = '';
  cashierId: string = '';
  cashierType: string = '';
  cashierPassword: string = '';
  branchId: string = '';
  showPassword = false;
  showconfirmPassword = false;
  editCashierObj: any = {}
  cashierTypeId: any;
  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    else {
      return 'password';
    }
  }
  getInputType1() {
    if (this.showconfirmPassword) {
      return 'text';
    }
    else {
      return 'password';
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  toggleShowPassword1() {
    this.showconfirmPassword = !this.showconfirmPassword;
  }
  constructor(public cashierService: CashierService,
    private nbToastr: NbToastrService,
    protected dialogRef: NbDialogRef<EditCashierComponent>) {

  }

  ngOnInit(): void {
    this.getCashierTypes()
    this.editCashierObj.cashierName = this.cashierName
    this.editCashierObj.cashierType = this.cashierTypeId
    console.log(this.cashierType, this.cashierTypeId, "cashierType")
  }
  getCashierTypes() {
    this.cashierService.getCashierTypes().subscribe((res: any) => {
      this.cashierTypes = res.posRoles
    }, err => console.log(err))
  }
  editCashier() {
    console.log(this.editCashierObj.cashierPassword, "obj")
    this.cashierService.editCashier(this.editCashierObj, this.cashierId).subscribe((res: any) => {
      // console.log(this.editCashierObj,"editCashierForm");
      if (res.isSucceeded == true) {
        this.onClose('success');
      } else {
        this.onClose(res.message);
      }
      this.nbToastr.show(res.message, 'success', {
        status: 'success',
        hasIcon: false,
        icon: 'checkmark-outline'
      });
      this.cashierService.refresh.emit();
      this.dialogRef.close();
    },
      (err) => {
        console.log(err)
        this.nbToastr.show('Cannot Edit CashierName', 'error', {
          status: 'danger',
          hasIcon: false,
          icon: 'close-outline'
        });
      });
  }
  onClose(msg: string) {
    this.dialogRef.close(msg);
  }
  cancel() {
    this.dialogRef.close()
  }
}
