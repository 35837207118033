import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-branch-tab',
  templateUrl: './branch-tab.component.html',
  styleUrls: ['./branch-tab.component.scss'],
})
export class BranchTabComponent implements OnInit {
  id: string | null;

  constructor(private route: ActivatedRoute,
  ) {
    let val = localStorage.getItem('searchVal');
    let data = JSON.parse(val!)


    if (data.type == 'business') {
      this.id = this.route.snapshot.paramMap.get('id');
      // console.log(this.id, 'this.id');
    } else {
      this.id = data.id;
    }

  }

  ngOnInit(): void {
  }
  tabs: any[] = [
    {
      title: 'Main',
      icon: 'globe-2-outline',
      route: '/home/branch-tab/main-tab',
      active:true,
    },
    {
      title: 'Items',
      icon: 'grid-outline',
      route:  '/home/branch-tab/items-tab' ,
    },
    {
      title: 'Coupons',
      icon: 'credit-card-outline',
      route: '/home/branch-tab/coupons-tab',
    },
    {
      title: 'Transactions',
      icon: 'layers-outline',
      route:'/home/branch-tab/trxs-tab'
    },
    {
      title: 'POS',
      icon: 'smartphone-outline',
      route:'/home/branch-tab/pos-tab'
    },
  ];

}
