import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';

import { NbToastrService } from '@nebular/theme';
import { DeletedTransaction } from '../../models/transaction.model';
import { ItemsService } from '../../services/items.service';
import { BuisnessService } from '../../services/buisness.service';


@Component({
  selector: 'app-deletedtxrs-branch',
  templateUrl: './deletedtxrs-branch.component.html',
  styleUrls: ['./deletedtxrs-branch.component.scss']
})
export class DeletedtxrsBranchComponent implements OnInit {
  trx: any = {}
  categories: any = []
  subcategories: any = []
  items: any = []
  payments: any = []
  devices: any = []
  trxOperations: DeletedTransaction[] = []
  trxOperationsList: DeletedTransaction[] = []
  branchID: any = "";
  param: any = {};
  loading: boolean = false;
  fromDate!: any | NgbDateStruct;
  toDate!: any | NgbDateStruct;
  showFromDate: boolean = true;
  showToDate: boolean = true;
  dateTo: any;
  dateFrom: any
  exportedDeletedTrxs: any;

  searchTerm: any;
  fileName = 'DeletedTrxSheet.xlsx';
  date = new Date()
  constructor(
    public datePipe: DatePipe,
    private nbToastr: NbToastrService,
    private itemService: ItemsService, private calendar: NgbCalendar,
    private businessService: BuisnessService,) {
    let val = localStorage.getItem('searchVal');
    this.param = JSON.parse(val!);
    this.branchID = this.param.id;
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }
  ngOnInit(): void {
    this.getCategoryByBranchId();
    this.getItems();
  }
  onSearch(event: any) {
    this.searchTerm = event.target.value;
    if (this.searchTerm == "") {
      this.trxOperations = this.trxOperationsList;
    } else {
      this.trxOperations = this.trxOperationsList.filter((el: any) =>
        el.transactionMasterId == this.searchTerm || el.voucherNO == this.searchTerm
        || el.transactionDateTime == this.searchTerm
        || el.cashier == this.searchTerm || el.totalAmount == this.searchTerm
        || el.totalTaxAmount == this.searchTerm || el.couponCode == this.searchTerm
        || el.discountValue == this.searchTerm || el.totalAfterDiscount == this.searchTerm
        || el.totalAfterTaxAmount == this.searchTerm
      );
    };
  }
  filterDeletedTrx() { }
  getDeletedTrx() {
    if (this.showFromDate == true) {
      if (this.fromDate != undefined || typeof (this.fromDate) == 'object') {
        let fromDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day)
        this.dateFrom = this.datePipe.transform(fromDate, 'yyyy-MM-dd')
      }
    }
    if (this.showToDate == true) {
      if (this.toDate != undefined || typeof (this.toDate) == 'object') {
        let toDate = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day)
        this.dateTo = this.datePipe.transform(toDate, 'yyyy-MM-dd')
      }
    }
    this.loading = true;
    this.businessService.getDeletedTrxs(this.branchID, this.dateFrom, this.dateTo).subscribe((res: any) => {
      this.trxOperations = res.transactions;
      this.trxOperationsList = res.transactions;
      this.loading = false;

      if (this.trxOperations == null) {
        this.trxOperations = []
      }
    }, (err:any) => console.log(err));
  }
  DisableTrx(id: any) {
    let status: boolean = true;
    this.loading = true;
    this.businessService.disableTrx({ transactionId: id, isDeleted: !status }).subscribe((res:any) => {
      this.nbToastr.show(id + ' disabled successfully!', 'disabled', {
        status: 'success'
      });

      this.getDeletedTrx();
      this.loading = false;
    }, (err:any) => {
      console.log(err.errors[0])
      this.nbToastr.show(err.message, 'Erorr', {
        status: 'danger'
      });
    }
    )
  }
  getItems() {
    this.itemService.getItems(this.branchID).subscribe((res: any) => {
      this.items = res;
    }, (err:any) => console.log(err));
  }
  getCategoryByBranchId() {
    this.loading = true;
    this.itemService.getCategories(this.branchID).subscribe((res: any) => {
      this.categories = res;
      this.loading = false;
    }, (err:any) => console.log(err));
  }
  getDevices() {
    this.businessService.getDevice().subscribe((res: any) => {
      this.devices = res
      console.log(res, "devices")
    },
      (err:any) => {
        console.error({ err });
      })
  }
  getPayment() {
    this.businessService.getPamentMode().subscribe((res: any) => {
      this.payments = res
      console.log(res, "payments")
    },
      (err:any) => {
        console.error({ err });
      })
  }
  selectCategory($event: any) {
    this.getSubCategory($event)
  }
  getSubCategory(categoryId: number) {
    this.loading = true;
    this.itemService.getSubCategories(categoryId, this.branchID).subscribe((res: any) => {
      this.subcategories = res;
      this.loading = false
    }, (err:any) => console.log(err));
  }

  head = [
    { title: "No", dataKey: "transactionMasterId" },
    { title: "Date", dataKey: "transactionDateTime" },
    { title: "Receipt Number", dataKey: "voucherNO" },
    { title: "Coupon Code", dataKey: "couponCode" },
    { title: "Discount Type", dataKey: "discountType" },
    { title: "Discount Value", dataKey: "discountValue" },
    { title: "Total", dataKey: "totalAmount" },
    { title: "Tax", dataKey: "totalTaxAmount" },
    { title: "Discount", dataKey: "totalDiscount" },
    // { title: "Total", dataKey: "totalAfterTaxAmount" },
    // { title: "Total After Discount", dataKey: "totalAfterDiscount" },
    { title: "Cashier", dataKey: "cashier" },
  ]
  exportPdf() {
    this.exportedDeletedTrxs = this.trxOperations
    let doc = new jsPDF();
    autoTable(doc, {
      columns: this.head,
      body: this.exportedDeletedTrxs
    });
    doc.save('DeletedTransations.pdf');
  }
  exportExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.trxOperations);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'DeletedTransations.xlsx');
  }

  EnableTrx(id: any) {
    let status: boolean = false;
    this.loading = true;
    this.businessService.disableTrx({ transactionId: id, isDeleted: status }).subscribe((res:any) => {
      this.nbToastr.show('disabled successfully!', 'disabled', {
        status: 'success'
      });

      this.getDeletedTrx();
      this.loading = false;
    }, (err:any) => {
      console.log(err.errors[0])
      this.nbToastr.show(err.message, 'Erorr', {
        status: 'danger'
      });
    }
    )
  }
}
