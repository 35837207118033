<ng-container [ngSwitch]="field.type">
  <app-dynamic-input
    *ngSwitchCase="'text'"
    [formName]="formName"
    [field]="field"
  ></app-dynamic-input>

  <app-dynamic-input
    *ngSwitchCase="'number'"
    [formName]="formName"
    [field]="field"
  ></app-dynamic-input>

  <app-dynamic-toggle
    *ngSwitchCase="'bool'"
    [formName]="formName"
    [field]="field"
  ></app-dynamic-toggle>
</ng-container>
