<nb-form-field>
  <nb-icon nbPrefix status="warning" icon="search-outline" pack="eva"></nb-icon>
  <input
    class="{{
      customClass == 'outter-search' ? 'outter-search py-3' : 'inner-search'
    }}"
    #autoInput
    nbInput
    fullWidth
    type="text"
    (input)="onChange($event.target)"
    placeholder="Search Business, Branches"
    [nbAutocomplete]="auto"
    autofocus
    autocomplete="off"
  />
  <button
    nbSuffix
    nbButton
    ghost
    [nbSpinner]="loading"
    nbSpinnerStatus="warning"
    nbSpinnerSize="tiny"
  ></button>
</nb-form-field>
<nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
  <nb-option
    *ngFor="let option of filteredOptions$ | async"
    [value]="option.id"
  >
    <div class="d-flex justify-content-between align-items-start w-100">
      <span class="d-block">{{ option.name ? option.name : option }}</span>
      <span
        class="d-block search-badge rounded px-1 {{
          option.type == 'br' ? 'branch_badge' : 'business_badge'
        }}"
        >{{ option.type }}</span
      >
    </div>
  </nb-option>
</nb-autocomplete>
