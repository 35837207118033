import { Component, OnInit } from '@angular/core';
import { BuisnessService } from '../../services/buisness.service';
import { SuggestService } from '../../services/suggest.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  styleUrl: './reference.component.scss'
})
export class ReferenceComponent implements OnInit {

  isLoading: boolean = false;


  businessTypes: any[] = [];
  businessTypeId: any;

  categories: any[] = [];
  categoryId: any;

  subCategories: any[] = [];
  subCategoryId: any;

  items: any[] = [];
  itemId: any;

  constructor(
    private suggestService : SuggestService,
    private businessService: BuisnessService

  ) { }

  ngOnInit(): void {
    this.onGetBusinessType();
  }
  onGetBusinessType() {
    this.businessService.getBusinessTypes().subscribe({next:res =>{ this.businessTypes = res;console.log(this.businessTypes);
    }, error:err => console.log(err)});
  }
  onBusinessTypeChange()
  {
    console.log(this.businessTypeId);
    this.clear();
    this.onLoadData();

  }
  updateevent(){
    console.log('from parent');
    
  }
  onLoadData(){
    this.isLoading=true;
    forkJoin({
      categories: this.suggestService.getCategoriesWithImages(this.businessTypeId),
      subcategories: this.suggestService.getSubCategoriesWithImages(this.businessTypeId),
      items: this.suggestService.getItemsWithImages(this.businessTypeId)
    }).subscribe({
      next: (results:any) => {
        console.log(results);
        
        this.categories = results.categories;
        this.subCategories = results.subcategories;
        this.items = results.items;
        this.isLoading=false;

      },
      error: (error) => {
        console.error('Error fetching data:', error);
      },
      complete:()=> {
        this.isLoading=false;
      },
    });
    
  }
  clear(){
    this.categoryId=null;
    this.subCategoryId=null;
    this.itemId=null
  }

  onCategoryChange(){}
  onSubCategoryChange(){}













  // this.suggestService.getCategoriesWithImages(this.businessTypeId).subscribe({
  //   next:(res:any)=>{
  //     console.log(res);
  //     this.categories=res;
      
  //   },
  //   error:err=>{
  //     console.log(err);
      
  //   }
  // })
}
