<nb-card class="m-0">
  <nb-card-header>
    <h5>Add New POS</h5>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="addPosForm">
      <div class="form-row">
       <div class="col-12 col-md-8">
        <div class="row">
          <div class="mb-3 col-12">
            <label class="mb-2">SN<span class="text-danger">*</span></label>
            <input type="text" formControlName="sn" nbInput fullWidth placeholder="Enter POS SN">
            <span *ngIf="submitted && addPosForm.get('sn')?.errors?.['required']" class="text-danger">SN is required</span>
          </div>
          <div class="mb-3 col-12">
            <label class="mb-2">POS Type<span class="text-danger">*</span></label>
            <select class="form-control custom-select" formControlName="posTypeId" (change)="onChange($event)" nbInput>
              <option selected disabled value=""> Select POS Type</option>
              <option *ngFor="let type of types" value="{{type.posTypeId}}">
                {{type.typeName}}
              </option>
            </select>
            <span *ngIf="submitted && addPosForm.get('posTypeId')?.errors?.['required']" class="text-danger">POS Type is required</span>
          </div>
          <div class="mb-3 col-12">
            <label class="mb-2">Description</label>
            <textarea type="text" formControlName="description" nbInput fullWidth
              placeholder="Enter Description"></textarea>
          </div>
        </div>
       </div>
       <div class="col-12 col-md-4 text-center mt-4">
        <img
        src="{{currentPOSImg}}"
        class="img-fluid pos-img" alt="pos">
       </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="text-right">
    <button nbButton status="basic" (click)="onCancel()">Cancel</button>
    <button nbButton status="success" (click)="onSave()">Save</button>
  </nb-card-footer>
</nb-card>
