import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FeatureService } from '../../../services/feature.service';

@Component({
  selector: 'app-add-feature-group',
  templateUrl: './add-feature-group.component.html',
  styleUrls: ['./add-feature-group.component.scss']
})
export class AddFeatureGroupComponent implements OnInit {
  featureGroupForm: FormGroup = new FormGroup({
    nameInLanguages: new FormControl([], Validators.required),
    key: new FormControl('', Validators.required)
  });

  nameInLanguages: any[] = [
    {languageId: 1, name: ''},
    {languageId: 2, name: ''},
    {languageId: 3, name: ''},
    {languageId: 4, name: ''},
  ];
  key: string = '';
  submitted: boolean = false;
  valid: boolean = true;
  constructor(private nbToaster: NbToastrService, private activeModal: NgbActiveModal, private featureService: FeatureService) { }

  ngOnInit(): void {
  }

  onSave() {
    this.submitted = true;
    if(this.key && this.nameInLanguages[0].name && this.nameInLanguages[1].name && this.nameInLanguages[2].name && this.nameInLanguages[3].name) {
      this.valid = false;

      this.featureService.createFeatureGroup({nameInLanguages: this.nameInLanguages, key: this.key.replace(/ /g,"_")}).subscribe(res => {
        // console.log({res});
        this.onClose(res);
      }, err => {
        console.log({err})
        this.nbToaster.show(err.errors[0], 'ERROR', {
          status: 'danger',
          hasIcon: false
        });
      });

      this.submitted = false;
    } else {
      this.valid = false;
    }
  }

  onClose(msg: any) {
    this.activeModal.close(msg);
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  onNameChange($event: any) {
    // console.log($event.target.value)
    //this.key = $event.target.value;
  }

}
