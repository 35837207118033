<div class="container">
<nb-card class="m-0"  style="width: 50%;">
  <nb-card-header>
    <h5>{{customer ? 'Edit ' : 'Add New'}} Customer</h5>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="addCustomerForm">
      <div class="form-row">
        <div class="mb-3 col-12">
          <label class="mb-2">Full Name</label>
          <input type="text" formControlName="fullname" nbInput fullWidth placeholder="Enter Customer Name">
          <small class="text-danger" *ngIf="sumbitted && !addCustomerForm.get('fullname')!.valid  && addCustomerForm.get('fullname')!.errors?.['required']">
            name is required.
          </small>
        </div>
        <div class="mb-3 col-12">
          <label class="mb-2">Phone</label>
          <input type="text" formControlName="phone" nbInput fullWidth placeholder="Enter Customer Phone">
          <small class="text-danger" *ngIf="sumbitted && !addCustomerForm.get('phone')!.valid  &&  addCustomerForm.get('phone')!.errors?.['required']">
            phone is required.
          </small>
          <small class="text-danger" *ngIf="sumbitted && !addCustomerForm.get('phone')!.valid  &&  addCustomerForm.get('phone')!.errors?.['pattern']">
            phone is not valid.
          </small>
        </div>
        <div class="mb-3 col-12">
          <label class="mb-2">Email</label>
          <input type="email" formControlName="email" nbInput fullWidth placeholder="Enter Customer Email">
          <small class="text-danger" *ngIf="sumbitted && !addCustomerForm.get('email')!.valid  &&  addCustomerForm.get('email')!.errors?.['required']">
            email is required.
          </small>
        </div>
        <div class="mb-3 col-12">
          <label class="mb-2">Gender</label>
          <nb-select class="w-100" fullWidth placeholder="Select Gender" formControlName="gender">
            <nb-option [value]="0">Male</nb-option>
            <nb-option [value]="1">Female</nb-option>
          </nb-select>
          <small class="text-danger" *ngIf="sumbitted && !addCustomerForm.get('gender')!.valid  && addCustomerForm.get('gender')!.errors?.['required']">
            gender is required.
          </small>
        </div>
        <div class="mb-3 col-12">
          <label class="mb-2">Customer Type</label>
          <nb-select class="w-100" fullWidth placeholder="Select Customer Type" formControlName="customerType">
            <nb-option [value]="1">Cash</nb-option>
            <nb-option [value]="2">Credit</nb-option>
          </nb-select>
          <small class="text-danger" *ngIf="sumbitted && !addCustomerForm.get('customerType')!.valid && addCustomerForm.get('customerType')!.errors?.['required']">
            customer type is required.
          </small>
        </div>
        <div class="mb-3 col-12" formGroupName="address">
          <h6>Address</h6>

          <div class="row">
            <div class="mb-3 col-6">
              <label class="mb-2">Country</label>
              <nb-select class="w-100" [selected]="selectedCountry" fullWidth placeholder="Select Country" (selectedChange)="onGetCities($event)">
                <nb-option *ngFor="let country of countries" [value]="country">{{country.name}}
                </nb-option>
              </nb-select>
            </div>
            <div class="mb-3 col-6">
              <label class="mb-2">City</label>
              <nb-select [selected]="selectedCity" fullWidth formControlName="cityId" class="w-100" placeholder="Select City">
                <nb-option *ngFor="let city of cities" [value]="city.id">{{city.name}}
                </nb-option>
              </nb-select>
              <small class="text-danger" *ngIf="sumbitted && !addCustomerForm.get('address.cityId')!.valid && addCustomerForm.get('address.cityId')!.errors?.['required']">
                city is required.
              </small>
            </div>
            <div class="mb-3 col-6">
              <label class="mb-2">Street</label>
              <input type="text" formControlName="street" nbInput fullWidth placeholder="Enter street">
            </div>
            <div class="mb-3 col-6">
              <label class="mb-2">District</label>
              <input type="text" formControlName="district" nbInput fullWidth placeholder="Enter district">
            </div>
            <div class="mb-3 col-6">
              <label class="mb-2">Flat</label>
              <input type="text" formControlName="flat" nbInput fullWidth placeholder="Enter flat no">
            </div>
            <div class="mb-3 col-6">
              <label class="mb-2">Building Name</label>
              <input type="text" formControlName="buildingName" nbInput fullWidth
                placeholder="Enter building name or no">
            </div>
            <div class="mb-3 col-12">
              <label class="mb-2">Location</label>
              <input type="text" formControlName="location" nbInput fullWidth placeholder="Enter full address">
              <small class="text-danger" *ngIf="sumbitted && !addCustomerForm.get('address.location')!.valid && addCustomerForm.get('address.location')!.errors?.['required']">
                location is required.
              </small>
            </div>
          </div>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="text-right">
    <button nbButton status="basic" (click)="onCancel()">Cancel</button>
    <button nbButton status="success" (click)="onSave()">Save</button>
  </nb-card-footer>
</nb-card>
</div>
