<nb-card>
    <nb-card-header>
        <h5>POS History: {{ posSerialNumber }}</h5>
    </nb-card-header>
    <nb-card-body>
        <div class="table-responsive">
            <p *ngIf="pos.length <= 0" class="text-center mb-0">no data available</p>
            <table class="table table-bordered" *ngIf="pos.length > 0">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Assigned To</th>
                        <th scope="col">Assigned On</th>
                        <th scope="col">Next Action</th>
                        <th scope="col">Action On</th>
                        <th scope="col">Notes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let device in pos;let i = index;">
                        <th scope="row">{{ i + 1 }}</th>
                        <td>{{pos.user}}</td>
                        <td>{{pos.date}}</td>
                        <td>{{pos.action}}</td>
                        <td>{{pos.date}}</td>
                        <td>{{pos.description ? pos.description : "-"}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </nb-card-body>
</nb-card>
