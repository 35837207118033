<nb-layout>
    <nb-layout-header subheader>
        <div class="user d-flex justify-content-between align-items-center w-100">
            <img src="assets/imgs/Logo.svg" class="logo-img img-fluid" alt="smartEpay">
            <app-dropdown-menu [onClose]="onClose"></app-dropdown-menu>
        </div>
    </nb-layout-header>
    <nb-layout-column  (click)="closeMenu()">
        <div class="welcome my-5 text-center">
            <h1>Welcome to smart e-pay</h1>
            <p>Get it all under controll</p>
        </div>
        <div class="search-form mx-auto">
            <app-search-form customClass="outter-search"></app-search-form>
        </div>
        <!-- <div class="last-search py-5">
            <div class="row justify-content-center">
                <h5 class="recent-title">Recent Business</h5>
                <div class="col-12 col-md-4 col-lg-3 col-xxl-2">
                    <div class="card search-card shadow">
                        <img src="assets/imgs/Rectangle 61.png" class="card-img-top" alt="...">
                        <div class="card-body d-flex align-items-center justify-content-between">
                            <h5 class="card-title">Merchant</h5>
                            <nb-icon icon="arrow-forward-outline" status="success"
                                [options]="{ animation: { type: 'pulse' } }"></nb-icon>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-xxl-2">
                    <div class="card search-card shadow">
                        <img src="assets/imgs/Rectangle 61.png" class="card-img-top" alt="...">
                        <div class="card-body d-flex align-items-center justify-content-between">
                            <h5 class="card-title">Merchant</h5>
                            <nb-icon icon="arrow-forward-outline" status="success"
                                [options]="{ animation: { type: 'pulse' } }"></nb-icon>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-xxl-2">
                    <div class="card search-card shadow">
                        <img src="assets/imgs/Rectangle 61.png" class="card-img-top" alt="...">
                        <div class="card-body d-flex align-items-center justify-content-between">
                            <h5 class="card-title">Merchant</h5>
                            <nb-icon icon="arrow-forward-outline" status="success"
                                [options]="{ animation: { type: 'pulse' } }"></nb-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </nb-layout-column>
</nb-layout>

