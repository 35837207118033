<nb-card>
  <nb-card-header>
         <h5 class="modal-title">Transaction {{trxNo}}  </h5>
        </nb-card-header>
    <nb-card-body>
      <div class="row" *ngIf="trxOperations== null">
        <strong style="text-align: center;"> No Transaction Found!</strong>                
    </div>
        <div class="row" *ngIf="trxOperations != null" >
          <div class="table-responsive">
            <table class="table table-bordered" id="transactionTable" #transactionTable [nbSpinner]="loading" nbSpinnerStatus="basic">
              <thead>
                <tr>
                  <th scope="col">Transaction No</th>
                  <th scope="col">Date and Time</th>
                  <th scope="col">Branch Name</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Tax Rate</th>
                  <th scope="col">Tax Amount</th>
                  <th scope="col">Discount </th>
                  <th scope="col">Item Name</th>
                  <th scope="col">Options</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let trxOperation of trxOperations; let i= index;">
                  <td>{{i + 1}}</td>
                  <td>{{trxOperation.transactionDate | date: 'yyyy-MM-dd'}}</td>
                  <td>{{trxOperation.branchName}}</td>
                  <td>{{trxOperation.qty}}</td>
                  <td>{{trxOperation.taxRate}}</td>
                  <td>{{trxOperation.taxAmount}}</td>
                  <td>{{trxOperation.discount}}</td>
                  <td>{{trxOperation.itemName}}</td>
                  <td>
                    <div class="row">
                      <div class="col d-flex"> 
                        <button nbButton hero placement="left" ngbTooltip="Disable"
                         (click)="DisableTrx(trxOperation.itemId)"
                        tooltipClass="custom-tooltip" shape="round" size="small" status="success">
                            <nb-icon icon="eye-outline"></nb-icon>
                          </button> 
                    </div></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </nb-card-body>
</nb-card>