
<div class="row">
    <div class="col-12">
        <div class="row d-flex ">
            <div class="col-12 col-md-6 col-lg-4 mb-3">
                <input class="input-style" fullWidth type="text" nbInput placeholder="Search"
                    (input)="onSearch($event)">
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-3">
                <button nbButton size="medium" hero status="primary" (click)="generateCoupon()">
                    <nb-icon icon="plus-square-outline"></nb-icon>
                    Generate Coupons
                </button>
            </div>
            <div class="col-12 col-md-12 col-lg-4 mb-3">
                <img src="../assets/imgs/excel.png" class="img-fluid excel-img" (click)="exportExcel()">
                <img src="../assets/imgs/pdf.png" class="img-fluid pdf-img" (click)="exportPdf()">
            </div>
        </div>
        <div class="padding"></div>
        <div class="row d-flex">
            <div class="col-12  col-lg-4  mb-3">
                <nb-checkbox id="checker1" [checked]="showgenerateDate" (checkedChange)="checkGenerateDate($event)"
                    class="space">
                </nb-checkbox>
                Generated date

                <nb-checkbox id="checker2" [checked]="showexpiryDate" (checkedChange)="checkExpiryDate($event)"
                    class="space">
                </nb-checkbox>
                Expiry date

                <span class="form-group" *ngIf="showgenerateDate">
                    <div class="inline">
                        <input nbInput placeholder="Select Generate Date" name="generateDate" [(ngModel)]="generate"
                            size="small" ngbDatepicker #d="ngbDatepicker">
                        <nb-icon icon="calendar-outline" (click)="d.toggle()" class="calendr">
                        </nb-icon>
                    </div>
                </span>
            </div>
            <div class="col-12 col-lg-4 mb-3">
                <span id="space"></span>
                <span class="form-group" *ngIf="showexpiryDate">
                    <div class="inline">
                        <input nbInput placeholder="Select Expiry Date" name="expiryDate" [(ngModel)]="expire"
                            size="small" ngbDatepicker #d="ngbDatepicker">
                        <nb-icon icon="calendar-outline" (click)="d.toggle()" class="calendr">
                        </nb-icon>
                    </div>
                </span>
                <span class="form-group">
                    Status
                    <select class="form-control custom-select" [(ngModel)]="searchData.status"
                        placeholder="Select Status">
                        <option *ngFor="let status of coupounStatuses" value="{{status.value}}">{{status.name}}
                        </option>
                    </select>
                </span>
            </div>

            <div class="col-12 col-lg-4 mb-3 ">

                <button nbButton hero style="margin-bottom: 3px;" size="medium" status="success"
                    (click)="filterCoupon()">
                    <nb-icon icon="funnel-outline"></nb-icon>
                    Filter
                </button>

                <span id="space"></span>
                <button nbButton hero size="medium" status="warning" (click)="clearFilterData()">
                    <nb-icon icon="close-outline"></nb-icon>
                    Clear
                </button>
            </div>
        </div>
        <div class="padding"></div>
                <div class="row" *ngIf="couponList.length == 0">
                    <strong style="text-align: center;display: table;margin: 0 auto;"> No Coupons Found!</strong>
                </div>
                <div class="row" *ngIf="couponList.length != 0">
                    <div class="table-responsive" [nbSpinner]="loading" nbSpinnerStatus="basic">
                        <table class="table table-bordered" id="couponTable" #couponTable>
                            <thead>
                                <tr>
                                    <th>
                                        No
                                    </th>
                                    <th [appSort]="couponList" data-order="desc" data-name="createdOn" scope="col">
                                        Generated
                                        date</th>
                                    <th [appSort]="couponList" data-order="desc" data-name="expiryDate" scope="col">
                                        Expiry
                                        Date
                                    </th>
                                    <th [appSort]="couponList" data-order="desc" data-name="discountType" scope="col">
                                        Type
                                    </th>
                                    <th [appSort]="couponList" data-order="desc" data-name="discountValue" scope="col">
                                        Value
                                    </th>
                                    <th [appSort]="couponList" data-order="desc" data-name="amountDeducted" scope="col">
                                        Amount
                                        deducted</th>
                                    <th [appSort]="couponList" data-order="desc" data-name="isActive" scope="col">Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let coupon of couponList  | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i = index">
                                    <th scope="row">{{i + 1}}</th>
                                    <td>{{coupon.createdOn | date: 'yyyy-MM-dd'}}</td>
                                    <td>{{coupon.expiryDate | date: 'yyyy-MM-dd'}}</td>
                                    <td>{{coupon.discountType}}</td>
                                    <td>{{coupon.discountValue}}</td>
                                    <td>{{coupon.amountDeducted}}</td>
                                    <td>
                                        <button placement="left" ngbTooltip="Active" tooltipClass="custom-tooltip"
                                            *ngIf="coupon.isActive==false"
                                            (click)="changeCouponStatus(coupon.couponMasterId,coupon.isActive)" nbButton
                                            status="success" shape="round" hero size="small">
                                            <nb-icon icon="checkmark-square-2-outline"></nb-icon>
                                        </button>

                                        <button placement="top" ngbTooltip="Deactive" tooltipClass="custom-tooltip"
                                            *ngIf="coupon.isActive==true"
                                            (click)="changeCouponStatus(coupon.couponMasterId,coupon.isActive)" nbButton
                                            status="primary" shape="round" hero size="small">
                                            <nb-icon icon="close-square-outline"></nb-icon>
                                        </button>

                                        <button placement="right" ngbTooltip="Detials" tooltipClass="custom-tooltip"
                                            nbButton class="mx-2" (click)="goToCouponDetails(coupon.couponMasterId)"
                                            status="warning" shape="round" hero size="small">
                                            <nb-icon icon="browser-outline"></nb-icon>
                                        </button>

                                        <button placement="left" ngbTooltip="Download Coupon" tooltipClass="custom-tooltip"
                                            nbButton class="mx-2" (click)="downloadCoupon(coupon.couponMasterId)"
                                            status="success" shape="round" hero size="small">
                                            <nb-icon icon="file-text-outline"></nb-icon>
                                        </button>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div *ngIf="couponList != null">
                            <ngb-pagination [collectionSize]="couponList.length" [(page)]="page" [maxSize]="5"
                                [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                        </div>

                    </div>
                </div>


                <nb-alert status="success" *ngIf="success.isSucceeded">
                    <nb-icon icon="checkmark-outline"></nb-icon>
                    Coupon status change successfully
                </nb-alert>
                <div class="alert alert-danger" *ngIf="alert.alert">{{ alert.message }} please try again.</div>
    </div>
</div>