import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Expense, GetExpensesResponse } from '../types/get-expenses-response';
import { GetExpenseTypeResponse } from '../types/get-expense-type-response';
import { GetPaymentModeResponse } from '../types/get-payment-mode-response';
import { catchError, concatMap, EMPTY, Subscription } from 'rxjs';
import { ExpensesService } from '../../../services/expenses.service';
import { BranchService } from '../../../services/branch.service';
import {
  NB_THEME_OPTIONS,
  NbDialogService,
  NbToastrService,
} from '@nebular/theme';
import { PostEditExpenseModalComponent } from '../post-edit-expense-modal/post-edit-expense-modal.component';
import { ConfirmExpenseDeleteModalComponent } from '../confirm-expense-delete-modal/confirm-expense-delete-modal.component';
import {
  FilteringOptions,
  Logical,
  Operator,
  OrderType,
} from '../types/filtering-options';

type DateRange = {
  start: Date;
  end: Date;
};
@Component({
  selector: 'app-expenses-list',
  templateUrl: './expenses-list.component.html',
  styleUrl: './expenses-list.component.scss',
})
export class ExpensesListComponent implements OnInit, OnDestroy {
  @Input() branchId!: any; // any typed because of the bad implementation in branch-tab.component
  businessId: any;

  expenses: Expense[] = [];
  expenseTypes: GetExpenseTypeResponse[] = [];
  paymentModes: GetPaymentModeResponse[] = [];

  isFilterOn: boolean = false;
  filterByExpenseTypeId: number = 0;
  filterByPaymentModeId: number = 0;
  filterDateRang?: DateRange;
  filterKeyword = '';
  currentPage: number = 1;
  totalPagesCount: number = -1;

  totalAmount: number = 0;
  filterOptions: FilteringOptions = {
    count: 10,
    filterInfos: [],
    orderInfos: [
      {
        orderType: OrderType.DESC,
        property: 'date',
      },
    ],
    page: 1,
  };
  subscriptions: Subscription[] = [];

  constructor(
    private expensesService: ExpensesService,
    private branchService: BranchService,
    private nbToastr: NbToastrService,
    private dialogService: NbDialogService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  toggleFilter() {
    this.isFilterOn = !this.isFilterOn;
    if (!this.isFilterOn) {
      this.resetFilter();
      this.loadExpenses();
    }
  }

  loadData() {
    this.loadPaymentModes();
    this.loadExpenseTypes();
    this.loadExpenses();
  }

  loadPaymentModes() {
    this.subscriptions.push(
      this.expensesService
        .getPaymentModesByBranchId(this.branchId)
        .pipe(
          catchError((errorMessage) => {
            this.nbToastr.danger(errorMessage, 'Error', {
              icon: 'close-outline',
            });
            return EMPTY;
          })
        )
        .subscribe((res) => (this.paymentModes = res))
    );
  }

  loadExpenseTypes() {
    this.subscriptions.push(
      this.branchService
        .getBusinessByBranchId(this.branchId)
        .pipe(
          catchError((errorMsg) => {
            this.nbToastr.danger('Cannot obtain business id', 'Service Error', {
              icon: 'close-outline',
            });
            // immediately complete without emitting values
            return EMPTY;
          }),
          //concatMap used to make sure that businessId exists before making request using it
          concatMap((res1: any) => {
            this.businessId = res1.branch?.businessId;
            return this.expensesService.getExpenseTypes(this.businessId).pipe(
              catchError((errorMessage) => {
                this.nbToastr.danger(errorMessage, 'Error', {
                  icon: 'close-outline',
                });
                return EMPTY;
              })
            );
          })
        )
        .subscribe((res) => (this.expenseTypes = res))
    );
  }

  loadExpenses() {
    this.subscriptions.push(
      this.expensesService
        .getExpensesByBranchId(this.branchId, this.filterOptions)
        .pipe(
          catchError((errMsg) => {
            this.nbToastr.danger(errMsg, 'Error', {
              icon: 'close-outline',
            });
            return EMPTY;
          })
        )
        .subscribe((res) => {
          this.filterOptions.count = res.pageSize;
          this.filterOptions.page = res.currentPageNumber;
          this.totalPagesCount = res.totalPageCount;
          this.totalAmount = res.totalAmount;
          // this.expenses.push(...res.value);
          this.expenses = res.value;
        })
    );
  }

  loadNextPage() {
    if (this.filterOptions.page == this.totalPagesCount) {
      // set indicator to disable next btn
      return;
    }
    this.filterOptions.page += 1;
    this.subscriptions.push(
      this.expensesService
        .getExpensesByBranchId(this.branchId, this.filterOptions)
        .pipe(
          catchError((errMsg) => {
            this.nbToastr.danger(errMsg, 'Error', {
              icon: 'close-outline',
            });
            return EMPTY;
          })
        )
        .subscribe((res) => {
          this.filterOptions.count = res.pageSize;
          this.filterOptions.page = res.currentPageNumber;
          this.totalPagesCount = res.totalPageCount;
          this.totalAmount = res.totalAmount;
          this.expenses = res.value;
        })
    );
  }
  loadPreviousPage() {
    if (this.filterOptions.page == 1) {
      // set indicator to disable prev btn
      return;
    }
    this.filterOptions.page -= 1;
    // incase we perviously loaded the last page and it contained only few items
    this.filterOptions.count < 10 ? (this.filterOptions.count = 10) : '';

    this.subscriptions.push(
      this.expensesService
        .getExpensesByBranchId(this.branchId, this.filterOptions)
        .pipe(
          catchError((errMsg) => {
            this.nbToastr.danger(errMsg, 'Error', {
              icon: 'close-outline',
            });
            return EMPTY;
          })
        )
        .subscribe((res) => {
          this.filterOptions.count = res.pageSize;
          this.filterOptions.page = res.currentPageNumber;
          this.totalPagesCount = res.totalPageCount;
          this.totalAmount = res.totalAmount;
          this.expenses = res.value;
        })
    );
  }

  filter() {
    // set values to filterOptions
    this.filterOptions = {
      count: 10,
      filterInfos: [],
      orderInfos: [
        {
          orderType: OrderType.DESC,
          property: 'date',
        },
      ],
      page: 1,
    };
    
    if(this.filterKeyword!= ''){
      this.filterOptions.filterInfos.push(        {
        logical: Logical.AND,
        operator: Operator.Contains,
        value: this.filterKeyword,
        propertyName: 'note',
      })
    }

    if (this.filterByExpenseTypeId != 0) {
      this.filterOptions.filterInfos.push({
        logical: Logical.AND,
        operator: Operator.Equals,
        propertyName: 'expenseTypeId',
        value: `${this.filterByExpenseTypeId}`,
      });
    }

    if (this.filterByPaymentModeId != 0) {
      this.filterOptions.filterInfos.push({
        logical: Logical.AND,
        operator: Operator.Equals,
        propertyName: 'paymentModeId',
        value: `${this.filterByPaymentModeId}`,
      });
    }

    if (this.filterDateRang) {
      this.filterOptions.filterInfos.push(
        {
          logical: Logical.AND,
          operator: Operator.LessThan,
          propertyName: 'date',
          value: `${this.filterDateRang.end.toDateString()}`,
        },
        {
          logical: Logical.AND,
          operator: Operator.GreaterThan,
          propertyName: 'date',
          value: `${this.filterDateRang.start.toDateString()}`,
        }
      );
    }

    this.loadExpenses();
  }

  resetFilter() {
    this.filterByExpenseTypeId = 0;
    this.filterByPaymentModeId = 0;
    this.filterDateRang = undefined;
    this.filterOptions = {
      count: 10,
      filterInfos: [],
      orderInfos: [
        {
          orderType: OrderType.DESC,
          property: 'date',
        },
      ],
      page: 1,
    };
  }

  addExpense() {
    // dialog should not be opened if data is missing
    this.dialogService
      .open(PostEditExpenseModalComponent, {
        context: {
          branchId: this.branchId,
          expenseTypes: this.expenseTypes,
          paymentModes: this.paymentModes,
          businessId: this.businessId,
        },
      })
      .onClose.subscribe((res?: string) => {
        if (res) {
          this.nbToastr.success(res, 'Created');
          // reload the expenses if new one added
          this.resetFilter();
          this.loadExpenses();
        }
        // reload expense types anyway (for UC when new type added though the post expense dialog)
        this.loadExpenseTypes();
      });
  }

  editExpense(expense: Expense) {}

  deleteExpense(expense: Expense) {
    this.dialogService
      .open(ConfirmExpenseDeleteModalComponent)
      .onClose.subscribe((res) => {
        if (res) {
          this.subscriptions.push(
            this.expensesService.deleteExpense(expense.id).subscribe()
          );
          this.resetFilter();
          this.loadExpenses();
        }
      });
  }

  download(url?: string) {
    if (url) this.expensesService.downloadFile(url);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subs) => subs.unsubscribe());
  }
}
