<nb-card class="model-full">
    <nb-card-header>
        <h5 class="modal-title">Pick Your Location</h5>
    </nb-card-header>
    <nb-card-body>
        <div class="row">
            <div class="col-12">
                <input #mapSearchField nbInput class="map-input my-4" type="text" placeholder="Enter Location" [value]="defaultbranchAddress">
                <google-map [options]="mapConfig" (mapClick)="moveMarker($event)" 
                [center]="initialCoordinates" [zoom]="18"  [nbSpinner]="loading" nbSpinnerStatus="basic">
                    <map-marker [position]="marker.position"  (titleChanged)="Title($event)"
                     [title]="marker.title" [options]="marker.options">
                    </map-marker>
                </google-map>
            </div>
        </div>
    </nb-card-body>

    <nb-card-footer class="text-end">
        <button nbButton status="success" (click)="addLocation()">
            <nb-icon icon="save-outline"></nb-icon>Save
        </button>
        <button nbButton status="danger" (click)="cancel()">
            <nb-icon icon="close-outline"></nb-icon>Cancel
        </button>
    </nb-card-footer>
</nb-card>