import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-confirm-expense-delete-modal',
  templateUrl: './confirm-expense-delete-modal.component.html',
  styleUrl: './confirm-expense-delete-modal.component.scss'
})
export class ConfirmExpenseDeleteModalComponent {
  constructor(private dialogRef:NbDialogRef<ConfirmExpenseDeleteModalComponent>){}
  onConfirm(){
    this.dialogRef.close(true)
  }
  onCancel(){
    this.dialogRef.close(false)
  }
}
