<nb-card  [nbSpinner]="isLoading">
    <nb-card-header>
        <div class="row">
            <!-- [(selected)]="BusinessID" -->
            <!-- <div class="col-md-4 mb-3">
                <nb-select placeholder="Select Business" fullWidth [(ngModel)]="businessId"
                    (selectedChange)="getBuisnessBranches()">
                    <nb-option *ngFor="let business of businesses" value="{{ business?.id }}">
                        {{ business?.name }}</nb-option>
                    <nb-option *ngIf="businesses.length==0">No businesses</nb-option>
                </nb-select>
            </div> -->
            <div class="col-md-4 mb-3">
                <input  nbInput placeholder="Business"  [(ngModel)]="searchQuery" [nbAutocomplete]="autoBusiness" fullWidth
                (input)="filterBusinesses(searchQuery)"
                
                />
                <nb-autocomplete  #autoBusiness>
                    <nb-option *ngFor="let business of filteredBusinesses" [value]="business.name" (click)="getBuisnessBranches(business)">
                        {{ business?.name }}
                    </nb-option>
                    <nb-option *ngIf="filteredBusinesses.length === 0">
                        No businesses found.
                    </nb-option>
                </nb-autocomplete>
            </div>
            <div class="col-md-4 mb-3">
                <nb-select placeholder="Branch" fullWidth [(ngModel)]="branchId" (selectedChange)="getbranchCategory()">
                    <nb-option *ngFor="let branch of branches" value="{{ branch?.id }}">
                        {{ branch?.name }}</nb-option>
                    <nb-option *ngIf="branches.length==0">No Branches Found!</nb-option>

                </nb-select>

            </div>
            <div class="col-md-4 mb-3">
                <nb-select fullWidth placeholder="Select Category" [(ngModel)]="categoryId"
                    (selectedChange)="selectCategory($event)">
                    <nb-option *ngFor="let category of categories"
                        value="{{category.categoryId}}">{{category.categoryNameEN}}</nb-option>
                    <nb-option *ngIf="categories.length==0">No Category Found!</nb-option>
                </nb-select>
            </div>
            <div class="col-md-4 mb-3">
                <nb-select fullWidth placeholder="Select SubCategory" [(ngModel)]="subCategoryId"
                    (selectedChange)="selectSubCategory()">
                    <nb-option *ngFor="let subcategory of subCategories"
                        value="{{subcategory.subCategoryId}}">{{subcategory.subCategoryNameEN}}</nb-option>
                    <nb-option *ngIf="subCategories.length==0">No SubCategory Found!</nb-option>
                </nb-select>
            </div>
            <!-- <nb-select fullWidth placeholder="Search">
                <nb-option *ngIf="subCategories.length==0">No SubCategory Found!</nb-option>
                <nb-option>Business Type1</nb-option>
                <nb-option>Business Type2</nb-option>
                <nb-option>Business Type3</nb-option>
            </nb-select> -->
        </div>

    </nb-card-header>

    <nb-card-body>
        <nb-tabset>
        <nb-tab tabTitle="Category">    
            <app-img-card *ngIf="categories" [categories]="categories"  (updateParentEvent)="updateevent()"></app-img-card>
        </nb-tab>
        <nb-tab tabTitle="Subcategory">
            <app-img-card *ngIf="subCategories" [subCategories]="subCategories" (updateParentEvent)="updateevent()" [isCategory]="false"></app-img-card>
        </nb-tab>
        <nb-tab tabTitle="Items">
            <app-branch-items-img-manage [items]="items"  (updateParentEvent)="updateevent()"></app-branch-items-img-manage>
        </nb-tab>
        </nb-tabset>


    </nb-card-body>


</nb-card>