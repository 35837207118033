import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { CashierService } from '../../services/cashier.service';


@Component({
  selector: 'app-add-cashier',
  templateUrl: './add-cashier.component.html',
  styleUrls: ['./add-cashier.component.scss']
})
export class AddCashierComponent implements OnInit {
  cashierTypes: any = []
  cashierName: string = '';
  posRoleId: any = '';
  cashierPassword: string = '';
  cashierConfirmPassword: string = '';
  branchId: string = '';
  addCashierForm: FormGroup = new FormGroup({
    cashierName: new FormControl(''),
    cashierPassword: new FormControl(''),
    // cashierConfirmPassword: new FormControl(''),
    branchId: new FormControl(''),
    posRoleId: new FormControl('')
  });
  showPassword = false;
  showconfirmPassword = false;

  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    else {
      return 'password';
    }
  }
  getInputType1() {
    if (this.showconfirmPassword) {
      return 'text';
    }
    else {
      return 'password';
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  toggleShowPassword1() {
    this.showconfirmPassword = !this.showconfirmPassword;
  }
  constructor(public cashierService: CashierService,
    private nbToastr: NbToastrService,
    protected dialogRef: NbDialogRef<AddCashierComponent>) { }
  ngOnInit(): void {
    this.getCashierTypes()
    this.addCashierForm.setValue({
      cashierName: this.cashierName,
      cashierPassword: this.cashierPassword,
      // cashierConfirmPassword: this.cashierConfirmPassword,
      branchId: this.branchId,
      posRoleId: this.posRoleId,
    })
  }
  getCashierTypes() {
    this.cashierService.getCashierTypes().subscribe((res: any) => {
      this.cashierTypes = res.posRoles
    }, err => console.log(err))
  }
  saveCashier() {
    console.log(this.addCashierForm.value.cashierPassword , this.cashierConfirmPassword)
    if (this.addCashierForm.value.cashierPassword === this.cashierConfirmPassword) {
      this.addCashierForm.value.posRoleId = Number(this.addCashierForm.value.posRoleId)
      if (this.addCashierForm.value.cashierPassword.search(/[0-9]/) < 0 &&
        this.addCashierForm.value.cashierConfirmPassword.search(/[0-9]/) < 0) {
        this.nbToastr.show('The Password must be at least 8 and at max 100 characters long.', 'error', {
          status: 'danger',
          hasIcon: false,
          icon: 'close-outline'
        });
      }
      else { this.cashierService.addCashier(this.addCashierForm.value).subscribe((res: any) => {
        console.log(this.addCashierForm.value, "addCashierForm", res);

        if (res.isSucceeded == true) {
          this.onClose('success');
        } else {
          this.onClose(res.message);
        }
        this.nbToastr.show('Cashier Added successfully.', 'success', {
          status: 'success',
          hasIcon: false,
          icon: 'checkmark-outline'
        });
        this.cashierService.refresh.emit();
        this.dialogRef.close();
      },
        (errors) => {
          this.nbToastr.show(errors.statusText, 'error', {
            status: 'danger',
            hasIcon: false,
            icon: 'close-outline'
          });
        });
      }
    } else if (this.addCashierForm.value.cashierPassword !== this.cashierConfirmPassword) {
      this.nbToastr.show("password and confirm password  doesn't match", 'error', {
        status: 'danger',
        hasIcon: false,
        icon: 'close-outline'
      });
    }
  }
  onClose(msg: string) {
    this.dialogRef.close(msg);
  }

  cancel() {
    this.dialogRef.close();
  }
}
