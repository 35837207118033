import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbToastrService } from '@nebular/theme';

import { AddBranchPosComponent } from '../add-pos/add-pos.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisconnectPosComponent } from '../disconnect-pos/disconnect-pos.component';
import { ReplacePosComponent } from '../replace-pos/replace-pos.component';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { UserService } from '../../services/user.service';
import { ParamsPosComponent } from '../params-pos/params-pos.component';
import { PosService } from '../../services/pos.service';

@Component({
  selector: 'app-pos-branch',
  templateUrl: './pos-branch.component.html',
  styleUrls: ['./pos-branch.component.scss'],
})
export class PosBranchComponent implements OnInit {
  selectedItem = '0';
  lock: boolean = false;
  connected: boolean = true;
  POSs: any[] = [];
  filteredPOSs: any[] = [];
  posTypes: any[] = [];
  params: any = {};
  selectedModal: string = 'assign';
  posId: string = '';
  sim: string | null = '';
  head = [
    { title: 'id', dataKey: 'posId' },
    { title: 'serial number', dataKey: 'sn' },
    { title: 'description', dataKey: 'description' },
    { title: 'isLocked', dataKey: 'isLocked' },
    { title: 'isDeleted', dataKey: 'isDeleted' },
    { title: 'pos Type', dataKey: 'posType' },
    { title: 'pos Status', dataKey: 'posStatus' },
  ];
  show: boolean = false;
  message: string = '';

  alert: any = {
    alert: false,
    message: '',
    status: '',
  };

  constructor(
    private posService: PosService,
    private userService: UserService,
    private route: ActivatedRoute,
    private nbToastr: NbToastrService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    let val = localStorage.getItem('searchVal');
    this.params = JSON.parse(val!);
    this.onGetBranchPos();
    this.onGetPosTypes();

  }

  changeType() {}

  openMdm() {}

  onPosLock(id: string, locked: boolean) {
    let body = {
      posId: id,
      notes: '',
      locked: !locked,
    };

    this.posService.lockPos(body).subscribe(
      (res: any) => {
        console.log(res);
        this.alert = {
          alert: true,
          message: res.message,
          status: 'success',
        };
        setTimeout(() => {
          this.alert.alert = false;
        }, 2000);

        this.userService.logs.emit('lock pos in branch');
        this.onGetBranchPos();
      },
      (err) => {
        this.alert = {
          alert: true,
          message: 'something went wrong, please try again',
          status: 'danger',
        };
        setTimeout(() => {
          this.alert.alert = false;
        }, 2000);
      }
    );
  }

  onGetBranchPos() {
    //console.log(this.id)
    this.posService.getBranchPos(this.params.id).subscribe(
      (res) => {
        //console.log(res)
        this.POSs = res;
        this.filteredPOSs = res;
      },
      (err) => console.log(err)
    );
  }

  onGetPosTypes() {
    this.posService.getPosTypes(this.params.id).subscribe(
      (res) => {
        this.posTypes = res;
        //console.log(this.posTypes)
      },
      (err) => {
        console.log(err);
      }
    );
  }

  changeModal(name: string, pos?: any, sim?: any) {
    this.posId = pos;
    this.sim = sim;
    this.selectedModal = name;
    // console.log(this.selectedModal)
  }

  openAssignModal() {
    const modalRef = this.modalService.open(AddBranchPosComponent, {
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.name = this.params.name;
    modalRef.componentInstance.id = this.params.id;
    modalRef.closed.subscribe((res: string) => {
      if (res == 'success') {
        this.nbToastr.show('POS assigned successfully', 'success', {
          status: 'success',
          hasIcon: true,
          icon: 'checkmark-outline',
        });
        this.userService.logs.emit('assign pos to branch log');
        this.onGetBranchPos();
        this.onGetPosTypes();
      } else {
        this.nbToastr.show(res, 'erorr', {
          status: 'danger',
          hasIcon: true,
          icon: 'close-outline',
        });
      }
    });
  }

  openDisconnectModal(pos: {}) {
    const modalRef = this.modalService.open(DisconnectPosComponent, {
      centered: true,
    });
    modalRef.componentInstance.name = this.params.name;
    modalRef.componentInstance.id = this.params.id;
    modalRef.componentInstance.pos = pos;

    modalRef.closed.subscribe((res: string) => {
      console.log(res);
      if (res == 'success') {
        this.nbToastr.show('POS Disconnected successfully', 'success', {
          status: 'success',
          hasIcon: true,
          icon: 'checkmark-outline',
        });
        this.userService.logs.emit('disconnect pos in branch log');
        this.onGetBranchPos();
        this.onGetPosTypes();
      } else {
        this.nbToastr.show(res, 'erorr', {
          status: 'danger',
          hasIcon: true,
          icon: 'close-outline',
        });
      }
    });
  }

  openReplaceModal(pos: {}) {
    const modalRef = this.modalService.open(ReplacePosComponent, {
      centered: true,
      size: 'xl',
    });
    modalRef.componentInstance.name = this.params.name;
    modalRef.componentInstance.id = this.params.id;
    modalRef.componentInstance.pos = pos;

    modalRef.closed.subscribe((res: string) => {
      console.log(res);
      if (res == 'success') {
        this.nbToastr.show('POS Replaced successfully', 'success', {
          status: 'success',
          hasIcon: true,
          icon: 'checkmark-outline',
        });
        this.onGetBranchPos();
        this.userService.logs.emit('replace pos in branch log');
      } else {
        this.nbToastr.show(res, 'erorr', {
          status: 'danger',
          hasIcon: true,
          icon: 'close-outline',
        });
      }
    });
  }

  openParamstModal(pos?: any) {
   console.log('pos log',pos);
    const modalRef = this.modalService.open(ParamsPosComponent, {
      centered: true,
      size: 'xl ',
    });

    if(pos) {
      modalRef.componentInstance.selectedPos = pos.posId;
    modalRef.componentInstance.posType = pos.posType;
    modalRef.componentInstance.posImg = pos.posTypeImageUrl;
    }

  }

  onSearch(event: any) {
    let searchTerm = event.target.value.toLowerCase();
    if (searchTerm == '') {
      this.filteredPOSs = this.POSs;
    } else {
      //console.log(searchTerm)
      this.filteredPOSs = this.POSs.filter(
        (pos) =>
          pos.sn.toLowerCase().includes(searchTerm) ||
          pos.posType.toLowerCase().includes(searchTerm) ||
          pos.posStatus.toLowerCase().includes(searchTerm)
      );
    }
  }

  exportExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.filteredPOSs);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'branch-pos.xlsx');
  }

  exportPdf() {
    let doc = new jsPDF();
    autoTable(doc, {
      columns: this.head,
      body: this.POSs,
    });
    doc.save('branch-pos.pdf');
  }


}
