import { Component, OnInit } from '@angular/core';
import { Category, SubCategory } from '../../models/category.model';
import { ItemsService } from '../../services/items.service';
import { Subscription } from 'rxjs';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-branch-category-logo',
  templateUrl: './branch-category-logo.component.html',
  styleUrls: ['./branch-category-logo.component.scss'],
})
export class BranchCategoryLogoComponent implements OnInit {
  isLoading: boolean = false;
  obj?: Category | SubCategory | any;
  placeholder =
    'https://socialimpact.com/wp-content/uploads/2021/03/logo-placeholder-300x210.jpg';
  imgFile?: File;
  logoName: string = '';
  branchID?: string;
  subscription?: Subscription;

  constructor(
    private itemService: ItemsService,
    private toastrService: NbToastrService,
    private dialogRef: NbDialogRef<BranchCategoryLogoComponent>
  ) {}

  isCategory(): boolean {
    return !!this.obj?.categoryId;
  }

  getId(): number {
    return this.isCategory() ? this.obj!.categoryId : this.obj.subCategoryId;
  }

  getName(): string {
    if (this.isCategory()) {
      return this.obj! ? this.obj.categoryNameEN : '';
    } else {
      return this.obj! ? this.obj.subCategoryNameEN : '';
    }
  }

  ngOnInit(): void {
    // console.log('isCategory', this.isCategory())
    let isCategory = this.isCategory();
    let obj = this.obj;
    console.log({ isCategory, obj });
  }

  uploadSubCategoryLogo(formData: FormData) {
    this.isLoading = true;
    let id = this.getId();
    this.subscription = this.itemService
      .uploadSubCategoryImage(formData, id, this.logoName)
      .subscribe(
        (res: any) => {
          this.isLoading = false;
          //console.log(res);
          this.dialogRef.close(res.url);
        },
        (err: HttpErrorResponse) => {
          this.isLoading = false;
          console.log(err);
          this.toastrService.danger(err.message);
        }
      );
  }

  uploadCategoryLogo(formData: FormData) {
    this.isLoading = true;
    let id = this.getId();
    this.subscription = this.itemService
      .uploadCategoryImage(formData, id, this.logoName)
      .subscribe(
        (res: any) => {
          this.isLoading = false;
          this.toastrService.success('Logo uploaded successfully');
          this.dialogRef.close(res.url);

          this.obj.imageUrl = res.url;
          this.placeholder = res.url;

          // console.log(res);
        },
        (err: HttpErrorResponse) => {
          this.isLoading = false;
          console.log(err);
          this.toastrService.danger(err.message);
        }
      );
  }

  onSelect(event: any) {
    if (this.logoName) {
      this.imgFile = event.addedFiles[0];
      let formData = new FormData();
      formData.append('logofile', this.imgFile!);
      if (this.isCategory()) {
        this.uploadCategoryLogo(formData);
      } else {
        this.uploadSubCategoryLogo(formData);
      }
    } else {
      this.toastrService.danger('Please enter a name for the logo');
    }
  }

  onClick(drop: any) {
    drop.showFileSelector();
  }
}
