import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { POSParam, Pos, SetPOSParam, posResponse } from '../models/pos.model';

@Injectable({
  providedIn: 'root',
})
export class PosService {
  constructor(private http: HttpClient) {}

  getAllPos(isPaginated: boolean) {
    return this.http
      .post<any>(
        environment.baseURL + '/Pos/available-pos', {}
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error) => throwError('Something Went Wrong'))
      );
  }

  getBranchPos(branchID: string) {
    return this.http
      .get<any>(environment.baseURL + `/Pos/${branchID}/pos`)
      .pipe(
        map((data) => {
          return data.pos;
        }),
        catchError((error) => throwError('Something Went Wrong'))
      );
  }

  assignPos(pos: Pos) {
    return this.http.post<posResponse>(
      environment.baseURL + '/Pos/assign',
      pos
    );
  }

  replacePos(body: {}) {
    return this.http.post<posResponse>(
      environment.baseURL + `/Pos/replace`,
      body
    );
  }

  lockPos(body: {}) {
    return this.http.post(environment.baseURL + `/Pos/Lock`, body);
  }

  disconnectPos(body: {}) {
    return this.http.post<posResponse>(
      environment.baseURL + '/Pos/Disconnect',
      body
    );
  }

  getPosParams(posId: string) {
    return this.http.get<POSParam[]>(
      environment.baseURL + `/PosParameter/GetPosParams`,
      {
        params: { posId },
      }
    );
  }

  setPosParam(param: SetPOSParam) {
    return this.http.post(
      environment.baseURL + `/PosParameter/SetPosParams`,
      param
    );
  }

  setBranchPosParams(param: SetPOSParam) {
    return this.http.post(
      environment.baseURL + `/PosParameter/SetBranchDefaultPosParams/`,
      param
    );
  }

  getDefaultBranchPosParams(branchId: string) {
    return this.http.get<POSParam[]>(
      environment.baseURL + `/PosParameter/GetBranchDefaultPosParams`,
      {
        params: { branchId },
      }
    ); //TODO:not completed
  }

  //get pos types count
  getPosTypes(branchId: string) {
    return this.http
      .get(environment.baseURL + `/Pos/${branchId}/posTypeCount`)
      .pipe(
        map((data: any) => {
          return data.posCount;
        }),
        catchError((error) => throwError('Something Went Wrong'))
      );
  }

  getPosHistory(id: string) {
    return this.http.get(environment.baseURL + `/Pos/${id}/history`).pipe(
      map((data: any) => {
        return data.histories;
      }),
      catchError((error) => throwError('Something Went Wrong'))
    );
  }

  getPOSTypes() {
    return this.http.post(environment.baseURL + '/Pos/postypes', {}).pipe(
      map((data: any) => {
        return data.value;
      }),
      catchError((error) => throwError('Something Went Wrong'))
    );
  }

  addPos(pos: any) {
    return this.http.post(environment.baseURL + '/Pos', pos);
  }

  addPosType(pos: any) {
    return this.http.post(environment.baseURL + '/Pos/CreatePosType', pos);
  }

  editPosType(pos: any) {
    return this.http.post(environment.baseURL + '/Pos/EditPosType', pos);
  }
}
