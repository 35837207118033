<div class="row">
  <div class="col">

    <div class="row d-flex">
      <div class="col-12 col-md-6 col-lg-6 mb-3">
        <span class="form-group" *ngIf="showFromDate">
          From Date
          <div class="inline">
            <input nbInput placeholder="Select From Date" name="fromDate" [(ngModel)]="fromDate" size="small"
              ngbDatepicker #d="ngbDatepicker">
            <nb-icon icon="calendar-outline" (click)="d.toggle()" class="calendr">
            </nb-icon>
          </div>
        </span>
      </div>

      <div class="col-12 col-md-6 col-lg-6 mb-3">
        <span class="form-group" *ngIf="showToDate">
          To Date
          <div class="inline">
            <input nbInput placeholder="Select To Date" name="toDate" [(ngModel)]="toDate" size="small" ngbDatepicker
              #d="ngbDatepicker">
            <nb-icon icon="calendar-outline" (click)="d.toggle()" class="calendr"></nb-icon>
          </div>
        </span>
      </div>
    </div>
    <div class="padding"></div>
    <div class="padding"></div>


  <div class="row d-flex">
    <div class="col-12 col-md-6 mb-3">
      Claimed Source
      <nb-select placeholder="Select Claim Source" [(ngModel)]="searchData.claimSource">
        <nb-option value="web">Web</nb-option>
        <nb-option value="POS">Pos</nb-option>
      </nb-select>
    </div>
    <div class="col-12 col-md-6  mb-3">

      Claim Status
      <nb-select placeholder="Select Claim Status" [(ngModel)]="searchData.claimStatus">
        <nb-option [value]="1">approved</nb-option>
        <nb-option [value]="2">rejected</nb-option>
        <nb-option [value]="3">waiting</nb-option>
      </nb-select>
    </div>
  </div>

    <div class="row d-flex">
      <div class="col-12 col-md-6 mb-3">
        <input class="input-style" fullWidth type="text" nbInput placeholder="Search" name="search" (input)="onSearch($event)">
        </div>
        <div class="col-12  col-md-6">
        <button nbButton hero size="medium" (click)="getClaims()" style="margin-bottom: 3px;" status="success">
          <nb-icon icon="funnel-outline"></nb-icon>
          Filter
        </button>
        <button nbButton hero size="medium" status="warning" (click)="clearFilterData()">
          <nb-icon icon="close-outline"></nb-icon>
          Clear
        </button>
          <img src="../assets/imgs/excel.png" class="img-fluid excel-img"  (click)="exportExcel()">
          <img src="../assets/imgs/pdf.png" class="img-fluid pdf-img"  (click)="exportPdf()">
      </div>
    </div>
    <div class="padding"></div>
        <div class="row" *ngIf="claims?.length == 0" [nbSpinner]="loading"
        nbSpinnerStatus="basic">
          <strong style="text-align: center;"> No Claims Found!</strong>
        </div>
        <div class="row" *ngIf="claims?.length != 0">
          <div class="table-responsive">
            <table class="table table-bordered" id="claimTable" #claimTable [nbSpinner]="loading"
              nbSpinnerStatus="basic">
              <thead>
                <tr>
                  <th>Transaction No</th>
                  <th>Offline Transaction ID</th>
                  <th scope="col" [appSort]="claims" data-order="desc" data-name="transactioDate">Transaction Date</th>
                  <th scope="col" [appSort]="claims" data-order="desc" data-name="claimDate">Claim Date</th>
                  <th scope="col" [appSort]="claims" data-order="desc" data-name="claimedBy">Claimed By</th>
                  <th scope="col" [appSort]="claims" data-order="desc" data-name="claimSource">Calim Source</th>
                  <th scope="col" [appSort]="claims" data-order="desc" data-name="claimStatus">Claim Status</th>
                  <th scope="col">Options</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let claim of claims; let i=index;">
                  <th scope="row">{{i + 1}}</th>
                  <td>{{claim.offlineTransactionId? claim.offlineTransactionId : '-'}}</td>
                  <td>{{claim.transactioDate | date: 'yyyy-MM-dd'}}</td>
                  <td>{{claim.claimDate | date: 'yyyy-MM-dd'}}</td>
                  <td>{{claim.claimedBy}}</td>
                  <td>{{claim.claimSource}}</td>
                  <td *ngIf="claim.claimStatus=='1'">approved</td>
                  <td *ngIf="claim.claimStatus=='2'">rejected</td>
                  <td *ngIf="claim.claimStatus=='3'">waiting</td>
                  <td>
                    <button nbButton hero placement="left" ngbTooltip="Show Transaction" tooltipClass="custom-tooltip"
                      (click)="showTrx(claim.transactionMasterId)" shape="round" size="small" status="primary">
                      <nb-icon icon="browser-outline"></nb-icon>
                    </button>
                    <button nbButton hero placement="top" *ngIf="claim?.claimStatus == '3'" ngbTooltip="Approve" tooltipClass="custom-tooltip"
                      (click)="approve(claim.calimId)" shape="round" size="small" status="success">
                      <nb-icon icon="checkmark-square-2-outline"></nb-icon>
                    </button>
                    <button nbButton hero placement="right" *ngIf="claim?.claimStatus == '3'" ngbTooltip="Reject" tooltipClass="custom-tooltip"
                      (click)="reject(claim.calimId)" shape="round" size="small" status="warning">
                      <nb-icon icon="close-square-outline"></nb-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  </div>
</div>
