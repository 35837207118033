<p class="text-center font-weight-medium my-5" *ngIf="!searchVal">search for business / branch to get started <span
    class="font-weight-normal">🧐</span></p>
<nb-card class="rounded-2" *ngIf="searchVal && searchVal.type == 'business'">
  <nb-card-body class="p-0">
    <app-main-business-tab></app-main-business-tab>
  </nb-card-body>
</nb-card>
<nb-card class="rounded-2" *ngIf="searchVal && searchVal.type == 'branch'">
  <nb-card-body>
    <app-branch-tab></app-branch-tab>
  </nb-card-body>
</nb-card>
