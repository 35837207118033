import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {
  files: File[] = [];
  file!: File;
  @Input() heading: string = "";
  @Input() text: string = "";
  @Output() uploaded = new EventEmitter<File>();
  @Output() removed = new EventEmitter<null>();

  constructor() { }

  ngOnInit(): void {
  }


  onSelect(event: any) {
    //console.log(event);
    this.files.push(...event.addedFiles);
    this.file = this.files[0];
    //upload
    let data = new FormData();
    data.append('itemSheet', this.file);
    this.uploaded.emit(this.file);
  }

  onRemove(event: any) {
    //console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
    // if(this.files.length > 0) {
    //   this.file = this.files[0];
    //   let data = new FormData();
    //   data.append('itemSheet', this.file);
    //   this.uploaded.emit(this.file);
    // } else {
      this.removed.emit();
  }

  onUpload() {
    
  }

}
