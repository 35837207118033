import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NewLayoutComponent } from './new-layout/new-layout.component';
import { PrintingLayoutService } from '../../services/printing-layout.service';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-printing-layouts',
  templateUrl: './printing-layouts.component.html',
  styleUrls: ['./printing-layouts.component.scss'],
})
export class PrintingLayoutsComponent implements OnInit {
  selectedLayoutType: string = 'ticket';
  printingLayouts: any;
  tokenPrintingLayouts: any;
  ticketPrintingLayouts: any;
  filteredPrintingLayouts: any;

  loading: boolean = true;
  constructor(
    private modalService: NgbModal,
    private printingLayoutService: PrintingLayoutService,
    private router :Router,
    private rooute : ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getPrintingLayouts();
  }

  getPrintingLayouts() {
    this.loading = true;
    this.printingLayoutService.getPrintingLayout().subscribe(
      (res) => {
        this.printingLayouts = res;
        this.getTicketPrintingLayouts();
        this.loading = false;
      },
      (err) => console.log(err)
    );
  }

  onAddLayout() {
    // const modalRef = this.modalService.open(NewLayoutComponent, {
    //   centered: true,
    //   size: 'xl',
    // });
    // modalRef.closed.subscribe((res) => {
    //   if (res == 'success') {
    //     this.selectedLayoutType = 'ticket';
    //     this.getPrintingLayouts();
    //   }
    // });
    this.router.navigate(['new-layout'] ,{ relativeTo: this.rooute}) ;
  }

  onChange(event: any) {
    this.loading = true;
    event == 'token'
      ? this.getTokenPrintingLayouts()
      : this.getTicketPrintingLayouts();
    this.loading = false;
  }

  getTicketPrintingLayouts() {
    this.loading = true;
    this.ticketPrintingLayouts = this.printingLayouts.filter((data: any) => {
      return data.layoutType == 1;
    });
    this.filteredPrintingLayouts = this.ticketPrintingLayouts;
    this.loading = false;
  }

  getTokenPrintingLayouts() {
    this.loading = true;
    this.tokenPrintingLayouts = this.printingLayouts.filter((data: any) => {
      return data.layoutType == 0;
    });
    this.filteredPrintingLayouts = this.tokenPrintingLayouts;
    this.loading = false;
  }

  onRefresh() {
    this.loading = true;
    this.selectedLayoutType = 'ticket';
    this.getPrintingLayouts();
    this.loading = false;
  }
}
