<nb-card class="m-0">
  <nb-card-header>
    <h5>{{table? 'Update' : 'Create'}} Table</h5>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="tableForm">
      <div class="form-row">
        <div class="mb-3 col-12">
          <label class="mb-2">table name</label>
          <input type="text" formControlName="tableName" nbInput fullWidth>
          <span *ngIf="submitted && tableForm.get('tableName')?.errors?.['required']" class="text-danger">table name is required</span>
        </div>
        <div class="mb-3 col-12">
          <label class="mb-2">table number</label>
          <input type="number" formControlName="tableNumber" nbInput fullWidth>
          <span *ngIf="submitted && tableForm.get('tableNumber')?.errors?.['required']" class="text-danger">table number is required</span>
        </div>
        <div class="mb-3 col-12">
          <label class="mb-2">table capacity</label>
          <input type="number" formControlName="tableCapacity" nbInput fullWidth>
          <span *ngIf="submitted && tableForm.get('tableCapacity')?.errors?.['required']" class="text-danger">table capacity is required</span>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="text-right">
    <button nbButton status="basic" (click)="onCancel()">Cancel</button>
    <button nbButton status="success" (click)="submit()">Save</button>
  </nb-card-footer>
</nb-card>
