import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pos-branch-tab',
  templateUrl: './pos-branch-tab.component.html',
  styleUrls: ['./pos-branch-tab.component.scss']
})
export class PosTabComponent implements OnInit {
  tabName: string = 'pos';
  constructor() { }

  ngOnInit(): void {
  }
}
