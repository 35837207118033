<nb-card>
  <nb-card-header>
    <h5 class="modal-title">Add Expense Type </h5>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="postForm" >
    <div class="form-row">
      <div class="col-12 my-2">
        <label for="" class="d-bloc">Expense Type Name</label>
        <input type="text" formControlName="name" nbInput required fullWidth placeholder="Ex. Cash" >
      </div>
    </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex justify-content-end">
      <button nbButton status="basic" (click)="onCancel()">Cancel</button>
      <button nbButton status="success" (click)="onSubmit()">Save</button>
    </div>
  </nb-card-footer>
</nb-card>