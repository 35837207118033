import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkersService } from '../../../services/workers.service';


@Component({
  selector: 'app-add-worker',
  templateUrl: './add-worker.component.html',
})
export class AddWorkerComponent implements OnInit {
  @Input() branch!: string;
  @Input() worker!: {id: string,firstname: string, lastname: string, gender: number | string};
  submitted: boolean = false;
  addWorkerForm: FormGroup = new FormGroup({
    firstname: new FormControl("", Validators.required),
    lastname: new FormControl("", Validators.required),
    gender: new FormControl(0, Validators.required),
  });

  constructor(
    private workersService: WorkersService,
     private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    if(this.worker) {
      this.addWorkerForm.addControl('workerId', new FormControl(this.worker.id));

      this.addWorkerForm.patchValue({
        firstname: this.worker.firstname,
        lastname: this.worker.lastname,
        gender: this.worker.gender
      });

    } else {
      this.addWorkerForm.addControl('branchId', new FormControl(this.branch));
    }
  }

  onClose(msg: string) {
    this.activeModal.close(msg);
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  onSave() {
    this.submitted = true;

    if(this.addWorkerForm.valid) {
      if(this.worker) {
        this.workersService.editWorker(this.addWorkerForm.value).subscribe(res => {
          this.onClose('success');
        }, err => this.onClose('err'))
      } else {
        this.workersService.addWorker(this.addWorkerForm.value).subscribe(res => {
          this.onClose('success');
        }, err => this.onClose('err'))
      }
    }

  }

}
