import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { EditLayoutComponent } from '../edit-layout/edit-layout.component';
import { ViewLayoutComponent } from '../view-layout/view-layout.component';
import { PrintingLayoutService } from '../../../services/printing-layout.service';
import { PrintingLayout } from '../../../models/printing-layout.model';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent implements OnInit {
  @Input() layout!: PrintingLayout;
  @Output() refreshLayout = new EventEmitter<null>();

  constructor(
    private nbToastr: NbToastrService,
    private printingLayoutService: PrintingLayoutService,
    public userservice: UserService,
    private modalService: NgbModal,
    private router : Router,
    private route : ActivatedRoute
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      console.log('this is the third message');
    }, 1000);
  }

  editLayout(layout: PrintingLayout) {
    // const modalRef = this.modalService.open(EditLayoutComponent, {
    //   centered: true,
    //   size: 'xl',
    // });
    // modalRef.componentInstance.layout = layout;
    // modalRef.closed.subscribe((res) => {
    //   if (res == 'success') {
    //     this.nbToastr.show('printing layout updated successfully', 'success', {
    //       status: 'success',
    //       hasIcon: true,
    //       icon: {
    //         icon: 'checkmark-outline',
    //         pack: 'eva',
    //       },
    //     });

    //     this.refreshLayout.emit();
    //   } else {
    //     this.nbToastr.show(
    //       'failed to update printing layout, please try again',
    //       'erorr',
    //       {
    //         status: 'danger',
    //         hasIcon: true,
    //         icon: {
    //           icon: 'alert-circle-outline',
    //           pack: 'eva',
    //         },
    //       }
    //     );
    //   }
    // });
    localStorage.setItem('layout',JSON.stringify(layout))
    this.router.navigate(['edit-layout'] ,{ relativeTo: this.route}) ;


  }

  deleteLayout(id: string) {
    this.printingLayoutService.deletePrintingLayout(id).subscribe(
      (res) => {
        this.userservice.logs.emit('deleted layout in branch log');
        this.nbToastr.show('layout deleted successfully', 'success', {
          status: 'success',
          hasIcon: true,
          icon: 'checkmark-outline',
        });

        this.refreshLayout.emit();
      },
      (err) => {
        this.nbToastr.show(
          'failed to delete layout, please try again.',
          'erorr',
          {
            status: 'danger',
            hasIcon: true,
            icon: 'alert-circle-outline',
          }
        );
      }
    );
  }

  viewLayout(layout: PrintingLayout) {
    const modalRef = this.modalService.open(ViewLayoutComponent, {
      centered: true,
      size: 'sm',
    });
    modalRef.componentInstance.layout = layout;
  }
}
