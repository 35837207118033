import { Component, OnInit } from '@angular/core';
import {LogMessage as NgxLogMessage} from 'ngx-log-monitor';
import {Observable, timer} from "rxjs";
import {map, take} from "rxjs/operators";

@Component({
  selector: 'app-pos-branch-logs',
  templateUrl: './pos-branch-logs.component.html',
  styleUrls: ['./pos-branch-logs.component.scss']
})
export class PosBranchLogsComponent implements OnInit {
  pos:any;
  logStream$:Observable<NgxLogMessage>

  logs: NgxLogMessage[] = [
    {message: 'A simple log message'},
    {message: 'A success message', type: 'SUCCESS'},
    {message: 'A success message', type: 'SUCCESS'},
    {message: 'A success message', type: 'SUCCESS'},
    {message: 'A success message', type: 'SUCCESS'},
    {message: 'A success message', type: 'SUCCESS'},
    {message: 'A success message', type: 'SUCCESS'},
    {message: 'A success message', type: 'SUCCESS'},
    {message: 'A success message', type: 'SUCCESS'},
    {message: 'A success message', type: 'SUCCESS'},
    {message: 'A success message', type: 'SUCCESS'},
    {message: 'A success message', type: 'SUCCESS'},
    {message: 'A success message', type: 'SUCCESS'},
    {message: 'A success message', type: 'SUCCESS'},
    {message: 'A success message', type: 'SUCCESS'},
    {message: 'A success message', type: 'SUCCESS'},
    {message: 'A success message', type: 'SUCCESS'},
    {message: 'A success message', type: 'SUCCESS'},
    {message: 'A warning message', type: 'WARN'},
    {message: 'An error message', type: 'ERR'},
    {message: 'An info message', type: 'INFO'},
  ];

  selectedItem='0'
  posdates:any = [
    {id:'0',lastused:'1-1-2021'},
    {id:'1',lastused:'1-2-2021'},
  ]
  constructor() {
    this.logStream$ = timer(0, 1000).pipe(
      take(this.logs.length),
      map(i => this.logs[i])
    )!;
  }

  ngOnInit(): void {
  }

  onDateChange(event: any) {
    //console.log(event, `${event.getDate()}-${event.getMonth() + 1}-${event.getFullYear()}`)
    let formatedDate = `${event.getDate()}-${event.getMonth() + 1}-${event.getFullYear()}`;
  }
}
