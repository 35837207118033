<!--add -->
<nb-card class="model-full">
  <nb-card-header>
    <h5>Add User</h5>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="addUserForm">
      <div class="row form-row">
        <div class="col-12 mb-3">
          <label for="recipient-name" class="col-form-label">User Name</label>
          <input type="text" fullWidth nbInput formControlName="userName" class="input-style"
            placeholder="Enter User Name">
            <small *ngIf="submitted && !this.addUserForm.get('userName')?.valid" class="text-danger">username is required</small>
        </div>
        <div class="col-12 mb-3">
          <label for="recipient-name" class="col-form-label">Email</label>
          <input type="email" fullWidth nbInput formControlName="email" name="email" class="input-style"
            placeholder="Enter User Email">
            <small *ngIf="submitted && !this.addUserForm.get('email')?.valid" class="text-danger">email is required</small>
        </div>
        <div class="col-12 mb-3">
          <label for="recipient-name" class="col-form-label">Phone Number</label>
          <input type="number" fullWidth nbInput formControlName="phoneNumber" name="phone" class="input-style"
            placeholder="Enter User Number">
            <small *ngIf="submitted && !this.addUserForm.get('phoneNumber')?.valid" class="text-danger">phone is required</small>
        </div>
        <div class="col-12 mb-3">
          <label for="recipient-name" class="col-form-label">Role</label>
          <nb-select placeholder="Select User Role" fullWidth formControlName="role">
            <nb-option *ngFor="let role of roles" [value]="role.name">{{role.name}}</nb-option>
          </nb-select>
          <small *ngIf="submitted && !this.addUserForm.get('role')?.valid" class="text-danger">role is required</small>
        </div>
        <div class="col-12 mb-3">
          <label for="recipient-name" class="col-form-label"> Password </label>
          <nb-form-field>
            <input [type]="getInputType()" class="input-style" nbInput fullWidth placeholder="Enter User Password"
              formControlName="password">
            <button nbSuffix nbButton ghost (click)="toggleShowPassword()">
              <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'" pack="eva"
                [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
              </nb-icon>
            </button>
          </nb-form-field>
          <small>Password must be at least 8 and at max 100 characters long</small>
        </div>
        <div class="col-12 mb-3">
          <label for="recipient-name" class="col-form-label">Retype - Password </label>
          <nb-form-field>
            <input [type]="getInputType1()" class="input-style" nbInput fullWidth placeholder="Retype User Password" [(ngModel)]="confirmpassword" [ngModelOptions]="{standalone: true}">
            <button nbSuffix nbButton ghost (click)="toggleShowPassword1()">
              <nb-icon [icon]="showconfirmPassword ? 'eye-outline' : 'eye-off-2-outline'" pack="eva"
                [attr.aria-label]="showconfirmPassword ? 'hide password' : 'show password'">
              </nb-icon>
            </button>
          </nb-form-field>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="text-end">
    <button nbButton status="success" (click)="saveUser()">
      <nb-icon icon="save-outline"></nb-icon>Save
    </button>
    <button nbButton status="danger" (click)="cancel()">
      <nb-icon icon="close-outline"></nb-icon>Cancel
    </button>
  </nb-card-footer>
</nb-card>
<!--add-->
