import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { SmsnotificationsService } from '../../../services/smsnotifications.service';


@Component({
  selector: 'app-edit-sms-notification',
  templateUrl: './edit-sms-notification.component.html',
  styleUrls: ['./edit-sms-notification.component.scss']
})
export class EditSmsNotificationComponent implements OnInit {
  events:any = [ ]
  branchId:string="";
  notificationEventId:string="";
  NotificationTypeId:string="5EE4D2E6-770A-429F-BB04-5004F096C87E";
  text:string='';
  smsId:string=""
  editSmsForm: FormGroup = new FormGroup({
    text: new FormControl(''),
    notificationEventId: new FormControl(''),
    branchId: new FormControl('' ),  
    NotificationTypeId : new FormControl('' ),  
  });
  constructor(public smsService:SmsnotificationsService,
    private nbToastr: NbToastrService,
    protected dialogRef: NbDialogRef<EditSmsNotificationComponent>) { }

  ngOnInit(): void {
    console.log(this.smsId,"smsId")
      this.getSmsNotificationEvents()
      this.editSmsForm.setValue({
      text:this.text,
      notificationEventId: this.notificationEventId,
      branchId: this.branchId,
      NotificationTypeId : this.NotificationTypeId, 
    })
  }
  getSmsNotificationEvents() {
    this.smsService.getNotificationEvents().subscribe((res:any)=>{
      this.events = res;
    },
    (err) => {
      console.error({ err });
    });
  }
  eidtSms() {
    this.smsService.editSmsNotification(this.editSmsForm.value,this.smsId).subscribe((res:any)=>{
      console.log(this.editSmsForm.value,"editSmsForm");          
      if(res.isSucceeded == true) {
        this.onClose('success');
      } else {
        this.onClose(res.message);
      }
      this.nbToastr.show('Sms Edited successfully.', 'success', {
        status: 'success',
        hasIcon: false,
        icon: 'checkmark-outline'
      });
      this.smsService.refresh.emit();
      this.dialogRef.close();
    },
    (err) => {
      this.nbToastr.show('Cannot Edit Sms', 'error', {
        status: 'danger',
        hasIcon: false,
        icon: 'close-outline'
      });
    });
  }
  onClose(msg: string) {
    this.dialogRef.close(msg);
  }
  cancel() {
    this.dialogRef.close()
  }
}
