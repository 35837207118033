import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { User } from '../models/user.model';
import { Log } from '../models/logs.model';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  @Output() refresh = new EventEmitter<null>();
  @Output() logs = new EventEmitter<string>();

  constructor(private http: HttpClient) {}
  register(user: User) {
    return this.http.post(`${environment.baseURL}/Users/register`, user);
  }

  forgotPassword(user: User) {
    return this.http.post(`${environment.baseURL}/Users/forgot-password`, user);
  }

  resetPassword(user: User) {
    return this.http.post(`${environment.baseURL}/Users/reset-password`, user);
  }
  editUser(user: User, userId: string) {
    return this.http.post(`${environment.baseURL}/Users/${userId}/edit`, user);
  }

  deleteUser(userId: string) {
    return this.http.post(`${environment.baseURL}/Users/${userId}/delete`, {});
  }

  getUsersBranch(branchId: string) {
    return this.http
      .get<User>(`${environment.baseURL}/Branches/${branchId}/users`)
      .pipe(
        map((data: User) => {
          return data;
        }),
        catchError((error) => {
          return throwError('Something Is Wrong');
        })
      );
  }

  getUsersBusiness(businessId: string) {
    return this.http
      .get<User>(`${environment.baseURL}/Users/business?id=${businessId}`)
      .pipe(
        map((data: User) => {
          return data;
        }),
        catchError((error) => {
          return throwError('Something Is Wrong');
        })
      );
  }

  getUserRoles() {
    return this.http
      .get<any>(`${environment.baseURL}/Users/get-user-roles`)
      .pipe(
        map((data: any) => {
          return data.userRoles;
        }),
        catchError((error) => {
          return throwError('Something Is Wrong');
        })
      );
  }

  getUserLogs(userId?: string) {
    let url = userId ? `RecentUserlogs/${userId}` : `RecentUserlogs`;
    return this.http.get(`${environment.baseURL}/Users/${url}`).pipe(
      map((data: any) => {
        return data.userLogs;
      }),
      catchError((error) => {
        return throwError('Something Went Wrong');
      })
    );
  }

  getbranchLogs(branchId?: string, isAll?: boolean): Observable<Log[]> {
    let url = `${environment.baseURL}/Branches/RecentLogs`;
    if (isAll) {
      url = `${environment.baseURL}/Branches/RecentLogs/${branchId}/true`;
    }
    return this.http.get<Log[]>(url).pipe(
      map((data: any) => {
        // if(isAll) return  data.branchLogs[0].logs
        return data.branchLogs;
      }),
      catchError((error) => {
        return throwError('Something Went Wrong');
      })
    );
  }

  getRecents() {
    return this.http
      .get(`${environment.baseURL}/Branches/GetRecentlyChangedBranchs`)
      .pipe(
        map((data: any) => {
          return data.branchLogs;
        }),
        catchError((error) => {
          return throwError('Something Went Wrong');
        })
      );
  }
}
