import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-recent',
  templateUrl: './recent.component.html',
  styleUrls: ['./recent.component.scss']
})
export class RecentComponent implements OnInit {
  @Input() log: any;
  // @Input() branchName: string = "";
  constructor() { }

  ngOnInit(): void {
  }

}
