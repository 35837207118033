import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { StockService } from '../../services/stock.service';


@Component({
  selector: 'app-edit-qty',
  templateUrl: './edit-qty.component.html',
  styleUrls: ['./edit-qty.component.scss']
})
export class EditQtyComponent implements OnInit {
  @Input() data: any;
  @Input() type: string = '';
  qty: number = 0;
  unitOfMeasures: any;
  selectedUnitOfMeasure: any;
  isLoading: boolean = false;
  nbToastr: any;
  constructor(private stockService: StockService, private dialogRef: NbDialogRef<EditQtyComponent>, private toastrService: NbToastrService) { }

  ngOnInit(): void {
    this.onGetMeasures();
    if(this.data) {
      this.qty = this.data.quantity;
    }
  }

  onGetMeasures() {
    this.stockService.getUnitOfMeasures().subscribe(res => {
      //console.log(res)
      this.unitOfMeasures = res;
      // this.selectedUnitOfMeasure = this.unitOfMeasures.find(unit => {
      //   return unit.id ==
      // })
    })
  }

  onUpadateQty() {
    this.isLoading = true;
    if (this.type == 'increase') {
      //console.log(this.data, this.qty, this.selectedUnitOfMeasure)
      let item = {
        itemId: this.data.id,
        amount: this.qty,
        unitOfMeasureId: this.selectedUnitOfMeasure
      }

      this.stockService.increaseQty(item).subscribe(res => {
        this.dialogRef.close();
      }, err => {
        this.toastrService.show('Something Went Wrong.', 'failure', {
          status: 'danger',
          hasIcon: true,
          icon: 'close-outline',
        });
      });

      this.isLoading = false;

    } else {
      let item = {
        itemStockId: this.data.id,
        amount: this.qty,
      }

      this.stockService.decreaseQty(item).subscribe(res => {
        this.dialogRef.close();
      }, err => {
        this.toastrService.show('Something Went Wrong.', 'failure', {
          status: 'danger',
          hasIcon: true,
          icon: 'close-outline',
        });
      });

      this.isLoading = false;
    }
  }
}
