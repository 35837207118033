import { DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import * as XLSX from 'xlsx';

import { NbToastrService } from '@nebular/theme';
import {
  NgbCalendar,
  NgbDateStruct,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';

import { Transaction } from '../../models/transaction.model';
import { BuisnessService } from '../../services/buisness.service';
import { ItemsService } from '../../services/items.service';

@Component({
  selector: 'app-trxs-branch',
  templateUrl: './trxs-branch.component.html',
  styleUrls: ['./trxs-branch.component.scss']
})
export class TrxsBranchComponent implements OnInit {
  trx: any = {}
  payments: any = []
  devices: any = []
  selectedItem = '0'
  reportTypes = [
    { id: '0', type: 'Trx', checked: true }, { id: '1', type: 'Daily Sales' }, { id: '2', type: 'Daily Sales by item' },
    { id: '3', type: 'Daily Sub-Category Sales' }, { id: '4', type: 'Daily Cashier Sales' },
    { id: '5', type: 'Business Day Report' }, { id: '6', type: 'Monthly Staff Sales' }
  ]
  categories: any = []
  subcategories: any = []
  items: any = []
  trxOperations: Transaction[] = []
  trxOperationsList: Transaction[] = []
  branchID: any = "";
  exportedTrxs: any = []
  searchTerm: any;
  fileName = '';

  date = new Date()
  loading: boolean = false;
  param: any = {};
  showFromDate: boolean = true;
  showToDate: boolean = true;
  dateTo: any;
  dateFrom: any
  branchData: any;
  businessID: any;
  reportType: any = 'Trx';
  businessSales: any = []
  businessSalesList: any = []
  itemsSales: any = []
  itemsSalesList: any = []
  cashierSales: any = []
  cashierSalesList: any = []
  subCategoriesSales: any = []
  subCategoriesSalesList: any = []
  fromDate: any | NgbDateStruct;
  toDate: any | NgbDateStruct;
  page = 1;
  pageSize = 10;
  constructor(private calendar: NgbCalendar,
    private businessService: BuisnessService,
    private itemService: ItemsService,
    public datePipe: DatePipe,
    private nbToastr: NbToastrService,
    private modalService: NgbModal,
    private route: ActivatedRoute,) {
    let val = localStorage.getItem('searchVal');
    this.param = JSON.parse(val!);
    this.branchID = this.param.id;  
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }
  ngOnInit(): void {
    this.getCategoryByBranchId();
    this.getItems();
    this.getBranchById()
    this.getTrx()
  } 
 
  getBranchById() {
    this.businessService.getBranch(this.branchID).subscribe(
      (res: any) => {
        this.branchData = res.branch;
        this.businessID = this.branchData.businessId
      },

      (err) => {
        console.log(err)
      }
    );
  }
  getBusinessSales(reporttype: any) {
    console.log(reporttype, "reporttype")
    this.reportType = reporttype.type
    this.getTrx()
  }
  filterTrx() {
  }
  getTrx() {
    this.fileName = this.reportType + '.xlsx';
    if (this.showFromDate == true && typeof(this.fromDate) != 'string') {
      if (this.fromDate != undefined || typeof (this.fromDate) == 'object') {
        let fromDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day)
        this.dateFrom = this.datePipe.transform(fromDate, 'yyyy-MM-dd')
      }
    }
    if (this.showToDate == true && typeof(this.toDate) != 'string') {
      if (this.toDate != undefined || typeof (this.toDate) == 'object') {
        let toDate = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day)
        this.dateTo = this.datePipe.transform(toDate, 'yyyy-MM-dd')
      }
    }
    if(typeof(this.fromDate) == 'string' || typeof(this.toDate) == 'string') {
        this.dateFrom = this.fromDate;
        this.dateTo = this.toDate;
    }
    if (this.reportType == 'Trx') {
      this.loading = true;
      this.businessService.getTrxs(this.branchID, this.dateFrom, this.dateTo).subscribe((res: any) => {
        this.trxOperations = res.transactions;
        this.trxOperationsList = res.transactions;
        this.loading = false;

        if (this.trxOperations == null) {
          this.trxOperations = []
        }
        //pagination
        for (let i = 1; i <= this.trxOperations?.length; i++) {
          this.trxOperations.push();
        }
      }, err => console.log(err));
    }
    if (this.reportType == "Daily Sales") {
      this.loading = true;
      this.businessService.getBusinessSales(this.businessID, this.dateFrom, this.dateTo).subscribe((res: any) => {
        this.businessSales = res.transactions;
        this.businessSalesList = res.transactions
        console.log(this.businessSales, "businessSales")
        this.loading = false;
        if (this.businessSales == null) {
          this.businessSales = []
        }
      }, err => console.log(err));
    }
    if (this.reportType == "Daily Sales by item") {
      this.loading = true;
      this.businessService.getItemSales(this.businessID, this.dateFrom, this.dateTo).subscribe((res: any) => {
        this.itemsSales = res.items;
        this.itemsSalesList = res.items
        console.log(this.itemsSales, "itemSales")
        this.loading = false;
        if (this.itemsSales == null) {
          this.itemsSales = []
        }
      }, err => console.log(err));
    }
    if (this.reportType == "Daily Sub-Category Sales") {
      this.loading = true;
      this.businessService.getsubCategorySales(this.businessID, this.dateFrom, this.dateTo).subscribe((res: any) => {
        this.subCategoriesSales = res.subCategorySales;
        this.subCategoriesSalesList = res.subCategorySales
        console.log(this.subCategoriesSales, "subCategorySales")
        this.loading = false;
        if (this.subCategoriesSales == null) {
          this.subCategoriesSales = []
        }
      }, err => console.log(err));
    }
    if (this.reportType == "Daily Cashier Sales") {
      this.loading = true;
      this.businessService.getCashierSales(this.businessID, this.dateFrom, this.dateTo).subscribe((res: any) => {
        this.cashierSales = res.dailyCashierSales;
        this.cashierSalesList = res.dailyCashierSales
        console.log(this.cashierSales, "dailyCashierSales")
        this.loading = false;
        if (this.cashierSales == null) {
          this.cashierSales = []
        }
      }, err => console.log(err));
    }
  }

  getCategoryByBranchId() {
    this.loading = true;
    this.itemService.getCategories(this.branchID).subscribe((res: any) => {
      this.categories = res;
      this.loading = false;
    }, err => console.log(err));
  }
  selectCategory($event: any) {
    this.getSubCategory($event)
  }
  getSubCategory(categoryId: number) {
    this.loading = true;
    this.itemService.getSubCategories(categoryId, this.branchID).subscribe((res: any) => {
      this.subcategories = res;
      this.loading = false
    }, err => console.log(err));
  }

  getItems() {
    this.itemService.getItems(this.branchID).subscribe((res: any) => {
      this.items = res;
    }, err => console.log(err));
  }
  getDevices() {
    this.businessService.getDevice().subscribe((res: any) => {
      this.devices = res
      console.log(res, "devices")
    },
      (err) => {
        console.error({ err });
      })
  }
  getPayment() {
    this.businessService.getPamentMode().subscribe((res: any) => {
      this.payments = res
      console.log(res, "payments")
    },
      (err) => {
        console.error({ err });
      })
  }
  onSearch(event: any) {
    this.searchTerm = event.target.value;
    if (this.searchTerm == "") {
      this.trxOperations = this.trxOperationsList;
      this.businessSales = this.businessSalesList
      this.itemsSales = this.itemsSalesList
    } else {
      if (this.reportType == 'Trx') {
        this.trxOperations = this.trxOperationsList.filter((el: any) =>
          el.transactionMasterId == this.searchTerm || el.voucherNO == this.searchTerm
          || el.transactionDateTime == this.searchTerm || el.terminalId == this.searchTerm 
          || el.cashier == this.searchTerm || el.totalAmount == this.searchTerm ||
          el.paymentModeId == this.searchTerm
          || el.totalTaxAmount == this.searchTerm || el.couponCode == this.searchTerm
          || el.discountValue == this.searchTerm || el.totalAfterDiscount == this.searchTerm
          || el.totalAfterTaxAmount == this.searchTerm
        );
      }

      if (this.reportType == 'Daily Sales') {
        this.businessSales = this.businessSalesList.filter((el: any) =>
          el.quantity == this.searchTerm || el.faceAmount == this.searchTerm
          || el.total == this.searchTerm
        );
      }
    };

    if (this.reportType == 'Daily Sales by item') {
      this.itemsSales = this.itemsSalesList.filter((el: any) =>
        el.itemName == this.searchTerm || el.subCateogryName == this.searchTerm ||
        el.quantity == this.searchTerm || el.faceAmount == this.searchTerm
        || el.total == this.searchTerm
      );
    }

    if (this.reportType == 'Daily Sub-Category Sales') {
      this.subCategoriesSales = this.subCategoriesSalesList.filter((el: any) =>
        el.subCateogryName == this.searchTerm ||
        el.taxAmount == this.searchTerm  || el.total == this.searchTerm
      );
    }

    if (this.reportType == 'Daily Cashier Sales') {
      this.cashierSales = this.cashierSalesList.filter((el: any) =>
        el.cahierName == this.searchTerm ||
         el.total == this.searchTerm
      );
    }
  }

  exportExcel(): void {
    let ws: XLSX.WorkSheet;
    /* pass here the table id */
    if (this.reportType == 'Trx') {
      ws = XLSX.utils.json_to_sheet(this.trxOperations);
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
    }
    /* pass here the table id */
    if (this.reportType == 'Daily Sales') {
      ws = XLSX.utils.json_to_sheet(this.businessSales);
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
    }
    /* pass here the table id */
    if (this.reportType == 'Daily Sales by item') {
      ws = XLSX.utils.json_to_sheet(this.itemsSales);
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
    }
    /* pass here the table id */
    if (this.reportType == 'Daily Sub-Category Sales') {
      ws = XLSX.utils.json_to_sheet(this.subCategoriesSales);
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
    }
    /* pass here the table id */
    if (this.reportType == 'Daily Cashier Sales') {
      ws = XLSX.utils.json_to_sheet(this.cashierSales);
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
    }

  }
  DisableTrx(id: any) {
    let status: boolean = true;
    this.loading = true;
    this.businessService.disableTrx({ transactionId: id, isDeleted: status }).subscribe(res => {
      this.nbToastr.show('disabled successfully!', 'disabled', {
        status: 'success'
      });

      this.getTrx();
      this.loading = false;
    }, err => {
      console.log(err.errors[0])
      this.nbToastr.show(err.message, 'Erorr', {
        status: 'danger'
      });
    }
    )
  }
  head = [
    { title: "No", dataKey: "transactionMasterId" },
    { title: "Date", dataKey: "transactionDateTime" },
    { title: "Receipt Number", dataKey: "voucherNO" },
    { title: "Coupon Code", dataKey: "couponCode" },
    { title: "Discount Type", dataKey: "discountType" },
    { title: "Discount Value", dataKey: "discountValue" },
    { title: "Total", dataKey: "totalAmount" },
    { title: "Tax", dataKey: "totalTaxAmount" },
    { title: "Discount", dataKey: "totalDiscount" },
    // { title: "Total", dataKey: "totalAfterTaxAmount" },
    // { title: "Total After Discount", dataKey: "totalAfterDiscount" },
    { title: "Cashier", dataKey: "cashier" },
  ]
  businessSaleshead = [
    // {title:"Item":dataKey:"Item"},
    { title: "Date", dataKey: "date" },
    { title: "Quantity", dataKey: "quantity" },
    { title: "Face Amount", dataKey: "faceAmount" },
    { title: "Total", dataKey: "total" },
  ]
  itemSaleshead = [
    { title: "Item", dataKey: "itemName" },
    { title: "SubCategory", dataKey: "subCateogryName" },
    { title: "Quantity", dataKey: "quantity" },
    { title: "Face Amount", dataKey: "faceAmount" },
    { title: "Total", dataKey: "total" },
  ]
  subCategorySaleshead = [
    // { title: "Item", dataKey: "itemName" },
    { title: "SubCategory", dataKey: "subCateogryName" },
    { title: "Tax Amount", dataKey: "taxAmount" },
    { title: "Total", dataKey: "total" },
  ]
  cashierSaleshead = [
    { title: "Cashier", dataKey: "cahierName" },
    { title: "Total", dataKey: "total" },
  ]
  exportPdf() {
    if (this.reportType == "Trx") {
      this.exportedTrxs = this.trxOperations
      let doc = new jsPDF();
      autoTable(doc, {
        columns: this.head,
        body: this.exportedTrxs
      });
      doc.save('TrxReports.pdf');
    }
    if (this.reportType == "Daily Sales") {
      this.exportedTrxs = this.businessSales
      let doc = new jsPDF();
      autoTable(doc, {
        columns: this.businessSaleshead,
        body: this.exportedTrxs
      });
      doc.save('BusinessSalesReports.pdf');
    }
    if (this.reportType == "Daily Sales by item") {
      this.exportedTrxs = this.itemsSales
      let doc = new jsPDF();
      autoTable(doc, {
        columns: this.itemSaleshead,
        body: this.exportedTrxs
      });
      doc.save('ItemSalesReports.pdf');
    }
    if (this.reportType == "Daily Sub-Category Sales") {
      this.exportedTrxs = this.subCategoriesSales
      let doc = new jsPDF();
      autoTable(doc, {
        columns: this.subCategorySaleshead,
        body: this.exportedTrxs
      });
      doc.save('SubCategorySalesReports.pdf');
    }
    if (this.reportType == "Daily Cashier Sales") {
      this.exportedTrxs = this.cashierSales
      let doc = new jsPDF();
      autoTable(doc, {
        columns: this.cashierSaleshead,
        body: this.exportedTrxs
      });
      doc.save('CashierSalesReports.pdf');
    }
  }
}
