<div class="row">
  <div class="col-12">
      <nb-card>
          <nb-card-header>
              <!-- <div class="search">
                  <nb-form-field>
                      <nb-icon nbPrefix icon="search-outline" status="warning" pack="eva"></nb-icon>
                      <input type="text" status="basic" nbInput placeholder="search by business name"
                          (input)="onSearch($event)" autofocus>
                  </nb-form-field>
              </div> -->
              <div class="d-flex align-items-center justify-content-end">
              <button status="success" nbButton hero (click)="onAddCustomer()">
                  <nb-icon icon="plus-outline"></nb-icon> New Customer
              </button>
            </div>
          </nb-card-header>
          <nb-card-body>
              <div class="table-responsive">
                  <table class="table table-bordered" [nbSpinner]="loading" nbSpinnerStatus="basic">
                      <thead>
                          <tr>
                              <th scope="col">#</th>
                              <th scope="col">Customer Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Phone No.</th>
                              <th scope="col">Customer Address</th>
                              <th>Actions</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let customer of customers; let i = index ">
                              <th scope="row">{{ i + 1 }}</th>
                              <td>{{ customer.fullname }}</td>
                              <td>{{ customer.email }}</td>
                              <td>{{ customer.phoneNumber }}</td>
                              <td>{{ customer?.defaultAddress?.location? customer?.defaultAddress?.location : '-'}}</td>
                              <td>
                                  <button placement="top" ngbTooltip="Edit"
                                      class="mx-md-2 my-2 my-md-0" nbButton status="warning" size="small" shape="round" hero
                                      (click)="onEdit(customer)">
                                      <nb-icon icon="edit-outline"></nb-icon>
                                  </button>
                                  <button class="m-0" placement="right" ngbTooltip="Delete Customer"  nbButton
                                      status="danger" size="small" shape="round"
                                      hero (click)="onDelete(customer.id)">
                                      <nb-icon icon="trash-2-outline"></nb-icon>
                                  </button>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                  <!-- <nav aria-label="table pagination">
                      <ul class="pagination justify-content-end">
                          <li class="page-item" [ngClass]="{ 'disabled': prevPage == null }"  (click)="onPrevious()">
                              <span class="page-link">Previous</span>
                          </li>
                          <li class="page-item" [ngClass]="{ 'active': pageNumber == page }" *ngFor="let page of pages" (click)="onPageChange(page)"><span class="page-link">{{ page }}</span></li>
                          <li class="page-item" [ngClass]="{ 'disabled': !nextPage }" (click)="onNext()">
                              <span class="page-link">Next</span>
                          </li>
                      </ul>
                  </nav> -->
              </div>
              <div class="py-3 text-center" *ngIf="customers.length == 0">
                <p>No available tables to show</p>
              </div>
          </nb-card-body>
      </nb-card>
  </div>
</div>
