import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';


import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { TrxDetailsComponent } from '../trx-details/trx-details.component';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { claimedTrx } from '../../models/transaction.model';
import { BuisnessService } from '../../services/buisness.service';

@Component({
  selector: 'app-claims-branch',
  templateUrl: './claims-branch.component.html',
  styleUrls: ['./claims-branch.component.scss']
})
export class ClaimsBranchComponent implements OnInit {
  selectedItem = '0'
  claim: any = {};
  claims: claimedTrx[] = []
  claimList: claimedTrx[] = []
  searchTerm: any;
  loading: boolean = false;
  searchData: any = {
    claimSource: '',
    claimStatus: ''
  }
  fileName = 'ClaimSheet.xlsx';

  fromDate!: any | NgbDateStruct;
  toDate!: any | NgbDateStruct;
  showFromDate: boolean = true;
  showToDate: boolean = true;
  dateTo: any;
  dateFrom: any
  param: any = {};
  branchID: any = "";
  constructor(
    private dialogService: NbDialogService,
    private businessService: BuisnessService,
    private nbToastr: NbToastrService,
    private modalService: NgbModal, private calendar: NgbCalendar,
    public datePipe: DatePipe,) {
    let val = localStorage.getItem('searchVal');
    this.param = JSON.parse(val!);
    this.branchID = this.param.id;
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit(): void {
    // this.getClaims()
  }
  onSearch(event: any) {
    this.searchTerm = event.target.value;
    if (this.searchTerm == "") {
      this.claims = this.claimList;
    } else {
      this.claims = this.claimList.filter((el: any) =>
        el.voucherNo == this.searchTerm || el.transactioDate == this.searchTerm
        || el.claimDate == this.searchTerm || el.claimedBy == this.searchTerm
        || el.claimSource == this.searchTerm || el.claimStatus == this.searchTerm
      );
    };
  }
  getBoolean(value: any) {
    switch (value) {
      case true:
      case "true":
      case 1:
      case "1":
      case "on":
      case "yes":
        return true;
      default:
        return '';
    }
  }

  showTrx(id: any) {
    const modalRef = this.modalService.open(TrxDetailsComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.trxNo = id;
  }
  getClaims() {
    this.loading = true;
    if (this.showFromDate == true) {
      if (this.fromDate != undefined || typeof (this.fromDate) == 'object') {
        let fromDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day)
        this.dateFrom = this.datePipe.transform(fromDate, 'yyyy-MM-dd')
      }
    }
    if (this.showToDate == true) {
      if (this.toDate != undefined || typeof (this.toDate) == 'object') {
        let toDate = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day)
        this.dateTo = this.datePipe.transform(toDate, 'yyyy-MM-dd')
      }
    }
    this.businessService.getClaimTrxs(this.branchID, this.dateFrom, this.dateTo).subscribe((res: any) => {
      this.claims = res.claimsReport
      this.claimList = res.claimsReport
      this.filterClaim();
      this.loading = false;

      if (this.claims == null) {
        this.claims = []
      }
    },
      (err) => {
        console.error({ err });
      })
  }
  filterClaim() {
    if (this.searchData.claimSource == '' && this.searchData.claimStatus != 1 && this.searchData.claimStatus != 2 && this.searchData.claimStatus != 3) {
      this.claims = this.claimList;
      console.log(this.searchData.claimStatus, this.searchData.claimSource, 'search is NULL')
    } else {
      if (this.searchData.claimStatus != '' && this.searchData.claimSource != '') {
        this.claims = this.claimList.filter((claim) => {
          return claim.claimStatus == this.searchData.claimStatus && claim.claimSource == this.searchData.claimSource
        })
        console.log(this.claims, this.searchData, "claimSource")

      }
      else if (this.searchData.claimSource != '') {
        this.claims = this.claimList.filter((claim) => {
          return claim.claimSource == this.searchData.claimSource
        })
      } else if (this.searchData.claimStatus != '') {
        this.claims = this.claimList.filter((claim) => {
          return claim.claimStatus === this.searchData.claimStatus
        })
      }
      else if (this.searchData.claimSource != '' || this.searchData.claimStatus != '') {
        this.claims = this.claimList.filter((claim) => {
          return claim.claimSource == this.searchData.claimSource || claim.claimStatus == this.searchData.claimStatus
        })
        console.log(this.claims, this.searchData, "claimStatus")
      }
    }
  }
  clearFilterData() {
    this.searchData.claimSource = ""
    this.searchData.claimStatus = ""
    this.claims = this.claimList
  }
  exportExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.claims);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'claims.xlsx');
  }
  exportPdf() {
    let doc = new jsPDF();
    autoTable(doc, { html: '#claimTable' }); // typescript compile time error
    doc.save('Claims.pdf');
  }
  approve(id: any) {
    console.log(id,)
    let status = 1;
    this.loading = true;
    this.businessService.changeClaimStatus({ claimId: id, status: status }).subscribe(res => {
      this.nbToastr.show(' Approved successfully!', 'Approved', {
        status: 'success'
      });
      this.getClaims();
      this.loading = false;
    }, err => {
      console.log(err.errors[0])
      this.nbToastr.show(err.message, 'Erorr', {
        status: 'danger'
      });
    }
    )
  }
  reject(id: any) {
    let status = 2;
    this.loading = true;
    this.businessService.changeClaimStatus({ claimId: id, status: status }).subscribe(res => {
      this.nbToastr.show(' Rejected successfully!', 'Rejected', {
        status: 'success'
      });
      this.getClaims();
      this.loading = false;
    }, err => {
      console.log(err.errors[0])
      this.nbToastr.show(err.message, 'Erorr', {
        status: 'danger'
      });
    }
    )
  }
}
