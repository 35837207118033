import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';

import { UserService } from '../../services/user.service';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserBranchComponent implements OnInit {

  constructor(public userService: UserService,
    private nbToastr: NbToastrService,
    protected dialogRef: NbDialogRef<AddUserBranchComponent>) { }
  branchId: any;
  userName: string = '';
  email: string = '';
  role: string = '';
  password: string = '';
  confirmpassword: string = ''
  userList: User[] = [];
  phoneNumber: string = ''
  submitted: boolean = false;
  addUserForm: FormGroup = new FormGroup({
    userName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    role: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    phoneNumber: new FormControl(''),
    // businessId: new FormControl(''),
    branchId: new FormControl('')
  });

  roles: any = [];
  showPassword = false;
  showconfirmPassword = false;

  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    else {
      return 'password';
    }
  }
  getInputType1() {
    if (this.showconfirmPassword) {
      return 'text';
    }
    else {
      return 'password';
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  toggleShowPassword1() {
    this.showconfirmPassword = !this.showconfirmPassword;
  }
  ngOnInit(): void {
    console.log(this.branchId)
    this.addUserForm.patchValue({
      userName: this.userName,
      email: this.email,
      role: this.role,
      password: this.password,
      phoneNumber: this.phoneNumber,
      // businessId: localStorage.getItem('businessId'),
      branchId: this.branchId
    })
    this.getUsers();
    this.userService.refresh.subscribe(res => this.getUsers());
    this.getUserRoles()
  }
  getUsers() {
    this.userService.getUsersBranch(this.branchId).subscribe((res: any) => {
      this.userList = res;

    },
      (err) => {
        console.error({ err });
      });
  }
  getUserRoles() {
    this.userService.getUserRoles().subscribe((res: any) => {
      this.roles = res.filter((r: any) => {
        return r.name == "branch"
      });

      console.log(this.roles, "roles")
    },
      (err) => {
        console.error({ err });
      });
  }
  saveUser() {
    // console.log(this.addUserForm.value)
    if (this.addUserForm.value.password === this.confirmpassword) {
      // let user = this.addUserForm.value;
      this.userService.register(this.addUserForm.value).subscribe((res: any) => {
        console.log(this.addUserForm.value, "addUser");
        if (res.isSucceeded == true) {
          this.getUsers()
          this.userService.refresh.emit();
          this.dialogRef.close();
          this.nbToastr.show('User Added successfully.', 'success', {
            status: 'success',
            hasIcon: false,
            icon: 'checkmark-outline'
          });
        }
      },
        (errors) => {
          //console.log(errors, "errors")
          this.submitted = true;
          if (errors.error.StatusCode == 500) {
            this.nbToastr.show(errors.error.Message, 'error', {
              status: 'danger',
              hasIcon: true,
              icon: 'close-outline',
              toastClass: 'toatser-index'
            });
          } else {
            let err = '';
            if (errors.error?.errors && Array.isArray(errors.error?.errors)) {
              err = errors.error?.errors[0]?.description ? errors.error?.errors[0].description : errors.error?.errors[0];
            } else if (errors.error?.errors) {
              for (const key in errors.error?.errors) {
                err = errors.error?.errors[key][0];
              }
            }
            else {
              err = errors.error.message
            }


            this.nbToastr.show(err, 'error', {
              status: 'danger',
              hasIcon: true,
              icon: 'close-outline',
              toastClass: 'toatser-index'
            });
          }
          // if(errors.statusCode == 400) {
          //   this.nbToastr.show(errors.errors[0].description, 'error', {
          //     status: 'danger',
          //     hasIcon: false,
          //     icon: 'close-outline'
          //   });
          // }
          if (errors.error.StatusCode == 500) {
            this.nbToastr.show(errors.error.Message, 'error', {
              status: 'danger',
              hasIcon: false,
              icon: 'close-outline'
            });
          }
          // if (this.addUserForm.value.userName == "") {
          //   this.nbToastr.show(errors.error.errors.UserName[0], 'error', {
          //     status: 'danger',
          //     hasIcon: false,
          //     icon: 'close-outline',
          //     toastClass: 'toatser-index'
          //   });
          // }
          // if (this.addUserForm.value.email == "") {
          //   this.nbToastr.show(errors.error.errors.Email[0], 'error', {
          //     status: 'danger',
          //     hasIcon: false,
          //     icon: 'close-outline',
          //     toastClass: 'toatser-index'
          //   });
          // }
          // if (this.addUserForm.value.password.search(/[A-Z]/)) {
          //   this.nbToastr.show(errors.error.errors[0].description, 'error', {
          //     status: 'danger',
          //     hasIcon: false,
          //     icon: 'close-outline',
          //     toastClass: 'toatser-index'
          //   });
          // }
          // if (this.addUserForm.value.password.search(/[0-9]/) < 0) {
          //   this.nbToastr.show(errors.error.errors.Password[0], 'error', {
          //     status: 'danger',
          //     hasIcon: false,
          //     icon: 'close-outline',
          //     toastClass: 'toatser-index'
          //   });
          // }
          // if (this.addUserForm.get('password')!.value.length < 8) {
          //   this.nbToastr.show(errors.error.errors.Password[0], 'error', {
          //     status: 'danger',
          //     hasIcon: false,
          //     icon: 'close-outline',
          //     toastClass: 'toatser-index'
          //   });
          // }
          // if (this.addUserForm.value.role == "") {
          //   this.nbToastr.show(errors.error.errors.Role[0], 'error', {
          //     status: 'danger',
          //     hasIcon: false,
          //     icon: 'close-outline',
          //     toastClass: 'toatser-index'
          //   });
          // }
        })
    }
    else if (this.addUserForm.value.password !== this.confirmpassword) {
      this.nbToastr.show("password and confirm password  doesn't match", 'error', {
        status: 'danger',
        hasIcon: false,
        icon: 'close-outline'
      });
    }
  }
  onClose(msg: string) {
    this.dialogRef.close(msg);
  }
  cancel() {
    this.dialogRef.close();
  }

}
