import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { UserService } from '../../../services/user.service';


@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss']
})
export class DeleteUserBranchComponent implements OnInit {
  userId:any;
  constructor(public userService:UserService,
    private nbToaster: NbToastrService,
    protected dialogRef: NbDialogRef<DeleteUserBranchComponent>, ) { }

  ngOnInit(): void {
  }

  deleteUser() {
  this.userService.deleteUser(this.userId).subscribe((res:any)=>{
    if(res.isSucceeded == true) {
      this.onClose('success');
    } else {
      this.onClose(res.message);
    }
    this.nbToaster.show('User Deleted successfully.', 'success', {
      status: 'success',
      hasIcon: false,
      icon: 'checkmark-outline'
    });
    this.userService.refresh.emit();
    this.dialogRef.close();    console.log(res);
  },
  (err) => {
    console.log(err)
    this.nbToaster.show('Cannot Delete User', 'error', {
      status: 'danger',
      hasIcon: false,
      icon: 'close-outline'
    });  
  });
}
onClose(msg: string) {
  this.dialogRef.close(msg);
}
cancel(){
  this.dialogRef.close()
}
}
