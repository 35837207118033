<h6>{{ heading }}</h6>
<div class="dropzone mt-3">
    <ngx-dropzone (change)="onSelect($event)">
        <ngx-dropzone-label>{{ text }}</ngx-dropzone-label>
        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
        </ngx-dropzone-preview>
    </ngx-dropzone>
    <!-- <div class="text-right mt-3">
        <button nbButton hero status="success" (click)="onUpload()">Upload</button>
    </div> -->
</div>

