import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BuisnessService } from '../../services/buisness.service';


@Component({
  selector: 'app-business-tab',
  templateUrl: './business-tab.component.html',
  styleUrls: ['./business-tab.component.scss'],
  styles: [`
    :host nb-tab {
      padding: 1.25rem;
    }
  `],
})
export class BusinessTabComponent implements OnInit {
  load: boolean = false;
  searchVal: any;
  constructor(private route: ActivatedRoute, private businessService: BuisnessService) { }

  ngOnInit(): void {
    this.searchVal = localStorage.getItem('searchVal');
    this.searchVal = JSON.parse(this.searchVal);
  }

}
