import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable, of, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { posResponse } from '../../models/pos.model';
import { PosService } from '../../services/pos.service';
import { SalesmanService } from '../../services/salesman.service';


@Component({
  selector: 'app-add-pos',
  templateUrl: './add-pos.component.html',
  styleUrls: ['./add-pos.component.scss']
})
export class AddBranchPosComponent implements OnInit {
  @Input() name: string = "";
  @Input() id: string = "";

  posType: string = "Mp3";
  selected: string = 'wifi';
  POSs: any[] = [];
  salesMen: any[] = [];
  success: posResponse = {
    isSucceeded: false,
    message: "",
    statusCode: 0,
    terminalId: 0
  };

  alert: any = {
    alert: false,
    message: ""
  };

  options: any[] = [];



  addPosForm: FormGroup = new FormGroup({
    sim: new FormControl(''),
    branchId: new FormControl({ value: '', disabled: true }),
    posId: new FormControl(0),
    notes: new FormControl(''),
    salesManId: new FormControl(0),
  });


  constructor(private posService: PosService, 
    private userService: UserService,
     private salesmanService: SalesmanService,
      public activeModal: NgbActiveModal) {
  
  }

  ngOnInit(): void {
    //  console.log(this.name)
    this.addPosForm.patchValue({
      branchId: this.id
    });

    this.onGetAllPos();
    this.onGetSalesmen();
  }

  search: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term => term === '' ? []
        : this.options.filter((v: any) => v.sn.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  formatter = (x: { sn: string }) => x.sn;

  onAssign() {
    let modifiedPosId = Number(this.addPosForm.get('posId')?.value);
    this.addPosForm.patchValue({
      posId: modifiedPosId
    });

    this.posService.assignPos(this.addPosForm.getRawValue()).subscribe(res => {
      this.success = res;
      if(res.isSucceeded == true) {
        this.onClose('success');
      } else {
        this.onClose(res.message);
      }
      this.addPosForm.reset();
    }, err => {
      // console.log(err.error.errors)
      if (err.status === 0) {
        console.log(err.status)
        this.alert = {
          alert: true,
          message: "please check your connection and try again."
        }
      } else if(err.error.errors) {
        let errors: any = [];
        let msg = "invalid ";

        Object.keys(err.error.errors).forEach((key) => {
          errors.push(key)
        });

        if (errors.length > 1) {
          errors.forEach((err: any, index: any) => {
            if (index == errors.length - 1) {
              msg += ' ' + err;
            }
            else {
              msg += err + ', ';
            }

          })
        } else {
          msg += errors[0]
        }

        this.alert = {
          alert: true,
          message: msg
        }
      } else if (err.status == 500) {
        this.alert = {
          alert: true,
          message: err.statusText
        }
      }

      setTimeout(() => {
        this.alert.alert = false;
      }, 2000);
    })
  }

  onClose(msg: string) {
    this.activeModal.close(msg);
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  onGetAllPos() {
    this.posService.getAllPos(false).subscribe(res => {
      //console.log(res)
      this.POSs = res.value;
      if (this.POSs !== null) {
        this.POSs.forEach(pos => {
          this.options.push({ sn: pos.sn, posType: pos.posType, posStatus: pos.posStatus })
        });
      }


    }, err => {
      console.log(err)
    })
  }

  onGetSalesmen() {
    this.salesmanService.getSalesman().subscribe((res:any) => {
      //console.log(res);
      this.salesMen = res;
    }, (err:any) => console.log(err));
  }

  onPosSelectionChange(event: any) {
    let sn = event.target.value;
    let pos = this.POSs.filter(pos => {
      return pos.sn == sn;
    });

    this.posType = pos[0].posType;
    this.addPosForm.patchValue({
      posId: pos[0].posId
    });
  }

}
