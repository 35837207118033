import { Component, OnInit } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { AddBusinessFormComponent } from './add-business-form/add-business-form.component';
import { RenameBusinessFormComponent } from './rename-business-form/rename-business-form.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';
import { Buisness } from '../../models/buisness.model';
import { BuisnessService } from '../../services/buisness.service';


@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit {
  businessesList: Buisness[] = [];
  businesses: Buisness[] = [];
  searchTerm: string = "";
  pages: number[] = [];
  pageNumber: number = 1;
  // nextPage: string = "";
  // prevPage!: any;
  loading: boolean = false;
  paginationMetadata: any = {};
  totalPages: any;

  nextPage: boolean = true;
  prevPage: boolean = false;
  constructor(
    private businessService: BuisnessService,
     private userService: UserService, 
     public modalService: NgbModal, 
     private nbToastr: NbToastrService
    ) { }

  ngOnInit(): void {
    this.getBusinessesWithFilter(1);
    this.businessService.refresh.subscribe(res => {
      this.ngOnInit();
    })
  }

  getBusinesses() {
    this.loading = true;
    this.pages = [];
    this.businessService.getBuisnesses(true).subscribe(res => {
      //console.log(res)
      this.businessesList = res.data;
      this.paginationMetadata = res.paginationMetadata;
      this.businesses = res.data;
      this.loading = false;

      this.pageNumber = this.paginationMetadata.currentPage;
      this.nextPage = res.nextPage;
      this.prevPage = res.previousPage;
      this.totalPages = this.paginationMetadata.totalPages;

      //pagination
      for (let i = 1; i <= this.businessesList?.length; i++) {
        this.businessesList.push();
      }

      for (let i = 1; i <= this.totalPages; i++) {
        this.pages.push(i);
      }

      // console.log(this.totalPages, this.pages)


    }, err => console.log(err));
  }

  getBusinessesWithFilter(page: number, searchKey?: string) {
    this.loading = true;
    this.businessService.getBuisnessesWithFilter(page, searchKey ? searchKey : '').subscribe(res => {
      console.log(res.value);
      this.businessesList = res.value;
      this.loading = false;

      //pagination
      let pagination = Array.from(
        { length: res.totalPageCount },
        (_, i) => i + 1
      );
      let start =
        res.currentPageNumber >= 4 ? res.currentPageNumber - 3 : 0;
      let end =
        res.currentPageNumber < res.totalPageCount
          ? res.currentPageNumber + 3
          : res.currentPageNumber;

      let pagesToShow = pagination.slice(start, end);
      this.pages = pagesToShow;
      this.pageNumber = res.currentPageNumber;
      //  console.log({pagesToShow})
      if (res.currentPageNumber == pagination.length) {
        this.nextPage = false;
      }

      if (res.currentPageNumber > 1) {
        this.prevPage = true;
      } else {
        this.prevPage = false;
      }
    })
  }

  // onPageChange(page: number) {
  //   //console.log(page)
  //   this.loading = true;
  //   this.businessService.pageChange(`http://178.157.14.40:5555/api/v1/Businesses?IsPaginated=True&Page=${page}&ItemsPerPage=10`).subscribe(res => {
  //     this.businessesList = res.data;
  //     this.businesses = res.data;
  //     this.paginationMetadata = res.paginationMetadata;
  //     this.pageNumber = this.paginationMetadata.currentPage;
  //     this.nextPage = res.nextPage;
  //     this.prevPage = res.previousPage;
  //     //console.log(this.prevPage, this.nextPage)
  //     this.loading = false;
  //   }, err => { this.loading = false; this.nbToastr.danger('no business found', 'erorr') });
  // }

  // onNext() {
  //   if (this.nextPage) {
  //     this.loading = true;
  //     // console.log(this.nextPage)
  //     this.businessService.pageChange(this.nextPage).subscribe(res => {
  //       console.log(res)
  //       this.businessesList = res.data;
  //       this.businesses = res.data;
  //       this.paginationMetadata = res.paginationMetadata;
  //       this.pageNumber = this.paginationMetadata.currentPage;
  //       this.nextPage = res.nextPage;
  //       this.prevPage = res.previousPage;
  //       this.loading = false;
  //     }, err => console.log(err));
  //   }
  // }

  // onPrevious() {
  //   if (this.prevPage) {
  //     this.loading = true;
  //     this.businessService.pageChange(this.prevPage).subscribe(res => {
  //       //console.log(res)
  //       this.businessesList = res.data;
  //       this.businesses = res.data;
  //       this.paginationMetadata = res.paginationMetadata;
  //       this.pageNumber = this.paginationMetadata.currentPage;
  //       this.nextPage = res.nextPage;
  //       this.prevPage = res.previousPage;
  //       this.loading = false;
  //     }, err => console.log(err));
  //   }

  // }


  onAddBusiness() {
    const modalRef = this.modalService.open(AddBusinessFormComponent, { centered: true });
    modalRef.closed.subscribe(res => {
      if (res == 'success') {
        this.getBusinessesWithFilter(this.pageNumber);
        this.userService.logs.emit('add new business log');
      }
    })
  }

  onRename(name: string, email: string, phone: string, id: string) {
    const modalRef = this.modalService.open(RenameBusinessFormComponent, { centered: true });
    modalRef.componentInstance.name = name;
    modalRef.componentInstance.email = email;
    modalRef.componentInstance.phone = phone;
    modalRef.componentInstance.id = id;

    modalRef.closed.subscribe(res => {
      if (res == 'success') {
        this.getBusinessesWithFilter(this.pageNumber);
        this.userService.logs.emit('rename business log');
      } else {
        this.nbToastr.show(res, 'erorr', {
          status: 'danger',
          hasIcon: true,
          icon: 'close-outline'
        });
      }
    })
  }

  onDisable(id: string, name: string, status: boolean) {
    this.loading = true;
    this.businessService.disableBuisness({ businessId: id, status: !status }).subscribe(res => {
      this.nbToastr.show(name + ' disabled successfully!', 'disabled', {
        status: 'success',
        hasIcon: true,
        icon: 'checkmark-outline'
      });

      this.getBusinessesWithFilter(this.pageNumber);
      this.userService.logs.emit('disconnect business log');
      this.loading = false;
    }, err => {
      console.log(err.errors[0])
      this.nbToastr.show(err.message, 'Erorr', {
        status: 'danger',
        hasIcon: true,
        icon: 'close-outline'
      });
    }
    )
  }

  //search
  // onSearch(event: any) {
  //   this.businessesList = this.businesses;
  //   this.searchTerm = event.target.value.toLowerCase();
  //   if (this.searchTerm == "") {
  //     this.businessesList = this.businesses;
  //   } else {
  //     this.businessesList = this.businesses.filter((business) => {
  //       return business.name.toLowerCase().includes(this.searchTerm)
  //     });
  //   };
  // }

  onNext() {
    //console.log(this.pageNumber)
    this.getBusinessesWithFilter(this.pageNumber + 1);
  }

  onPrevious() {
    //console.log(this.pageNumber)
    this.getBusinessesWithFilter(this.pageNumber - 1);
  }

  onSearch(input: any) {
    if (input.target.value.length > 2) {
      this.getBusinessesWithFilter(1, input.target.value);
    } else if (input.target.value == '') {
      this.getBusinessesWithFilter(1);
    }
  }

  onPageChange(page: number) {
    this.pageNumber = page;
    this.getBusinessesWithFilter(page);
  }
}
