import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { PosService } from '../../services/pos.service';
import { PrintingLayoutService } from '../../services/printing-layout.service';
import { POSParam, SetPOSParam } from '../../models/pos.model';



const posParamsTypes: any = {
  Boolean: 'bool',
  Int: 'number',
  String: 'text',
};

@Component({
  selector: 'app-params-pos',
  templateUrl: './params-pos.component.html',
  styleUrls: ['./params-pos.component.scss'],
})
export class ParamsPosComponent implements OnInit {
  branchId!:string;
  selectedPos?: number;
  paramForm: FormGroup = new FormGroup({});
  postType!: string;
  @Input() posImg: string = '';
  printingTicktLayouts: any[] = [];
  selectedTicktPrintingLayout: any;
  printingTokenLayouts: any[] = [];
  selectedTokenPrintingLayout: any;
  constructor(
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private posService: PosService,
    private spinner: NgxSpinnerService,
    private nbToaster: NbToastrService,
    private printingLayoutService: PrintingLayoutService
  ) {
    this.paramForm = this.fb.group({
      params: this.fb.array([]),
    });
  }

  onGetPrintingLayout() {
    this.printingLayoutService.getPrintingLayout().subscribe(res => {

      this.printingTicktLayouts = res.filter(r => {
        return r.layoutType == 1;
      });

      this.printingTokenLayouts = res.filter(r => {
        return r.layoutType == 0;
      });

      console.log(this.printingTicktLayouts,this.printingTokenLayouts )

      if (this.selectedPos) {
        this.getPosParams();
      } else {
        this.getDefaultParams();
      }


    }, err => console.log({ err }))
  }

  params(): FormArray {
    return this.paramForm.get('params') as FormArray;
  }

  model: any = {};

  addParam() {
    this.params().push(
      this.fb.group({
        qty: '',
        price: '',
      })
    );
  }

  onLayoutParamChange(event: any, type: string) {
    console.log(this.model)
    let paramId;
    let printlayoutid;
    if (type == 'ticket') {
      paramId = this.model['OrderLayoutId'].id;
      printlayoutid = this.selectedTicktPrintingLayout.printingLayoutId;

    } else {
      paramId = this.model['TransactionLayoutId'].id;
      printlayoutid = this.selectedTokenPrintingLayout.printingLayoutId;

    }
    console.log('ticket changed')
    this.setParamValue(paramId, printlayoutid);

    
    // console.log(event, paramId)
    // if (type == 'ticket') {
    // console.log();
    
      
    // }
    // if (type == 'token') {
    //   console.log(this.selectedTokenPrintingLayout.printingLayoutId);
      
        
    //   }
    // console.log(event.target.value);
    // console.log(event.target);
    
    // this.setParamValue(paramId, event.printingLayoutId);
    // console.log(this.selectedTicktPrintingLayout);
    // console.log(JSON.parse(this.selectedTicktPrintingLayout));
    // console.log(this.selectedTokenPrintingLayout);
    // console.log(this.selectedTokenPrintingLayout.printingLayoutId);
    
  }

  onFormChange(data: any) {
    //console.log({ data });
    for (const field of Object.keys(data)) {
      let paramId = this.model[field].id;
      this.setParamValue(paramId, data[field]);
    }
  }

  setParamValue(paramId: string, value: any) {
    console.log('setting param value')
    let currentData = localStorage.getItem('searchVal');
    let branchId = JSON.parse(currentData!).id;
    const setPosParam: SetPOSParam = {
      paramKey: paramId,
      paramValue: `${value}`,
      posId: this.selectedPos,
      branchId: branchId,
    };

    this.spinner.show();
    if (this.selectedPos) {
      this.setPosParams(setPosParam);
    } else {
      this.setBranchPosParams(setPosParam);
    }
  }

  setPosParams(param: SetPOSParam) {
    this.posService.setPosParam(param).subscribe({
      next: (data) => this.onSetSuccess(data),
      error: (error) => this.onSetError(error),
      complete: () => this.spinner.hide(),
    });
  }

  setBranchPosParams(param: SetPOSParam) {
    console.log('send param value')
    this.posService.setBranchPosParams(param).subscribe({
      next: (data) => this.onSetSuccess(data),
      error: (error) => this.onSetError(error),
      complete: () => this.spinner.hide(),
    });
  }

  onSetSuccess(data: any) {
    this.nbToaster.success('Param updated successfully', 'Success');
  }
  onSetError(error: any) {
    this.nbToaster.danger('Issue on updating param', 'Something went wrong');
    this.spinner.hide();
  }

  onGetSuccess(data: POSParam[]) {
    console.log({ data })
    const posParams: any = {};
    data.map((param) => {
      posParams[param.paramName] = {
        type: posParamsTypes[param.dataType],
        value: param.paramValue,
        label: param.paramName,
        id: param.paramId,
      };
    });
    this.model = posParams;

    console.log(this.model)

    if(this.model.OrderLayoutId.value) {
      this.selectedTicktPrintingLayout = this.printingTicktLayouts.find(ticket => {
        return ticket.printingLayoutId == this.model.OrderLayoutId.value;
      });

    }

    if(this.model.TransactionLayoutId.value) {
      this.selectedTokenPrintingLayout = this.printingTokenLayouts.find(token => {
        return token.printingLayoutId == this.model.TransactionLayoutId.value;
      });
    }
  }

  onGetError(error: any) {
    // this.nbToaster.danger('Issue on getting params', 'Something went wrong');
    this.model = undefined;
  }

  getPosParams() {
    this.posService.getPosParams(this.selectedPos!.toString()).subscribe({
      next: (data) => this.onGetSuccess(data),
      error: (error) => this.onGetError(error),
    });
  }

  getDefaultParams() {
    let currentData = localStorage.getItem('searchVal');
    let branchId = JSON.parse(currentData!).id;
    this.posService.getDefaultBranchPosParams(branchId).subscribe({
      next: (data) => this.onGetSuccess(data),
      error: (error) => this.onGetError(error),
    });
  }

  ngOnInit(): void {
    this.onGetPrintingLayout();
    let currentData = localStorage.getItem('searchVal');
     this.branchId = JSON.parse(currentData!).id;
     console.log('branchId',this.branchId);
     
  }

  onCancel() {
    this.activeModal.dismiss();
  }
}
