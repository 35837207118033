<div class="cutome-height">
    <div class="d-flex align-items-center justify-content-between logs position-relative mx-3 my-2">
        <h5 class="mb-0">Logs</h5>
        <span class="custom-badge py-1 px-2 rounded-1">{{ logs.length }}</span>
    </div>
    <div *ngIf="logs.length > 0">
        <app-log *ngFor="let log of logs" [user]="log.userName" [action]="log.action" [date]="log.date"></app-log>
    </div>
    <div *ngIf="logs.length <= 0" class="mx-3">
        <p class="my-3">logs history is empty ..</p>
    </div>
</div>
