<div class="p-2">
    <div class="d-flex justify-content-between align-items-center" (click)="openMenu()">
        <nb-user shape="round" [name]="user" [title]="role" width="40"
            height="40" showInitials="true" color="#fff"></nb-user>
        <nb-icon status="basic" icon="{{isOpen ? 'arrow-ios-upward-outline' : 'arrow-ios-downward-outline'}}"
            class="mx-3"></nb-icon>
    </div>
    <div class="dropdown">
        <ul class="dropdown-menu dropdown-menu-right border-0 shadow py-2" [ngClass]="{'show': isOpen}">
            <li class="sm-dropdown d-md-none dropdown-item border-bottom">
                <span>{{ role }}</span>
            </li>
            <!-- <li class="dropdown-item py-2">
                <nb-icon status="basic" icon="moon-outline" class="mr-2"></nb-icon> dark mode
            </li> -->
            <li class="dropdown-item py-2" (click)="onLogout()">
                <nb-icon status="danger" icon="log-out-outline" class="mr-2"></nb-icon> logout
            </li>
        </ul>
    </div>

</div>