import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dynamic-toggle',
  templateUrl: './dynamic-toggle.component.html',
  styleUrls: ['./dynamic-toggle.component.scss'],
})
export class DynamicToggleComponent implements OnInit {
  @Input() field: any;
  @Input() formName!: FormGroup;

  constructor() {}

  ngOnInit(): void {}
}
