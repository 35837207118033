<div class="container">
  <div class="mb-3">
    <div class="card shadow-sm">
      <div class="card-body py-3 text-center">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h6>Branch Logo</h6>
          <div>
            <label for="logo-file-upload" class="custom-file-upload">
              <nb-icon icon="cloud-upload-outline" status="success" class="icon"></nb-icon> </label>
            <input (change)="updateLogo($event, {name: 'logo', type: 1})" id="logo-file-upload" style="display: none;" type="file" />
          </div>
        </div>
        <img class="img-fluid" src="{{branchLogo? branchLogo : logoPlaceholder}}" alt="">
      </div>
    </div>
  </div>
  <div class="mb-3">
    <div class="card shadow-sm">
      <div class="card-body py-3 text-center">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h6>Default Logo</h6>
          <div>
            <label for="default-file-upload" class="custom-file-upload">
              <nb-icon icon="cloud-upload-outline" status="success" class="icon"></nb-icon>
            </label>
            <input (change)="updateLogo($event, {name: 'Default Logo', type: 2})" id="default-file-upload" style="display: none;" type="file" />
          </div>
        </div>
        <img class="img-fluid" src="{{branchDefaultLogo? branchDefaultLogo : logoPlaceholder}}" alt="">
      </div>
    </div>
  </div>
  <div class="mb-3">
    <div class="card shadow-sm">
      <div class="card-body py-3 text-center">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h6>Printing Logo</h6>
          <div>
            <label for="printing-file-upload" class="custom-file-upload">
              <nb-icon icon="cloud-upload-outline" status="success"  class="icon"></nb-icon> </label>
            <input (change)="updateLogo($event, {name: 'Printing Logo', type: 3})" id="printing-file-upload" style="display: none;"  type="file" />
          </div>
        </div>
        <img class="img-fluid" src="{{branchPrintLogo? branchPrintLogo : logoPlaceholder}}" alt="">
      </div>
    </div>
  </div>
  <div class="mb-3">
    <div class="card shadow-sm">
      <div class="card-body py-3 text-center">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h6>Footer Logo</h6>
          <div>
            <label for="footer-file-upload" class="custom-file-upload">
              <nb-icon icon="cloud-upload-outline" status="success" class="icon"></nb-icon>
            </label>
            <input (change)="updateLogo($event, {name: 'Footer Logo', type: 4})" id="footer-file-upload" style="display: none;" type="file" />
          </div>
        </div>
        <img class="img-fluid" src="{{branchFooterLogo? branchFooterLogo : logoPlaceholder}}" alt="">
      </div>
    </div>
  </div>
</div>
