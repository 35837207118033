<!-- <div class="scrolling-area">
    <div class="text-center pb-1">Branch Logs</div>

  </div> -->
<div class="text-center pb-2">Branch Logs</div>

<nb-card size="small">
  <nb-layout>
    <nb-layout-column>
      <nb-list>
        <nb-list-item *ngFor="let log of logObs$ | async">
          <nb-user [name]="log.userName" [title]="log.action"> </nb-user>
          <div class="date">{{ log.date | dateAgo }}</div>
        </nb-list-item>
      </nb-list>
    </nb-layout-column>
  </nb-layout>
</nb-card>
