
<!-- reset password -->
<nb-card class="model-full">
  <nb-card-header>
        <h5 class="modal-title">Reset Cashier Password</h5></nb-card-header>
          <nb-card-body>
            <form  [formGroup]="resetCashierForm">
              <div class="row form-row">
              <div class="col-12  mb-3">
                <label for="recipient-name" class="col-form-label">You are resting password for </label>
                <input type="text" formControlName="cashierName" fullWidth nbInput class="input-style" [value]="cashierName">
              </div>
              <div class="col-12  mb-3">
                <label for="recipient-name" class="col-form-label">New Password </label>
                <nb-form-field>
                  <input [type]="getInputType()" class="input-style" nbInput fullWidth placeholder="Enter Cashier Password"
                  formControlName="password" >
                  <button nbSuffix nbButton ghost (click)="toggleShowPassword()">
                    <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'"
                             pack="eva"
                             [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
                    </nb-icon>
                  </button>
                </nb-form-field>
              </div>
              <div class="col-12  mb-3">
                <label for="recipient-name" class="col-form-label">Retype - Password </label>
                <nb-form-field>
                  <input [type]="getInputType1()" class="input-style" nbInput fullWidth placeholder="Retype Cashier Password" [ngModelOptions]="{standalone: true}" [(ngModel)]="cashierConfirmPassword" >
                  <button nbSuffix nbButton ghost (click)="toggleShowPassword1()">
                    <nb-icon [icon]="showconfirmPassword ? 'eye-outline' : 'eye-off-2-outline'"
                            pack="eva"
                            [attr.aria-label]="showconfirmPassword ? 'hide password' : 'show password'">
                    </nb-icon>
                  </button>
                </nb-form-field>
              </div>
            </div>
            </form>
        </nb-card-body>
        <nb-card-footer class="text-end">
          <button nbButton  status="success" (click)="resetCashierPassword()"><nb-icon icon="save-outline"></nb-icon>Save</button>
          <button nbButton  status="danger" (click)="cancel()"><nb-icon icon="close-outline"></nb-icon>Cancel</button>
        </nb-card-footer>
      </nb-card>
  <!--reset password-->
