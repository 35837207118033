import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PosService } from '../../../services/pos.service';


@Component({
  selector: 'app-pos-history',
  templateUrl: './pos-history.component.html',
  styleUrls: ['./pos-history.component.scss']
})
export class PosHistoryComponent implements OnInit {
  @Input() posSerialNumber: string = "";
  @Input() posId: string = "";
  pos: any = [];
  constructor(private posService: PosService, public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.onGetPoaHistory(this.posId);
  }

  onGetPoaHistory(id: string) {
    this.posService.getPosHistory(id).subscribe(res => this.pos = res)
  }
}
