import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-branch-items-manage',
  templateUrl: './branch-items-manage.component.html',
  styleUrls: ['./branch-items-manage.component.scss'],
})
export class BranchItemsManageComponent implements OnInit {
  @Input() branchID!: string; // branch id passed to component
  constructor() {}

  ngOnInit(): void {}

}
