<nb-card class="m-0">
  <nb-card-header>
    <h5>Create Package</h5>
  </nb-card-header>
  <nb-card-body>
    <form>
      <div class="form-row">
        <div class="mb-3 col-md-6">
          <label class="mb-2">Package  Name EN</label>
          <input type="text" [(ngModel)]="nameInLanguages[0].name" nbInput fullWidth>
          <!-- <span *ngIf="submitted && Package GroupForm.get('sn')?.errors?.['required']" class="text-danger">SN is required</span> -->
        </div>
        <div class="mb-3 col-md-6">
          <label class="mb-2">Package  Name AR</label>
          <input type="text" [(ngModel)]="nameInLanguages[1].name" nbInput fullWidth>
          <!-- <span *ngIf="submitted && Package GroupForm.get('sn')?.errors?.['required']" class="text-danger">SN is required</span> -->
        </div>
        <div class="mb-3 col-md-6">
          <label class="mb-2">Package  Name TR</label>
          <input type="text" [(ngModel)]="nameInLanguages[2].name" nbInput fullWidth>
          <!-- <span *ngIf="submitted && Package GroupForm.get('sn')?.errors?.['required']" class="text-danger">SN is required</span> -->
        </div>
        <div class="mb-3 col-md-6">
          <label class="mb-2">Package  Name FR</label>
          <input type="text" [(ngModel)]="nameInLanguages[3].name" nbInput fullWidth>
          <!-- <span *ngIf="submitted && featureGroupForm.get('sn')?.errors?.['required']" class="text-danger">SN is required</span> -->
        </div>
        <div class="mb-3 col-md-6">
          <nb-checkbox [(ngModel)]="canBeTrial" status="success">Trial ​</nb-checkbox>
        </div>
        <div class="mb-3 col-12">
          <table class="table table-bordered table-sm">
            <tr>
              <th>Cur​</th>
              <th>Mo</th>
              <th style="text-decoration: line-through;">Mo​</th>
              <th>Yr</th>
              <th style="text-decoration: line-through;">Yr</th>
            </tr>
            <tbody>
              <tr>
                <th>
                  AED
                </th>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[0].monthlyBasePrice" nbInput fullWidth>
                </td>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[0].monthlyPriceWithDiscount" nbInput fullWidth>
                </td>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[0].yearlyBasePrice" nbInput fullWidth>
                </td>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[0].yearlyPriceWithDiscount" nbInput fullWidth>
                </td>
              </tr>
              <tr>
                <th>
                  TRY
                </th>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[1].monthlyBasePrice" nbInput fullWidth>
                </td>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[1].monthlyPriceWithDiscount" nbInput fullWidth>
                </td>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[1].yearlyBasePrice" nbInput fullWidth>
                </td>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[1].yearlyPriceWithDiscount" nbInput fullWidth>
                </td>
              </tr>
              <tr>
                <th>
                  USD
                </th>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[2].monthlyBasePrice" nbInput fullWidth>
                </td>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[2].monthlyPriceWithDiscount" nbInput fullWidth>
                </td>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[2].yearlyBasePrice" nbInput fullWidth>
                </td>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[2].yearlyPriceWithDiscount" nbInput fullWidth>
                </td>
              </tr>
              <!-- <tr>
                <th>
                  Tr
                </th>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[3].monthlyBasePrice" nbInput fullWidth>
                </td>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[3].monthlyPriceWithDiscount" nbInput fullWidth>
                </td>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[3].yearlyBasePrice" nbInput fullWidth>
                </td>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[3].yearlyPriceWithDiscount" nbInput fullWidth>
                </td>
              </tr> -->
            </tbody>
          </table>
        </div>
        <hr>
        <div class="col-12">
          <h5>Features</h5>
          <div class="row">
            <div class="col-md-4" *ngFor="let detail of featureDetails">
              <p class="fw-bold font-italic">{{detail.defaultName}}</p>
              <nb-checkbox class="d-block mr-2" (checkedChange)="onCheckedChange(feature)" *ngFor="let feature of detail.features" status="success">{{feature.defaultName}}</nb-checkbox>
            </div>
          </div>
        </div>
      </div>
      <span *ngIf="submitted && !valid" class="text-danger">*all fields are required</span>
    </form>
  </nb-card-body>
  <nb-card-footer class="text-right">
    <button nbButton status="basic" (click)="onCancel()">Cancel</button>
    <button nbButton status="success" (click)="onSave()">Save</button>
  </nb-card-footer>
</nb-card>
