<nb-card [nbSpinner]="isLoading">
  <nb-card-body>
    <div class="row">
      <div class="col-md-6">
        <input nbInput placeholder="search by category name.."  id="search" class="search-input" (input)="onSearch($event)"/>
      </div>
      <div class="col-md-6">
        <div class="btn-container">
          <button nbButton status="primary" (click)="newCategory()">
            <nb-icon icon="plus" [options]="{ animation: { type: 'shake' } }"></nb-icon>
            New Category
          </button>
        </div>
      </div>
    </div>


    <!--local input <input nbInput [nbFilterInput]="dataSource" id="search" class="search-input" /> -->



    <table [nbTreeGrid]="dataSource" [nbSort]="dataSource" (sort)="updateSort($event)">
      <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns"></tr>
      <tr [clickToToggle]="false" nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumns"
        class="{{row.data.isChild? 'bg-light': ''}}"></tr>

      <ng-container [nbTreeGridColumnDef]="customColumn">
        <th nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(customColumn)" *nbTreeGridHeaderCellDef>
          {{ customColumn }}
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row"
          class="fw-bold {{row.data.isChild? 'text-primary' : 'text-uppercase'}}">
          <nb-fs-icon [expanded]="row.expanded" [isExpandable]="row.children"></nb-fs-icon>
          {{ row.data[customColumn] }}
        </td>
      </ng-container>

      <ng-container *ngFor="let column of defaultColumns; let index = index" [nbTreeGridColumnDef]="column"
        [showOn]="getShowOn(index)">
        <th nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(column)" *nbTreeGridHeaderCellDef>
          {{ column }}
        </th>
        <div *ngIf="column != 'options'">
          <td nbTreeGridCell *nbTreeGridCellDef="let row" (click)="viewIcon(row.data)" style="text-align: center">
            <!--            {{ row.data[column] || "-" }}-->
            <!--            <img [src]="'http://' + row.data[column]" [alt]="row.data.isChild ? 'subcategory logo' : 'category logo'" width="15%" *ngIf="row.data[column]">-->
            <!--            <div *ngIf="!row.data[column]">-->
            <!--              <nb-icon icon="image-outline"></nb-icon>-->
            <!--              click to add logo-->
            <!--            </div>-->

            <!--            <ngx-dropzone style="display: none" (change)="onLogoChange($event)"></ngx-dropzone>-->
            <ng-icons [imgUrl]="row.data[column]"></ng-icons>
          </td>
        </div>
        <div *ngIf="column == 'options'">
          <td nbTreeGridCell *nbTreeGridCellDef="let row" style="padding-left: 0">
            <nb-button-group status="warning" ghost style="margin-bottom: 12px">
              <button nbButton (click)="deleteCategory(row.data)">
                <nb-icon icon="trash-2-outline" class="cat-ico"></nb-icon>
              </button>
              <button nbButton (click)="editCategory(row.data)">
                <nb-icon icon="edit-2-outline" class="cat-ico"></nb-icon>
              </button>
              <button nbButton  (click)="changeActiveStatus(row.data)">
                <nb-icon [icon]="row.data.isActive? 'eye-outline' : 'eye-off-outline'" class="cat-ico"></nb-icon>
              </button>
              <button nbButton title="New Sub Category" *ngIf="!row.data.isChild" (click)="newSubCategory(row.data)">
                <nb-icon icon="plus-circle-outline" class="cat-ico"></nb-icon>
              </button>
            </nb-button-group>

            <span class="mx -2">
              <button nbButton ghost status="warning" (click)="updatePlace(row, 'up')"
                [disabled]="row.data.place == minPlace">
                <nb-icon icon="arrow-upward-outline"></nb-icon>
              </button>
              <button nbButton ghost status="warning" (click)="updatePlace(row, 'down')" [disabled]="
                  row.data.place ==
                maxPlace
                ">
                <nb-icon icon="arrow-downward-outline" class="cat-ico"></nb-icon>
              </button>
            </span>
          </td>
        </div>
      </ng-container>
    </table>
    <nav aria-label="table pagination">
      <ul class="pagination justify-content-end mt-3">
          <li class="page-item" [ngClass]="{ 'disabled': !prevPage }"  (click)="onPrevious()">
              <span class="page-link">Prev</span>
          </li>
          <li class="page-item" [ngClass]="{ 'active': pageNumber == page }" *ngFor="let page of pages" (click)="onPageChange(page)"><span class="page-link">{{ page }}</span></li>
          <li class="page-item" [ngClass]="{ 'disabled': !nextPage }" (click)="onNext()">
              <span class="page-link">Next</span>
          </li>
      </ul>
  </nav>
  </nb-card-body>
</nb-card>
