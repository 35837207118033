import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(private http: HttpClient) { }

  getBranchLogo(id: string) {
    return this.http.get(`${environment.baseURL}/Branches/BranchLogo?branchId=${id}`, { responseType: 'text' });
  }

  getBranchDefaultLogo(id: string) {
    return this.http.get(`${environment.baseURL}/Branches/BranchDefaultLogo?branchId=${id}`, { responseType: 'text' });
  }

  getBranchPrintLogo(id: string) {
    return this.http.get(`${environment.baseURL}/Branches/BranchPrintLogo?branchId=${id}`, { responseType: 'text' });
  }

  getBranchFooterLogo(id: string) {
    return this.http.get(`${environment.baseURL}/Branches/BranchFooterLogo?branchId=${id}`, { responseType: 'text' });
  }

  getBusinessByBranchId(id: string) {
    return this.http.get(`${environment.baseURL}/Branches/${id}/branch`);
  }
}
