import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as XLSX from 'xlsx';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
import { NbDialogService, NbToastrService, } from '@nebular/theme';
import { User } from '../../models/user.model';
import { Branch } from '../../models/branches.model';
import { BuisnessService } from '../../services/buisness.service';
import { PosService } from '../../services/pos.service';
import { UserService } from '../../services/user.service';
import { AddUserComponent } from '../../components/user-business/add-user.component';
import { ForgotPasswordComponent } from '../../components/user-business/forgot-password/forgot-password.component';
import { EditUserComponent } from '../../components/user-business/edit-user/edit-user.component';
import { DeleteUserComponent } from '../../components/user-business/delete-user/delete-user.component';


@Component({
  selector: 'app-main-business-tab',
  templateUrl: './main-business-tab.component.html',
  styleUrls: ['./main-business-tab.component.scss']
})
export class MainBusinessTabComponent implements OnInit {
  userList: User[] = [];
  branchType: string = "branch";
  posList: any = [];
  branches: Branch[] = [];
  exportedBranches: any = []
  business: any;
  branchId: any;
  branchName: any;
  businessId: any;
  branchObj: any = {};
  businessName: any;
  paramId: any;
  paramName: any;
  paramType: any;
  paramStatus: any;
  fileName = 'BranchSheet.xlsx';
  status: any;
  loading: boolean = false;
  param: any = {};
  branchID: any
  config: any;
  pageOfbranches: any = []
  p: number = 1;
  usernumber: number = 1;
  shortBranchId: any;
  page = 1;
  pageSize = 10;

  constructor(public router: Router,
    private dialogService: NbDialogService,
    public route: ActivatedRoute,
    private nbToastr: NbToastrService,
    protected businessService: BuisnessService,
    protected posService: PosService,
    public userservice: UserService,) {

    let val = localStorage.getItem('searchVal');
    this.param = JSON.parse(val!);
  }

  ngOnInit(): void {
    this.getParams()
    this.getBranchByBusinessId()
    this.getUsers()
    this.userservice.refresh.subscribe(res => this.getUsers());
    this.businessService.refresh.subscribe(res => this.getBranchByBusinessId());
    this.showPartOfText();
  }
  getBranchByBusinessId() {
    this.businessService.getBranchesByBusinessId(this.businessId).subscribe((res: any) => {
      this.branches = res.branches;
      if (this.branches == null) {
        this.branches = []
      }
      //pagination
      for (let i = 1; i <= this.branches?.length; i++) {
        this.branches.push();
      }
      this.exportedBranches = this.branches;
    },
      (err) => {
        console.error({ err });
      });
  }
  showPartOfText() {
    this.branchID = this.param.id
    this.shortBranchId = this.param.id.substring(0, 1)
  }
  getParams() {
    this.paramId = this.param.id;
    this.paramType = this.param.type
    this.paramName = this.param.name
    if (this.paramType == 'business') {
      this.businessName = this.paramName;
      this.businessId = this.paramId;
      // this.getUsers(this.businessId)
      console.log(this.paramType, this.branches, "branches")
    }
    else if (this.paramType == 'branch') {
      this.branchName = this.paramName;
      this.branchId = this.paramId
      this.getPosByBranch(this.branchId)
    }
  }
  goToBranch(branch: any) {
    this.branchId = branch.id;
    let newVal = {
      businessId: "",
      id: branch.id,
      name: branch.name,
      type: "branch"
    }
    localStorage.setItem('searchVal', JSON.stringify(newVal));
    this.router.navigateByUrl(`home/branch-tab/${branch.id}`)
  }
  goToDashboard(id: any) {
    this.router.navigateByUrl(`home/branch-tab/${id}`);
  }
  changeStatusBranch(id: string, status: boolean) {
    this.loading = true;
    status = !status;
    console.log(status, "status of branch")
    this.businessService.changestatusBranch({ branchId: id, isDisabled: status }).subscribe(res => {
      this.nbToastr.show('Branch status change successfully.', 'success', {
        status: 'success',
        hasIcon: false,
        icon: 'checkmark-outline'
      });
      this.getBranchByBusinessId();
      this.businessService.refresh.emit();
      this.loading = false;
    }, err => {
      console.log(err);
      this.nbToastr.show(err.statusText, 'error', {
        status: 'danger',
        hasIcon: false,
        icon: 'close-outline'
      });
    });
    this.businessService.refresh.emit();
  }
  getUsers() {

    this.loading = true;
    this.userservice.getUsersBusiness(this.businessId).subscribe((res: any) => {
      this.userList = res;
      console.log(this.userList)
      if (this.userList == null) {
        this.userList = []
      }
      this.loading = false;
    },
      (err) => {
        console.error({ err });
      });
  }

  addUser(id: number) {
    const modalRef = this.dialogService.open(AddUserComponent,
      {
        context: {
          businessId: id
        },
        dialogClass: 'model-full'
      }
    )
    modalRef.onClose.subscribe((res: string) => {
      console.log(res)
      this.userservice.logs.emit('add user in branch log');
    })
  }
  resetUserPassword(id: string, userName: string, userId: string, email: string,
    password: string, confirmpassword: string) {
    const modalRef = this.dialogService.open(ForgotPasswordComponent, {
      context: {
        businessId: id,
        userName: userName,
        userId: userId,
        email: email,
      }
    })
    modalRef.onClose.subscribe((res: string) => {
      console.log(res)
      this.userservice.logs.emit('reset user password  in branch log');
    })
  }
  editUser(id: string, userId: string, userName: string,
    email: string, role: string, password: string) {
    // console.log({role})
    const modalRef = this.dialogService.open(EditUserComponent, {
      context: {
        businessId: id,
        userId: userId,
        userName: userName,
        role: role,
        email: email,
        password: password,
        // confirmpassword: confirmpassword
      }
    })
    modalRef.onClose.subscribe((res: string) => {
      this.userservice.logs.emit('edit user in branch log');
    })
  }
  deleteUser(id: string) {
    const modalRef = this.dialogService.open(DeleteUserComponent, {
      context: {
        userId: id
      }
    })
    modalRef.onClose.subscribe((res: string) => {
      console.log(res)
      this.userservice.logs.emit('deletd user in branch log');
    })
  }
  getPosByBranch(paramId: string) {
    this.posService.getBranchPos(paramId).subscribe((res: any) => {
      this.posList = res;
      console.log(res, "posList")
    },
      (err) => {
        console.error({ err });
        this.posList.length = 0
      })
  }
}
