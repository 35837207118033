import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { NbThemeService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})

export class DropdownMenuComponent implements OnInit {
  isOpen: boolean = false;
  role: string = "";
  user: string = "";
  id: string = "";
  @Input() onClose: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
     private authService: NbAuthService,
      private userService: UserService,
       private themeService: NbThemeService) { }

  ngOnInit(): void {
    this.onClose.subscribe(res => this.isOpen = res);
    this.authService.onTokenChange().subscribe(res => {
      // console.log(res.getPayload())
      this.user = res.getPayload().preferred_username;
      this.role = res.getPayload().role;
      this.id = res.getPayload().userId;
    });
  }

  onLogout() {
    localStorage.clear();
    this.router.navigateByUrl('auth/login').then(() => {
      this.authService.isAuthenticated();
      location.reload();
    });
  }

  openMenu() {
    this.isOpen = !this.isOpen;
  }

  onToggleDarkMode() {
    this.themeService.changeTheme('dark');
  }
}
