<nb-card>
  <nb-card-header>
    <div class="d-flex align-items-start justify-content-between">
      <div class="filter d-flex  gap-1" >
        <button nbButton hero status="success" (click)="toggleFilter()">
          <nb-icon icon="funnel-outline"></nb-icon> {{isFilterOn ? "Clear":"Show"}} Filter 
        </button>
        <div class="total-amount">
          <span>Total Amount : </span>{{totalAmount}}
        </div>
      </div>
      <div>
        <input type="text" nbInput placeholder="Search for notes" [(ngModel)]="filterKeyword">
        <button nbButton hero status="success" (click)="addExpense()" >
          <nb-icon icon="plus-outline"></nb-icon> Add Expense
        </button>
      </div>
    </div>
    <div style="display: none;gap: 10px;"class="mt-2" [class.d-flex]="isFilterOn">
      <nb-select placeholder="Select Expense Type" [(selected)]="filterByExpenseTypeId">
        <nb-option *ngFor="let expenseType of expenseTypes; let i = index" [value]="expenseType.id">{{expenseType.name}}</nb-option>
      </nb-select>
      <nb-select placeholder="Select Payment Mode " [(selected)]="filterByPaymentModeId">
        <nb-option *ngFor="let paymentMode of paymentModes; let i = index" [value]="paymentMode.id">{{paymentMode.name}}</nb-option>
      </nb-select>
      <input class="d-block" [(ngModel)]="filterDateRang" nbInput placeholder="Pick Date Range" [nbDatepicker]="rangepicker">
      <nb-rangepicker #rangepicker></nb-rangepicker>
      <button nbButton hero  status="success" (click)="filter()" >
        Apply
      </button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <!-- <div class="table-responsive" *ngIf="tables.length > 0"> -->
    <div class="table-responsive">
      <table class="table table-bordered" [nbSpinner]="false" nbSpinnerStatus="basic">
        <thead>
          <tr>
            <th scope="col">Type Of Expense</th>
            <th scope="col">Payment Mode</th>
            <th scope="col">Expense Amount</th>
            <th scope="col">Expense Date</th>
            <th scope="col">Note</th>
            <th scope="col">Action</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let expense of expenses; let i = index ">
            <td>{{expense.expenseTypeName}}</td>
            <td>{{expense.paymentModeName}}</td>
            <th>{{expense.amount}}</th>
            <td>{{expense.date | date}}</td>
            <td>{{expense.note}}</td>
            <td>
              <!-- <button placement="top" ngbTooltip="Edit" class="mx-md-2 my-2 my-md-0" nbButton status="warning"
                size="small" shape="round" hero >
                <nb-icon icon="edit-outline"></nb-icon>
              </button> -->
              <button class="mx-md-2 my-2 my-md-0" placement="top" ngbTooltip="Download" nbButton status="warning" size="small"
                shape="round" hero (click)="download(expense.fileUrl)" >
                <nb-icon icon="download"></nb-icon>
              </button>
              <button class="mx-md-2 my-2 my-md-0" placement="top" ngbTooltip="Delete" nbButton status="danger" size="small"
                shape="round" hero (click)="deleteExpense(expense)">
                <nb-icon icon="trash-2-outline"></nb-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
    <div class="py-3 text-center" *ngIf="expenses.length == 0">
      <p>No available expenses to show</p>
    </div>
    <div class="d-flex justify-content-center" *ngIf="totalPagesCount > 1">
      <nb-button-group  ghost>
      <button nbButtonToggle (click)="loadPreviousPage()">Previous</button>
      <button nbButtonToggle  value="" >{{filterOptions.page}} of {{totalPagesCount}}</button>
      <button nbButtonToggle (click)="loadNextPage()">Next</button>
    </nb-button-group>
  </div>
  </nb-card-body>
</nb-card>
