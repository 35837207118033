import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesmanService } from '../../services/salesman.service';
import { PosService } from '../../services/pos.service';


@Component({
  selector: 'app-disconnect-pos',
  templateUrl: './disconnect-pos.component.html',
  styleUrls: ['./disconnect-pos.component.scss']
})
export class DisconnectPosComponent implements OnInit {
  selectedItem = '0';
  disconnectPosForm: FormGroup = new FormGroup({
    posId: new FormControl({ value: '', disabled: true }),
    notes: new FormControl('')
  });
  salesman: any;
  @Input() name: string = "";
  @Input() id: string = "";
  @Input() pos: any;

  alert: any = {
    alert: false,
    message: "",
    status: 'success'
  };

  constructor(
    private posService: PosService, 
    private salesmanService: SalesmanService, 
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.disconnectPosForm.patchValue({
      posId: this.pos.sn
    });

    this.getSalesMan();
  }

  onDisconnect() {

    this.disconnectPosForm.patchValue({
      posId: this.pos.posId
    });

    //console.log(this.disconnectPosForm.getRawValue());
    this.posService.disconnectPos(this.disconnectPosForm.getRawValue()).subscribe(res => {
      // this.setAlert('success', res.message);
      if(res.isSucceeded == true) {
        this.activeModal.close('success');
      } else {
        this.activeModal.close('err')
      }
    }, err => {
      //console.log(err)
      if (err.status === 0) {
        //console.log(err.status)
        this.setAlert('danger', "please check your connection and try again.");
      } else {
        this.setAlert('danger', err.error.title);
      }
    })
  }

  onClose(msg: string) {
    this.activeModal.close(msg);
  }

  onCancel() {
    this.activeModal.dismiss()
  }

  getSalesMan() {
    // this.salesmanService.getSalesman().subscribe(res => {
    //   console.log(res)
    //   this.salesman = res.filter(saleman => {
    //     return saleman.id == this.id;
    //   });

    //   console.log(this.salesman)
    // }, err => console.log(err))
  }

  setAlert(status: string, msg: string) {
    this.alert = {
      alert: true,
      message: msg,
      status: status
    };

    setTimeout(() => {
      this.alert.alert = false;
      if (status == 'success') {
        this.activeModal.close()
      }
    }, 2000);
  }
}
