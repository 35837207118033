<nb-card class="m-0">
  <nb-card-header>
      <h5>{{worker? 'Edit Worker' : 'Add New Worker'}}</h5>
  </nb-card-header>
  <nb-card-body>
      <form [formGroup]="addWorkerForm">
          <div class="form-row">
              <div class="mb-3 col-12">
                  <label class="mb-2">First name</label>
                  <input type="text" formControlName="firstname" nbInput fullWidth placeholder="Enter Your First Name">
                  <small class="text-danger" *ngIf="submitted && addWorkerForm.get('firstname')?.value == ''">first name is required</small>
              </div>
              <div class="mb-3 col-12">
                  <label class="mb-2">Last name</label>
                  <input type="text" formControlName="lastname" nbInput fullWidth placeholder="Enter Your Last Name">
                  <small class="text-danger" *ngIf="submitted && addWorkerForm.get('firstname')?.value == ''">last name is required</small>
              </div>
              <div class="mb-3 col-12">
                  <label class="mb-2">Gender</label>
                  <select class="form-control custom-select" formControlName="gender" nbInput>
                      <option [value]="0">male</option>
                      <option [value]="1">female</option>
                  </select>
              </div>
          </div>
      </form>
  </nb-card-body>
  <nb-card-footer class="text-right">
      <button nbButton status="basic" (click)="onCancel()">Cancel</button>
      <button nbButton status="success" (click)="onSave()">Save</button>
  </nb-card-footer>
</nb-card>
