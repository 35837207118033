<div class="cutome-height">
    <div class="d-flex align-items-center justify-content-between recents mx-3 my-2">
        <h5 class="mb-0">Recent</h5>
        <span class="custom-badge py-1 px-2 rounded-1">{{recents.length}}</span>
        <!-- <select class="py-1 px-1">
        <option value="">5</option>
        <option value="">10</option>
    </select> -->
    </div>
    <div *ngIf="recents.length > 0" class="">
        <app-recent *ngFor="let recent of recents" [log]="recent"
            (click)="showBranch(recent)"></app-recent>
    </div>
    <div *ngIf="recents.length <= 0" class="mx-3">
        <p class="my-3">{{msg}} ..</p>
    </div>
</div>
