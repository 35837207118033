<nb-card>
  <nb-tabset class="px-3">
    <nb-tab tabTitle="POS">
      <nb-card class="border-0">
        <nb-card-header class="px-0 d-flex justify-content-between align-items-center">
          <div class="search">
            <nb-form-field>
              <nb-icon nbPrefix status="warning" icon="search-outline" pack="eva"></nb-icon>
              <input type="text" nbInput status="basic" placeholder="Search by SN, Type or Status"
                (input)="onSearch($event)" autofocus>
            </nb-form-field>
          </div>
          <div class="report d-flex align-items-center">
            <button nbButton hero status="success" (click)="onAddPOS()">
              <nb-icon icon="plus-outline"></nb-icon> add POS
            </button>
            <button nbButton status="info" hero (click)="onShowReport()">Show Report</button>
          </div>
        </nb-card-header>
        <nb-card-body>
          <div class="table-responsive" *ngIf="filteredPOSs.length > 0">
            <table class="table table-bordered" [nbSpinner]="loading" nbSpinnerStatus="basic">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">IMEI</th>
                  <th scope="col">Type</th>
                  <th scope="col">Status</th>
                  <th scope="col">Branch Name</th>
                  <th scope="col">Added Date</th>
                  <th scope="col">Options</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let pos of filteredPOSs; let i = index ">
                  <th scope="row">{{ i + 1 }}</th>
                  <td>{{pos.sn}}</td>
                  <td>{{pos.posType}}</td>
                  <td>{{pos.posStatus}}</td>
                  <td>{{pos.branchName}}</td>
                  <td>{{pos.createdOn | date : 'short'}}</td>
                  <td>
                    <button placement="right" ngbTooltip="History" tooltipClass="custom-tooltip" nbButton status="warning"
                      shape="round" hero (click)="onShowHistory(pos.posId, pos.sn)">
                      <nb-icon icon="clock-outline"></nb-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <nav aria-label="table pagination">
              <ul class="pagination justify-content-end">
                <li class="page-item" [ngClass]="{ 'disabled': prevPage == null }" (click)="onPrevious()">
                  <span class="page-link">Previous</span>
                </li>
                <li class="page-item" [ngClass]="{ 'active': pageNumber == page }" *ngFor="let page of pages"
                  (click)="onPageChange(page)"><span class="page-link">{{ page }}</span></li>
                <li class="page-item" [ngClass]="{ 'disabled': !nextPage }" (click)="onNext()">
                  <span class="page-link">Next</span>
                </li>
              </ul>
            </nav>
          </div>
          <div class="py-3 text-center" *ngIf="filteredPOSs.length == 0">
            <p>No available POS to show</p>
          </div>
        </nb-card-body>
      </nb-card>
    </nb-tab>
    <nb-tab tabTitle="POS Types">
      <nb-card class="border-0">
        <nb-card-header class="px-0 text-right">
          <button nbButton hero status="success" (click)="onAddPOSType()">
            <nb-icon icon="plus-outline"></nb-icon> add POS Type
          </button>
        </nb-card-header>
        <nb-card-body>
          <div class="table-responsive" *ngIf="types.length > 0">
            <table class="table table-bordered" [nbSpinner]="loading" nbSpinnerStatus="basic">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Image</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let type of types; let i = index ">
                        <th scope="row">{{ i + 1 }}</th>
                        <td>{{type.typeName}}</td>
                        <td>
                          <img width="100" class="img-fluid" src="{{type.imageUrl}}" alt="">
                        </td>
                        <td>
                            <button placement="right" ngbTooltip="delete" tooltipClass="custom-tooltip" nbButton status="danger" size="small" shape="round" hero (click)="deletePOSType()">
                                <nb-icon icon="trash-2-outline"></nb-icon>
                            </button>
                            <button placement="right" ngbTooltip="edit" tooltipClass="custom-tooltip" nbButton status="success" size="small" shape="round" hero (click)="onEditPOSType(type)">
                              <nb-icon icon="edit-outline"></nb-icon>
                          </button>
                        </td>
                    </tr>
                </tbody>
            </table>
          </div>
          <div class="py-3 text-center" *ngIf="types.length == 0">
            <p>No available POS Types to show</p>
          </div>
        </nb-card-body>
      </nb-card>
    </nb-tab>
  </nb-tabset>
</nb-card>
