<div class="row">
    <div class="col-12">
        <nb-card>
            <nb-card-header class="d-flex-column d-md-flex justify-content-between align-items-center">
                <div class="search">
                    <nb-form-field>
                        <nb-icon nbPrefix icon="search-outline" status="warning" pack="eva"></nb-icon>
                        <input type="text" status="basic" nbInput placeholder="search by business name"
                            (input)="onSearch($event)" autofocus>
                    </nb-form-field>
                </div>
                <button nbButton hero (click)="onAddBusiness()">
                    <nb-icon icon="plus-outline"></nb-icon> add Business
                </button>
            </nb-card-header>
            <nb-card-body>
                <h5 class="fw-bold">Business</h5>
                <div class="table-responsive">
                    <table class="table table-bordered" [nbSpinner]="loading" nbSpinnerStatus="basic">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Branches</th>
                                <th scope="col">Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let business of businessesList; let i = index ">
                                <th scope="row">{{ i + 1 }}</th>
                                <td>{{ business.name }}</td>
                                <td>{{ business.noOfBranches }}</td>
                                <td>
                                    <!-- <button class="m-0" placement="left" ngbTooltip="Dashboard"
                                        nbButton status="warning" size="small" shape="round" hero>
                                        <nb-icon icon="grid-outline"></nb-icon>
                                    </button> -->
                                    <button placement="top" ngbTooltip="Edit"
                                        class="mx-md-2 my-2 my-md-0" nbButton status="warning" size="small" shape="round" hero
                                        (click)="onRename(business.name, business.email, business.phone, business.id)">
                                        <nb-icon icon="edit-outline"></nb-icon>
                                    </button>
                                    <button class="m-0" placement="right" ngbTooltip="{{business.disabled? 'enable' : 'disable'}}"  nbButton
                                        status="{{business.disabled? 'danger' : 'success'}}" size="small" shape="round"
                                        hero (click)="onDisable(business.id, business.name, business.disabled)">
                                        <nb-icon icon="{{business.disabled? 'eye-off-outline' : 'eye-outline'}}">
                                        </nb-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="table pagination">
                      <ul class="pagination justify-content-end mt-3">
                        <li class="page-item" [ngClass]="{ 'disabled': !prevPage }" (click)="onPrevious()">
                          <span class="page-link">Prev</span>
                        </li>
                        <li class="page-item" [ngClass]="{ 'active': pageNumber == page }" *ngFor="let page of pages"
                          (click)="onPageChange(page)"><span class="page-link">{{ page }}</span></li>
                        <li class="page-item" [ngClass]="{ 'disabled': !nextPage }" (click)="onNext()">
                          <span class="page-link">Next</span>
                        </li>
                      </ul>
                    </nav>
                </div>
            </nb-card-body>
        </nb-card>
    </div>
</div>
