<div class="row">
  <div class="col-12">
    <nb-card>
      <nb-card-header>
        <div class="text-right">
          <button nbButton status="success" hero (click)="onAddEditWorker()">
            <nb-icon icon="plus-outline"></nb-icon> Add Worker
          </button>
        </div>
      </nb-card-header>
      <nb-card-body>
        <div class="table-responsive" *ngIf="workers.length > 0">
          <table class="table table-bordered" [nbSpinner]="loading" nbSpinnerStatus="basic">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let worker of workers; let i = index ">
                <td>{{ i + 1 }}</td>
                <td>
                  {{worker.firstname}} {{worker.lastname}}
                </td>
                <td>
                  <button placement="right" ngbTooltip="Edit" tooltipClass="custom-tooltip" nbButton status="info"
                    size="small" shape="round" hero (click)="onAddEditWorker(worker)">
                    <nb-icon icon="edit-outline"></nb-icon>
                  </button>
                  <button placement="right" ngbTooltip="Delete" tooltipClass="custom-tooltip" nbButton status="danger"
                    size="small" shape="round" hero (click)="onDeleteWorker(worker.id)">
                    <nb-icon icon="trash-outline"></nb-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <nav aria-label="table pagination">
            <ul class="pagination justify-content-end mt-3">
              <li class="page-item" [ngClass]="{ 'disabled': !prevPage }" (click)="onPrevious()">
                <span class="page-link">Prev</span>
              </li>
              <li class="page-item" [ngClass]="{ 'active': pageNumber == page }" *ngFor="let page of pages"
                (click)="onPageChange(page)"><span class="page-link">{{ page }}</span></li>
              <li class="page-item" [ngClass]="{ 'disabled': !nextPage }" (click)="onNext()">
                <span class="page-link">Next</span>
              </li>
            </ul>
          </nav> -->
        </div>
        <div class="py-3 text-center" *ngIf="workers.length == 0">
          <p>this branch has no workers yet.</p>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>
