<div class="log d-flex align-items-center py-2">
    <img class="img-fluid rounded-circle" src="assets/imgs/avatar.svg" width="45" height="45" alt="user">
    <div class="ms-3 w-100">
        <p class="mb-1">{{ user }}</p>
        <div class="d-flex justify-content-between align-items-center">
            <span class="action">{{ action }}</span>
            <span class="time">{{ date | date: 'shortTime'}}</span>
        </div>
    </div>
</div>
