import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.scss']
})
export class ImportExportComponent implements OnInit {
  action: string = "import";
  constructor() { }

  ngOnInit(): void {
  }

}
