<nb-card [nbSpinner]="isLoading">
  <nb-card-body>
    <div class="row">
      <div class="col-md-6">
        <input nbInput placeholder="search by item name.." id="search" class="search-input" (input)="onSearch($event)" />
      </div>
      <div class="col-md-6">
        <div class="btn-container">
          <button nbButton status="primary" (click)="newItem()">
            <nb-icon icon="plus" [options]="{ animation: { type: 'shake' } }"></nb-icon>
            New Item
          </button>
        </div>
      </div>
    </div>
    <table [nbTreeGrid]="dataSource" [nbSort]="dataSource" (sort)="updateSort($event)">
      <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns"></tr>
      <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumns"></tr>

      <ng-container [nbTreeGridColumnDef]="customColumn">
        <th nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(customColumn)" *nbTreeGridHeaderCellDef>
          {{ customColumn }}
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">
          <!-- <nb-fs-icon
            [expanded]="row.expanded"
            [isExpandable]="row.children"
          ></nb-fs-icon> -->
          {{ row.data[customColumn] }}
        </td>
      </ng-container>

      <ng-container *ngFor="let column of defaultColumns; let index = index" [nbTreeGridColumnDef]="column"
        [showOn]="getShowOn(index)">
        <th nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(column)" *nbTreeGridHeaderCellDef>
          {{ column }}
        </th>
        <div *ngIf="column == 'icon'">
          <td nbTreeGridCell *nbTreeGridCellDef="let row" (click)="viewIcon(row.data)">
            <ng-icons [imgUrl]="row.data[column]"></ng-icons>
          </td>
        </div>
        <div *ngIf="column == 'quantity'">
          <td nbTreeGridCell *nbTreeGridCellDef="let row">
            <div class="d-flex align-items-center" *ngIf="row.data[column]">
              <button ghost nbButton (click)="updateItemQty(row.data, 'decrease')">
                <nb-icon icon="minus-outline" class="item-ico"></nb-icon>
              </button>
              <span class="mx-2">{{row.data[column]}}</span>
              <button ghost nbButton (click)="updateItemQty(row.data,'increase')">
                <nb-icon icon="plus-outline" class="item-ico"></nb-icon>
              </button>
            </div>
            <span *ngIf="!row.data[column]">limited</span>
          </td>
        </div>
        <div *ngIf="column == 'options'">
          <td nbTreeGridCell *nbTreeGridCellDef="let row" style="padding-left: 0">
            <!-- {{row.data | json}} -->
            <nb-button-group status="warning" ghost style="margin-bottom: 12px">
              <button nbButton (click)="deleteItem(row.data)">
                <nb-icon icon="trash-2-outline" class="item-ico"></nb-icon>
              </button>
              <button nbButton (click)="updateItem(row.data)">
                <nb-icon icon="edit-2-outline" class="item-ico"></nb-icon>
              </button>
              <button nbButton (click)="updateItemVisibilty(row.data)">
                <nb-icon icon="{{row.data.isVisible? 'eye-outline' : 'eye-off-outline'}}" class="item-ico"></nb-icon>
              </button>
              <!-- <button nbButton>
                <nb-icon icon="eye-off-outline" class="item-ico"></nb-icon>
              </button>
              <button nbButton>
                <nb-icon icon="eye-off-outline" class="item-ico"></nb-icon>
              </button> -->
            </nb-button-group>
            <span>
              <button nbButton ghost status="warning" (click)="updatePlace(row.data, 'up')"
                [disabled]="row.data.place == 1">
                <nb-icon icon="arrow-upward-outline" class="item-ico"></nb-icon>
              </button>
              <button nbButton ghost status="warning" (click)="updatePlace(row.data, 'down')"
                [disabled]="row.data.place == itemCount + 1">
                <nb-icon icon="arrow-downward-outline" class="item-ico"></nb-icon>
              </button>
            </span>
          </td>
        </div>
      </ng-container>

    </table>
    <nav aria-label="table pagination">
      <ul class="pagination justify-content-end mt-3">
        <li class="page-item" [ngClass]="{ 'disabled': !prevPage }" (click)="onPrevious()">
          <span class="page-link">Prev</span>
        </li>
        <li class="page-item" [ngClass]="{ 'active': pageNumber == page }" *ngFor="let page of pages"
          (click)="onPageChange(page)"><span class="page-link">{{ page }}</span></li>
        <li class="page-item" [ngClass]="{ 'disabled': !nextPage }" (click)="onNext()">
          <span class="page-link">Next</span>
        </li>
      </ul>
    </nav>
  </nb-card-body>
</nb-card>
