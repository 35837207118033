<!-- <div class="row">
    <div class="col-12 mb-3">
        Date
    <nb-select placeholder="" [(selected)]="selectedItem">
        <nb-option *ngFor="let date of posdates" value="{{date.id}}">{{date.lastused}}</nb-option>
      </nb-select>
      <div class="padding"></div>
      <nb-layout class="wrapper mb-3">
       </nb-layout>
    </div>
</div> -->

<div class="row">
    <div class="col-12 mb-3">
        <input nbInput placeholder="Date" [nbDatepicker]="datepicker">
        <nb-datepicker #datepicker (dateChange)="onDateChange($event)"></nb-datepicker>
    </div>
    <div class="col-12">
        <!-- <log-monitor
          [animated]="true"
          [icons]="true"
          [logStream]="(logStream$ | async) ?? logs[0]"
          theme="light"
          title="POS android Device logs"
        ></log-monitor> -->


    </div>
</div>
