import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TableService } from '../../../services/table.service';


@Component({
  selector: 'app-table-form',
  templateUrl: './table-form.component.html'
})
export class TableFormComponent implements OnInit {
  @Input() branchId: any;
  @Input() table: any;
  submitted: boolean = false;
  tableForm : FormGroup = this.fb.group({
    branchId: ['', Validators.required],
    tableNumber: ['', Validators.required],
    tableName: ['', Validators.required],
    tableCapacity: ['', Validators.required],
  })
  constructor(private fb: FormBuilder,
     private tableService: TableService,
      private nbToaster: NbToastrService, 
      private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    if (this.branchId) {
      this.tableForm.get('branchId')?.setValue(this.branchId);
    }
    if (this.table) {
      this.tableForm.removeControl('branchId');
      this.tableForm.addControl('id', new FormControl(this.table.id));
      this.tableForm.patchValue({
        tableNumber: this.table.tableNumber,
        tableName: this.table.tableName,
        tableCapacity: this.table.tableCapacity,
      });

      console.log(this.tableForm.value)
    }
  }

  submit() {
    this.submitted = true;
    if (this.tableForm.valid) {
      if (this.table && this.table.id) {
        this.tableService.editTable(this.tableForm.value).subscribe(res => {
          //console.log(res)
          this.submitted = false;
          this.activeModal.close(res);
        }, err => {
          console.log(err)
          this.nbToaster.show(err.message, 'ERROR', {
            status: 'danger',
            hasIcon: false
          });
          this.submitted = false;
        })
      } else {
        this.tableService.createTable(this.tableForm.value).subscribe(res => {
          console.log(res)
          this.submitted = false;
          this.activeModal.close(res);
        }, err => {
          console.log(err)
          this.nbToaster.show(err.message, 'ERROR', {
            status: 'danger',
            hasIcon: false
          });
          this.submitted = false;
        })
      }
    }
  }

  onCancel() {
    this.activeModal.dismiss();
  }
}
