import { Component, OnInit } from '@angular/core';
import {NgxDropzoneChangeEvent, NgxDropzoneComponent} from "ngx-dropzone";
import {Item} from "../../models/item.model";
import {HttpErrorResponse} from "@angular/common/http";
import {ItemsService} from "../../services/items.service";
import {Subscription} from "rxjs";
import {NbDialogRef, NbToastrService} from "@nebular/theme";

@Component({
  selector: 'app-branch-items-logo',
  templateUrl: './branch-items-logo.component.html',
  styleUrls: ['./branch-items-logo.component.scss']
})
export class BranchItemsLogoComponent implements OnInit {
  isLoading: boolean = false;
  logoName: string = "";
  item: Item | undefined;
  imgFile: File | undefined
  placeholder: string = "https://socialimpact.com/wp-content/uploads/2021/03/logo-placeholder-300x210.jpg";
  branchID?:string;
  private subscription?: Subscription;
  constructor(private itemService:ItemsService, private toastrService: NbToastrService, private dialogRef: NbDialogRef<BranchItemsLogoComponent>) { }

  ngOnInit(): void {
  }

  uploadItemLogo(formData: FormData) {
  if (this.logoName){
    this.isLoading = true;
    let id = this.item!.itemId
    this.subscription = this.itemService.uploadItemLogo(formData, id, this.logoName).subscribe(
      (res:any) => {
        this.isLoading = false
        this.toastrService.success("Logo uploaded successfully");
        // this.dialogRef.close()
        // this.itemService.getImage(res.url).subscribe((res: any) => {
        //   this.item!.imageUrl = res
        //   this.placeholder = res;
        // })
        this.dialogRef.close()
        this.item!.imageUrl = res.url
        this.placeholder = res.url
        //console.log(res);
      },
      (err:HttpErrorResponse) => {
        this.isLoading = false;
        console.log(err);
        this.toastrService.danger(err.message)
        this.dialogRef.close()
      }
    )
  }else {
    this.toastrService.danger("Please enter a logo name")
  }
  }

  onClick(drop: NgxDropzoneComponent) {
    drop.showFileSelector()
  }

  onSelect($event: NgxDropzoneChangeEvent) {
    this.imgFile = $event.addedFiles[0]
    let formData = new FormData();
    formData.append('logofile', this.imgFile!);
    this.uploadItemLogo(formData)
  }
}
