<nb-card [nbSpinner]="isLoading">
  <nb-card-header>
    <h5>{{ item ? "Update" : "New" }} Item</h5>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-12 col-md-8 mb-3">
        <form [formGroup]="newItem">
          <div class="row">
            <!-- <div class="mb-3 col-12">
              <label class="mb-2">Sub Category</label>
              <nb-form-field>
                <nb-icon nbSuffix class="{{searching? 'spinner-grow spinner-grow-sm' : '' }}"
                  icon="{{searching? 'loader-outline' : 'search-outline'}}" status="warning"></nb-icon>
                <input type="text" nbInput fullWidth (input)="onChange($event.target)" placeholder="Search"
                  [nbAutocomplete]="auto" [(ngModel)]="selectedSubCategory" [ngModelOptions]="{'standalone': true}"
                  [disabled]="this.item? true : false" />
                <nb-autocomplete #auto [activeFirst]="true" (selectedChange)="patchSubCategoryValue($event)">
                  <nb-option *ngFor="let subCategory of filteredOptions$ | async"
                    [value]="(subCategory.subCategoryId + ' - ' +  subCategory.subCategoryNameEN)">
                    {{ subCategory.subCategoryNameEN }}
                  </nb-option>
                </nb-autocomplete>
              </nb-form-field>
            </div> -->
            <div class="mb-3 col-md-6">
              <label class="mb-2">Category</label>
              <nb-select [(selected)]="selectedCategory" placeholder="select a category" fullWidth>
                <nb-option (selectionChange)="categoryChange(category.subCategories)" *ngFor="let category of categories" [value]="category">{{category.categoryNameEN}}</nb-option>
              </nb-select>
            </div>
            <div class="mb-3 col-md-6">
              <label class="mb-2">Subcategory</label>
              <nb-select [(selected)]="selectedSubCategory" placeholder="select a subcategory" formControlName="SubCategoryId" fullWidth>
                <nb-option (selectionChange)="patchSubCategoryValue(subcategory.subCategoryId)" *ngFor="let subcategory of subCategories" [value]="subcategory">{{subcategory.subCategoryNameEN}}</nb-option>
              </nb-select>
            </div>
            <div class="mb-3 col-12 col-md-6">
              <label class="mb-2">Arabic Item Name</label>
              <input (input)="autoTranslate && detectChange('ar', $event)" type="text" formControlName="ItemNameAR"
                nbInput fullWidth />
            </div>

            <div class="mb-3 col-12 col-md-6">
              <label class="mb-2">English Item Name</label>
              <input (input)="autoTranslate && detectChange('en', $event)" type="text" formControlName="ItemNameEN"
                nbInput fullWidth />
            </div>

            <div class="mb-3 col-12 col-md-6">
              <label class="mb-2">Turkish Item Name</label>
              <input (input)="autoTranslate && detectChange('tr', $event)" type="text" formControlName="ItemNameTR"
                nbInput fullWidth />
            </div>

            <div class="mb-3 col-12 col-md-6">
              <label class="mb-2">French Item Name</label>
              <input (input)="autoTranslate && detectChange('fr', $event)" type="text" formControlName="ItemNameFR"
                nbInput fullWidth />
            </div>
            <div class="mb-3 col-12">
              <nb-checkbox [(ngModel)]="autoTranslate" [ngModelOptions]="{'standalone': true}">Auto translate item
                name</nb-checkbox>
            </div>
            <div class="mb-3 col-12">
              <label class="mb-2">Item Type
                <small class="align-items-center d-block d-flex ng-star-inserted text-danger"
                  *ngIf="this.item && this.item.itemTypeID == 2">
                  <nb-icon class="mr-1" nbSuffix icon="alert-circle-outline" status="danger"></nb-icon>
                  changing the item type will delete all the current
                  product Add Ons
                </small>
              </label>
              <nb-radio-group (valueChange)="onTypeChange($event)" formControlName="ItemTypeID" status="success"
                class="d-md-flex">
                <nb-radio [value]="1" selected>Service</nb-radio>
                <nb-radio [value]="2">Product</nb-radio>
                <nb-radio [value]="3">Add On</nb-radio>
              </nb-radio-group>
            </div>
            <div class="col-12" *ngIf="newItem.get('ItemTypeID')?.value == 2">
              <label class="mb-2 w-100">Product add ons</label>
              <nb-tag-list *ngIf="itemDependencies.length > 0">
                <nb-tag size="small" *ngFor="let dependency of itemDependencies; let i = index" appearance="outline"
                  [text]="dependency.relatedItemNameEn?? dependency.itemNameEN" status="success"></nb-tag>
              </nb-tag-list>
              <button placement="right" ngbTooltip="add a new Add-On" tooltipClass="custom-tooltip" nbButton hero
                status="success" class="rounded-circle shadow mx-3" (click)="dialogService.open(dialog)">
                <nb-icon icon="plus-outline"> </nb-icon>
              </button>
              <!-- <table class="table table-borderd">
                <thead>
                  <tr>
                    <th>no.</th>
                    <th>name</th>
                    <th>relation</th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let dependency of itemDependencies; let i = index ">
                      <td>{{i + 1}}</td>
                      <td>{{dependency.relatedItemNameEn}}</td>
                      <td>{{dependency.relationName}}</td>
                    </tr>
                </tbody>
              </table> -->
            </div>
          </div>
        </form>
      </div>
      <div class="col-12 col-md-4 item-logo">
        <ngx-dropzone class="justify-content-center m-3" (change)="onSelect($event)" [multiple]="false"
          accept="image/*">
          <ngx-dropzone-label>Drop the item logo to upload</ngx-dropzone-label>
          <ngx-dropzone-preview *ngIf="itemImage" [removable]="true">
            <ngx-dropzone-label>{{ itemImage.name }}</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div>

      <div class="col-12">
        <label class="mb-2">Pricing: </label>
        <form [formGroup]="newItemPricing">
          <div class="row">
            <div class="col-md-2 col-sm-4 input-block">
              <div>
                Can Have Stock:
              </div>
              <nb-toggle size="small" formControlName="CanHaveStock"></nb-toggle>
            </div>
            <div class="col-md-2 col-sm-4 input-block">
              <div>
                Face Price:
              </div>
              <input type="text" formControlName="FacePrice" fieldSize="small" nbInput placeholder="Face Value" />
            </div>
            <div class="col-md-2 col-sm-4 input-block">
              <div>
                Discount:
              </div>
              <input type="text" formControlName="DiscountAmount" fieldSize="small" nbInput placeholder="Discount" />
            </div>
            <div class="col-md-2 col-sm-4 input-block">
              <div>
                isOpenPrice?:
              </div>
              <nb-toggle size="small" formControlName="IsOpenPrice" (checkedChange)="onOpenPriceChange($event)"></nb-toggle>
            </div>
            <div class="col-md-2 col-sm-4 input-block">

              <div>
                isOpenQuantity?:
              </div>
              <nb-toggle size="small" formControlName="IsOpenQuantity"></nb-toggle>
            </div>
            <div class="col-md-2 col-sm-4 input-block">
              <div>
                Minimum Price:
              </div>
              <input type="text" formControlName="MinimumPrice" fieldSize="small" nbInput placeholder="Min Price" />
            </div>
            <div class="col-md-2 col-sm-4 input-block">
              <div>
                Cost Price:
              </div>
              <input type="text" formControlName="BuyingPrice" fieldSize="small" nbInput placeholder="Cost Price" />
            </div>

            <div class="col-md-2 col-sm-4 input-block">
              <div>
                Max Price:
              </div>
              <input type="text" formControlName="MaximumPrice" fieldSize="small" nbInput placeholder="Max Price" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer class="text-right">
    <button nbButton status="danger" (click)="onClose()">Cancel</button>
    <button nbButton status="success" (click)="onSave()">{{this.item? 'Update ': 'Save'}}</button>
  </nb-card-footer>
</nb-card>
<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Select Add Ons for this Product</nb-card-header>
    <nb-card-body>
      <input class="mb-2" type="text" (input)="onSearchAddOn($event)" nbInput fullWidth placeholder="Search Add ons" />
      <div class="d-flex flex-column">
        <nb-checkbox [checked]="RelatedItemIds.includes(addon.itemId)" *ngFor="let addon of addons"
          (checkedChange)="toggle(addon)">{{addon.itemNameEN}}</nb-checkbox>
      </div>
    </nb-card-body>
    <nb-card-footer class="text-right">
      <button nbButton (click)="ref.close()">ok</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
