import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient) { }

  getOrders(branchId: number, page?: number, searchKey?: string) {
    let filterInfos = [];

    if(searchKey) {
      filterInfos.push({
        "logical": "And",
        "propertyName": "customer.fullname",
        "value": searchKey,
        "operator": "Contains"
      })
    }

    let body = {
      "filterInfos": filterInfos,
      "orderInfos": [
        {
          "orderType": "Asc",
          "property": "CreatedAt"
        }
      ],
      "columns": [],
      "count": 10,
      "page": page ? page : 1
    };
    return this.http.post(`${environment.baseURL}/Order/GetOrders?branchId=${branchId}`, body);
  }

  getOrderStatuesTypes(branchId: number) {
    return this.http.get(`${environment.baseURL}/Order/OrderStatusTypes?${branchId}`);
  }

  changeOrderStatus(body: any) {
    return this.http.patch(`${environment.baseURL}/Order/ChangeStatus`, body)
  }

  getOrderDetails(orderId: number) {
    return this.http.get(`${environment.baseURL}/Order/OrderDetails?orderId=${orderId}`);
  }
}
