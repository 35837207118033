import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { SuggestService } from '../../../services/suggest.service';

@Component({
  selector: 'app-reference-upload-img',
  templateUrl: './reference-upload-img.component.html',
  styleUrl: './reference-upload-img.component.scss'
})
export class ReferenceUploadImgComponent implements OnInit {
  @Input() item:any;
  @Input() BusinessTypeId:any;
  @Output() updateDataEvent = new EventEmitter<void>();

  @Input() isCategory=false;
  @Input() isSubCategory=false;
  @Input() isitem=false;

  imageUrl: string | ArrayBuffer | null = null;
  fileToUpload: File | null = null;

  constructor (
    private nbToastr: NbToastrService,
    private suggestService : SuggestService,
    protected dialogRef: NbDialogRef<ReferenceUploadImgComponent>

  ) { }
  ngOnInit(): void {
    
  }
  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  
  onDragLeave(event: DragEvent) {
    event.preventDefault();
  }

  
  onDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.fileToUpload = event.dataTransfer.files[0];
      this.previewImage(this.fileToUpload);
    }
  }

 
  onFileSelected(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      this.fileToUpload = event.target.files[0];
      this.previewImage(this.fileToUpload!);
    }
  }

  
  previewImage(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      this.imageUrl = reader.result;
    };
    reader.readAsDataURL(file);
  }

  
  removeImage() {
    this.imageUrl = null;
    this.fileToUpload = null;
  }

  saveChanges() { 
    if (this.fileToUpload) {
      let formData = new FormData();
      formData.append('File', this.fileToUpload!);
      formData.append('SuggestionId', this.item.id);
      formData.append('BusinessTypeId', this.BusinessTypeId);
      console.log(this.fileToUpload);
      formData.forEach((value, key) => {
        console.log(`${key}: ${value}`);
      });
      if (this.isCategory) {
        this.suggestService.addCategoryImage(formData).subscribe({
          next:(res: any) => {
            console.log(res);
    
            this.nbToastr.success('Logo uploaded successfully','Success');
            this.updateDataEvent.emit();
            this.dialogRef.close({ updated: true });
            },
            error:(err: any) => {
              console.log(err);
              this.nbToastr.danger(err.message);
            }         
        });
        
      }
      else if (this.isSubCategory){
        this.suggestService.addSubCategoryImage(formData).subscribe({
          next:(res: any) => {
            console.log(res);
    
            this.nbToastr.success('Logo uploaded successfully','Success');
            this.updateDataEvent.emit();
            this.dialogRef.close({ updated: true });
            },
            error:(err: any) => {
              console.log(err);
              this.nbToastr.danger(err.message);
            }         
        });
      }
      else if (this.isitem)
      {
        this.suggestService.addItemImage(formData).subscribe({
          next:(res: any) => {
            console.log(res);
    
            this.nbToastr.success('Logo uploaded successfully','Success');
            this.updateDataEvent.emit();
            this.dialogRef.close({ updated: true });
            },
            error:(err: any) => {
              console.log(err);
              this.nbToastr.danger(err.message);
            }         
        });
      }
    }
    else {
      this.nbToastr.warning('No image selected', 'Warning');

    }
  }
  
}
