import { Component, OnInit } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { NbToastrService } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FeatureService } from '../../../services/feature.service';

@Component({
  selector: 'app-add-package',
  templateUrl: './add-package.component.html',
  styleUrls: ['./add-package.component.scss']
})
export class AddPackageComponent implements OnInit {
  nameInLanguages: any[] = [
    { languageId: 1 },
    { languageId: 2 },
    { languageId: 3 },
    { languageId: 4 },
  ];

  priceInCurrencies: any[] = [
    { currencyId: 1 },
    { currencyId: 2 },
    { currencyId: 3 },
    // { currencyId: 4},
  ];
  featuregroups = [];
  businessTypes = [];
  featureIds: number[] = [];
  submitted: boolean = false;
  valid: boolean = true;
  canBeTrial: boolean = false;
  selectedFeatureGroup: any;
  selectedBusinessType: any;
  createdBy = '';
  featureDetails: any;
  constructor(private authService: NbAuthService, private nbToaster: NbToastrService, private activeModal: NgbActiveModal, private featureService: FeatureService) { }

  ngOnInit(): void {
    this.getAllFeatures();
    this.authService.onTokenChange().subscribe(res => {
      this.createdBy = res.getPayload().preferred_username;
    });
  }

  onSave() {
    this.submitted = true;
    if (this.nameInLanguages[0].name && this.nameInLanguages[1].name && this.nameInLanguages[2].name && this.nameInLanguages[3].name) {
      this.valid = false;
      let packageBody = {
        nameInLanguages: this.nameInLanguages,
        priceInCurrencies: this.priceInCurrencies,
        featureIds: this.featureIds,
        canBeTrial: this.canBeTrial,
        createdBy: this.createdBy
      }
      console.log(packageBody);
      this.featureService.createPackage(packageBody).subscribe(res => {
        // console.log({res});
        this.onClose(res);
      }, err => {
        //console.log({ err })
        this.nbToaster.show(err.erorr.messgae, 'ERROR', {
          status: 'danger',
          hasIcon: false
        });
      });

      this.submitted = false;
    } else {
      this.valid = false;
    }
  }

  onCheckedChange(feature: any) {
    // console.log(feature)
    let featureId = this.featureIds.find(id => {
      return id == feature.featureId
    });

    if (featureId) {
      this.featureIds = this.featureIds.filter(function (item) {
        return item !== featureId
      })
    } else {
      this.featureIds.push(feature.featureId);
    }

    console.log(this.featureIds)
  }

  onClose(msg: any) {
    this.activeModal.close(msg);
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  getAllFeatures() {
    this.featureService.getFeatureGroupsWithDetail().subscribe({
      next: (res: any) => {
        this.featureDetails = res;
      },
      error: (err) => {
        console.log(err.error)
      }
    })
  }

}
