import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import { BuisnessService } from '../../services/buisness.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { GoogleMap } from '@angular/google-maps';

import { NbToastrService } from '@nebular/theme';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddSmsComponent } from './add-sms/add-sms.component';
import { EditSmsComponent } from './edit-sms/edit-sms.component';
// import { AddSmsNotificationComponent } from '../sms-notification/add-sms-notification/add-sms-notification.component';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureService } from '../../services/feature.service';
import { Buisness } from '../../models/buisness.model';
import { SalesmanService } from '../../services/salesman.service';
import { SmsnotificationsService } from '../../services/smsnotifications.service';
//import { AddBusinessFormComponent } from '../business/add-business-form/add-business-form.component';


@Component({
  selector: 'app-new-branch',
  templateUrl: './new-branch.component.html',
  styleUrls: ['./new-branch.component.scss']
})
export class NewBranchComponent implements OnInit, AfterViewInit {
  @ViewChild('mapSearchField') searchField: ElementRef<HTMLInputElement> = {} as ElementRef;
  @ViewChild(GoogleMap) map!: GoogleMap;
  filteredBusinessOptions$!: Observable<Buisness[]>;
  selectedValue: string = 'old business';
  selectedBusinessId: string = "";
  selectedBusinessName: string = "";
  salesManId: string = "";
  salesMen: any;
  languages: any[] = [];
  subscriptionTypes: any[] = [];
  businessTypes: any[] = [];
  businessesList: Buisness[] = [];
  // isSelected: boolean = false;
  allowSMS: boolean = false;
  cities: any[] = [];
  countries: any[] = [];
  taxs: any[] = [];
  location: string = "";
  branchAddress: string = "";
  notificationBase: any[] = [];
  NotificationBases: any[] = [];
  balance: number = 100;
  position: { lat: number, lng: number } = { lat: 0, lng: 0 };
  marker: any = {};
  body: any = {
    Operation: 0,
    step: 0
  };
  mapZoom: number = 7;
  isDisbaled: boolean = true;
  loading: boolean = false;
  // isUploaded: boolean = false;
  branchInfoForm = new FormGroup({
    BusinessId: new FormControl(''),
    Name: new FormControl(''),
    DomainAddress: new FormControl('', [Validators.pattern(/\.catalogak.info$/), Validators.required]),
    FirstPhoneNumber: new FormControl(''),
    SecondPhoneNumber: new FormControl(''),
    Email: new FormControl(''),
    // SubscriptionTypeId: new FormControl(''),
    BusinessTypeId: new FormControl(''),
    LanguageId: new FormControl(''),
    SalesManId: new FormControl(''),
  });

  packgeInfoForm = new FormGroup({
    PackageId: new FormControl(''),
    Interval: new FormControl(''),
    Duration: new FormControl(''),
  });

  branchAddressForm = new FormGroup({
    Location: new FormControl({ value: "", disabled: true }),
    BranchAddress: new FormControl(''),
    CityId: new FormControl(''),
    // BranchId: new FormControl(null)
  });

  branchParamsForm = new FormGroup({
    CustomerAllowed: new FormControl(true),
    QueueAllowed: new FormControl(''),
    DiscountAllowed: new FormControl(0),
    ClaimAllowed: new FormControl(''),
    Payment: new FormControl([]),
    PrintAllowed: new FormControl(true),
    FooterMessage: new FormControl(''),
    RePrint: new FormControl(true),
    MerchantCopy: new FormControl(''),
    TaxAllowed: new FormControl(true),
    TRN: new FormControl(''),
    TaxID: new FormControl(''),
    TaxTypeId: new FormControl(''),
    SmsAllowed: new FormControl(false)
  });

  addBusinessForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    // joiningType: new FormControl(0),
    salesManId: new FormControl('', Validators.required)
  });

  form_data!: FormData;
  selectedCity: any;
  packages: any[] = [];

  constructor(
    private businessService: BuisnessService,
    private salesmanService: SalesmanService,
    private smsService: SmsnotificationsService,
    private toastrService: NbToastrService,
    private modalService: NgbModal,
    private router: Router,
    private featureService: FeatureService
  ) { }

  ngOnInit(): void {
    this.filteredBusinessOptions$ = of(this.businessesList);
    this.onGetSalesMan();
    this.onGetNotificationBases();
    this.onGetPackages();
  }

  private filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.businessesList.filter((optionValue: any) => optionValue.name.toLowerCase().includes(filterValue));
  }

  getFilteredOptions(value: string) {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onBusinessInput(inputVal: any) {
    //console.log(inputVal.target.value)
    if (inputVal.target.value.length >= 1) {
      this.loading = true;
      this.businessService.getBuisnesses(false).subscribe(res => {
        this.businessesList = res.businesses;
        this.filteredBusinessOptions$ = this.getFilteredOptions(inputVal.target.value);
        this.loading = false;
      }, err => console.log(err));

    }
  }

  convertString(value: string) {
    return Number(value);
  }

  //map
  mapConfig = {
    disableDefaultUI: true,
    fullscreenControl: true,
    zoomControl: true
  };

  initialCoordinates = {
    lat: 25.276987,
    lng: 55.296249
  };

  ngAfterViewInit(): void {
    //console.log('after init')
    this.onSearch()
  };

  onSearch() {
    const searchBox = new google.maps.places.SearchBox(
      this.searchField.nativeElement,
    );
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
      this.searchField.nativeElement
    );

    searchBox.addListener('places_changed', () => {
      //console.log('places changed')
      const places = searchBox.getPlaces();
      //console.log(places)
      if (places!.length == 0) {
        return;
      };

      const bounds = new google.maps.LatLngBounds();
      places!.forEach((place:any) => {
        if (!place.geometry || !place.geometry.location) {
          return;
        };

        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport)
          //console.log(bounds)
          this.location = `${place.geometry.location.lat()} ${place.geometry.location.lng()}`;
          this.position = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
          this.branchAddress = this.searchField.nativeElement.value;
          place.address_components
        } else {
          bounds.extend(place.geometry.location)
        };
      });

      this.map.fitBounds(bounds);
      this.marker = {
        position: this.position,
        title: this.searchField.nativeElement.value,
      };

      //this.marker.repaint();

    })
  }


  onMapClick(event: google.maps.MapMouseEvent) {
    // console.log('map clicked', { event })
    let bounds = this.map.getBounds();
    this.location = `${event.latLng!.lat()} ${event.latLng!.lng()}`;
    this.position = { lat: event.latLng!.lat(), lng: event.latLng!.lng() };
    //console.log(this.location)
    // this.map.fitBounds(bounds!);
    this.marker = {
      position: this.position,
      title: "",
    };
  }


  onBusinessAdd() {
    //console.log('adding business');
    if (this.body.branch) {
      delete this.body.branch.BusinessId
    }

    this.selectedBusinessName = this.addBusinessForm.get('name')!.value;
    this.body.Business = this.addBusinessForm.value;
    this.getBranchParams();

    if (this.selectedBusinessName !== '') {
      this.isDisbaled = false;
    }
    console.log(this.body)
  }

  onBusinessSelect(id: any) {
    //console.log(id);
    if (this.body.business) {
      delete this.body.business
    }

    let business = this.businessesList.filter(business => { return business.id == id });
    //console.log(business)
    this.selectedBusinessId = business[0].id;
    this.selectedBusinessName = business[0].name;
    if (this.selectedBusinessName !== '') {
      this.isDisbaled = false;
    }
    this.getBranchParams();
    console.log(this.body)
  }

  onBranchAdd() {

    if (this.selectedBusinessId) {
      this.branchInfoForm.patchValue({ BusinessId: this.selectedBusinessId });
    }
    //this.branchInfoForm.patchValue({ BusinessId: this.selectedBusinessId ? this.selectedBusinessId : null });
    // this.branchInfoForm.get('DomainAddress')
    this.body.branch = this.branchInfoForm.value;
    this.body.Package = this.packgeInfoForm.value;

    console.log(this.body)
  }

  onAddressAdd() {
    this.branchAddressForm.patchValue({ CityId: this.selectedCity.id, Location: this.location, BranchAddress: this.branchAddress ? this.branchAddress : this.branchAddressForm.get('BranchAddress')?.value });
    this.body.Address = this.branchAddressForm.getRawValue();
    this.onGetTaxs();
    console.log(this.body)
  }

  onParamsAdd() {
    //console.log(value)
    this.body.BranchParams = this.branchParamsForm.value;
    console.log(this.body)
  }

  onAllowSMS(event: any) {
    console.log(event)
    this.allowSMS = event;
    this.body.BranchParams.SmsAllowed = event;
    console.log(this.body)
  }

  // onNotificationBase(event: any) {
  //   this.notificationBase = event;
  // }

  onAddNotification() {
    this.body.Balance = this.balance;
    if (this.notificationBase) {
      this.body.NotificationBase = this.notificationBase;
    }

    this.body.ExtraParams = [];
    console.log(this.body)
  }

  onGetPackages() {
    this.featureService.getPackages().subscribe((res: any) => {
      this.packages = res;
      console.log({packages: this.packages})
    })
  }

  onGetSalesMan() {
    this.salesmanService.getSalesman().subscribe(res => {
      this.salesMen = res;
    }, err => console.log(err));
  }

  onGetLanguage() {
    this.businessService.getLanguage().subscribe(res => this.languages = res, err => console.log(err))
  }

  onGetBusinessType() {
    this.businessService.getBusinessTypes().subscribe(res => this.businessTypes = res, err => console.log(err))
  }

  onGetSubscriptionTypes() {
    this.businessService.getSubscriptionTypes().subscribe(res => this.subscriptionTypes = res, err => console.log(err))
  }

  onGetCountries() {
    this.businessService.getCountries().subscribe(res => this.countries = res.countries, err => console.log(err))
  }

  onGetCities(country: any) {
    //console.log(country)
    if (country.region == "ae") {
      this.initialCoordinates = { lat: 23.4241, lng: 53.8478 };
    } else if (country.region == "us") {
      this.initialCoordinates = { lat: 47.751076, lng: -120.740135 };
    } else if (country.region == "gb") {
      this.initialCoordinates = { lat: 54.91588228971097, lng: -2.615319977754358 };
    } else if (country.region == "tr") {
      this.initialCoordinates = { lat: 38.97877170715534, lng: 35.44514916287065 };
    }

    this.map.center;

    this.businessService.getCitiesByCountry(country.id).subscribe(res => this.cities = res.cities, err => console.log(err))
  }

  onSelectCities(val: any) {
    // console.log(val)
    this.selectedCity = val;
    if (val.name == "Sharjah") {
      this.initialCoordinates = { lat: 25.3570, lng: 55.4035 };
    } else if (val.name == "Dubai") {
      this.initialCoordinates = { lat: 25.2048, lng: 55.2708 };
    } else if (val.name == "Abu dhabi") {
      this.initialCoordinates = { lat: 24.4539, lng: 54.3773 };
    } else if (val.name == "Al ain") {
      this.initialCoordinates = { lat: 24.1302, lng: 55.8023 };
    } else if (val.name == "Istanbul") {
      this.initialCoordinates = { lat: 41.0082, lng: 28.9784 }
    }

    this.map.center;
    this.mapZoom = 10;
  }

  onGetTaxs() {
    this.businessService.getTaxs().subscribe(res => this.taxs = res, err => console.log(err));
  }

  onGetNotificationBases() {
    this.smsService.getNotificationBase().subscribe(res => {
      console.log(res)
      if (res !== null) {
        this.NotificationBases = res;
        this.NotificationBases.forEach((base: any) => {
          this.smsService.getNotificationEventNameByEventId(base.notificationEventId).subscribe(res => {
            base.eventName = res;
          }, err => console.log('Notification Base err:', err))
        })
      }

    }, err => console.log('Notification Events err:', err))
  }

  onFinish() {
    console.log('body: first', this.body);

    this.form_data = new FormData();

    this.form_data.append('Operation', '0');
    this.form_data.append('Balance', '100');
    if (this.body.Business) {
      Object.keys(this.body.Business).forEach((key) => {
        if (this.body.Business[key] !== "") {
          this.form_data.append(`Business.${this.capitalize(key)}`, this.body.Business[key])
        }
      });
    }

    Object.keys(this.body.branch).forEach((key) => {
      if (this.body.branch[key] !== "") {
        this.form_data.append(`branch.${key}`, this.body.branch[key])
      }
    });

    Object.keys(this.body.Package).forEach((key) => {
      if (this.body.Package[key] !== "") {
        this.form_data.append(`Package.${key}`, this.body.Package[key])
      }
    });

    Object.keys(this.body.Address).forEach((key) => {
      if (this.body.Address[key] !== "") {
        this.form_data.append(`Address.${key}`, this.body.Address[key])
      }
    });

    Object.keys(this.body.BranchParams).forEach((key) => {
      if (this.body.BranchParams[key] !== "") {
        this.form_data.append(`BranchParams.${key}`, this.body.BranchParams[key]);
      }

    });

    if (this.body.BranchParams.SmsAllowed) {
      this.form_data.append('NotificationBase', JSON.stringify(this.body.NotificationBase));
    }

    this.form_data.append('ExtraParams', JSON.stringify(this.body.ExtraParams));

    if (this.body.itemSheet) {
      console.log('item sheet')
      this.form_data.append('itemSheet', this.body.itemSheet);
    } else {
      this.form_data.delete('itemSheet');
    }

    // console.log(this.form_data)
    //console.log('body: end', this.body)

    this.businessService.addNewBranch(this.form_data).subscribe((res: any) => {
      this.toastrService.show(res.message, 'success', {
        status: 'success',
        hasIcon: true,
        icon: 'done-all-outline',
      });

      localStorage.removeItem('searchVal');
      this.router.navigateByUrl('home');
    }, err => {
      console.log(err)
      this.toastrService.show(err.error?.errors[0], 'error', {
        status: 'danger',
        hasIcon: true,
        icon: 'close-outline'

      });
      // this.toastrService.show(err.error.message, 'error', {
      //   status: 'danger',
      //   hasIcon: true,
      //   icon: 'close-outline'
      // });

    })

  }

  capitalize(word: string) {
    const str = word.charAt(0).toUpperCase() + word.slice(1);
    return str;
  }


  getBranchParams() {
    //console.log('params')
    this.onGetBusinessType();
    this.onGetLanguage();
    this.onGetSubscriptionTypes();
    this.onGetCountries();
  }

  onAddSms() {
    const modalRef = this.modalService.open(AddSmsComponent, { centered: true });
    modalRef.dismissed.subscribe(res => {
      //console.log(res)
      this.notificationBase.push(res);
      this.onGetNotificationBases();
      // this.onAddNotification();
    })
  }

  onEditSMS(base: any) {
    const modalRef = this.modalService.open(EditSmsComponent, { centered: true });
    modalRef.componentInstance.base = base;
    modalRef.closed.subscribe(res => {
      this.onGetNotificationBases();
      //console.log(res)
    })
  }

  onDeleteSMS(id: string) {
    this.smsService.deleteNotificationBase(id).subscribe(res => {
      this.toastrService.success(res, 'sucsess');
      this.onGetNotificationBases();
    }, err => this.toastrService.danger(err, 'Error'))
  }

  onUploaded(event: any) {
    // console.log(event)
    this.body.itemSheet = event;
    console.log(this.body)
  }

  onRemoved() {
    // console.log(event)
    delete this.body.itemSheet;
    console.log(this.body)
  }

}
