  <!--delete-->
    <nb-card>
        <nb-card-header>
          <h5 class="modal-title">Delete Cashier </h5>
        </nb-card-header>
        <nb-card-body>
          Confirm Deleting Cashier ?
        </nb-card-body>
        <nb-card-footer class="text-end">  
          <button nbButton  status="success"(click)="deleteCashier()"><nb-icon icon="save-outline"></nb-icon>Delete</button>
          <button nbButton  status="danger" (click)="cancel()"><nb-icon icon="close-outline"></nb-icon>Cancel</button>
        </nb-card-footer>
      </nb-card>      
<!--delete-->