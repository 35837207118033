<nb-card class="m-0 modal-container">
  <nb-card-header>
      <h5>{{expense ? 'Edit Expense' : 'Add Expense'}}</h5>
  </nb-card-header>
  <nb-card-body>
      <form [formGroup]="postExpenseForm">
          <div class="form-row">
              <div class="mb-3 col-12 ">
                <label class="mb-2 d-block">Branch ID</label>
                <input type="text"  nbInput disabled fullWidth [placeholder]="branchId">
              </div>
              <div class="mb-3 col-12 ">
                  <label class="mb-2 d-block">
                    Expense Type 
                    <nb-icon icon="plus-circle-outline" class="mx-1" style="cursor: pointer;" (click)="createExpenseType()"></nb-icon>
                  </label>
                  <nb-select formControlName="expenseTypeId" placeholder="Select Expense Type" fullWidth >
                    <nb-option *ngFor="let expenseType of expenseTypes; let i = index" [value]="expenseType.id">{{expenseType.name}}</nb-option>
                  </nb-select>
                  <small class="text-danger"
                  *ngIf="postExpenseForm.get('expenseTypeId')!.invalid && (postExpenseForm.get('expenseTypeId')!.touched || submitFailed) && postExpenseForm.get('expenseTypeId')!.errors?.['min']">
                  please pick Expense type. 
                  </small>
              </div>
              <div class="mb-3 col-12  ">
                  <label class="mb-2 d-block">Payment Mode</label>
                  <nb-select formControlName="paymentModeId" placeholder="Select Payment Mode" fullWidth>
                    <nb-option *ngFor="let paymentMode of paymentModes; let i = index" [value]="paymentMode.id">{{paymentMode.name}}</nb-option>
                  </nb-select>
                  <small class="text-danger"
                  *ngIf="postExpenseForm.get('paymentModeId')!.invalid && (postExpenseForm.get('paymentModeId')!.touched || submitFailed) && postExpenseForm.get('paymentModeId')!.errors?.['min']">
                  please pick Payment Mode. 
                  </small>
              </div>
              <div class="mb-3 col-12 ">
                <label class="mb-2 d-block">Note</label>
                <textarea nbInput formControlName="note" fullWidth placeholder="Enter any related text"></textarea>
              </div>
              <div class="mb-3 col-12 ">
                <label class="mb-2 d-block">Amount</label>
                <input type="number" formControlName="amount" nbInput fullWidth >
                <small class="text-danger"
                *ngIf="postExpenseForm.get('amount')!.invalid && (postExpenseForm.get('amount')!.touched || submitFailed) && postExpenseForm.get('amount')!.errors?.['min']">
                amount should be greater than zero.
                </small>
              </div>
              <div class="mb-3 col-12 ">
                <label class="mb-2 d-block">Upload Invoice</label>
                <input type="file" (change)="onFileChange($event)" nbInput fullWidth >
              </div>
              <div class="mb-3 col-12 d-flex justify-content-between">
                <label class="mb-2 d-block">Expense Date</label>
                <input formControlName="date" placeholder="Pick Expense Date" [nbDatepicker]="datePick" nbInput fullWidth>
                <nb-datepicker #datePick></nb-datepicker>
                <small class="text-danger"
                *ngIf="postExpenseForm.get('date')!.invalid && (postExpenseForm.get('date')!.touched || submitFailed) && postExpenseForm.get('date')!.errors?.['required']">
                please pick a date for the expense
                </small>
              </div>
          </div>
      </form>
  </nb-card-body>
  <nb-card-footer >
    <div class="d-flex justify-content-end">
      <button nbButton status="basic" class="d-inline-block ml-auto" (click)="onCancel()">Cancel</button>
      <button nbButton status="success" (click)="onSubmit()">Save</button>
    </div>
  </nb-card-footer>

</nb-card>
