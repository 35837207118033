<nb-card [nbSpinner]="isLoading">
  <nb-card-header>
    <h5 class="text-capitalize">{{type}} quantity</h5>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-6">
        <label class="label mb-2">Quantity</label>
        <input fullWidth type="number" [min]="data.quantity" [(ngModel)]="qty" nbInput *ngIf="type == 'increase'"/>
        <input fullWidth type="number" [max]="data.quantity" [(ngModel)]="qty" nbInput  *ngIf="type !== 'increase'"/>
      </div>
      <div class="col-6">
        <label class="label mb-2">Unit Of Measure</label>
        <nb-select fullWidth [(ngModel)]="selectedUnitOfMeasure" placeholder="Select Unit">
          <nb-option *ngFor="let unit of unitOfMeasures" [value]="unit.id">{{unit.name}}</nb-option>
        </nb-select>
      </div>
      <div class="col-12 mt-3 text-right">
        <button nbButton status="success" hero (click)="onUpadateQty()" [disabled]="selectedUnitOfMeasure && qty">
          update
        </button>
      </div>
    </div>
  </nb-card-body>
</nb-card>
