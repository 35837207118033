import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(private http: HttpClient) { }

  getUnitOfMeasures() {
    return this.http.get(`${environment.baseURL}/Stock/UnitOfMeasures`);
  }

  increaseQty(item: any) {
    return this.http.post(`${environment.baseURL}/Stock/Increase`, item);
  }

  decreaseQty(item: any) {
    return this.http.post(`${environment.baseURL}/Stock/Decrease`, item);
  }
}
