import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { ExpensesService } from '../../../services/expenses.service';
import { PostExpenseTypeRequest } from '../types/post-expense-type-request';
import { catchError, EMPTY, Subscription } from 'rxjs';

@Component({
  selector: 'app-post-expense-type-modal',
  templateUrl: './post-expense-type-modal.component.html',
  styleUrl: './post-expense-type-modal.component.scss',
})
export class PostExpenseTypeModalComponent implements OnInit, OnDestroy {
  @Input() businessId!: string;

  subscriptions:Subscription[]=[];
  
  postForm!: FormGroup;
  constructor(
    private dialogRef: NbDialogRef<PostExpenseTypeModalComponent>,
    private fb: FormBuilder,
    private expensesService: ExpensesService,
    private nbToastr: NbToastrService
  ) {}
  
  ngOnInit(): void {
  
    this.postForm = this.fb.group({
      businessId: this.fb.nonNullable.control(this.businessId),
      name: this.fb.nonNullable.control("",Validators.required)
    })
  }
  
  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    const request = { businessId:this.postForm.get("businessId")?.value,name:this.postForm.get("name")?.value } as PostExpenseTypeRequest;
    this.subscriptions.push(
      this.expensesService.postExpenseType(request)
    .pipe(
      catchError(err=>{
        this.nbToastr.danger("Something went wrong","Error")
        return EMPTY
      })
    )
    .subscribe( res => 
      this.dialogRef.close("success")
    )
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe())
  }
}

