<nb-card class="m-0">
  <nb-card-header class="d-flex justify-content-between align-items-center mb-2">
    <h5>
      {{ selectedPos ? "POS Default Param" : "Branch POS Default Param" }}
    </h5>
    <button nbButton status="basic" (click)="onCancel()">Cancel</button>
  </nb-card-header>
  <nb-card-body>
    <ngx-spinner bgColor="rgba(255,255,255,0.2)" size="large" color="#fff" type="ball-scale-multiple"></ngx-spinner>

    <!-- <form action=""> -->
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="row align-items-center device-row" [class.gap]="!model">
            <div class="col-12  d-flex align-items-center align-self-stretch first" *ngIf="selectedPos">
              <img src="{{posImg? posImg: 'https://placehold.co/100?text=image+unavailable'}}" width="100" class="img-fluid pos-img" />
            </div>

            <ng-template [ngIf]="model" [ngIfElse]="error">
              <div class="col-12 second py-3" [class.default-branch]="!selectedPos">
                <app-dynamic-form [model]="model" (onChange)="onFormChange($event)"></app-dynamic-form>
              </div>
            </ng-template>

            <ng-template #error>
              <div class="alert alert-danger">
                <strong>Error!</strong> No data found.
              </div>
            </ng-template>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <!-- <div class="row"> -->
            <div class="col-12">
              <div class="mb-3">
                <label class="label mb-2">Invoice Layout</label>
                <div class="d-flex">
                <select class="form-select flex-grow-1"  [ngModelOptions]="{standalone: true}" placeholder="Select inovice layout" [(ngModel)]="selectedTicktPrintingLayout" (change)="onLayoutParamChange($event, 'ticket')">
                  @for (layout of printingTicktLayouts; track $index) {
                  <option [ngValue]="layout">{{layout.layoutName}}</option>
                  }
                </select>
              </div>
            </div>
              <div>
                <app-view-layout *ngIf="selectedTicktPrintingLayout && branchId" [branchId]="branchId" [layout]="selectedTicktPrintingLayout"></app-view-layout>
              </div>
            <!-- </div> -->
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <!-- <div class="row"> -->
            <div class="col-12">
              <div class="mb-3">
                <label class="label mb-2">Token Layout</label>
                <div class="d-flex">
                <select  class="form-select flex-grow-1"  [ngModelOptions]="{standalone: true}" placeholder="Select Token layout" [(ngModel)]="selectedTokenPrintingLayout"  (change)="onLayoutParamChange($event, 'token')">
                  <option *ngFor="let layout of printingTokenLayouts" [ngValue]="layout">{{layout.layoutName}}</option>
                </select>
              </div>
            </div>
              <div>
                <app-view-layout *ngIf="selectedTokenPrintingLayout && branchId" [branchId]="branchId"  [layout]="selectedTokenPrintingLayout"></app-view-layout>
              </div>
            <!-- </div> -->
          </div>
        </div>
      </div>

    <!-- </form> -->
  </nb-card-body>
  <!-- <nb-card-footer class="text-right">
  </nb-card-footer> -->
</nb-card>
