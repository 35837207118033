<nb-card accent="primary" [nbSpinner]="isLoading">
  <nb-card-header> {{ category ? "Update" : "New" }} Category</nb-card-header>

  <nb-card-body>
    <form [formGroup]="newCategory">
      <img *ngIf="category" class="img-fluid" width="200" [src]="categoryImage" alt="">

      <input type="text" (input)="autoTranslate && detectChange('en', $event)" nbInput shape="rectangle" placeholder="Category English Name"
        formControlName="CategoryNameEN" />

      <input type="text"(input)="autoTranslate && detectChange('ar', $event)" nbInput shape="rectangle" placeholder="Category Arabic Name"
        formControlName="CategoryNameAR" />

      <input type="text"(input)="autoTranslate && detectChange('fr', $event)" nbInput shape="rectangle" placeholder="Category French Name"
        formControlName="CategoryNameFR" />

      <input type="text"(input)="autoTranslate && detectChange('tr', $event)" nbInput shape="rectangle" placeholder="Category Turkish Name"
        formControlName="CategoryNameTR" />

        <div class="mb-3 col-12">
          <nb-checkbox [(ngModel)]="autoTranslate" [ngModelOptions]="{'standalone': true}">Auto translate item name</nb-checkbox>
        </div>
      <ngx-dropzone  class="justify-content-center m-3"
        (change)="onSelect($event)" [multiple]="false" accept="image/*">
        <ngx-dropzone-label>Drop the item logo to {{category? 'change current logo':'upload'}}</ngx-dropzone-label>
        <ngx-dropzone-preview *ngIf="itemImage" [removable]="true">
          <ngx-dropzone-label>{{ itemImage.name }}</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>

    </form>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="success" (click)="save()">OK</button>
    <button nbButton status="danger" (click)="close()">Cancel</button>
  </nb-card-footer>
</nb-card>
