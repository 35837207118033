import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';



import {
  NbDialogRef,
  NbToastrService,
} from '@nebular/theme';
import { BuisnessService } from '../../services/buisness.service';

@Component({
  selector: 'app-select-branch-location',
  templateUrl: './select-branch-location.component.html',
  styleUrls: ['./select-branch-location.component.scss']
})
export class SelectBranchLocationComponent implements OnInit, AfterViewInit {
  @ViewChild('mapSearchField') searchField: ElementRef<HTMLInputElement> = {} as ElementRef;
  @ViewChild(GoogleMap) map!: GoogleMap;
  location: string = "";
  branchAddress: string = "";

  position: { lat: Number, lng: Number } = { lat: 0, lng: 0 };
  loading: boolean = false;
  marker: any = {};
  defaultbranchAddress:any;
  editBranchData: any = {
    address: {},
  };
  branchId: any;
  defaultLocation: any = [];
  initialCoordinates = { lat: 0, lng: 0 }
  constructor(
    private nbToastr: NbToastrService,
    protected dialogRef: NbDialogRef<SelectBranchLocationComponent>,
    public businessService: BuisnessService) {
    let val = localStorage.getItem('searchVal');

  }

  ngOnInit(): void {
    console.log(this.branchId, "branchId")
    console.log(this.initialCoordinates, "initialCoordinates")
    this.defaultLocation = this.defaultLocation.replace(/\s/g, ",")
    this.defaultLocation = this.defaultLocation.split(',')
    this.defaultLocation.push({ lat: Number(this.defaultLocation[0]), lng: Number(this.defaultLocation[1]) })
    this.marker.position = this.defaultLocation[2];
    console.log(this.marker.position,"pos")
    if (this.marker.position == undefined) {
      this.initialCoordinates = {
        lat: 25.276987,
        lng: 55.296249
      };
    } else if (this.marker.position.lat != 0 || this.marker.position.lng != 0) {
      this.initialCoordinates = {
        lat: this.marker.position.lat,
        lng: this.marker.position.lng,
      }
    }
  }
  //map
  mapConfig = {
    disableDefaultUI: true,
    fullscreenControl: false,
    zoomControl: true,
    gestureHandling: "greedy",
  };

  ngAfterViewInit(): void {
    //console.log('after init')
    const searchBox = new google.maps.places.SearchBox(
      this.searchField.nativeElement,
    );
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
      this.searchField.nativeElement
    );
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();
      if (places!.length == 0) {
        return;
      };
      const bounds = new google.maps.LatLngBounds();
      places!.forEach(place => {
        if (!place.geometry || !place.geometry.location) {
          return;
        };
        if (place.geometry.viewport) {
          // this.loading = true;
          bounds.union(place.geometry.viewport)
          this.location = `${place.geometry.location.lat()} ${place.geometry.location.lng()}`;
          this.position = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
          this.branchAddress = this.searchField.nativeElement.value;
          place.address_components
          // this.loading = false
        } else {
          bounds.extend(place.geometry.location)
        };
      });

      // this.moveMarker()
      this.map.fitBounds(bounds);
      this.marker = {
        position: this.position,
        title: this.searchField.nativeElement.value,
      };
      // this.marker.repaint();
    })
  }
  moveMarker(marker:google.maps.MapMouseEvent) {
    console.log(marker)
    this.location = `${marker.latLng!.lat()} ${marker.latLng!.lng()}`;
    this.position = { lat: marker.latLng!.lat(), lng: marker.latLng!.lng() };
    this.branchAddress = this.searchField.nativeElement.value
    this.marker = {
      position: this.position,
      title: this.searchField.nativeElement.value,
    };
  }
  Title(title:any) {
    // console.log(title,'hey')
  }
  addLocation() {
    this.editBranchData.address = {
      location: this.location,
      branchAddress: this.branchAddress,
    }
    this.loading = true;
    this.businessService
      .editBranchParams(this.editBranchData, this.branchId)
      .subscribe(
        (res: any) => {
          this.nbToastr.show('Branch Address Edited successfully.', 'success', {
            status: 'success',
            hasIcon: false,
            icon: 'checkmark-outline',
          });
          this.businessService.refresh.emit();
          this.loading = false;
          console.log(this.editBranchData);

          this.dialogRef.close()
        },
        (errors) => {
          if (errors.error.StatusCode == 500) {
            this.nbToastr.show(errors.error.Message, 'error', {
              status: 'danger',
              hasIcon: false,
              icon: 'close-outline',
            });
          }
          else {
            this.nbToastr.show(errors.title, 'error', {
              status: 'danger',
              hasIcon: false,
              icon: 'close-outline',
            });
          }
          this.businessService.refresh.emit();
          this.dialogRef.close()
        }
      );

  }
  cancel() {
    this.dialogRef.close()
  }
}  