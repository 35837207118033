import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { ItemsService } from '../../services/items.service';


@Component({
  selector: 'app-delete-item',
  templateUrl: './delete-item.component.html',
  styleUrls: ['./delete-item.component.scss'],
})
export class DeleteItemComponent implements OnInit {
  isLoading: boolean = false;
  item?: any;

  constructor(
    private dialogRef: NbDialogRef<DeleteItemComponent>,
    private itemService: ItemsService,
    private nbToastr: NbToastrService
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  deleteItem() {
    this.isLoading = true;
    this.itemService.deleteItem(this.item.id).subscribe(
      (_) => {
        this.nbToastr.success('Deleted Successfully', 'Success');
        this.isLoading = false;
        this.close();
      },
      (e) => {
        this.nbToastr.danger('Something Went Wrong', 'Failure');
        this.isLoading = false;
        this.close();
      }
    ),
      () => {
        this.close();
      };
  }
}
