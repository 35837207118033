import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { CashierService } from '../../../services/cashier.service';


@Component({
  selector: 'app-delete-cashier',
  templateUrl: './delete-cashier.component.html',
  styleUrls: ['./delete-cashier.component.scss']
})
export class DeleteCashierComponent implements OnInit {
  cashierId:string="";
  constructor(
    public cashierService:CashierService,
    private nbToastr: NbToastrService,
    protected dialogRef: NbDialogRef<DeleteCashierComponent>
    ) { }

  ngOnInit(): void {
  }
  deleteCashier() {
  this.cashierService.deleteCashier(this.cashierId).subscribe((res:any)=>{   
    if(res.isSucceeded == true) {
      this.onClose('success');
    } else {
      this.onClose(res.message);
    }
    this.nbToastr.show('Cashier Deleted successfully.', 'success', {
      status: 'success',
      hasIcon: false,
      icon: 'checkmark-outline'
    });
    this.cashierService.refresh.emit();
    this.dialogRef.close();   
  },
  (err) => {
    console.log(err)
    this.nbToastr.show('Cannot Delete Cashier', 'error', {
      status: 'danger',
      hasIcon: false,
      icon: 'close-outline'
    });
  })
  }
  onClose(msg: string) {
    this.dialogRef.close(msg);
  }
  cancel() {
    this.dialogRef.close()
  }
}
