import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  NbDialogService,
  NbSortDirection,
  NbSortRequest,
  NbToastrService,
  NbTreeGridDataSource,
  NbTreeGridDataSourceBuilder,
} from '@nebular/theme';
import { Item } from '../../models/item.model';
import { ItemsService } from '../../services/items.service';
import { NewItemComponent } from '../../modals/new-item/new-item.component';
import { BranchItemsLogoComponent } from '../../modals/branch-items-logo/branch-items-logo.component';
import { DeleteItemComponent } from '../../modals/delete-item/delete-item.component';
import { EditQtyComponent } from '../../modals/edit-qty/edit-qty.component';

interface TreeNode<T> {
  data: T;
}

interface ItemTable {
  id: number;
  name: string;
  icon: string;
  place: number;
  type: string;
  quantity: number;
  isVisible: boolean;
}

@Component({
  selector: 'app-branch-items-place',
  templateUrl: './branch-items-place.component.html',
  styleUrls: ['./branch-items-place.component.scss'],
})
export class BranchItemsPlaceComponent implements OnInit {
  isLoading: boolean = false;
  customColumn = 'name';
  itemCount: number = 0;
  branchID: string = '';
  defaultColumns = ['icon', 'quantity', 'options'];
  allColumns = [this.customColumn, ...this.defaultColumns];
  dataSource: NbTreeGridDataSource<ItemTable>;
  sortColumn: string = 'place';
  sortDirection: NbSortDirection = NbSortDirection.NONE;
  elements!: TreeNode<ItemTable>[];
  placeSorter: NbSortRequest = {
    column: 'place',
    direction: NbSortDirection.ASCENDING,
  };

  private data: Item[] = [];
  param: any;
  pages: number[] = [];
  pageNumber: number = 1;
  nextPage: boolean = true;
  prevPage: boolean = false;

  constructor(
    private dataSourceBuilder: NbTreeGridDataSourceBuilder<ItemTable>,
    private route: ActivatedRoute,
    private itemsService: ItemsService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {
    let val = localStorage.getItem('searchVal');
    this.branchID = JSON.parse(val!)!.id;
    let items = this.data.map((el) => {
      return <TreeNode<ItemTable>>{
        data: this.getItem(el),
      };
    });
    //console.log({ items });
    this.elements = items;
    this.dataSource = this.dataSourceBuilder.create(items);
  }

  ngOnInit(): void {
    this.getItems(1);
    this.itemsService.shouldReload.subscribe((_) => {
      this.getItems(1);
    });
  }

  onNext() {
    //console.log(this.pageNumber)
    this.getItems(this.pageNumber + 1);
  }

  onPrevious() {
    //console.log(this.pageNumber)
    this.getItems(this.pageNumber - 1);
  }

  onSearch(input: any) {
    if (input.target.value.length > 2) {
      this.getItems(1, input.target.value);
    } else if (input.target.value == '') {
      this.getItems(1);
    }
  }

  onPageChange(page: number) {
    this.pageNumber = page;
    this.getItems(page);
  }

  private getItems(page: number, searchKey?: string) {
    this.isLoading = true;
    this.itemsService
      .getItemsWithPagination(this.branchID!, page, searchKey ? searchKey : '')
      .subscribe(
        (res) => {
          //console.log({ res })
          this.isLoading = false;

          //pagination
          let pagination = Array.from(
            { length: res.totalPageCount },
            (_, i) => i + 1
          );
          let start =
            res.currentPageNumber >= 4 ? res.currentPageNumber - 3 : 0;
          let end =
            res.currentPageNumber < res.totalPageCount
              ? res.currentPageNumber + 3
              : res.currentPageNumber;

          let pagesToShow = pagination.slice(start, end);
          this.pages = pagesToShow;
          this.pageNumber = res.currentPageNumber;
          //  console.log({pagesToShow})
          if (res.currentPageNumber == pagination.length) {
            this.nextPage = false;
          }

          if (res.currentPageNumber > 1) {
            this.prevPage = true;
          } else {
            this.prevPage = false;
          }

          let data = res.value.map((el: any) => {
            return <TreeNode<ItemTable>>{
              data: this.getItem(el),
            };
          });
          this.data = res.value;
          this.itemCount = res.value.length;
          this.elements = data;
          this.dataSource = this.dataSourceBuilder.create(data);
          //console.log(this.dataSource, 'DS');
          this.dataSource.sort(this.placeSorter);
        },
        (err) => {
          this.isLoading = false;
          //console.log({ err });
        }
      );
  }

  getNode(data: any): TreeNode<ItemTable> {
    return <TreeNode<ItemTable>>(<unknown>{
      data: this.getItem(data),
    });
  }

  getItem(tst: Item): ItemTable {
    //console.log(tst)
    //TODO: replace with item model
    return <ItemTable>{
      id: tst.itemId,
      name: tst.itemNameEN ? tst.itemNameEN : tst.itemNameAR,
      place: tst.itemPlace,
      icon: tst.imageUrl,
      quantity: tst.quantity,
      isVisible: tst.isVisible
    };
  }

  updatePlace(row: ItemTable, dir: string) {
    //console.log({ row });
    let place;
    if (row.place > 1 && dir == 'up') {
      place = row.place - 1;
    } else {
      place = row.place + 1;
    }

    this.setItemPlace(row.id, place);
    let sorter: NbSortRequest = {
      column: 'place',
      direction: NbSortDirection.ASCENDING,
    };
    this.dataSource = this.dataSourceBuilder.create(this.elements);
    this.dataSource.toggleByIndex(0);
    this.dataSource.sort(sorter);
  }

  updateSort(sortRequest: NbSortRequest): void {
    //console.log({ sortRequest });

    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + nextColumnStep * index;
  }

  getItemFromItemTable(itemTable: ItemTable): Item {
    return this.data.find((item) => {
      return item.itemId == itemTable.id;
    })!;
  }

  updateItem(item: any) {
    this.dialogService.open(NewItemComponent, {
      closeOnBackdropClick: true,
      context: {
        branchID: this.branchID,
        item: this.getItemFromItemTable(item),
      },
    });
  }

  updateItemVisibilty(item: any) {
    let itemTable = this.getItemFromItemTable(item);
    this.itemsService.changeItemVisibilty(itemTable.itemId, !itemTable.isVisible).subscribe(res => {
      console.log(res)
      this.toastrService.success('item visibilty updated successfully');
      item.isVisible = !item.isVisible;
    }, err => {
      this.toastrService.danger('error updating item visibilty');
    })
  }

  viewIcon(itemTable: ItemTable) {
    let item = this.getItemFromItemTable(itemTable);
    let branchID = this.branchID;
    this.dialogService
      .open(BranchItemsLogoComponent, {
        context: {
          item: item,
          branchID: branchID,
        },
      })
      .onClose.subscribe(() => {
        this.getItems(this.pageNumber);
      });
  }

  deleteItem(item: any) {
    this.dialogService
      .open(DeleteItemComponent, {
        closeOnBackdropClick: true,
        context: {
          item: item,
        },
      })
      .onClose.subscribe(() => {
        this.getItems(this.pageNumber);
      });
  }

  newItem() {
    this.dialogService.open(NewItemComponent, {
      closeOnBackdropClick: true,
      context: {
        branchID: this.branchID,
      },
    });
  }

  setItemPlace(itemId: number, place: number) {
    this.isLoading = true;
    this.itemsService.setItemPlace(itemId, place).subscribe(
      (res) => {
        this.toastrService.success('Item place updated successfully');
        this.isLoading = false;
        this.getItems(this.pageNumber);
      },
      (err) => {
        this.toastrService.danger('Error updating item place');
        this.isLoading = false;
        //console.log({ err });
      }
    );
  }

  updateItemQty(data: any, type: string) {
    console.log('item>>', { data });
    const ref = this.dialogService.open(EditQtyComponent, {
      closeOnBackdropClick: true,
      context: {
        data: data,
        type: type
      },
    });

    ref.onClose.subscribe(res => {
      this.toastrService.show('quantity updated', 'success', {
        status: 'success',
        hasIcon: true,
        icon: 'check-outline',
      });

      this.getItems(1);
    })
  }
}
