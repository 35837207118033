import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  NbTreeGridDataSource,
  NbSortDirection,
  NbSortRequest,
  NbTreeGridDataSourceBuilder,
  NbSidebarService, NbDialogService, NbToastrService, NbDialogRef,
} from '@nebular/theme';
import { Observable } from 'rxjs';

import {NewItemComponent} from "../../modals/new-item/new-item.component";
import { ItemsService } from '../../services/items.service';
import { Item } from '../../models/item.model';

interface TreeNode<T> {
  data: T;
}

interface ItemTable {
  name: string;
  icon: string | Observable<any>;
  place: number;
  type: string;
  buyingPrice: number;
  faceValue: number;
  discount: number;
  openPrice: boolean;
  minPrice: number;
  maxPrice: number;
  openQuantity: boolean;
  category: string;
  itemId:number
  subCategoryId: number;
  itemTypeID: number;
}

@Component({
  selector: 'app-branch-items-pricing',
  templateUrl: './branch-items-pricing.component.html',
  styleUrls: ['./branch-items-pricing.component.scss'],
})
export class BranchItemsPricingComponent implements OnInit {
  customColumn = 'name';
  defaultColumns = [
    'icon',
    'buyingPrice',
    'faceValue',
    'discount',
    'openPrice',
    'minPrice',
    'maxPrice',
    'openQuantity',
  ];
  allColumns = [this.customColumn, ...this.defaultColumns];
  dataSource: NbTreeGridDataSource<ItemTable>;
  sortColumn: string = 'place';
  sortDirection: NbSortDirection = NbSortDirection.NONE;
  elements!: TreeNode<ItemTable>[];
  placeSorter: NbSortRequest = {
    column: 'place',
    direction: NbSortDirection.ASCENDING,
  };
  branchID: string | null;
  isLoading: boolean = false;
  itemLength: number = 0;
  pages: number[] = [];
  pageNumber: number = 1;
  nextPage: boolean = true;
  prevPage: boolean = false;
  items: any;

  constructor(
    private dataSourceBuilder: NbTreeGridDataSourceBuilder<ItemTable>,
    private sidebarService: NbSidebarService,
    private itemsService: ItemsService,
    private route: ActivatedRoute,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
  ) {
    let val = localStorage.getItem('searchVal');
    this.branchID = JSON.parse(val!)!.id;
    this.dataSource = this.dataSourceBuilder.create([{ data: { name: 'Loading...' } }]);
    this.dataSource
  }

  ngOnInit(): void {
   this.getItems(1)
  }

  onNext() {
    //console.log(this.pageNumber)
    this.getItems(this.pageNumber + 1);
  }

  onPrevious() {
    //console.log(this.pageNumber)
    this.getItems(this.pageNumber - 1);
  }

  onPageChange(page: number) {
    this.pageNumber = page;
    this.getItems(page);
  }

  onSearch(input:any) {
    if(input.target.value.length > 2) {
      this.getItems(1, input.target.value);
    } else if (input.target.value == "") {
      this.getItems(1);
    }
  }

  getItems(page: number, searchKey?: string){
    this.sidebarService.collapse();
    this.isLoading = true;
    this.itemsService.getItemsWithPagination(this.branchID!, page, searchKey? searchKey : '' ).subscribe((res) => {
      this.isLoading = false;
      this.items = res.value;
      // console.log({items})
       //pagination
       let pagination = Array.from({length: res.totalPageCount}, (_, i) => i + 1);
       let start = res.currentPageNumber >= 4? res.currentPageNumber - 3 : 0;
       let end = res.currentPageNumber < res.totalPageCount? res.currentPageNumber + 3 : res.currentPageNumber;

       let pagesToShow = pagination.slice(start, end);
       this.pages = pagesToShow;
       this.pageNumber = res.currentPageNumber;
      //  console.log({pagesToShow})
       if(res.currentPageNumber == pagination.length) {
         this.nextPage = false;
       }

       if(res.currentPageNumber > 1) {
         this.prevPage = true;
       } else {
        this.prevPage = false;
      }

      let data = res.value.map((el: any) => {
        return <TreeNode<ItemTable>>{
          data: this.getItem(el),
        };
      });
      this.itemLength = data.length;
      this.elements = data;
      this.dataSource = this.dataSourceBuilder.create(data);
     // console.log(this.dataSource, 'DS');
      this.dataSource.sort(this.placeSorter);
    });
  }

  getNode(data: any): TreeNode<ItemTable> {
    return <TreeNode<ItemTable>>(<unknown>{
      data: this.getItem(data),
    });
  }

  getItem(item: Item): ItemTable {
    return <any>{
      name: item.itemNameAR,
      place: Number(item.itemPlace),
      icon: item.imageUrl!,
      buyingPrice: item.buyingPrice,
      faceValue: item.facePrice,
      discount: item.discount,
      openPrice: item.isOpenPrice,
      minPrice: item.minimumPrice,
      maxPrice: item.maximumPrice,
      openQuantity: item.isOpenQuantity,
      category: item.subCategoryNameEN,
      itemId: item.itemId,
      subCategoryId: item.subCategoryId,
      itemTypeID: item.itemTypeID
    };
  }

  itemTableToIte(row: ItemTable): Item {
    return <Item>{
      itemNameAR: row.name,
      itemPlace: row.place,
      imageUrl: row.icon,
      buyingPrice: row.buyingPrice,
      facePrice: row.faceValue,
      discount: row.discount,
      isOpenPrice: row.openPrice,
      minimumPrice: row.minPrice,
      maximumPrice: row.maxPrice,
      isOpenQuantity: row.openQuantity,
      subCategoryNameEN: row.category,
      itemId:row.itemId,
      subCategoryId: row.subCategoryId,
      itemTypeID: row.itemTypeID
    };
  }

  updatePlace(row: ItemTable, dir: string) {
    //console.log({ row });
    let place;
    if (row.place > 1 && dir == 'up') {
      place = row.place - 1;
    } else {
      place = row.place + 1;
    }
    this.updateItemPlace(row, place, dir);

    let sorter: NbSortRequest = {
      column: 'place',
      direction: NbSortDirection.ASCENDING,
    };
    this.dataSource = this.dataSourceBuilder.create(this.elements);
    this.dataSource.toggleByIndex(0);
    this.dataSource.sort(sorter);
  }

  updateSort(sortRequest: NbSortRequest): void {
    //console.log({ sortRequest });

    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + nextColumnStep * index;
  }

  updateItemPlace(element: ItemTable, place: number, dir: string) {
    this.elements.forEach((item) => {
      if (item.data.place == place) {
        item.data.place = dir == 'up' ? place + 1 : place - 1; //move the origin downward
      }
      if (item.data.name == element.name) {
        item.data.place = place;
      }
    });
  }

  onEnter(event: Event, row: TreeNode<ItemTable>, dataEdited: string) {
    event.stopPropagation();
    //console.log({ row });
    this.updateItemPrice(row, dataEdited);
  }

  updateItemPrice(row: TreeNode<ItemTable>, dataEdited: string){
    let item: any = this.itemTableToIte(row.data);
    // console.log('updating price>>', {item},this.items);

    let modifiedItem = this.items.find((modifiedItem: Item) => {
      return modifiedItem.itemId == item.itemId;
    });

    if(modifiedItem) {
      modifiedItem[dataEdited] = item[dataEdited];
    }

    this.isLoading = true;
    this.itemsService.editItem(modifiedItem).subscribe({
      next: (res) => {
        this.isLoading = false
        //console.log({res});
        this.toastrService.success('تم تعديل السعر بنجاح', 'تم');
      },
      error: (err) => {
        this.isLoading = false
        //console.log({err});
        this.toastrService.danger('حدث خطأ أثناء تعديل السعر', 'خطأ');
        this.getItems(this.pageNumber)
      }
    })
  }

  newItem() {
   const ref = this.dialogService.open(NewItemComponent, {
      closeOnBackdropClick: true,
      context: {
        branchID: this.branchID,
      },
    });

    ref.onClose.subscribe(res => {
      this.getItems(1);
    })
  }
}
