
import { AfterViewInit, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { CouponService } from '../../services/coupon.service';

@Component({
  selector: 'app-coupon-branch',
  templateUrl: './coupon-branch.component.html',
  styleUrls: ['./coupon-branch.component.scss']
})
export class CouponBranchComponent implements  OnInit {
  coupons :  any[] = []
  loading: boolean = false;
  @Input() couponMasterId: any;
  @ViewChild(CouponBranchComponent) childView!: CouponBranchComponent;

  page = 1;
  pageSize =10;

  constructor(
    public couponService:CouponService,private router: Router, private activatedRoute: ActivatedRoute
   ) {
   }
  
  ngOnInit() {  
    console.log(this.couponMasterId,"coupon")       
     this.getCouponDetials()
  }
 
  getCouponDetials() {
    this.loading = true;    
    this.couponService.getCouponById(this.couponMasterId,false).subscribe(res => {
      this.coupons = res;
      this.loading = false;
      if(this.coupons == null){
        this.coupons = []
      }
      //pagination
      for(let i = 1; i <= this.coupons?.length; i++){
        this.coupons.push();
     }

    }, err => console.log(err));
  }
  downloadCoupon() {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.coupons);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'CouponCodes.xlsx',);
  }
}

