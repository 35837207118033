import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NbAuthService } from '@nebular/auth';
import { NbToastrService } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { PosService } from '../../../services/pos.service';


@Component({
  selector: 'app-pos-types',
  templateUrl: './pos-types.component.html',
  styleUrls: ['./pos-types.component.scss']
})
export class PosTypesComponent implements OnInit {
  @Input() posType: any;
  addPosTypeForm: FormGroup = new FormGroup({
    TypeName: new FormControl('', Validators.required),
    Image: new FormControl('', Validators.required),
    UploadedBy: new FormControl(''),
  });

  posTypeImage?: File
  submitted: boolean = false;

  constructor(
    private authService: NbAuthService,
     private activeModal: NgbActiveModal,
      private posService: PosService) { }

  ngOnInit(): void {
    if(this.posType) {
      //console.log(this.posType)
      this.addPosTypeForm.addControl('TypeId', new FormControl(this.posType.posTypeId));
      this.addPosTypeForm.patchValue({
        TypeName: this.posType.typeName,
        Image: this.posType.imageUrl,
        UploadedBy: this.posType.UploadedBy
      });
    } else {
      this.authService.onTokenChange().subscribe(res => {
        // console.log(res.getPayload())
        this.addPosTypeForm.get('UploadedBy')?.setValue(res.getPayload().preferred_username);
        this.addPosTypeForm.get('UploadedBy')?.disable();
      });
    }

  }

  onSave() {
    this.submitted = true;
    //console.log(this.addPosTypeForm.value);
    if (this.addPosTypeForm.valid) {
      let formdata = new FormData();
      for (const key in this.addPosTypeForm.getRawValue()) {
        //console.log(key, this.addPosTypeForm.getRawValue()[key]);
        formdata.append(key, this.addPosTypeForm.getRawValue()[key]);
      }

      if(this.posType) {
        //edit pos type

        this.posService.editPosType(formdata).subscribe(res => {
          //console.log(res);
          this.activeModal.close(res);
        }, err => {
          console.log(err);
          this.activeModal.close(err);
        });
      } else {
        //add new pos type

        this.posService.addPosType(formdata).subscribe(res => {
          //console.log(res);
          this.activeModal.close(res);
        }, err => {
          console.log(err);
          this.activeModal.close(err);
        });
      }

      this.submitted = false;
    }
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  updateTypeImage(event: NgxDropzoneChangeEvent) {
    this.posTypeImage = event.addedFiles[0];
    this.addPosTypeForm.get('Image')?.setValue(event.addedFiles[0]);
  }
}
