import {
  Directive,
  ElementRef,
  Input,
  TemplateRef,
  ViewContainerRef,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[lazyActivatable]',
})
export class LazyActivatableDirective {
  private changes!: MutationObserver;
  private activated: boolean = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private el: ElementRef,
    private vcr: ViewContainerRef
  ) {}

  ngAfterViewInit(): void {
    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      if (mutations[0].attributeName === 'class' && this.activated === false) {
        if (
          this.el.nativeElement.parentElement.className === 'content-active'
        ) {
          this.activated = true;
          this.changes.disconnect();
          this.vcr.createEmbeddedView(this.templateRef);
        }
      }
    });

    this.changes.observe(this.el.nativeElement.parentElement, {
      attributes: true,
    });
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }
}
