<div class="row p-4">
  <div class="col-12" *ngIf="paramType == 'business'">
    <h5 class="fw-bold text-capitalize">{{paramName}}</h5>
    <div class="branches">
      <h6 class="mb-0" *ngIf="branches.length > 0">Branches</h6>

      <div *ngIf="branches.length == 0" [nbSpinner]="loading" nbSpinnerStatus="basic">
        <strong style="display: table;margin: 0 auto;padding: 6px;">No Branches Found!</strong>
      </div>
      <div class="table-responsive" *ngIf="branches.length > 0" [nbSpinner]="loading" nbSpinnerStatus="basic">
        <table class="table table-bordered space" id="branchTable" #branchTable>
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Name</th>
              <th scope="col">POS</th>
              <th scope="col">Options</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let branch of branches | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i = index ">
              <th scope="row">{{ i + 1}}</th>
              <td>{{branch.name}}
              </td>
              <td>
                {{branch.posCount}}
              </td>
              <td class="d-flex align-items-center">
                <button (click)="goToBranch(branch)" nbButton placement="top" [disabled]="branch.isDisabled"
                  ngbTooltip="Dashboard" tooltipClass="custom-tooltip" shape="round" status="warning">
                  <nb-icon icon="grid-outline"></nb-icon>
                </button>
                <nb-toggle placement="right" ngbTooltip="Change Status" tooltipClass="custom-tooltip"
                  [checked]="branch.isDisabled"   (change)="changeStatusBranch(branch.id,branch.isDisabled)" class="mx-2">
                </nb-toggle>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="branches != null">
          <ngb-pagination [collectionSize]="branches.length" [(page)]="page" [maxSize]="5" [rotate]="true"
            [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
        </div>
      </div>
    </div>
    <div class="users">
      <nb-accordion>
        <nb-accordion-item expanded>
          <nb-accordion-item-header class="d-flex justify-content-between">
            <div class="container">
              <div class="row">
                <div class="
                  col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8
                ">
                  <h6 class="fw-bold text-capitalize">users</h6>
                </div>
                <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">

                  <button status="success" nbButton hero (click)="addUser(paramId)">
                    <nb-icon icon="plus-outline"></nb-icon> Add User
                  </button>
                </div>
              </div>
            </div>
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <div *ngIf="userList.length == 0" [nbSpinner]="loading" nbSpinnerStatus="basic">
              <strong style="text-align: center;">No Users Found!</strong>
            </div>
            <div *ngIf="userList.length > 0">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Name</th>
                      <th scope="col">Role</th>
                      <th scope="col">Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let user of userList; let i = index ">
                      <th scope="row" class="userId">{{ i + 1 }}</th>
                      <td>{{user.userName}}</td>
                      <td>{{user.role}}</td>
                      <td class="d-flex align-items-center">
                        <button nbButton hero placement="left" ngbTooltip="Rest Password" tooltipClass="custom-tooltip"
                          status="success" hero shape="round" size="small"
                          (click)="resetUserPassword(paramId,user.userName,user.id,user.email,user.password,user.confirmpassword)">
                          <nb-icon icon="refresh-outline"></nb-icon>
                        </button>
                        <button nbButton hero placement="top" ngbTooltip="Edit" tooltipClass="custom-tooltip"
                          status="primary" shape="round" size="small"
                          (click)="editUser(paramId,user.id,user.userName,user.email,user.role, user.passwordHash)">
                          <nb-icon icon="edit-outline"></nb-icon>
                        </button>
                        <button nbButton hero placement="right" ngbTooltip="Delete" tooltipClass="custom-tooltip"
                          status="warning" shape="round" size="small" (click)="deleteUser(user.id)">
                          <nb-icon icon="trash-outline"></nb-icon>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
    </div>
  </div>
</div>
