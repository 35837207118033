import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GetExpenseTypeResponse } from '../types/get-expense-type-response';
import { GetPaymentModeResponse } from '../types/get-payment-mode-response';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { Expense } from '../types/get-expenses-response';
import { PostExpenseRequest } from '../types/post-expense-request';
import { ExpensesService } from '../../../services/expenses.service';
import { catchError, EMPTY, Subscription } from 'rxjs';
import { PostExpenseTypeModalComponent } from '../post-expense-type-modal/post-expense-type-modal.component';

@Component({
  selector: 'app-post-edit-expense-modal',
  templateUrl: './post-edit-expense-modal.component.html',
  styleUrl: './post-edit-expense-modal.component.scss',
})
export class PostEditExpenseModalComponent implements OnInit, OnDestroy {
  @Input() branchId!: string;
  @Input() expenseTypes!: GetExpenseTypeResponse[];
  @Input() paymentModes!: GetPaymentModeResponse[];
  @Input() expense?: Expense;
  @Input() businessId!: string;

  postExpenseForm!: FormGroup;
  selectedFile?: Blob | File;

  submitFailed = false;

  subscriptions: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    private nbToastr: NbToastrService,
    private nbDialogService: NbDialogService,
    private expenseService: ExpensesService,
    private dialogRef: NbDialogRef<PostEditExpenseModalComponent>
  ) {}

  ngOnInit(): void {
    this.initForm();
    console.log(this.postExpenseForm.get('expenseTypeId')?.touched)
    console.log(this.postExpenseForm.get('expenseTypeId')?.errors)
  }

  initForm() {
    this.postExpenseForm = this.fb.group({
      branchId: this.fb.control(this.branchId),
      expenseTypeId: this.fb.control(0, [Validators.required,Validators.min(1)]),
      paymentModeId: this.fb.control(0, [Validators.required,Validators.min(1)]),
      amount: this.fb.control(0, [Validators.required,Validators.min(1)]),
      date: this.fb.control(new Date(),[Validators.required]),
      note: this.fb.control(''),
    });
  }

  createExpenseType() {
    if (!this.businessId) return;
    this.nbDialogService
      .open(PostExpenseTypeModalComponent, {
        context: {
          businessId: this.businessId,
        },
      })
      .onClose.subscribe((res:any) => {
        if(res){
          this.nbToastr.success("new expense type added","Added successfully")
          // fetch the expense types (to include the new one)
          this.getExpenseTypes()
        }
      });
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    console.log(this.postExpenseForm.value);
    if(!this.postExpenseForm.valid){
      this.nbToastr.danger('not allowed values, please recheck input fields', 'Validation Error');
      this.submitFailed = true;
      return;
    }
    const request = {
      ...this.postExpenseForm.value,
      file: this.selectedFile,
    } as PostExpenseRequest;

    this.subscriptions.push(
      this.expenseService
        .postExpense(request)
        .pipe(
          catchError((err) => {
            this.nbToastr.danger('Adding new expense failed', 'Error');
            return EMPTY;
          })
        )
        .subscribe((res) => {
          this.dialogRef.close('new expense added');
        })
    );
  }

  getExpenseTypes(){
    this.subscriptions.push(
      this.expenseService.getExpenseTypes(this.businessId)
      .pipe(
        catchError(err => {
          this.nbToastr.danger("failed to load expense types","error")
          return EMPTY
        })
      )
      .subscribe(res => 
        this.expenseTypes = res
      )
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subs) => {
      subs.unsubscribe();
    });
  }
}
