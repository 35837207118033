import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { BuisnessService } from '../../../services/buisness.service';
import { debounceTime } from 'rxjs';
import { AreasService } from '../../../services/areas.service';

@Component({
  selector: 'app-add-area',
  templateUrl: './add-area.component.html',
  styleUrl: './add-area.component.scss'
})
export class AddAreaComponent implements OnInit {

  @Input() area: any;
  @Input() branchID!: any; 
  @Input() countryId: any;

  submitted = false;
  addAreaForm!: FormGroup;

  cities: any[] = []; 
  filteredCities: any[] = [];
  
  areas: any[] = [];
  filteredAreas: any[] = [];
  
  selectedCity: any = null;
  selectedArea: any = null;
  
  edit:boolean=false;
  constructor(
    private fb: FormBuilder,
    private nbToastr: NbToastrService,
    private areasService : AreasService,
    private businessService: BuisnessService,
    protected dialogRef: NbDialogRef<AddAreaComponent>
  ) { }

  
  ngOnInit(): void {
    this.addAreaForm = this.fb.group({
      cityName: ['', Validators.required],
      areaName: ['', Validators.required],
      minimumOrderAmount: [null, Validators.required],
      deliveryFee: [null, Validators.required],
      maximumDeliveryDiscount: [null, Validators.required],
      branchId: ['', Validators.required]
    });
    if (this.area) {
      this.addAreaForm.get('cityName')?.disable();
      this.addAreaForm.get('areaName')?.disable();
    }

    (this.area) ? this.patchValues() : ''
    this.getCities();

    this.addAreaForm.get('cityName')!.valueChanges.pipe(debounceTime(300)).subscribe(value => {
      this.filterCities(value);
    });

    this.addAreaForm.get('areaName')!.valueChanges.pipe(debounceTime(300)).subscribe(value => {
      this.filterAreas(value);
    });

  }
  patchValues() {
    this.addAreaForm.patchValue({
      cityName: this.area.area.city.name, 
      areaName: this.area.area.name, 
      minimumOrderAmount: this.area.minOrderAmount, 
      deliveryFee: this.area.deliveryFee,
      maximumDeliveryDiscount: this.area.maxDeliveryDiscount
    });
    this.edit=true;
  }
  filterCities(value: string) {
      this.filteredCities = this.cities.filter(city =>
        city.name.toLowerCase().includes(value.toLowerCase())
      );
        // Check if the entered value exactly matches a city name
      const exactMatch = this.cities.find(city => city.name.toLowerCase() === value.toLowerCase());

      if (exactMatch) {
        // If there is an exact match, select the city by its ID
        this.selectedCity = exactMatch;
      } else {
        // If no exact match clear selectedCity and keep the entered value as the new city name
        this.selectedCity = null;
        this.filteredAreas =[];
        this.addAreaForm.patchValue({ areaName: '' }); 
      }
  }
  filterAreas(value: string) {
      this.filteredAreas = this.areas.filter(area =>
        area.name.toLowerCase().includes(value.toLowerCase())
      );
        // Check if the entered value exactly matches an area name
      const exactMatchArea = this.areas.find(area => area.name.toLowerCase() === value.toLowerCase());

      if (exactMatchArea) {
        // If there is an exact match, select the area by its ID
        this.selectedArea = exactMatchArea;
      } else {
        // If no exact match clear selectedArea and keep the entered value as the new area name
        this.selectedArea = null;
      }
    
  } 
  selectCity(city: any) {    
    this.selectedCity = city
    this.addAreaForm.patchValue({
      cityName: city.name
    });
    this.getAreaforCity();
  } 
  selectArea(area: any) {
    this.selectedArea = area;
    this.addAreaForm.patchValue({
      areaName: area.name
    });
  }
  getCities() {
    if (this.countryId) {
      this.businessService.getCitiesByCountry(this.countryId).subscribe(
        {
          next: (res: any) => {
            this.cities = res.cities;
            this.filteredCities = [...this.cities];
            console.log('Cities loaded:', this.cities);
          },
          error: (err) => console.log(err)
        });
    }
  }
  getAreaforCity(){
    if (this.selectedCity.id) {
      this.areasService.getCityArea(this.selectedCity.id).subscribe(
        {
          next: (res: any) => {
            this.areas = res;
            this.filteredAreas = [...this.areas];
            console.log('Areas loaded:', this.areas);
          },
          error: (err) => console.log(err)
        });
      
    }
  }
  onSave() {
    this.submitted = true;
    console.log(this.addAreaForm.value);
    const formValues = this.addAreaForm.value;
    console.log(this.branchID);

    const requestPayload = {
      cityName: this.selectedCity ? undefined : formValues.cityName,
      cityId: this.selectedCity ? this.selectedCity.id : undefined, 
      areaName: this.selectedArea ? undefined : formValues.areaName,
      areaId: this.selectedArea ? this.selectedArea.id : undefined, 
      branchId: this.branchID,
      minimumOrderAmount: formValues.minimumOrderAmount,
      deliveryFee: formValues.deliveryFee,
      maximumDeliveryDiscount: formValues.maximumDeliveryDiscount
    };
    console.log('Request Payload:', requestPayload);
    console.log(this.selectedCity);
    
    if (this.area) {
      const EditrequestPayload = {
        branchId: this.branchID,
        branchAreaId : this.area.id,
        minimumOrderAmount: formValues.minimumOrderAmount,
        deliveryFee: formValues.deliveryFee,
        maximumDeliveryDiscount: formValues.maximumDeliveryDiscount
      };
      console.log(this.branchID);
      
      this.areasService.editBranchArea(EditrequestPayload).subscribe(
        {
          next: (res: any) => {
            console.log('res',res);
            
            this.nbToastr.show('Area Edit successfully.', 'success', {
              status: 'success',
              hasIcon: false,
              icon: 'checkmark-outline'
            });
            this.onClose('success');

          },
          error: (err) =>{ 
            console.log(err);
            // console.log(err.errors[0])
            this.nbToastr.show(err.error?.errors[0], 'Erorr', {
              status: 'danger',
              hasIcon: true,
              icon: 'close-outline'
            });
          }
        });

    }
    else {
      this.areasService.createBranchArea(requestPayload).subscribe(
        {
          next: (res: any) => {
            console.log('res',res);
            
            this.nbToastr.show('Area Added successfully.', 'success', {
              status: 'success',
              hasIcon: false,
              icon: 'checkmark-outline'
            });
            this.onClose('success');

          },
          error: (err) =>{ 
            console.log(err);
            // console.log(err.errors[0])
            this.nbToastr.show(err.message, 'Erorr', {
              status: 'danger',
              hasIcon: true,
              icon: 'close-outline'
            });
          }
        });

    }

    // this.onClose('success');
  }
  onCancel() {
    this.dialogRef.close();
  }
  onClose(msg: string) {
    this.dialogRef.close(msg);
  }


}
