<nb-card [nbSpinner]="isLoading">
  <nb-card-header> Delete Category </nb-card-header>
  <nb-card-body>
    <div>Are You Sure ?</div>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="danger" (click)="submit()">Yes</button>
    <button nbButton status="basic" (click)="close()">Cancel</button>
  </nb-card-footer>
</nb-card>
