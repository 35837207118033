import { Component, OnInit } from '@angular/core';
import {BuisnessService} from "../../services/buisness.service";
import {environment} from "../../../environments/environment";

interface PosInfo {
  posType: string;
  posStatus:number
  sn: number;
  terminalId: number;
}

interface PosInfos{
  count: number;
  online:number;
  offline:number;
  posType:number;
}



@Component({
  selector: 'app-pos-info',
  templateUrl: './pos-info.component.html',
  styleUrls: ['./pos-info.component.scss'],
})
export class PosInfoComponent implements OnInit {
  private branchID?: string;
  posInfo: any[];
  baseImageUrl: string;
  constructor(private businessService:BuisnessService) {
    this.posInfo = [];
    this.baseImageUrl = environment.baseImageUrl;
  }

  ngOnInit(): void {
    let val = localStorage.getItem('searchVal');
    this.branchID = JSON.parse(val!)!.id;
    this.getPosInfo()

  }
  getPosInfo() {
    this.businessService.getPosInfo(this.branchID!).subscribe(
      (res:PosInfo | PosInfos[]) => {
        console.log({'posInfo': res})
        if (Array.isArray(res)) {
          this.posInfo = res;
        } else {
          this.posInfo.push(res);
        }
      },
      (err) => {
        console.log(err);
        this.posInfo = [{
          posType: 'N/A',
          posStatus: 0,
          terminalId: 0,
          sn: 0,
        }];
      }
    );
  }
}
