<nb-card>
    <nb-card-body>
      <nb-actions>
        <nb-action class="{{ action == 'import' ? 'active' : ''}}" icon="download-outline" title="import" (click)="action = 'import'"></nb-action>
        <nb-action class="{{ action == 'export' ? 'active' : ''}}" icon="upload-outline" title="export" (click)="action = 'export'"></nb-action>
      </nb-actions>
    </nb-card-body>
  </nb-card>
  <nb-card>
      <nb-card-body>
          <app-import heading="Import branch" text="Drop branch file or click to browse files" *ngIf="action == 'import'"></app-import>
          <app-export *ngIf="action == 'export'"></app-export>
      </nb-card-body>
  </nb-card>
