import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { UserService } from '../../../services/user.service';


@Component({
  selector: 'app-reset-password-user',
  templateUrl: './reset-password-user.component.html',
  styleUrls: ['./reset-password-user.component.scss']
})
export class ResetPasswordUserBranchComponent implements OnInit {
  userId:any;
  userName:string=''
  email:string=''
  resetUserPassword:any = {}
  
  showPassword = true;
  showconfirmPassword = true;
  
  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    else{
      return 'password';
    }
  }
  getInputType1() {
    if (this.showconfirmPassword) {
      return 'text';
    }
    else{
      return 'password';
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  toggleShowPassword1() {
    this.showconfirmPassword = !this.showconfirmPassword;
  }
  constructor(public userService:UserService,
    protected dialogRef: NbDialogRef<ResetPasswordUserBranchComponent>, 
    private nbToaster: NbToastrService
    ) { }

  ngOnInit(): void {
  }
  restPassword() {
    this.resetUserPassword.email = this.email;
    if(this.resetUserPassword.code =="") {
      this.nbToaster.show('Code can not be empty!', 'success', {
        status: 'success',
        hasIcon: false,
        icon: 'checkmark-outline'
      });
    } 
    else {
    if(this.resetUserPassword.password === this.resetUserPassword.confirmpassword) {
    this.userService.resetPassword(this.resetUserPassword).subscribe((res:any)=>{
      console.log(this.resetUserPassword);
      this.nbToaster.show('User Reseted Password successfully.', 'success', {
        status: 'success',
        hasIcon: false,
        icon: 'checkmark-outline'
      });
      this.userService.refresh.emit();
      this.dialogRef.close();    },
    (err) => {
      console.error({ err });
      this.nbToaster.show(err.error.Message, 'error', {
        status: 'danger',
        hasIcon: false,
        icon: 'close-outline'
      });
    });
  } else if(this.resetUserPassword.password!=this.resetUserPassword.confirmpassword) {
    this.nbToaster.show('password and confirm password  do not match', 'error', {
      status: 'danger',
      hasIcon: false,
      icon: 'close-outline'
    });
  }
}

  }
  cancel() {
    this.dialogRef.close()
  }
}
