import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  PrintingLayout,
  PrintingLayoutKey,
} from '../models/printing-layout.model';

@Injectable({
  providedIn: 'root',
})
export class PrintingLayoutService {
  constructor(private http: HttpClient) {}

  addPrintingLayout(layout: PrintingLayout) {
    return this.http.post(environment.baseURL + '/PrintingLayout', layout);
  }

  editPrintingLayout(layoutID: string, layout: PrintingLayout) {
    return this.http.post(
      environment.baseURL + `/PrintingLayout/${layoutID}/Edit`,
      layout
    );
  }

  deletePrintingLayout(layoutID: string) {
    return this.http.post(
      environment.baseURL + `/PrintingLayout/${layoutID}/Delete`,
      {}
    );
  }

  getPrintingLayout() {
    return this.http
      .get<PrintingLayout[]>(environment.baseURL + `/PrintingLayout/GetLayouts`)
      .pipe(
        map((data: PrintingLayout[]) => {
          return data;
        }),
        catchError((error) => throwError('Something Went Wrong'))
      );
  }

  getPrinitingLayoutKeys() {
    return this.http
      .get<PrintingLayoutKey[]>(
        environment.baseURL + `/PrintingLayout/PrintingLayoutKeys`
      )
      .pipe(catchError((error) => throwError('Something Went Wrong')));
  }
}
