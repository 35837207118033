import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { CashierService } from '../../../services/cashier.service';


@Component({
  selector: 'app-reset-password-cashier',
  templateUrl: './reset-password-cashier.component.html',
  styleUrls: ['./reset-password-cashier.component.scss']
})
export class ResetPasswordCashierComponent implements OnInit {
  cashierId: string = '';
  cashierName: string = '';
  password: string = '';
  cashierConfirmPassword: string = '';
  branchId: string = '';
  resetCashierForm: FormGroup = new FormGroup({
    // cashierName: new FormControl(''),
    password: new FormControl('')
    // cashierConfirmPassword: new FormControl(''),
    // branchId: new FormControl('')
  });
  showPassword = false;
  showconfirmPassword = false;

  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    else {
      return 'password';
    }
  }
  getInputType1() {
    if (this.showconfirmPassword) {
      return 'text';
    }
    else {
      return 'password';
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  toggleShowPassword1() {
    this.showconfirmPassword = !this.showconfirmPassword;
  }
  constructor(public cashierService: CashierService,
    private nbToastr: NbToastrService,
    protected dialogRef: NbDialogRef<ResetPasswordCashierComponent>) { }

  ngOnInit(): void {
    this.resetCashierForm.setValue({
      // cashierName: this.cashierName,
      password: this.password
      // cashierConfirmPassword: this.cashierConfirmPassword,
      // branchId: this.branchId
    })
  }
  resetCashierPassword() {
    if (this.resetCashierForm.value.password === this.cashierConfirmPassword) {
      if (this.resetCashierForm.value.password.search(/[0-9]/) < 0 &&
        this.resetCashierForm.value.cashierConfirmPassword.search(/[0-9]/) < 0) {
        this.nbToastr.show('The Password must be at least 8 and at max 100 characters long.', 'error', {
          status: 'danger',
          hasIcon: false,
          icon: 'close-outline'
        });
      }
      else {
        this.cashierService.resetPassword(this.resetCashierForm.value, this.cashierId).subscribe((res: any) => {
          console.log(this.resetCashierForm.value, "resetCashierForm");
          this.nbToastr.show('Cashier Reset Password successfully.', 'success', {
            status: 'success',
            hasIcon: false,
            icon: 'checkmark-outline'
          });
          this.cashierService.refresh.emit();
          this.dialogRef.close();
        },
          (err) => {
            this.nbToastr.show('Cannot Reset Password Of Cashier', 'error', {
              status: 'danger',
              hasIcon: false,
              icon: 'close-outline'
            });
          });
      }
    } else if (this.resetCashierForm.value.password !== this.cashierConfirmPassword) {
      this.nbToastr.show("password and confirm password  doesn't match", 'error', {
        status: 'danger',
        hasIcon: false,
        icon: 'close-outline'
      });
    }
  }
  cancel() {
    this.dialogRef.close()
  }
}
