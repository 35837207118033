<div class="row">
  <div class="col">
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <!-- <strong class="title">Report</strong> -->
        <span class="form-group" *ngIf="showFromDate">
          From Date
          <div class="inline">
            <input nbInput placeholder="Select From Date" name="fromDate" [(ngModel)]="fromDate" size="small"
              ngbDatepicker #d="ngbDatepicker">
            <nb-icon icon="calendar-outline" (click)="d.toggle()" class="calendr">
            </nb-icon>
          </div>
        </span>
      </div>
      <div class="col-12 col-md-6  mb-3">
        <span class="form-group" *ngIf="showToDate">
          To Date
          <div class="inline">
            <input nbInput placeholder="Select To Date" name="toDate" [(ngModel)]="toDate" size="small" ngbDatepicker
              #d="ngbDatepicker">
            <nb-icon icon="calendar-outline" (click)="d.toggle()" class="calendr"></nb-icon>
          </div>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-3">
        <!-- <strong class="title">Report Type</strong> -->
        <nb-radio-group value="0">
          <nb-radio class="wrapper-radio" *ngFor="let reporttype of reportTypes" value="{{reporttype.id}}"
            (click)="getBusinessSales(reporttype)">
            {{reporttype.type}}</nb-radio>
        </nb-radio-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 mb-3">
        Category
        <nb-select placeholder="Select Category" [(ngModel)]="trx.category" (selectedChange)="selectCategory($event)">
          <nb-option *ngFor="let category of categories" value="{{category.categoryId}}">{{category.categoryNameEN}}
          </nb-option>
          <nb-option *ngIf="categories==null">No Category Found!</nb-option>
        </nb-select>
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-3">
        Sub-Category
        <nb-select placeholder="Select SubCategory" [(ngModel)]="trx.subcategory">
          <nb-option *ngFor="let subcategory of subcategories" value="{{subcategory.subCategoryId}}">
            {{subcategory.subCategoryNameEN}}</nb-option>
          <nb-option *ngIf="subcategories==null">No SubCategory Found!</nb-option>
        </nb-select>
      </div>
      <div class="col-12 col-md-12 col-lg-4 mb-3">
        Item
        <nb-select placeholder="Select Item">
          <nb-option *ngFor="let item of items" value="{{item.itemId}}">
            {{item.itemNameEN}}</nb-option>
          <nb-option *ngIf="items==null">No Items Found!</nb-option>
        </nb-select>
      </div>
    </div>


    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 mb-3">
        Payment Mode
        <nb-select placeholder="Select Payment Mode" [(ngModel)]="trx.payment">
          <nb-option *ngFor="let payment of payments" value="{{payment.id}}">{{payment.name}}</nb-option>
        </nb-select>
      </div>

      <div class="col-12 col-md-6 col-lg-4 mb-3">
        Device
        <nb-select placeholder="Select Device" [(ngModel)]="trx.device">
          <nb-option *ngFor="let device of devices" value="{{device.id}}">{{device.name}}</nb-option>
        </nb-select>
      </div>

      <div class="col-12 col-md-12 col-lg-4 mb-3">
        <button nbButton size="medium" hero status="primary" (click)="getTrx()">
          <nb-icon icon="archive-outline"></nb-icon>
          Show Report
        </button>
      </div>
    </div>


    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <input class="input-style" fullWidth type="text" nbInput placeholder="Search" name="search"
          (input)="onSearch($event)">
      </div>
      <div class="col-12  col-md-6 wrapper-img">
        <img src="../assets/imgs/excel.png" class="img-fluid excel-img" (click)="exportExcel()">
        <img src="../assets/imgs/pdf.png" class="img-fluid pdf-img" (click)="exportPdf()">
      </div>
    </div>
    <!--trx-->

    <div *ngIf="reportType=='Trx'">
      <div class="row" *ngIf="trxOperations.length == 0" [nbSpinner]="loading" nbSpinnerStatus="basic">
        <strong style="text-align: center;"> No Transactions Found!</strong>
      </div>
      <div class="row" *ngIf="trxOperations.length != 0">
        <div class="table-responsive">
          <table class="table table-bordered" id="transactionTable" #transactionTable>
            <thead>
              <tr>
                <th>Transaction No</th>
                <th>Transaction ID</th>
                <th>Offline Transaction ID</th>
                <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="voucherNO">Receipt Number</th>
                <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="transactionDateTime">Date and
                  Time</th>
                <!-- <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="comments">Comments</th> -->
                <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="terminalId">Terminal ID</th>
                <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="cashier">Cashier</th>
                <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="paymentModeId">Payment Mode</th>
                <!-- <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="service">Service</th> -->
                <!-- <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="quantity">Quantity</th> -->
                <!-- <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="amount">Amount</th> -->
                <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="totalAmount">Total</th>
                <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="totalTaxAmount">Tax Amount</th>
                <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="couponCode">Coupon Code</th>
                <!-- <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="coupon">Coupon %</th> -->
                <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="discountValue">Discount Amount
                </th>
                <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="totalAfterDiscount">Total After
                  Discount</th>
                <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="totalAfterTaxAmount">Total
                  After Tax</th>
                <th scope="col">Options</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let trxOperation of trxOperations | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i = index">
                <td>{{i + 1}}</td>
                <td>{{trxOperation.transactionMasterId}}</td>
                <td>{{trxOperation.offlineTransactionId? trxOperation.offlineTransactionId :'-'}}</td>
                <td>{{trxOperation.voucherNO}}</td>
                <td>{{trxOperation.transactionDateTime | date: 'yyyy-MM-dd'}}</td>
                <!-- <td>{{trxOperation.comments}}</td> -->
                <td>{{trxOperation?.terminalId}}</td>
                <td>{{trxOperation.cashier}}</td>
                <td>{{trxOperation?.paymentModeId}}</td>
                <!-- <td>{{trxOperation.service}}</td>
              <td>{{trxOperation.quantity}}</td> -->
                <!-- <td>{{trxOperation.amount}}</td> -->
                <td>{{trxOperation.totalAmount}}</td>
                <td>{{trxOperation.totalTaxAmount}}</td>
                <td>{{trxOperation.couponCode}}</td>
                <!-- <td>{{trxOperation.coupon}}</td> -->
                <td>{{trxOperation.discountValue}}</td>
                <td>{{trxOperation.totalAfterDiscount}}</td>
                <td>{{trxOperation.totalAfterTaxAmount}}</td>
                <td>
                  <button nbButton hero shape="round" size="small" status="success" placement="left"
                    ngbTooltip="Delete Trx" tooltipClass="custom-tooltip"
                    (click)="DisableTrx(trxOperation.transactionMasterId)">
                    <nb-icon icon="eye-outline"></nb-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="trxOperations != null">
            <ngb-pagination [collectionSize]="trxOperations.length" [(page)]="page" [maxSize]="5" [rotate]="true"
              [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
    <!--trx-->
    <!--Daily Sales-->
    <div *ngIf="reportType=='Daily Sales'">
      <div class="row" *ngIf="businessSales.length == 0" [nbSpinner]="loading" nbSpinnerStatus="basic">
        <strong style="text-align: center;"> No Business Sales Found!</strong>
      </div>
      <div class="row" *ngIf="businessSales.length != 0">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <!-- <tr> <th>Item</th></tr> -->
              <tr>
                <th>No</th>
                <th>Date</th>
                <th>Quantity</th>
                <th>Face Amount</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let daily of businessSales; let i =index;">
                <!-- <td>{{daily.item}}</td> -->
                <td>{{i+1}}</td>
                <td>{{daily.date | date: 'yyyy-MM-dd'}}</td>
                <td>{{daily.quantity}}</td>
                <td>{{daily.faceAmount}}</td>
                <td>{{daily.total}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--Daily Sales-->
    <!--Daily Items Sales-->
    <div *ngIf="reportType== 'Daily Sales by item'">
      <div class="row" *ngIf="itemsSales.length == 0" [nbSpinner]="loading" nbSpinnerStatus="basic">
        <strong style="text-align: center;"> No Items Sales Found!</strong>
      </div>
      <div class="row" *ngIf="itemsSales.length != 0">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>No</th>
                <th>Item</th>
                <th>SubCategory</th>
                <th>Quantity</th>
                <th>Face Amount</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of itemsSales; let i=index">
                <td>{{i+1}}</td>
                <td>{{item.itemName}}</td>
                <td>{{item.subCateogryName}}</td>
                <td>{{item.quantity}}</td>
                <td>{{item.faceAmount}}</td>
                <td>{{item.total}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--Daily Items Sales-->
    <!--Daily SubCat Sales-->
    <div *ngIf="reportType=='Daily Sub-Category Sales'">
      <div class="row" *ngIf="subCategoriesSales.length == 0" [nbSpinner]="loading" nbSpinnerStatus="basic">
        <strong style="text-align: center;"> No SubCategory Sales Found!</strong>
      </div>
      <div class="row" *ngIf="subCategoriesSales.length != 0">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>No</th>
                <th>SubCategory</th>
                <th>Tax Amount</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let subcategory of subCategoriesSales; let i=index">
                <td>{{i+1}}</td>
                <td>{{subcategory.subCateogryName}}</td>
                <td>{{subcategory.taxAmount}}</td>
                <td>{{subcategory.total}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--Daily SubCat Sales-->
    <!--Daily Cashier Sales-->
    <div *ngIf="reportType=='Daily Cashier Sales'">
      <div class="row" *ngIf="cashierSales.length == 0" [nbSpinner]="loading" nbSpinnerStatus="basic">
        <strong style="text-align: center;"> No Cashier Sales Found!</strong>
      </div>
      <div class="row" *ngIf="cashierSales.length != 0">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>No</th>
                <th>Cashier</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cashier of cashierSales; let i=index">
                <td>{{i+1}}</td>
                <td>{{cashier.cahierName}}</td>
                <td>{{cashier.total}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--Daily Cashier Sales-->
    <!--Daily Business Sales-->
    <!-- <nb-card>
          <nb-card-body>
            <div class="row" *ngIf="trxOperations.length == 0">
              <strong style="text-align: center;"> No Transactions Found!</strong>
            </div>
            <div class="row" *ngIf="trxOperations.length != 0">
              <div class="table-responsive" >
                <table class="table table-bordered" >
                  <thead>
                    <tr>
                      <th>
                        Transaction No</th></tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let trxOperation of trxOperations; let i=index">
                      <td>{{i + 1}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </nb-card-body>
        </nb-card> -->
    <!--Daily Business Sales-->
    <!--Daily monthly Sales-->
    <!-- <nb-card>
          <nb-card-body>
            <div class="row" *ngIf="trxOperations.length == 0">
              <strong style="text-align: center;"> No Transactions Found!</strong>
            </div>
            <div class="row" *ngIf="trxOperations.length != 0">
              <div class="table-responsive" >
                <table class="table table-bordered" >
                  <thead>
                    <tr>
                      <th>
                        Transaction No</th></tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let trxOperation of trxOperations; let i=index">
                      <td>{{i + 1}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </nb-card-body>
        </nb-card> -->
    <!--Daily monthly Sales-->
  </div>
</div>
