import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { GetExpenseTypeResponse } from '../components/expenses/types/get-expense-type-response';
import { catchError, Observable, throwError } from 'rxjs';
import { PostExpenseTypeRequest } from '../components/expenses/types/post-expense-type-request';
import { PostExpenseRequest } from '../components/expenses/types/post-expense-request';
import { GetExpensesResponse } from '../components/expenses/types/get-expenses-response';
import { GetPaymentModeResponse } from '../components/expenses/types/get-payment-mode-response';
import { FilteringOptions } from '../components/expenses/types/filtering-options';

@Injectable({
  providedIn: 'root',
})
export class ExpensesService {
  url: string = environment.baseURL + '/expense';
  constructor(private http: HttpClient) {}

  getPaymentModesByBranchId(
    branchId: string
  ): Observable<GetPaymentModeResponse[]> {
    return this.http
      .get<GetPaymentModeResponse[]>(
        environment.baseURL +
          `/branchSettings/branchPaymentModes?branchId=${branchId}`
      )
      .pipe(catchError(this.handelError));
  }

  getExpenseTypes(businessId: string): Observable<GetExpenseTypeResponse[]> {
    return this.http
      .get<GetExpenseTypeResponse[]>(
        this.url + `/GetExpenseType?BusinessId=${businessId}`
      )
      .pipe(catchError(this.handelError));
  }

  postExpenseType(postExpenseType: PostExpenseTypeRequest) {
    return this.http
      .post(this.url + '/AddExpenseType', postExpenseType)
      .pipe(catchError(this.handelError));
  }

  getExpensesByBranchId(
    branchId: string,
    options: FilteringOptions
  ): Observable<GetExpensesResponse> {
    return this.http
      .post<GetExpensesResponse>(
        this.url + `/GetBranchExpenses?branchId=${branchId}`,
        options
      )
      .pipe(catchError(this.handelError));
  }

  deleteExpense(expenseId: number) {
    // always returns 200 ok
    return this.http
      .delete(this.url + `/Delete?id=${expenseId}`)
      .pipe(catchError(this.handelError));
  }

  // TODO : recheck the body
  postExpense(postExpense: PostExpenseRequest) {
    const formData = new FormData();
    formData.append('branchId', postExpense.branchId);
    formData.append('expenseTypeId', postExpense.expenseTypeId.toString());
    formData.append('amount', postExpense.amount.toString());
    formData.append('date', postExpense.date.toDateString());
    formData.append('paymentModeId', postExpense.paymentModeId.toString());
    formData.append('note', postExpense.note);
    if (postExpense.file) formData.append('file', postExpense.file);

    return this.http
      .post(this.url + '/AddBranchExpense', formData)
      .pipe(catchError(this.handelError));
  }

  downloadFile(url: string): void {
    this.http.get(url, { responseType: 'blob' }).subscribe((blob) => {
      const urlParts = url.split('/');
      const filename = urlParts[urlParts.length - 1] || 'downloaded-file';

      // Create a download link for the file
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(downloadUrl);
    });
  }
  handelError(httpError: HttpErrorResponse) {
    let errMsg = '';

    if (httpError.error instanceof ErrorEvent) {
      // client error (network error ...)
      errMsg = `Error: ${httpError.error.message}`;
    } else {
      // server error
      errMsg = `Server Error: ${httpError.message}`;
    }
    // this error will be received by the observers , as a observable error
    return throwError(() => errMsg);
  }
}
