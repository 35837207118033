import { Component, Input, OnInit } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { TableFormComponent } from './table-form/table-form.component';
import { TableService } from '../../services/table.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  tables: any[] = [];
  @Input() branchId: any;
  loading: boolean = false;
  constructor(
    private tableService: TableService,
     private nbToaster: NbToastrService, 
     private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getTables();
  }

  getTables() {
    this.loading = true;
    this.tableService.getTables(this.branchId).subscribe((res: any) => {
      //console.log(res)
      this.tables = res.data;
      this.loading = false;
    }, err => this.loading = false)
  }

  onCreateTable() {
    const modalRef = this.modalService.open(TableFormComponent, { centered: true });
    modalRef.componentInstance.branchId = this.branchId;
    modalRef.closed.subscribe(res => {
      //console.log(res)
      if (res.isSucceeded) {
        this.nbToaster.show(res.message, 'SUCCESS', {
          status: 'success',
          hasIcon: false
        });

        this.getTables();
      };
    })
  }

  onUpdateTable(table: any) {
    const modalRef = this.modalService.open(TableFormComponent, { centered: true });
    modalRef.componentInstance.table = table;
    modalRef.closed.subscribe(res => {
      //console.log(res)
      if (res.isSucceeded) {
        //this.getPOSTypes();
        this.nbToaster.show(res.message, 'SUCCESS', {
          status: 'success',
          hasIcon: false
        });

        this.tables[this.getIndex(table.id)] = res.data;
      };
    })
  }

  delete(id: number) {
    this.tableService.deleteTable(id).subscribe((res: any) => {
      this.nbToaster.show('table deleted', 'SUCCESS', {
        status: 'success',
        hasIcon: false
      });

      this.tables = this.tables.filter(recored => {
        return recored.id !== id
      });
    }, err => {
      this.nbToaster.show(err.message, 'ERROR', {
        status: 'danger',
        hasIcon: false
      });
    })
  }

  getIndex(id: number) {
    return this.tables.findIndex(d => d.id == id);
  }

}
