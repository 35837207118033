<div class="card shadow-sm">
  <div class="card-body py-3">
    <div class="d-flex align-items-center justify-content-between card-title">
      <div class="ticket-title">
        <h6 class="mb-0">{{ layout.layoutName }}</h6>
      </div>
      <div class="d-flex ticket-actions">
        <button
          nbButton
          status="primary"
          hero
          shape="round"
          size="small"
          (click)="viewLayout(layout)"
        >
          <nb-icon icon="eye-outline"></nb-icon>
        </button>
        <button
          nbButton
          status="success"
          hero
          shape="round"
          size="small"
          (click)="editLayout(layout)"
        >
          <nb-icon icon="edit-outline"></nb-icon>
        </button>
        <button
          nbButton
          status="warning"
          hero
          shape="round"
          size="small"
          (click)="deleteLayout(layout.printingLayoutId)"
        >
          <nb-icon icon="trash-2-outline"></nb-icon>
        </button>
      </div>
    </div>
    <div>
      <!-- {{ layout.layoutText }} -->
    </div>
  </div>
</div>
