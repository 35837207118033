import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-branch-items',
  templateUrl: './branch-items.component.html',
  styleUrls: ['./branch-items.component.scss'],
})
export class BranchItemsComponent implements OnInit {
  @Input() branchID: any;

  constructor() {}

  ngOnInit(): void {}
}
