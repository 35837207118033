<nb-card class="m-0">
    <nb-card-header>Add SMS</nb-card-header>
    <nb-card-body>
        <form [formGroup]="addSMSForm">
            <div class="row">
                <div class="col-12 mb-3">
                    <input formControlName="text" type="text" placeholder="Enter SMS Text" nbInput fullWidth>
                </div>
                <div class="col-12 mb-3">
                    <select class="form-control custom-select" fullWidth placeholder="Select Notifictaion Event"
                        formControlName="notificationEventId">
                        <option value="0" disabled>Select Notification Event</option>
                        <option *ngFor="let notification of notificationEvents" [value]="notification.notificationEventId">{{
                            notification.notificationEventName }}</option>
                    </select>
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer>
        <div class="text-right">
            <button nbButton status="success" hero (click)="onAdd()"> Add</button>
        </div>
    </nb-card-footer>
</nb-card>