
<!--edit -->
  <nb-card class="model-full">
    <nb-card-header>
          <h5 class="modal-title">Edit Cashier</h5></nb-card-header>
      <nb-card-body>
            <div class="row form-row">
            <div class="col-12  mb-3">
              <label class="col-form-label">Name</label>
              <input class="input-style"type="text" fullWidth nbInput  class="input-style"
               [(ngModel)]="editCashierObj.cashierName">
            </div>
            <div class="col-12 mb-3">
              <label class="col-form-label">Type</label>
              <nb-select placeholder="Select Cashier Type" fullWidth
              [(ngModel)]="editCashierObj.cashierType">
                <nb-option *ngFor="let cashierType of cashierTypes" [value]="cashierType.posRoleId">
                  {{cashierType.posRoleName}}
                </nb-option>
              </nb-select>
            </div>
            <!-- <div class="col-12  mb-3">
              <label class="col-form-label"> Password </label>
              <nb-form-field>
                <input [type]="getInputType()" class="input-style" nbInput fullWidth placeholder="Enter Cashier Password"
                [(ngModel)]="editCashierObj.cashierPassword">
                <button nbSuffix nbButton ghost (click)="toggleShowPassword()">
                  <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'"
                           pack="eva"
                           [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
                  </nb-icon>
                </button>
              </nb-form-field>
            </div>
            <div class="col-12  mb-3">
              <label class="col-form-label">Retype - Password </label>
            <nb-form-field>
              <input [type]="getInputType1()" class="input-style" nbInput fullWidth placeholder="Retype Cashier Password"
               [(ngModel)]="editCashierObj.cashierConfirmPassword">
              <button nbSuffix nbButton ghost (click)="toggleShowPassword1()">
                <nb-icon [icon]="showconfirmPassword ? 'eye-outline' : 'eye-off-2-outline'"
                         pack="eva"
                         [attr.aria-label]="showconfirmPassword ? 'hide password' : 'show password'">
                </nb-icon>
              </button>
            </nb-form-field>
            </div> -->
          </div>
      </nb-card-body>
        <nb-card-footer class="text-end">
          <button nbButton  status="success" (click)="editCashier()"><nb-icon icon="save-outline"></nb-icon>Save</button>
          <button nbButton  status="danger" (click)="cancel()"><nb-icon icon="close-outline"></nb-icon>Cancel</button>
        </nb-card-footer>
      </nb-card>
  <!--edit-->
