<nb-card>

    <nb-card-header>
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <label class="mb-2">Ticket Type:</label>
                <nb-radio-group status="success" class="d-md-flex" [(value)]="selectedLayoutType"
                    (valueChange)="onChange($event)">
                    <nb-radio value="ticket">Ticket</nb-radio>
                    <nb-radio value="token">Token</nb-radio>
                </nb-radio-group>
            </div>
            <button nbButton hero (click)="onAddLayout()">
                <nb-icon icon="plus-outline"></nb-icon> Add Layout
            </button>
        </div>
    </nb-card-header>
    <nb-card-body>
        <div class="row">
            <div class="col-12 col-md-6 col-xl-3 mb-3 h-100" *ngFor="let printLayout of filteredPrintingLayouts"
                [nbSpinner]="loading" nbSpinnerStatus="basic">
                <app-ticket [layout]="printLayout" (refreshLayout)="onRefresh()"></app-ticket>
            </div>
        </div>
    </nb-card-body>
</nb-card>
