import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as XLSX from 'xlsx';
import { PosHistoryComponent } from './pos-history/pos-history.component';
import { AddPosComponent } from './add-pos/add-pos.component';
import { PosTypesComponent } from './pos-types/pos-types.component';
import { NbToastrService } from '@nebular/theme';
import { PosService } from '../../services/pos.service';
import { BuisnessService } from '../../services/buisness.service';

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.scss']
})
export class PosComponent implements OnInit {
  selectedType: string = "";
  selectedStatus: string = "";
  POSs: any[] = [];
  filteredPOSs: any[] = [];
  loading: boolean = false;
  pages: number[] = [];
  pageNumber: number = 1;
  nextPage: string = "";
  prevPage!: any;
  paginationMetadata: any = {};
  totalPages: any;
  types: any[] = [];

  constructor(private nbToaster: NbToastrService,
     private posService: PosService, 
     private modalService: NgbModal, 
     private businessService: BuisnessService) { }

  ngOnInit(): void {
    this.getPOS();
    this.getPOSTypes();
  }

  onShowHistory(id?: any, serial?: any) {
    const modalRef = this.modalService.open(PosHistoryComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.posSerialNumber = serial;
    modalRef.componentInstance.posId = id;
  }

  onAddPOS() {
    const modalRef = this.modalService.open(AddPosComponent, { centered: true });
    modalRef.componentInstance.types = this.types;
    modalRef.closed.subscribe(res => {
      if (res == 'success') {
        this.getPOS();
      }
    })
  }

  onAddPOSType() {
    const modalRef = this.modalService.open(PosTypesComponent, { centered: true });
    modalRef.closed.subscribe(res => {
      console.log(res)
      if (res.isSucceeded) {
        this.getPOSTypes();
        this.nbToaster.show(res.message, 'SUCCESS', {
          status: 'success',
          hasIcon: false
        });
      } else {
        this.nbToaster.show(res.message, 'ERROR', {
          status: 'danger',
          hasIcon: false
        });
      }
    })
  }

  onEditPOSType(type: any) {
    const modalRef = this.modalService.open(PosTypesComponent, { centered: true });
    modalRef.componentInstance.posType = type;
    modalRef.closed.subscribe(res => {
      console.log(res)
      if (res.isSucceeded) {
        this.getPOSTypes();
        this.nbToaster.show(res.message, 'SUCCESS', {
          status: 'success',
          hasIcon: false
        });
      } else {
        this.nbToaster.show(res.message, 'ERROR', {
          status: 'danger',
          hasIcon: false
        });
      }
    })
  }

  getPOS() {
    this.loading = true;
    this.posService.getAllPos(true).subscribe(res => {

      this.POSs = res.value;
      this.filteredPOSs = res.value;
      this.loading = false;
    //   this.paginationMetadata = res.paginationMetadata;
    //   this.pageNumber = this.paginationMetadata.currentPage;
    //   this.nextPage = res.nextPage;
    //   this.prevPage = res.previousPage;
    //   this.totalPages = this.paginationMetadata.totalPages;

    //   //pagination
    //   for(let i = 1; i <= this.filteredPOSs?.length; i++){
    //     this.filteredPOSs.push();
    //  }

    //   for (let i = 1; i <= this.totalPages; i++) {
    //     this.pages.push(i);
    //   }

    }, err => {
      this.loading = false;
      console.log(err)
    });
  }

  getPOSTypes() {
    this.posService.getPOSTypes().subscribe(res => {
      //console.log(res)
      this.types = res;
    }, err => {
      console.log(err)
    })
  }

  deletePOSType() {

  }

  onPageChange(page: number) {
    this.loading = true;
    this.businessService.pageChange(`http://178.157.14.40:5555/api/v1/Pos/available-pos?isPaginated=true&Page=${page}&ItemsPerPage=10`).subscribe(res => {
      this.POSs = res.data;
      this.filteredPOSs = res.data;
      this.paginationMetadata = res.paginationMetadata;
      this.pageNumber = this.paginationMetadata.currentPage;
      this.nextPage = res.nextPage;
      this.prevPage = res.previousPage;
      //console.log(this.prevPage, this.nextPage)
      this.loading = false;
    }, err => { this.loading = false; console.log(err) });
  }

  onNext() {
    if (this.nextPage) {
      this.loading = true;
      // console.log(this.nextPage)
      this.businessService.pageChange(this.nextPage).subscribe(res => {
        console.log(res)
        this.POSs = res.data;
        this.filteredPOSs = res.data;
        this.paginationMetadata = res.paginationMetadata;
        this.pageNumber = this.paginationMetadata.currentPage;
        this.nextPage = res.nextPage;
        this.prevPage = res.previousPage;
        this.loading = false;
      }, err => console.log(err));
    }
  }

  onPrevious() {
    if (this.prevPage) {
      this.loading = true;
      this.businessService.pageChange(this.prevPage).subscribe(res => {
        //console.log(res)
        this.POSs = res.data;
        this.filteredPOSs = res.data;
        this.paginationMetadata = res.paginationMetadata;
        this.pageNumber = this.paginationMetadata.currentPage;
        this.nextPage = res.nextPage;
        this.prevPage = res.previousPage;
        this.loading = false;
      }, err => console.log(err));
    }
  }


  onSearch(event: any) {
    this.filteredPOSs = this.POSs;
    let searchTerm = event.target.value.toLowerCase();
    if (searchTerm == "") {
      this.filteredPOSs = this.POSs;
    } else {
      this.filteredPOSs = this.POSs.filter(pos => {return pos.sn.toLowerCase().includes(searchTerm) || pos.posType.toLowerCase().includes(searchTerm) || pos.posStatus.toLowerCase().includes(searchTerm)});
    };
  }

  onShowReport() {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.filteredPOSs);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'pos.xlsx');
  }



}
