<div class="row">
  <div class="alert fw-bold {{ 'alert-' + alert.status }}" *ngIf="alert.alert">
    {{ alert.message }}
  </div>
  <div class="col-12 d-flex align-items-center py-3 flex-wrap">
    <div class="d-flex align-items-center flex-wrap">
      <nb-card class="shadow m-3" *ngFor="let type of posTypes">
        <nb-card-body class="d-flex justify-content-center position-relative">
          <nb-badge text="{{type.type}}({{type.total}})" status="success" hero position="top right" class="custom-badge">
          </nb-badge>
          <img src="{{type.posTypeImageUrl? type.posTypeImageUrl : 'https://placehold.co/100?text=image+unavailable'}}" width="100" class="img-fluid pos-img" />
        </nb-card-body>
      </nb-card>
    </div>
    <button placement="right" ngbTooltip="assign new pos" tooltipClass="custom-tooltip" nbButton hero status="success"
      class="rounded-circle shadow mx-3" (click)="openAssignModal()">
      <nb-icon icon="plus-outline"> </nb-icon>
    </button>
  </div>
  <div class="col-12">
    <nb-card class="border-0">
      <nb-card-header class="border-0 d-flex-column d-md-flex justify-content-between align-items-center">
        <div class="search">
          <nb-form-field>
            <nb-icon nbPrefix icon="search-outline" status="warning" pack="eva"></nb-icon>
            <input type="text" status="basic" nbInput placeholder="Search by SN, Type" (input)="onSearch($event)"
              autofocus />
          </nb-form-field>
        </div>

        <div class="d-flex align-items-center">
          <button placement="left" ngbTooltip="branch default pos params" tooltipClass="custom-tooltip" nbButton hero
            status="warning" class="rounded-circle mx-3" (click)="openParamstModal()">
            <nb-icon icon="options"> </nb-icon>
          </button>

          <img src="../assets/imgs/excel.png" class="img-fluid excel-img" (click)="exportExcel()" />
          <img src="../assets/imgs/pdf.png" class="img-fluid pdf-img" (click)="exportPdf()" />
        </div>
      </nb-card-header>
      <nb-card-body>
        <div *ngIf="POSs.length == 0">
          <p class="text-center font-weight-medium text-capitlized">
            No POSs in this branch yet, you can
            <span class="assign" (click)="openAssignModal()">Assign a POS</span>
          </p>
        </div>
        <div class="table-responsive" *ngIf="POSs.length > 0">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">IMEI</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
                <th scope="col">Terminal</th>
                <th scope="col">Last Used</th>
                <th scope="col">Last User</th>
                <th scope="col">Options</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let pos of filteredPOSs; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ pos.sn }}</td>
                <td>{{ pos.posType }}</td>
                <td>{{ pos.description }}</td>
                <td>{{ pos.terminalId }}</td>
                <td>{{ pos.lastModifiedOn }}</td>
                <td>{{ pos.lastModifiedBy }}</td>
                <td>
                  <button placement="left" ngbTooltip="params" tooltipClass="custom-tooltip" nbButton status="warning"
                    shape="round" size="small" hero (click)="openParamstModal(pos)" class="mb-1">
                    <nb-icon icon="smartphone-outline"></nb-icon>
                  </button>
                  <button placement="top" ngbTooltip="{{ pos.isLocked ? 'unlock' : 'lock' }}"
                    tooltipClass="custom-tooltip" nbButton (click)="onPosLock(pos.posId, pos.isLocked)"
                    status="{{ pos.isLocked ? 'success' : 'danger' }}" shape="round" size="small" hero class="mb-1">
                    <nb-icon icon="lock-outline"></nb-icon>
                  </button>
                  <!-- <button placement="top" ngbTooltip="MDM" tooltipClass="custom-tooltip" nbButton
                                        (click)="openMdm()" status="warning" shape="round" size="small" hero
                                        class="mb-1">
                                        <nb-icon icon="keypad-outline"></nb-icon>
                                    </button> -->
                  <button nbButton placement="top" ngbTooltip="{{
                      pos.posStatus == 'Disconnected' ? 'connect' : 'disconnect'
                    }}" tooltipClass="custom-tooltip" status="{{
                      pos.posStatus == 'Disconnected' ? 'success' : 'danger'
                    }}" shape="round" size="small" hero (click)="openDisconnectModal(pos)" class="mb-1">
                    <nb-icon icon="power-outline"></nb-icon>
                  </button>
                  <button placement="top" ngbTooltip="replace" tooltipClass="custom-tooltip" nbButton status="warning"
                    shape="round" size="small" hero (click)="openReplaceModal(pos)" class="mb-1">
                    <nb-icon icon="flip-2-outline"></nb-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>
<app-modal size="{{ selectedModal == 'params' ? 'xl' : 'replace' ? 'xl' : 'lg' }}">
  <app-params-pos *ngIf="selectedModal == 'params'"></app-params-pos>
</app-modal>
<ngb-toast class="bg-success text-light" [delay]="2000" *ngIf="show" [autohide]="true" (hidden)="show = false">
  {{ message }}
</ngb-toast>
