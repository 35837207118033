import { Component, Input, OnInit } from '@angular/core';



import * as uuid from 'uuid';
import { NbToastrService } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PrintingLayoutKey, PrintingSectinos } from '../../../models/printing-layout.model';
import { PrintingLayoutService } from '../../../services/printing-layout.service';
import { UserService } from '../../../services/user.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-edit-layout',
  templateUrl: './edit-layout.component.html',
  styleUrls: ['./edit-layout.component.scss'],
})
export class EditLayoutComponent implements OnInit {
  editLayout: any = {};
  layoutJson: any = { transactions: {}, sort: [] };
  newLayouts: PrintingLayoutKey[] = [];
  sortedKeys: any[] = [];
  sortHeader:  any[] = [];
  sortBody:  any[] = [];
  sortFooter:  any[] = [];
  addedKeys: PrintingLayoutKey[] = [];
  transactionKeys: any = {};
  previewLayout: string = '';
  draggingIndex?: string;
  filterKeyTerm: string = '';
  keysCount: any = { header: 0, body: 0, footer: 0 };
  imgurlPlaceholder : string = 'https://www.altkamul.net/content/merchants/50112101/50112101/printing.bmp';

  @Input() layout!: any;

  constructor(
    private printingLayoutService: PrintingLayoutService,
    // public activeModal: NgbActiveModal,
    public userservice: UserService,
    public toastrService: NbToastrService,
    private router : Router
  ) {}

  ngOnInit(): void {
    this.layout = JSON.parse(localStorage.getItem('layout') || '{}')
    console.log('from edit layout',this.layout)
    this.editLayout.layoutName = this.layout.layoutName;
    this.editLayout.layoutType = this.layout.layoutType;
    this.editLayout.layoutText = this.layout.layoutText;
    this.parseLayoutJson();
    this.getLayoutKeys();
  }

  parseLayoutJson() {
    this.layoutJson = JSON.parse(this.layout.layoutText);
    console.log(this.layoutJson)
    this.sortedKeys = this.layoutJson.sort;
    this.sortBody = this.layoutJson.sort_body;
    this.sortHeader = this.layoutJson.sort_header;
    this.sortFooter = this.layoutJson.sort_footer;
    this.addedKeys = this.sortedKeys.map((el: string) => {
      return this.layoutJson[el];
    });
    this.transactionKeys = this.layoutJson.transactions;
    this.keysCount.header = this.sortHeader.length;
    this.keysCount.body = this.sortBody.length;
    //console.log({sortedKeys: this.sortedKeys}, {addedKeys: this.addedKeys})
  }

  getAddedKeys(sectionName?: string): PrintingLayoutKey[] {
    return this.addedKeys.filter((el) => el.sectionName == sectionName);
  }

  filterKeys(): PrintingLayoutKey[] {
    return this.newLayouts.filter((el) =>
      el.name.toLowerCase().includes(this.filterKeyTerm.toLowerCase())
    );
  }

  onSave() {
    this.updateJson();
    this.layoutJson.transactions = this.transactionKeys;
    this.layoutJson.sort = this.sortedKeys;
    this.layoutJson.sort_header = this.sortHeader;
    this.layoutJson.sort_body = this.sortBody;
    this.layoutJson.sort_footer = this.sortFooter;
    this.editLayout.layoutText = JSON.stringify(this.layoutJson);
    this.editLayout.CopyType = this.editLayout.layoutType;
    this.printingLayoutService
      .editPrintingLayout(this.layout.printingLayoutId, this.editLayout)
      .subscribe(
        (res) => {
          this.userservice.logs.emit('edit printing layout in branch log');
          // this.activeModal.close('success');
          this.toastrService.show('printing layout updated successfully', 'success', {
            status: 'success',
            hasIcon: true,
            icon: {
              icon: 'checkmark-outline',
              pack: 'eva',
            },
          });
        this.router.navigate(['home/printing-layouts']);

        },
        (err) => {
          // this.activeModal.close('error');
        // this.router.navigate(['home/printing-layouts']);
        this.toastrService.show(
          'failed to edit  layout, please try again',
          'error',
          {
            status: 'danger',
            hasIcon: true,
            icon: {
              icon: 'alert-circle-outline',
              pack: 'eva',
            },
          }
        );

        }
      );
  }

  onClose() {
    // this.activeModal.close();
    this.router.navigate(['home/printing-layouts']);
  
  }

  onContentChanged(data: any) {
    let historyStyle = data.event.source.history._operations.map((el: any) => {
      return el;
    });
    historyStyle = historyStyle.map((el: any) => {
      return el;
    });
    JSON.stringify(historyStyle);
  }

  updateJson(data?: PrintingLayoutKey) {
    if (data) {
      this.sortedKeys.push(data.uuid);
      if (data.sectionName == PrintingSectinos.Body) {
        this.transactionKeys[data.uuid] = data.label;
        //console.log({transactionKeys: this.transactionKeys})
        this.sortBody.push(data.uuid);
      } else if(data.sectionName == PrintingSectinos.Header) {
        this.sortHeader.push(data.uuid)
      } else if(data.sectionName == PrintingSectinos.Footer){
        this.sortFooter.push(data.uuid)
      }
      this.layoutJson[data.uuid] = data;
    } else {
      this.sortedKeys = [];
      this.addedKeys.forEach((el) => {
        this.sortedKeys.push(el.uuid);
      });

      Object.keys(this.transactionKeys).forEach((id: string) => {
        const index = this.addedKeys.findIndex((el2) => el2.uuid == id);
        this.transactionKeys[id] = this.addedKeys[index].label;
      });
    }
  }

  allowLabelEdit(uuid: string) {
    console.log('allowLabelEdit')
    const index = this.addedKeys.findIndex((el) => el.uuid == uuid);
    this.addedKeys[index].editable = true;
    console.log(this.addedKeys[index])
  }

  getLayoutPreview(): string {
    console.log(this.addedKeys , 'added keys');
    
    return this.addedKeys.reduce(
      (acc, curr) => acc + this.renderKeysOptions(curr) + '',
      ''
    );
  }

  renderKeysOptions(key: PrintingLayoutKey): string {
    let para = "<p class='";
    let value = key.label ? key.label : key.initialValue;

    if (key.name=='logo') {
      key.initialValue = `<img src="${this.imgurlPlaceholder}" width="200" >`;
      key.options!.align=1;
    }

    if (key.options!.size == 2) {
      para += 'fw-bold ';
    }

    if (key.options!.align == 0) {
      para += 'text-left ';
    }

    if (key.options!.align == 1) {
      para += 'text-center ';
    }

    if (key.options!.align == 2) {
      para += 'text-end ';
    }

    para += "'>" + value + '</p>';
    return para;
  }

  isEditable(index: number): boolean {
    return this.addedKeys[index].editable;
  }

  toggleEdit(uuid: string) {
    const index = this.addedKeys.findIndex((el) => el.uuid == uuid);
    this.addedKeys[index].editable = !this.addedKeys[index].editable;
  }

  addToLayout(data: PrintingLayoutKey) {
    data.options = { isBold: false, size: 1, align: 0 };
    data.editable = false;
    if(data.label) {
      data.label = data.name;
    }
    data = { ...data, uuid: uuid.v4() };
    if (data.sectionName == PrintingSectinos.Body) {
      this.addedKeys.splice(
        this.keysCount.header + this.keysCount.body,
        0,
        data
      );
      this.keysCount.body++;
    }

    if (data.sectionName == PrintingSectinos.Header) {
      this.addedKeys.splice(this.keysCount.header, 0, data);
      this.keysCount.header++;
    }

    if (data.sectionName == PrintingSectinos.Footer) {
      this.addedKeys.push(data);
      this.keysCount.footer++;
    }

    this.updateJson(data);
  }

  allowDrop(ev: any): void {
    ev.preventDefault();
  }

  drag(ev: any, isSort: boolean = false): void {
    ev.dataTransfer.setData('dragElement', ev.target.id);
    ev.dataTransfer.setData('isSort', isSort);
  }

  drop(ev: any): void {
    ev.preventDefault();
    const isSort = ev.dataTransfer.getData('isSort');
    const data = ev.dataTransfer.getData('dragElement');
    let layoutKey;
    if (JSON.parse(isSort)) {
      layoutKey = this.newLayouts.find((el) => el.uuid == data);
    } else {
      layoutKey = this.newLayouts.find((el) => el.id == data);
    }
    this.addToLayout(layoutKey!);
  }

  onDragEnter(toIndex: string, type: string): void {
    if (this.draggingIndex !== toIndex) {
      this._reorderItem(this.draggingIndex!, toIndex, type);
    }
  }

  onDragStart(fromIndex: string): void {
    this.draggingIndex = fromIndex;
  }

  deleteKey(uuid: string, type: string) {
    const index = this.addedKeys.findIndex((el) => el.uuid == uuid);
    const key = this.addedKeys.splice(index, 1);
    const sortedIdx = this.sortedKeys.findIndex((el) => el == key[0].uuid);
    this.sortedKeys.splice(sortedIdx, 1);

    delete this.transactionKeys[key[0].uuid];
    delete this.layoutJson[key[0].uuid];

    if(type == 'body') {
      this.keysCount.body--;
    } else if(type == 'header') {
      this.keysCount.header--;
    } else {
      this.keysCount.footer--;
    }
  }

  private _reorderItem(fromUUID: string, toUUID: string, type: string): void {
    //console.log(this.addedKeys)
    const fromIndex = this.addedKeys.findIndex((el) => el.uuid == fromUUID);
    const itemToBeReordered = this.addedKeys.splice(fromIndex, 1)[0];
    const toIndex = this.addedKeys.findIndex((el) => el.uuid == toUUID);
    this.addedKeys.splice(toIndex, 0, itemToBeReordered);

    // this.draggingIndex = toUUID;

    ////////////
    if(type == 'body') {
      const fromBodyIndex = this.sortBody.findIndex((el) => el == fromUUID);
      const itemBodyToBeReordered = this.sortBody.splice(fromBodyIndex, 1)[0];
      const toBodyIndex = this.sortBody.findIndex((el) => el == toUUID);
      this.sortBody.splice(toBodyIndex, 0, itemBodyToBeReordered);
    } else if(type == 'header') {
      const fromHeaderIndex = this.sortHeader.findIndex((el) => el == fromUUID);
      const itemHeaderToBeReordered = this.sortHeader.splice(fromHeaderIndex, 1)[0];
      const toHeaderIndex = this.sortHeader.findIndex((el) => el == toUUID);
      this.sortHeader.splice(toHeaderIndex, 0, itemHeaderToBeReordered);
    } else if(type == 'footer'){
      const fromFooterIndex = this.sortFooter.findIndex((el) => el == fromUUID);
      const itemFooterToBeReordered = this.sortFooter.splice(fromFooterIndex, 1)[0];
      const toFooterIndex = this.sortFooter.findIndex((el) => el == toUUID);
      this.sortFooter.splice(toFooterIndex, 0, itemFooterToBeReordered);
    }
  }

  onDragEnd(): void {
    this.draggingIndex = undefined;
  }

  toggleBold(id: number) {
    // console.log(this.addedKeys, index, this.addedKeys[index])
    let key = this.addedKeys.find(key => {
      return key.id == id;
    })
    if (key!.options!.size == 1) {
      key!.options!.size = 2;
    } else {
      key!.options!.size = 1;
    }
  }

  onGetSuccess(keys: PrintingLayoutKey[]) {

    // keys = keys.sort((a, b) => (a.sectionName > b.sectionName ? 1 : -1));
    // this.newLayouts = keys;
    const customOrder = ['Header', 'Body', 'Footer'];

    keys = keys.sort((a, b) => {
      return customOrder.indexOf(a.sectionName) - customOrder.indexOf(b.sectionName);
    });
    this.newLayouts = keys;

  }

  onGetError(error: any) {}

  getLayoutKeys() {
    this.printingLayoutService.getPrinitingLayoutKeys().subscribe({
      next: (data) => this.onGetSuccess(data),
      error: (err) => this.onGetError(err),
    });
  }
}
