<nb-card  [nbSpinner]="isLoading">
    <nb-card-header>
        <div class="row">
            <div class="col-md-4 mb-3">
                <nb-select placeholder="Business Type" fullWidth [(ngModel)]="businessTypeId"
                    (selectedChange)="onBusinessTypeChange()">
                    <nb-option *ngFor="let business of businessTypes" value="{{ business?.id }}">
                        {{ business?.name }}</nb-option>
                    <nb-option *ngIf="businessTypes.length==0">No businesses</nb-option>
                </nb-select>
            </div>
        </div>

    </nb-card-header>

    <nb-card-body>
        <nb-tabset>
            <nb-tab tabTitle="Category">
                <div class="mt-4">
                    <app-reference-category *ngIf="categories" [categories]="categories"
                        [businessTypeId]="businessTypeId" (updateDataEvent)="onLoadData()"></app-reference-category>
                </div>
    
            </nb-tab>
    
            <nb-tab tabTitle="SubCategory">
                <div class="mt-4">
                    <app-reference-subcategory *ngIf="subCategories" [subCategories]="subCategories"
                        [businessTypeId]="businessTypeId" (updateDataEvent)="onLoadData()"></app-reference-subcategory>
                </div>
    
            </nb-tab>
            <nb-tab tabTitle="Items">
                <div class="mt-4">
                    <app-reference-items *ngIf="items" [items]="items"
                        [businessTypeId]="businessTypeId" (updateDataEvent)="onLoadData()"></app-reference-items>
                </div>
            </nb-tab>
        </nb-tabset>
    
    </nb-card-body>


</nb-card>
