import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SmsnotificationsService } from '../../../services/smsnotifications.service';


@Component({
  selector: 'app-edit-sms',
  templateUrl: './edit-sms.component.html',
  styleUrls: ['./edit-sms.component.scss']
})
export class EditSmsComponent implements OnInit {
  @Input() base: any;
  @Output() refreshBase = new EventEmitter<null>();
  editSMSForm: FormGroup = new FormGroup({
    text: new FormControl(''),
    notificationTypeId: new FormControl(''),
    notificationEventId: new FormControl(''),
  });
  constructor(private smsService: SmsnotificationsService, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.editSMSForm.patchValue({ text: this.base.text});
  }

  onEdit() {
    this.editSMSForm.patchValue({ notificationTypeId: this.base.notificationTypeId, notificationEventId: this.base.notificationEventId});
    this.smsService.editNotificationBase(this.editSMSForm.value, this.base.notificationBaseID).subscribe(res => {
      this.activeModal.close(res)
      this.editSMSForm.reset({ text: this.editSMSForm.get('text')!.value});
      //this.refreshBase.emit();
    }, err => this.activeModal.close(err))
  }

}
