<nb-card>
  <nb-card-body>
    <nb-tabset>
      <nb-tab tabTitle="Pos" class="mt-3">
        <app-pos-branch></app-pos-branch>
      </nb-tab>
      <nb-tab tabTitle="Logs"  class="mt-3">
        <app-pos-branch-logs></app-pos-branch-logs>
      </nb-tab>
    </nb-tabset>
  </nb-card-body>
</nb-card>




