<h6>Export branch</h6>
<div class="options my-2">
    <nb-radio-group status="success" class="d-md-flex" [(value)]="selected">
        <nb-radio value="all">Everything</nb-radio>
        <nb-radio value="no trx">Without TRX</nb-radio>
        <nb-radio value="custom">Custom</nb-radio>
      </nb-radio-group>
</div>
<div class="custom-options d-flex flex-column" *ngIf="selected == 'custom'">
    <nb-checkbox class="mb-1" status="success" (checkedChange)="toggle($event, 'branch param')">Branch Param</nb-checkbox>
    <nb-checkbox class="mb-1" status="success" (checkedChange)="toggle($event, 'items')">Items</nb-checkbox>
    <nb-checkbox class="mb-1" status="success" (checkedChange)="toggle($event, 'layout')">Layout</nb-checkbox>
    <nb-checkbox class="mb-1" status="success" (checkedChange)="toggle($event, 'pos')">POS</nb-checkbox>
    <nb-checkbox class="mb-1" status="success" (checkedChange)="toggle($event, 'trx')">TRX</nb-checkbox>
    <nb-checkbox class="mb-1" status="success" (checkedChange)="toggle($event, 'Queue')">Queue</nb-checkbox>
</div>
<div class="text-end mt-3">
    <button nbButton hero status="success">Download Branch File</button>
</div>
