import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  constructor(private http: HttpClient) { }

  getTables(id: number) {
    return this.http.get(`${environment.baseURL}/Table/GetBranchTables?branchId=${id}`);
  }

  getTableById(id: number) {
    return this.http.get(`${environment.baseURL}/Table/Get?id=${id}`);
  }

  createTable(body: any) {
    return this.http.post(`${environment.baseURL}/Table/Create`, body);
  }

  editTable(body: any) {
    return this.http.patch(`${environment.baseURL}/Table/Edit`, body);
  }

  deleteTable(id: number) {
    return this.http.delete(`${environment.baseURL}/Table/Delete?id=${id}`);
  }
}

