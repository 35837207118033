import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { GoogletranslateService } from "../../services/googletranslate.service";
import { NgxDropzoneChangeEvent } from "ngx-dropzone";
import { Category, SubCategory } from '../../models/category.model';
import { ItemsService } from '../../services/items.service';
import { Item } from '../../models/item.model';

enum ItemType {
  Product = 1,
  Service = 2,
}

@Component({
  selector: 'app-new-item',
  templateUrl: './new-item.component.html',
  styleUrls: ['./new-item.component.scss'],
})
export class NewItemComponent implements OnInit {
  newItem: FormGroup;
  item?: Item;
  itemImage?: File
  newItemPricing: FormGroup;
  isLoading: boolean = false;
  searching: boolean = false;
  subCategories: SubCategory[] = [];
  filteredOptions$?: Observable<SubCategory[]>;
  branchID!: string | null; // branch id passed to component
  SubCategoryId: any;
  addons: any;
  itemDependencies: any[] = [];
  RelatedItemIds: any[] = [];
  selectedSubCategory!: SubCategory;
  autoTranslate: boolean = true;
  categories!: Category[];
  selectedCategory!: Category;
  constructor(
    private fb: FormBuilder,
    private itemsService: ItemsService,
    private nbToastr: NbToastrService,
    private route: ActivatedRoute,
    private dialogRef: NbDialogRef<NewItemComponent>,
    private googleTranslate: GoogletranslateService,
    private toastrService: NbToastrService,
    public dialogService: NbDialogService
  ) {
    this.newItem = this.fb.group({
      ItemNameEN: [''],
      ItemNameAR: [''],
      ItemNameFR: [''],
      ItemNameTR: [''],
      SubCategoryId: ['', Validators.compose([Validators.required])],
      ItemTypeID: ['1', Validators.compose([Validators.required])],
      IsActive: [true]
    });
    this.newItemPricing = this.fb.group({
      BuyingPrice: [0, Validators.compose([Validators.required])],
      FacePrice: [0, Validators.compose([Validators.required])],
      DiscountAmount: [0, Validators.compose([Validators.required])],
      IsOpenQuantity: [false, Validators.compose([Validators.required])],
      IsOpenPrice: [false, Validators.compose([Validators.required])],
      MinimumPrice: [0, Validators.compose([Validators.required])],
      MaximumPrice: [0, Validators.compose([Validators.required])],
      CanHaveStock: [false]
    });
  }

  ngOnInit(): void {
    this.subCategories = [];
    this.filteredOptions$ = of(this.subCategories);
    this.getCategories();
    if (this.item) {
      console.log(this.item, 'Updating');
      this.newItem.patchValue({
        ItemNameEN: this.item.itemNameEN,
        ItemNameAR: this.item.itemNameAR,
        ItemNameFR: this.item.itemNameFR,
        ItemNameTR: this.item.itemNameTR,
        SubCategoryId: this.item.subCategoryId,
        ItemTypeID: this.item.itemTypeID,
      });
      this.newItemPricing.patchValue({
        BuyingPrice: this.item.buyingPrice,
        FacePrice: this.item.facePrice,
        DiscountAmount: this.item.discount,
        IsOpenQuantity: this.item.isOpenQuantity,
        IsOpenPrice: this.item.isOpenPrice,
        MinimumPrice: this.item.minimumPrice,
        MaximumPrice: this.item.maximumPrice,
        CanHaveStock: this.item.canHaveStock
      });

      if (this.item.itemDependencies) {
        this.itemDependencies = this.item.itemDependencies;
        this.item.itemDependencies.forEach((dependency: any) => {
          this.RelatedItemIds.push(dependency.relatedItemId)
        })
      }

      if (this.item.itemTypeID == 2) {
        this.getItemsAddOnType(0);
      }



    }
  }

  categoryChange(subategories: any) {
    this.subCategories = [];
    this.subCategories = subategories;
  }

  getItemsAddOnType(page: number, searchKey?: string) {
    this.itemsService.getItemsAddOnTypes(this.branchID!, page, searchKey ? searchKey : '').subscribe(res => {
      console.log({ res })
      this.addons = res.value;
    })
  }

  onSave() {
    //console.log(this.newItemPricing.value, this.newItem.value)
    if (this.newItemPricing.valid && this.newItem.valid) {
      console.log('items are valid')
      if (this.item) {
        console.log('updating item>>')
        this.updateItem();
      } else {
        console.log('saving item>>')
        this.createItem();
      }
    } else {
      this.nbToastr.danger('you must add a new subcategory first');
    }
  }

  createItem() {
    console.log({ RelatedItemIds: this.RelatedItemIds })
    let item: any = {
      ...this.newItemPricing.value,
      ...this.newItem.value,
    };

    item.BranchId = this.branchID!;

    let itemFormData = new FormData();

    for (const key in item) {
      itemFormData.append(key, item[key]);
    }


    if (this.RelatedItemIds.length > 0) {
      this.RelatedItemIds.forEach(item => {
        itemFormData.append('RelatedItemIds', item);
      })
    }

    this.isLoading = true;
    this.itemsService.addItem(itemFormData).subscribe(
      (response: any) => {

        this.itemsService.shouldReload.emit(true);
        this.newItem.reset();
        this.newItemPricing.reset();
        //console.log({ response });
        if (this.itemImage) {
          this.uploadImage(response.id);
          this.nbToastr.show('Item Added successfully. uploading Item Image..', 'success', {
            status: 'success',
            hasIcon: true,
            icon: 'checkmark-outline',
          });
        } else {
          this.nbToastr.show('Item Added successfully.', 'success', {
            status: 'success',
            hasIcon: true,
            icon: 'checkmark-outline',
          });
          this.isLoading = false;
          this.dialogRef.close();
        }
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
        this.nbToastr.show('Something Went Wrong.', 'failure', {
          status: 'danger',
          hasIcon: true,
          icon: 'close-outline',
        });
      }
    );
  }

  updateItem() {
    let item: Item = {
      ...this.newItemPricing.value,
      ...this.newItem.value,
    };
    item.branchId = this.branchID!;
    item.itemId = this.item!.itemId;
    item.relatedItemIds = this.RelatedItemIds
    this.isLoading = true;

    // console.log(item)

    this.itemsService.editItem(item).subscribe(
      (response) => {
        this.isLoading = false;

        this.newItem.reset();
        this.newItemPricing.reset();
        // console.log({ response });

        if (this.itemImage) {
          this.uploadImage(item.itemId);
          this.nbToastr.show('Item Updated successfully. uploading Item Image..', 'success', {
            status: 'success',
            hasIcon: false,
            icon: 'checkmark-outline',
          });
        } else {
          this.nbToastr.show('Item Updated successfully', 'success', {
            status: 'success',
            hasIcon: false,
            icon: 'checkmark-outline',
          });
          this.isLoading = false;
          this.onClose();
        }

      },
      (err) => {
        console.log(err);
        this.isLoading = false;
        this.nbToastr.show('Something Went Wrong.', 'failure', {
          status: 'danger',
          hasIcon: false,
          icon: 'close-outline',
        });
      }
    );
  }

  onClose() {
    this.dialogRef.close();
    this.itemsService.shouldReload.emit(true);
  }

  private filter(value: string): SubCategory[] {
    const filterValue = value.toLowerCase();
    return this.subCategories.filter((optionValue) =>
      optionValue!.subCategoryNameEN!.toLowerCase().includes(filterValue)
    );
  }

  getFilteredOptions(value: string): Observable<SubCategory[]> {
    return of(value).pipe(map((filterString) => this.filter(filterString)));
  }

  onTypeChange($e: any) {
    //console.log($e)
    if ($e !== 2) {
      if (this.item && this.item.itemDependencies) {
        this.itemDependencies = [];
        this.item!.itemDependencies = [];
        this.RelatedItemIds = [];
      }
    } else {
      this.getItemsAddOnType(0);
    }
  }

  toggle($e: any) {
    //console.log($e)
    let isAddOnAlreadyAdded = this.RelatedItemIds.find(item => {
      return item == $e.itemId
    });

    if (!isAddOnAlreadyAdded) {
      this.RelatedItemIds.push($e.itemId);

      this.itemDependencies.push($e);

    } else {
      this.RelatedItemIds = this.RelatedItemIds.filter(item => {
        return item !== $e.itemId
      });

      this.itemDependencies = this.itemDependencies.filter(item => {
        return item.itemId !== $e.itemId
      });
    }

    console.log(this.RelatedItemIds)
  }

  onSearchAddOn($e: any) {
    //console.log($e)
    this.getItemsAddOnType(0, $e.target.value);
  }

  patchSubCategoryValue(value: any) {
    // if (!this.item && value) {
    //   let subCategoryId = value.split('-')[0];
    this.newItem.patchValue({
      SubCategoryId: value,
    });
    // }
  }

  onChange(input: any) {
    //console.log(input.value.length);
    this.subCategories = [];
    this.searching = true;
    if (input.value.length > 0) {
      this.itemsService
        .findSupCategories(input.value, this.branchID!)
        .subscribe(
          (res: SubCategory[]) => {
            this.subCategories = res;
            this.filteredOptions$ = this.getFilteredOptions(input.value);
            this.searching = false
          },
          (err) => {
            this.searching = false;
            console.log('sub categories not found');
          }
        );
    } else {
      this.searching = false;
      this.subCategories = [];
      this.filteredOptions$ = this.getFilteredOptions(input.value);
    }
  }

  getCategories() {
    this.itemsService.getCategories(this.branchID!).subscribe(res => {
      this.categories = res;
      if (this.item) {
        // this.selectedCategory = this.categories.filter(cat => {
        //   cat.subCategories.forEach(sub => {
        //     return sub.subCategoryId == this.item!.subCategoryId
        //   })
        // })[0];

        this.categories.forEach(cat => [
          cat.subCategories.forEach(sub => {
            if (sub.subCategoryId == this.item!.subCategoryId) {
              this.selectedCategory = cat;
              this.selectedSubCategory = sub;
            }
          })
        ])


        console.log(this.selectedCategory)
      }
    })
  }

  detectChange(lang: string, $e: any) {
    let value = $e.target.value;

    if (lang !== 'ar') {
      this.googleTranslate.translate({ q: value, target: 'ar' }).subscribe(res => {
        this.newItem.get('ItemNameAR')?.setValue(res)
      });
    }

    if (lang !== 'en') {
      this.googleTranslate.translate({ q: value, target: 'en' }).subscribe(res => {
        this.newItem.get('ItemNameEN')?.setValue(res)
      });
    }

    if (lang !== 'tr') {
      this.googleTranslate.translate({ q: value, target: 'tr' }).subscribe(res => {
        this.newItem.get('ItemNameTR')?.setValue(res)
      });
    }

    if (lang != 'fr') {
      this.googleTranslate.translate({ q: value, target: 'fr' }).subscribe(res => {
        this.newItem.get('ItemNameFR')?.setValue(res)
      });
    }
  }

  onSelect(event: NgxDropzoneChangeEvent) {
    console.log(event.addedFiles[0])
    this.itemImage = event.addedFiles[0];
    //console.log(this.itemImage.name)
  }

  uploadImage(itemId: number) {
    // this.isLoading = true;
    let formData = new FormData();
    formData.append('logofile', this.itemImage!);
    this.itemsService.uploadItemLogo(formData, itemId, this.itemImage!.name).subscribe((res: any) => {
      this.isLoading = false;
      this.toastrService.success('added successfully');
      this.dialogRef.close();
    }, error => { this.isLoading = false })
  }

  onOpenPriceChange($event: boolean) {
    if ($event) {
      this.newItemPricing.patchValue({
        FacePrice: 0,
        DiscountAmount: 0
      })
    }
  }
}
