import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import {
  NbTreeGridDataSource,
  NbSortDirection,
  NbSortRequest,
  NbTreeGridDataSourceBuilder,
  NbDialogService,
  NbToastrService,
  NbTreeGridComponent,
} from '@nebular/theme';

import { NewSubCategoryComponent } from '../../modals/new-sub-category/new-sub-category.component';
import { SubCategoryListComponent } from '../../modals/sub-category-list/sub-category-list.component';
import { BranchCategoryLogoComponent } from '../../modals/branch-category-logo/branch-category-logo.component';
import { ItemsService } from '../../services/items.service';
import { Category, SubCategory } from '../../models/category.model';




import { DeleteCategoryComponent } from '../../modals/delete-category/delete-category.component';
import { NewCategoryComponent } from '../../modals/new-category/new-category.component';
import { CDK_TABLE } from '@angular/cdk/table';

interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>[];
  expanded?: boolean;
}

interface CategoryTable {
  id: number;
  name: string;
  icon: string;
  place: number;
  isChild: boolean;
  isActive?: boolean;
  parentLength?: number;
  parentId?: number;
}

@Component({
  selector: 'app-branch-category',
  templateUrl: './branch-category.component.html',
  styleUrls: ['./branch-category.component.scss'],
})
export class BranchCategoryComponent implements OnInit {
  @Input()
  branchID!: string; // branch id passed to component
  isLoading: boolean = false;
  customColumn = 'name';
  defaultColumns = ['icon', 'options'];
  allColumns = [this.customColumn, ...this.defaultColumns];
  dataSource!: NbTreeGridDataSource<CategoryTable>;
  sortColumn: string = 'place';
  sortDirection: NbSortDirection = NbSortDirection.NONE;
  elements!: TreeNode<CategoryTable>[];
  placeSorter: NbSortRequest = {
    column: 'place',
    direction: NbSortDirection.ASCENDING,
  };
  minPlace: number = 0;
  maxPlace: number = 0;
  private data: any = [];
  pages: number[] = [];
  pageNumber: number = 1;
  nextPage: boolean = true;
  prevPage: boolean = false;
  logoPlaceholder: string =
    'https://socialimpact.com/wp-content/uploads/2021/03/logo-placeholder-300x210.jpg';

  constructor(
    private dataSourceBuilder: NbTreeGridDataSourceBuilder<CategoryTable>,
    private itemService: ItemsService,
    private dialogService: NbDialogService,
    private nbToaster: NbToastrService
  ) { }

  onNext() {
    //console.log(this.pageNumber)
    this.getCategories(this.pageNumber + 1);
  }

  onPrevious() {
    //console.log(this.pageNumber)
    this.getCategories(this.pageNumber - 1);
  }

  onSearch(input: any) {
    if (input.target.value.length > 2) {
      this.getCategories(1, input.target.value);
    } else if (input.target.value == '') {
      this.getCategories(1);
    }
  }

  onPageChange(page: number) {
    this.pageNumber = page;
    this.getCategories(page);
  }

  viewIcon(obj: CategoryTable) {
    console.log('viewing item')
    let cat;
    if (obj.isChild) {
      //console.log('isChild')
      cat = this.getSubCategoryFromCategoryTable(obj);
      //console.log({cat})
    } else {
      cat = this.getCategoryFromCategoryTable(obj);
    }

    let branchID = this.branchID;
    this.dialogService
      .open(BranchCategoryLogoComponent, {
        context: {
          obj: cat,
          branchID,
        },
      })
      .onClose.subscribe((res) => {
        obj.icon = res;
      });
  }

  makeUrlSafe(url: string) {
    console.log({ url });
    return 'http://' + url;
  }

  getNode(data: any): TreeNode<CategoryTable> {
    return <TreeNode<CategoryTable>>(<unknown>{
      data: this.getCat(data),
      children: this.getNode(data),
    });
  }

  updatePlace(row: TreeNode<CategoryTable>, dir: string) {
    console.log({ row });

    let place = 0;
    if (row.data.place > 1 && dir == 'up') {
      place = row.data.place - 1;
    } else {
      place = row.data.place + 1;
    }
    row.expanded = false;
    this.updateItemPlace(row.data, place, dir);

    let sorter: NbSortRequest = {
      column: 'place',
      direction: NbSortDirection.ASCENDING,
    };
    this.dataSource = this.dataSourceBuilder.create(this.elements);
    // if (row.data.isChild)
    let idx = this.getIndex(row);
    console.log({ idx });

    this.dataSource.toggleByIndex(idx);
    this.dataSource.sort(sorter);
  }

  updateItemPlace(element: CategoryTable, place: number, dir: string) {
    if (element.isChild) {
      //elemnt is SubCategory
      this.elements.forEach((category) => {
        if (category.children) {
          category.children.forEach((subCategry) => {
            if (subCategry.data.place == place) {
              subCategry.data.place = dir == 'up' ? place + 1 : place - 1; //move the origin downward
            }
            if (subCategry.data.name == element.name) {
              subCategry.data.place = place; //put destination in place
            }
          });
        }
      });
    } else {
      //element is category
      this.elements.forEach((category) => {
        if (category.data.place == place) {
          category.data.place = dir == 'up' ? place + 1 : place - 1; //move the origin downward or downward
        }
        if (category.data.name == element.name) {
          category.data.place = place;
        }
      });
    }
  }

  getIndex(element: TreeNode<CategoryTable>): number {
    let idx = 0;
    if (element.children) {
      idx = this.elements.indexOf(element);
    } else {
      this.elements.forEach((category, index) => {
        if (category.children) {
          category.children.forEach((subCategry) => {
            if (subCategry.data.name == element.data.name) {
              idx = index;
            }
          });
        }
      });
    }
    return idx;
  }

  getMaxRowLength(element: TreeNode<CategoryTable>): number {
    let length = 0;
    //if elemnt is child calc the category children array

    //if element is paret retun child[] length

    if (element.children) {
      //elemnt is Category
      length = this.elements.length;
    } else {
      // element is subCategory
      this.elements.forEach((category) => {
        if (category.children) {
          category.children.forEach((subCategry) => {
            if (subCategry.data.name == element.data.name) {
              length = category.children?.length!;
            }
          });
        }
      });
    }

    console.log({ length });

    return length;
  }

  getNodes(
    data: SubCategory[],
    parentLength: number = 0,
    categoryId?: number
  ): TreeNode<CategoryTable>[] {
    return data.map((el) => {
      return <TreeNode<CategoryTable>>{
        data: this.getSub(el, parentLength, categoryId),
        parentLength: parentLength,
      };
    });
  }

  getCat(tst: Category): CategoryTable {
    return <CategoryTable>{
      id: tst.categoryId,
      name: tst.categoryNameEN,
      place: Number(tst.categoryPlace),
      icon: tst.imageUrl,
      isChild: false,
      isActive: tst.isActive
    };
  }

  getSub(
    sub: SubCategory,
    parentLength: number,
    parentId?: number
  ): CategoryTable {
    return <CategoryTable>{
      name: sub.subCategoryNameAR,
      place: Number(sub.subCategoryPlace),
      icon: sub.imageUrl,
      isChild: true,
      isActive: sub.isActive,
      parentLength: parentLength,
      parentId: parentId,
    };
  }

  ngOnInit(): void {
    console.log(this.branchID);
    this.getCategories(this.pageNumber);
  }

  updateSort(sortRequest: NbSortRequest): void {
    console.log({ sortRequest });
    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  onLogoChange(e: any) {
    console.log({ e });
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  async getCategories(page: number, searchKey?: string) {
    this.isLoading = true;
    this.itemService
      .getCategoriesWithPagination(
        this.branchID,
        page,
        searchKey ? searchKey : ''
      )
      .subscribe(
        (res) => {
          //console.log({ res });

          this.isLoading = false;

          //pagination
          let pagination = Array.from(
            { length: res.totalPageCount },
            (_, i) => i + 1
          );
          let start =
            res.currentPageNumber >= 4 ? res.currentPageNumber - 3 : 0;
          let end =
            res.currentPageNumber < res.totalPageCount
              ? res.currentPageNumber + 3
              : res.currentPageNumber;

          let pagesToShow = pagination.slice(start, end);
          this.pages = pagesToShow;
          this.pageNumber = res.currentPageNumber;
          //  console.log({pagesToShow})
          if (res.currentPageNumber == pagination.length) {
            this.nextPage = false;
          }

          if (res.currentPageNumber > 1) {
            this.prevPage = true;
          } else {
            this.prevPage = false;
          }

          this.data = res.value;
          let cats = res.value.map((el: Category) => {
            return <TreeNode<CategoryTable>>{
              data: this.getCat(el),
              children: this.getNodes(
                el.subCategories,
                el.subCategories!.length,
                el.categoryId
              ),
            };
          });

          this.minPlace = Math.min.apply(
            Math,
            cats.map((el: any) => el.data.place)
          );
          this.maxPlace = Math.max.apply(
            Math,
            cats.map((el: any) => el.data.place)
          );

          this.elements = cats;

          this.dataSource = this.dataSourceBuilder.create(this.elements);
          this.dataSource.sort(this.placeSorter);
        },
        (err) => {
          this.isLoading = false;
          console.log({ err });
        },
        () => {
          this.isLoading = false;

          let categories = this.data.map((el: Category) => {
            return <TreeNode<CategoryTable>>{
              data: this.getCat(el),
              children: this.getNodes(
                el.subCategories!,
                el.subCategories!.length!,
                el.categoryId
              ),
            };
          });

          this.elements = categories;
          this.dataSource = this.dataSourceBuilder.create(categories);
          this.dataSource.sort(this.placeSorter);
        }
      );
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + nextColumnStep * index;
  }

  newCategory() {
    let branchID = this.branchID;
    this.dialogService
      .open(NewCategoryComponent, {
        context: {
          branchID,
        },
      })
      .onClose.subscribe((onClose) => {
        this.getCategories(1);
      });
  }

  subCategoryList(category: CategoryTable) {
    //console.log({ category })
    let branchID = this.branchID;
    this.dialogService.open(SubCategoryListComponent, {
      context: {
        categoryId: category.id,
        branchId: branchID,
      },
    });
  }

  newSubCategory(category: CategoryTable) {
    //console.log({ category })
    let branchID = this.branchID;
    this.dialogService
      .open(NewSubCategoryComponent, {
        context: {
          branchID,
          categoryId: category.id,
        },
      })
      .onClose.subscribe((onClose) => {
        this.getCategories(this.pageNumber);
      });
  }

  getCategoryFromCategoryTable(categoryTable: CategoryTable): Category {
    return this.data.find((category: any) => {
      return category.categoryId == categoryTable.id;
    })!;
  }

  getSubCategoryFromCategoryTable(subCategory: CategoryTable): SubCategory {
    console.log({ subCategory });
    let data1 = this.data.find((category: any) => {
      return category.categoryId == subCategory.parentId;
    });

    console.log({ data1 });

    let data2 = data1.subCategories!.find((data1SubCategory: any) => {
      return data1SubCategory.subCategoryNameEN == subCategory.name;
    });

    console.log({ data2 });

    return data2;
  }

  editCategory(category: CategoryTable) {
    console.log({ category });
    let branchID = this.branchID;

    if (!category.isChild) {
      this.dialogService
        .open(NewCategoryComponent, {
          context: {
            branchID,
            category: this.getCategoryFromCategoryTable(category),
          },
        })
        .onClose.subscribe(() => {
          this.getCategories(this.pageNumber);
        });
    } else {
      let sub = this.getSubCategoryFromCategoryTable(category);
      console.log({ sub });
      this.dialogService
        .open(NewSubCategoryComponent, {
          context: {
            branchID,
            subCategory: this.getSubCategoryFromCategoryTable(category),
          },
        })
        .onClose.subscribe(() => {
          this.getCategories(this.pageNumber);
        });
    }
  }

  changeActiveStatus(category: CategoryTable) {
    //console.log({category})
    if ('isChild' in category && category.isChild) {
      let sub = this.getSubCategoryFromCategoryTable(category);
      let subDto = {
        subCategoryId: sub.subCategoryId,
        subCategoryNameAR: sub.subCategoryNameAR,
        subCategoryNameEN: sub.subCategoryNameEN,
        subCategoryNameFR:sub.subCategoryNameFR,
        subCategoryNameTR:sub.subCategoryNameTR,
        subCategoryPlace:sub.subCategoryPlace,
        isActive: !sub.isActive
      }

      this.itemService.editSubCategory(subDto).subscribe(res => {
        sub.isActive = subDto.isActive;
        category.isActive = subDto.isActive;
        this.nbToaster.show(`${category.name} is ${subDto.isActive? 'enabled': 'disabled'}`, 'success', {
          status: `${subDto.isActive? 'success': 'warning'}`,
          hasIcon: false,
        });
        //this.getCategories(1);
      }, err => {
        console.log(err);
        this.nbToaster.show('failed to update active status,please try again.', 'error', {
          status: 'danger',
          hasIcon: false,
        });
      });
      //console.log({sub})
    } else {
      let cat = this.getCategoryFromCategoryTable(category);

      let catDto = {
        categoryId: cat.categoryId,
        categoryNameAR: cat.categoryNameAR,
        categoryNameEN: cat.categoryNameEN,
        categoryNameFR:cat.categoryNameFR,
        categoryNameTR:cat.categoryNameTR,
        categoryPlace:cat.categoryPlace,
        isActive: !cat.isActive
      }

      this.itemService.editCategory(catDto).subscribe(res => {
        cat.isActive = catDto.isActive;
        category.isActive = catDto.isActive;
        this.nbToaster.show(`${category.name} is ${catDto.isActive? 'enabled': 'disabled'}`, 'success', {
          status: `${category.isActive? 'success': 'warning'}`,
          hasIcon: false,
        });
        //this.getCategories(1);
      }, err => {
        console.log(err);
        this.nbToaster.show('failed to update active status,please try again.', 'error', {
          status: 'danger',
          hasIcon: false,
        });
      });
    }
  }

  deleteCategory(category: CategoryTable | SubCategory) {
    let branchID = this.branchID;
    if ('isChild' in category && category.isChild) {
      category = this.getSubCategoryFromCategoryTable(category);
    }
    this.dialogService
      .open(DeleteCategoryComponent, {
        context: {
          category,
        },
      })
      .onClose.subscribe((onClose) => {
        this.getCategories(this.pageNumber);
      });
  }
}
@Component({
  selector: 'nb-fs-icon',
  template: `
    <nb-tree-grid-row-toggle [expanded]="expanded" *ngIf="isExpandable">
    </nb-tree-grid-row-toggle>
  `,
})
export class FsIconComponent {
  @Input()
  isExpandable!: boolean;
  @Input()
  expanded!: boolean;
}
