import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbSidebarService } from '@nebular/theme';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BuisnessService } from '../../services/buisness.service';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {

  constructor(private businessService: BuisnessService, public router: Router, private sidebarService: NbSidebarService) { }
  options: string[] = [];
  filteredOptions$!: Observable<any[]>;
  businesses: any[] = [];
  branches: any[] = [];
  list: any[] = [];
  loading: boolean = false;
  @Input() customClass: string = "";
  @ViewChild('autoInput') input: any;

  ngOnInit(): void {
    this.filteredOptions$ = of(this.options);
  }

  private filter(value: string): any[] {
    if (value == 'no data found') {
      return this.options;
    } else {
      const filterValue = value.toLowerCase();
      return this.options.filter((optionValue: any) => optionValue.name.toLowerCase().includes(filterValue));
    }
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange(input: any) {
    //console.log(input.value.length)
    if (input.value.length >= 1) {
      this.loading = true;
      this.businessService.search(input.value).subscribe((res: any) => {
        //console.log(res)
        if (res.isSucceeded) {
          this.businesses = [];
          this.branches = [];
          this.options = [];

          this.businesses = res.businesses;
          this.branches = res.branches;

          this.list = res.businesses.concat(res.branches);
          this.list.forEach((item: any) => {
            if (item.businessId) {
              item.type = 'br';
            } else {
              item.type = 'B';
            }

            this.options.push(item)
          });

          this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);
          this.loading = false;
        } else {
          this.options = [];
          this.options.push(res.message);
          this.filteredOptions$ = this.getFilteredOptions(res.message);
          this.loading = false;
        }

      }, err => console.log(err));
    } else {
      this.loading = false;
      this.options = [];
      this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);
    }
  }

  onSelectionChange($event: any) {
    // console.log($event)
    let name: any = [];

    this.options.forEach((option: any) => {
      if (option.id == $event) {
        name.push(option.name)
      }
    })

    this.filteredOptions$ = this.getFilteredOptions(name[0]);
    let value: any = this.list.filter((item: any) => {
      return item.id == $event;
    });

    let id = value[0].id;
    this.businesses.forEach(business => {
      if (business.id == id) {
        business.type = 'business';
        return value = business;
      }
    });

    this.branches.forEach(branch => {
      if (branch.id == id) {
        branch.type = 'branch';
        return value = branch;
      }
    });

    localStorage.setItem('searchVal', JSON.stringify(value));

    let currentUrl = this.router.url;
    if (currentUrl == '/home') {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
    } else {
      this.router.navigateByUrl('/home');
    }

    this.sidebarService.expand('sidebar');

  }

}
