import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NbDialogRef, NbToastrService } from '@nebular/theme';

import { HttpErrorResponse } from "@angular/common/http";
import { GoogletranslateService, TranslationObj } from "../../services/googletranslate.service";
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { ItemsService } from '../../services/items.service';
import { Category } from '../../models/category.model';


@Component({
  selector: 'app-new-category',
  templateUrl: './new-category.component.html',
  styleUrls: ['./new-category.component.scss'],
})
export class NewCategoryComponent implements OnInit {
  branchID: string = '';
  category?: Category;
  newCategory: FormGroup;
  isLoading: boolean = false;
  id: any = '';
  itemImage?: File
  categoryImage: any;
  autoTranslate: boolean = true;

  constructor(
    private fb: FormBuilder,
    public route: ActivatedRoute,
    private ItemService: ItemsService,
    private nbToastr: NbToastrService,
    private dialogRef: NbDialogRef<NewCategoryComponent>,
    private googleTranslate: GoogletranslateService
  ) {
    this.newCategory = this.fb.group({
      CategoryNameEN: ['', Validators.required],
      CategoryNameAR: ['', Validators.required],
      CategoryNameTR: [''],
      CategoryNameFR: [''],
    });
  }

  detectChange(lang: string, $e: any) {
    let value = $e.target.value;

    if(lang !== 'ar') {
      this.googleTranslate.translate({ q: value, target: 'ar' }).subscribe(res => {
        this.newCategory.get('CategoryNameAR')?.setValue(res)
      });
    }

    if(lang !== 'en') {
      this.googleTranslate.translate({ q: value, target: 'en' }).subscribe(res => {
        this.newCategory.get('CategoryNameEN')?.setValue(res)
      });
    }

    if(lang !== 'tr') {
      this.googleTranslate.translate({ q: value, target: 'tr' }).subscribe(res => {
        this.newCategory.get('CategoryNameTR')?.setValue(res)
      });
    }

    if(lang != 'fr') {
      this.googleTranslate.translate({ q: value, target: 'fr' }).subscribe(res => {
        this.newCategory.get('CategoryNameFR')?.setValue(res)
      });
    }
  }

  ngOnInit(): void {
    console.log(this.category)
    if (this.category) {
      this.newCategory.patchValue({
        CategoryNameEN: this.category.categoryNameEN,
        CategoryNameAR: this.category.categoryNameAR,
        CategoryNameTR: this.category.categoryNameTR,
        CategoryNameFR: this.category.categoryNameFR,
      });

      this.categoryImage = this.category.imageUrl;

      // this.ItemService.getImage(this.category.imageId).subscribe(res => {
      //   // this.categoryImage = res;
      //   var reader = new FileReader();
      //   reader.readAsDataURL(res);
      //   reader.onloadend = () => {
      //     this.categoryImage = reader.result;
      //   }
      // })
    }
    this.onFormChange()
  }

  // updateLogo(event: any) {
  //   this.itemImage = event.target.files[0];
  // }

  onFormChange() {
    this.detectEnValueChange()
  }

  detectFrValueChange() {
    this.newCategory.get("CategoryNameFR")!.valueChanges.subscribe((value => {
      this.translateToArabic(value);
      this.translateToEnglish(value);
      this.translateToTurkish(value);
    }));
  }

  detectTrValueChange() {
    this.newCategory.get("CategoryNameTR")!.valueChanges.subscribe((value => {
      this.translateToArabic(value);
      this.translateToFrench(value);
      this.translateToEnglish(value);
    }));
  }

  detectArValueChange() {
    this.newCategory.get("CategoryNameAR")!.valueChanges.subscribe((value => {
      this.translateToEnglish(value);
      this.translateToFrench(value);
      this.translateToTurkish(value);
    }));
  }

  detectEnValueChange() {
    this.newCategory.get("CategoryNameEN")!.valueChanges.subscribe((value => {
      this.translateToArabic(value);
      this.translateToFrench(value);
      this.translateToTurkish(value);
    }));
  }

  translateToEnglish(value: string) {
    this.isLoading = true;
    const translation: TranslationObj = { q: value, target: 'en' }
    this.googleTranslate.translate(translation).subscribe((res: any) => {
      this.isLoading = false;
      this.newCategory.patchValue({
        CategoryNameEN: res.data.translations[0].translatedText
      })
    }, error => { this.isLoading = false })
  }

  translateToArabic(value: string) {
    this.isLoading = true;
    const translation: TranslationObj = { q: value, target: 'ar' }
    this.googleTranslate.translate(translation).subscribe((res: any) => {
      this.isLoading = false;
      this.newCategory.patchValue({
        CategoryNameAR: res.data.translations[0].translatedText
      })
    }, error => { this.isLoading = false })
  }

  translateToTurkish(value: string) {
    this.isLoading = true;
    const translation: TranslationObj = { q: value, target: 'tr' }
    this.googleTranslate.translate(translation).subscribe((res: any) => {
      this.isLoading = false
      this.newCategory.patchValue({
        CategoryNameTR: res.data.translations[0].translatedText
      })
    }, error => { this.isLoading = false })
  }

  translateToFrench(value: string) {
    this.isLoading = true;
    const translation: TranslationObj = { q: value, target: 'fr' }
    this.googleTranslate.translate(translation).subscribe((res: any) => {
      console.log(res)
      this.newCategory.patchValue({
        CategoryNameFR: res.data.translations[0].translatedText
      })
    }, error => { this.isLoading = false })
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.newCategory.value.branchId = this.branchID;
    if (this.category) console.log('Updating...');
    if (this.newCategory.valid) {
      this.isLoading = true;
      if (this.category) {
        // updating category
        this.updateCategory();
      } else {
        // new category
        this.createCategory();
      }
    }
  }

  updateCategory() {
    let updatedCateory = this.newCategory.value;
    updatedCateory.categoryId = this.category!.categoryId;
    updatedCateory.itemPlace = this.category!.categoryPlace;

    this.ItemService.editCategory(updatedCateory).subscribe(
      (res: any) => {
        console.log(res);
        if (this.itemImage) {
          this.uploadCategoryImage(updatedCateory.CategoryNameEN, this.category!.categoryId)
        } else {
          this.isLoading = false;
          this.nbToastr.success(res.message);
          this.dialogRef.close();
        }

      },
      (err) => {
        console.log(err);
        if (err.error?.errors) this.nbToastr.danger(err.error?.errors);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  createCategory() {
    let newCategortFormData = new FormData();

    for (const key in this.newCategory.value) {
      newCategortFormData.append(key, this.newCategory.value[key]);
    }

    this.ItemService.addCategory(newCategortFormData).subscribe(
      (res: any) => {
        console.log('category res:', res);
        if (this.itemImage) {
          this.uploadCategoryImage(this.newCategory.get('CategoryNameEN')!.value, res.id);
        } else {
          this.isLoading = false;
          this.nbToastr.success(res.message);
          this.dialogRef.close();
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
        this.nbToastr.danger(err.message);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  uploadCategoryImage(name: string, id: number) {
    let img = new FormData();
    img.append('logofile', this.itemImage!);

    this.ItemService.uploadCategoryImage(img, id, name).subscribe(res => {
      this.isLoading = false;
      let msg = this.category? 'category updated successfully.': 'category added successfully.';
      this.nbToastr.success(msg);
      this.dialogRef.close();
    }, err => {
      this.nbToastr.danger(err.message);
      this.isLoading = false;
    })
  }

  onSelect(event: NgxDropzoneChangeEvent) {
    console.log(event.addedFiles[0])
    this.itemImage = event.addedFiles[0];
    //console.log(this.itemImage.name)
  }
}
