import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';


@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  @Input() businessId: string = ''
  @Input() userId: any;
  @Input() userName: string = ''
  @Input() email: string = ''
  @Input() role: string = ''
  selectedRole: string = ''
  @Input() password: string = ''
  confirmpassword: string = ''
  editUserForm: FormGroup = new FormGroup({
    userName: new FormControl(''),
    email: new FormControl(''),
    role: new FormControl(''),
    // businessId: new FormControl(''),
    password: new FormControl(''),
    // confirmpassword: new FormControl(''),
  });
  roles: any = []
  showPassword = true;
  showconfirmPassword = true;
  disableInput: boolean = true;
  userList: User[] = []
  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    else {
      return 'password';
    }
  }
  getInputType1() {
    if (this.showconfirmPassword) {
      return 'text';
    }
    else {
      return 'password';
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  toggleShowPassword1() {
    this.showconfirmPassword = !this.showconfirmPassword;
  }
  constructor(public userService: UserService,
    protected dialogRef: NbDialogRef<EditUserComponent>,
    private nbToaster: NbToastrService
  ) { }

  ngOnInit(): void {
    console.log(this.role, this.roles)
    this.editUserForm.patchValue({
      userName: this.userName,
      email: this.email,
      role: this.role,
      // businessId: this.businessId
      password: this.password,
      // confirmpassword: this.confirmpassword
    });
    console.log(this.editUserForm.value, "hey edit user business")
    this.getUsers();
    this.userService.refresh.subscribe(res => this.getUsers());
    this.getUserRoles()
  }
  getUsers() {
    this.userService.getUsersBusiness(this.businessId).subscribe((res: any) => {
      this.userList = res;
    },
      (err) => {
        console.error({ err });
      });
  }
  getUserRoles() {
    this.userService.getUserRoles().subscribe((res: any) => {
      this.roles = res;
      // this.roles.f
      this.roles = res.filter((role: any) => {
        return role.name == 'business'
      });
      console.log(this.roles, "roles")
    },
      (err) => {
        console.error({ err });
      });
  }
  editUser() {
    this.userService.editUser(this.editUserForm.value, this.userId).subscribe((res: any) => {
      if (res.isSucceeded == true) {
        this.onClose('success');
      } else {
        this.onClose(res.message);
      }
      this.nbToaster.show('User Edited successfully.', 'success', {
        status: 'success',
        hasIcon: false,
        icon: 'checkmark-outline'
      });
      this.userService.refresh.emit();
      this.dialogRef.close();
    },
      (err) => {
        console.error({ err });
        this.nbToaster.show(err.error.Message, 'error', {
          status: 'danger',
          hasIcon: false,
          icon: 'close-outline'
        });
      });

  }
  onClose(msg: string) {
    this.dialogRef.close(msg);
  }
  cancel() {
    this.dialogRef.close()
  }
}
