<nb-card class="m-0">
  <nb-card-header>
    <h5>{{posType?'Edit' : 'Add New'}} POS Type</h5>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="addPosTypeForm">
      <div class="form-row">
        <div class="mb-3 col-12">
          <label class="mb-2">Type Name <span class="text-danger">*</span></label>
          <input type="text" formControlName="TypeName" nbInput fullWidth placeholder="Enter POS Type Name">
          <span *ngIf="submitted && addPosTypeForm.get('TypeName')?.errors?.['required']" class="text-danger">Type name
            is required</span>
        </div>
        <div class="mb-3 col-12">
          <label class="mb-2">Pos Type Image <span class="text-danger">*</span></label>
          <div class="row  mb-1">
            <div class="col-12 {{posType? 'col-md-6 mb-3 mb-md-0' : ''}}">
              <ngx-dropzone class="justify-content-center h-100" (change)="updateTypeImage($event)" [multiple]="false"
                accept="image/*">
                <ngx-dropzone-label>Drop pos type image to {{posType? 'change current image':'upload'}}</ngx-dropzone-label>
                <ngx-dropzone-preview *ngIf="posTypeImage" [removable]="true">
                  <ngx-dropzone-label>{{ posTypeImage.name }}</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
            <div class="col-12 col-md-6" *ngIf="posType">
              <img class="img-fluid img-thumbnail" src="{{posType.imageUrl }}" alt="">
            </div>
          </div>
          <span *ngIf="submitted && addPosTypeForm.get('Image')?.errors?.['required']" class="text-danger">Image is
            required</span>
        </div>
        <div class="mb-3 col-12">
          <label class="mb-2">Uploaded By</label>
          <input type="text" formControlName="UploadedBy" nbInput fullWidth placeholder="Enter Name">
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="text-right">
    <button nbButton status="basic" (click)="onCancel()">Cancel</button>
    <button nbButton status="success" (click)="onSave()">Save</button>
  </nb-card-footer>
</nb-card>
