import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'app-recents',
  templateUrl: './recents.component.html',
  styleUrls: ['./recents.component.scss']
})
export class RecentsComponent implements OnInit {
  branchId: string = "";
  recents: any[] = [];
  msg: string = 'recent history is empty';

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit(): void {
    this.onGetBranchLogs();
  }

  onGetBranchLogs(id?: string) {
    setTimeout(() => {
      console.log('getting branch logs>>>')
      this.msg = 'getting recents';
      this.userService.getRecents().subscribe(res => {
        console.log(res)
        if (res == null) {
          console.log('no data found');
          this.msg = 'recent history is empty';
        } else {
          // res = res.slice(Math.max(res.length - 5, 0));
          this.recents = res;
        }

      }, err => console.log(err))
    }, 2000);
  }

  showBranch(branch: any) {
    // console.log(branch)
    let value = {
      businessId: "",
      id: branch.branchId,
      name: branch.branchName,
      type: "branch"
    };

    localStorage.setItem('searchVal', JSON.stringify(value));

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([`/home/branch-tab/${branch.branchId}`]);
  }

}
