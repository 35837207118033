import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NbAuthJWTInterceptor, NbAuthJWTToken, NbAuthModule, NbPasswordAuthStrategy } from '@nebular/auth';
import { environment } from '../environments/environment';
import { HomeComponent } from './pages/home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy, NgOptimizedImage } from '@angular/common';
import { OverlayContainer } from '@angular/cdk/overlay';
import { BearerInterceptor } from './interceptors/bearer.interceptor';
import { NbAccordionModule, NbActionsModule, NbAlertModule, NbAutocompleteModule, NbBadgeModule, NbButtonGroupModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbContextMenuModule, NbDatepickerModule, NbDialogModule, NbFormFieldModule, NbIconModule, NbInputModule, NbLayoutModule, NbListModule, NbMenuModule, NbPopoverModule, NbProgressBarModule, NbRadioModule, NbRouteTabsetModule, NbSearchModule, NbSelectModule, NbSidebarModule, NbSpinnerModule, NbStepperModule, NbTabsetModule, NbTagModule, NbThemeModule, NbToastrModule, NbToggleModule, NbTooltipModule, NbTreeGridComponent, NbTreeGridModule, NbUserModule, NbWindowModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SearchFormComponent } from './components/search-form/search-form.component';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { RecentsComponent } from './components/recents/recents.component';
import { LogsComponent } from './components/logs/logs.component';
import { LogComponent } from './components/logs/log/log.component';
import { RecentComponent } from './components/recents/recent/recent.component';
import { BranchCategoryComponent, FsIconComponent } from './components/branch-category/branch-category.component';
import { DeleteCategoryComponent } from './modals/delete-category/delete-category.component';
import { NewCategoryComponent } from './modals/new-category/new-category.component';
import { AddBranchPosComponent } from './components/add-pos/add-pos.component';
import { BranchCategoryIconsComponent } from './components/branch-category-icons/branch-category-icons.component';
import { BranchItemsManageComponent } from './components/branch-items-manage/branch-items-manage.component';
import { BranchItemsPlaceComponent } from './components/branch-items-place/branch-items-place.component';
import { BranchItemsPricingComponent } from './components/branch-items-pricing/branch-items-pricing.component';
import { BranchItemsSheetComponent } from './components/branch-items-sheet/branch-items-sheet.component';
import { BranchItemsComponent } from './components/branch-items/branch-items.component';
import { BranchLogosComponent } from './components/branch-logos/branch-logos.component';
import { BranchLogsComponent } from './components/branch-logs/branch-logs.component';
import { AddBusinessFormComponent } from './components/business/add-business-form/add-business-form.component';
import { BusinessComponent } from './components/business/business.component';
import { RenameBusinessFormComponent } from './components/business/rename-business-form/rename-business-form.component';
import { AddCashierComponent } from './components/cashier/add-cashier.component';
import { DeleteCashierComponent } from './components/cashier/delete-cashier/delete-cashier.component';
import { EditCashierComponent } from './components/cashier/edit-cashier/edit-cashier.component';
import { ResetPasswordCashierComponent } from './components/cashier/reset-password-cashier/reset-password-cashier.component';
import { ClaimsBranchComponent } from './components/claims-branch/claims-branch.component';
import { CouponBranchComponent } from './components/coupon-branch/coupon-branch.component';
import { AddCustomerComponent } from './components/customers/add-customer/add-customer.component';
import { CustomersComponent } from './components/customers/customers.component';
import { DeletedtxrsBranchComponent } from './components/deletedtxrs-branch/deletedtxrs-branch.component';
import { DisconnectPosComponent } from './components/disconnect-pos/disconnect-pos.component';
import { EditBranchComponent } from './components/edit-branch/edit-branch.component';
import { AddFeatureGroupComponent } from './components/feature/add-feature-group/add-feature-group.component';
import { AddFeatureComponent } from './components/feature/add-feature/add-feature.component';
import { AddPackageComponent } from './components/feature/add-package/add-package.component';
import { FeatureComponent } from './components/feature/feature.component';
import { GenerateCouponsComponent } from './components/generate-coupons/generate-coupons.component';
import { ExportComponent } from './components/import-export/export/export.component';
import { ImportExportComponent } from './components/import-export/import-export.component';
import { ImportComponent } from './components/import-export/import/import.component';
import { ModalComponent } from './components/modal/modal.component';
import { AddSmsComponent } from './components/new-branch/add-sms/add-sms.component';
import { EditSmsComponent } from './components/new-branch/edit-sms/edit-sms.component';
import { NewBranchComponent } from './components/new-branch/new-branch.component';
import { OperationsComponent } from './components/operations/operations.component';
import { OrderComponent } from './components/orders/order/order.component';
import { OrdersComponent } from './components/orders/orders.component';
import { ParamsPosComponent } from './components/params-pos/params-pos.component';
import { PosBranchLogsComponent } from './components/pos-branch-logs/pos-branch-logs.component';
import { PosBranchComponent } from './components/pos-branch/pos-branch.component';
import { PosInfoComponent } from './components/pos-info/pos-info.component';
import { AddPosComponent } from './components/pos/add-pos/add-pos.component';
import { PosHistoryComponent } from './components/pos/pos-history/pos-history.component';
import { PosTypesComponent } from './components/pos/pos-types/pos-types.component';
import { PosComponent } from './components/pos/pos.component';
import { EditLayoutComponent } from './components/printing-layouts/edit-layout/edit-layout.component';
import { NewLayoutComponent } from './components/printing-layouts/new-layout/new-layout.component';
import { PrintingLayoutsComponent } from './components/printing-layouts/printing-layouts.component';
import { TicketComponent } from './components/printing-layouts/ticket/ticket.component';
import { ViewLayoutComponent } from './components/printing-layouts/view-layout/view-layout.component';
import { ReplacePosComponent } from './components/replace-pos/replace-pos.component';
import { SelectBranchLocationComponent } from './components/select-branch-location/select-branch-location.component';
import { AddSmsNotificationComponent } from './components/sms-notification/add-sms-notification/add-sms-notification.component';
import { DeleteSmsNotificationComponent } from './components/sms-notification/delete-sms-notification/delete-sms-notification.component';
import { EditSmsNotificationComponent } from './components/sms-notification/edit-sms-notification/edit-sms-notification.component';
import { TableFormComponent } from './components/table/table-form/table-form.component';
import { TableComponent } from './components/table/table.component';
import { TrxDetailsComponent } from './components/trx-details/trx-details.component';
import { TrxsBranchComponent } from './components/trxs-branch/trxs-branch.component';
import { AddUserBranchComponent } from './components/user-branch/add-user.component';
import { DeleteUserBranchComponent } from './components/user-branch/delete-user/delete-user.component';
import { EditUserBranchComponent } from './components/user-branch/edit-user/edit-user.component';
import { ForgotPasswordBranchComponent } from './components/user-branch/forgot-password/forgot-password.component';
import { ResetPasswordUserBranchComponent } from './components/user-branch/reset-password-user/reset-password-user.component';
import { AddUserComponent } from './components/user-business/add-user.component';
import { DeleteUserComponent } from './components/user-business/delete-user/delete-user.component';
import { EditUserComponent } from './components/user-business/edit-user/edit-user.component';
import { ForgotPasswordComponent } from './components/user-business/forgot-password/forgot-password.component';
import { ResetPasswordUserComponent } from './components/user-business/reset-password-user/reset-password-user.component';
import { AddWorkerComponent } from './components/workers/add-worker/add-worker.component';
import { WorkersComponent } from './components/workers/workers.component';
import { LazyActivatableDirective } from './directives/lazy-activatable.directive';
import { BranchCategoryLogoComponent } from './modals/branch-category-logo/branch-category-logo.component';
import { BranchItemsLogoComponent } from './modals/branch-items-logo/branch-items-logo.component';
import { DeleteItemComponent } from './modals/delete-item/delete-item.component';
import { EditQtyComponent } from './modals/edit-qty/edit-qty.component';
import { NewItemComponent } from './modals/new-item/new-item.component';
import { NewSubCategoryComponent } from './modals/new-sub-category/new-sub-category.component';
import { SubCategoryListComponent } from './modals/sub-category-list/sub-category-list.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { DynamicFieldComponent } from './shared/components/dynamic-field/dynamic-field.component';
import { DynamicInputComponent } from './shared/components/dynamic-field/dynamic-input/dynamic-input.component';
import { DynamicToggleComponent } from './shared/components/dynamic-field/dynamic-toggle/dynamic-toggle.component';
import { DynamicFormComponent } from './shared/components/dynamic-form/dynamic-form.component';
import { SortDirective } from './utils/directive/sort.directive';
import { BranchIconsComponent } from './components/branch-icons/branch-icons.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { GoogleMapsModule } from '@angular/google-maps';
import { LogMonitorModule } from 'ngx-log-monitor';
import { CouponBranchTabComponent } from './pages/coupon-branch-tab/coupon-branch-tab.component';
import { BranchTabComponent } from './pages/branch-tab/branch-tab.component';
import { BusinessTabComponent } from './pages/business-tab/business-tab.component';
import { MainBranchTabComponent } from './pages/main-branch-tab/main-branch-tab.component';
import { MainBusinessTabComponent } from './pages/main-business-tab/main-business-tab.component';
import { PosTabComponent } from './pages/pos-branch-tab/pos-branch-tab.component';
import { SearchComponent } from './pages/search/search.component';
import { TrxsBranchTabComponent } from './pages/trxs-branch-tab/trxs-branch-tab.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { NewLayout2Component } from './components/printing-layouts/new-layout2/new-layout2.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CDK_TABLE, CdkTable, CdkTableModule } from '@angular/cdk/table';
import { DeliveryManagementComponent } from './components/delivery-management/delivery-management.component';
import { AddAreaComponent } from './components/delivery-management/add-area/add-area.component';
import { ReferenceComponent } from './pages/reference/reference.component';
import { BranchesComponent } from './pages/branches/branches.component';
import { DeleteAreaComponent } from './components/delivery-management/delete-area/delete-area.component';
import { ImgCardComponent } from './components/img-card/img-card.component';
import { EditCardComponent } from './components/img-card/edit-card/edit-card.component';
import { BranchItemsImgManageComponent } from './components/branch-items-img-manage/branch-items-img-manage.component';
import { EditBranchItemsImgComponent } from './components/branch-items-img-manage/edit-branch-items-img/edit-branch-items-img.component';
import { ReferenceCategoryComponent } from './components/reference-category/reference-category.component';
import { ReferenceSubcategoryComponent } from './components/reference-subcategory/reference-subcategory.component';
import { ReferenceItemsComponent } from './components/reference-items/reference-items.component';
import { ReferenceDataTableComponent } from './components/reference-data-table/reference-data-table.component';
import { ReferenceUploadImgComponent } from './components/reference-data-table/reference-upload-img/reference-upload-img.component';
import { ExpensesListComponent } from './components/expenses/expenses-list/expenses-list.component';
import { PostEditExpenseModalComponent } from './components/expenses/post-edit-expense-modal/post-edit-expense-modal.component';
import { ConfirmExpenseDeleteModalComponent } from './components/expenses/confirm-expense-delete-modal/confirm-expense-delete-modal.component';
import { PostExpenseTypeModalComponent } from './components/expenses/post-expense-type-modal/post-expense-type-modal.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PosInfoComponent,
    BranchLogsComponent,
    BranchIconsComponent,
    DropdownMenuComponent,
    SearchFormComponent,
    RecentsComponent,
    RecentComponent,
    LogsComponent,
    LogComponent,
    FsIconComponent,
    DeleteCategoryComponent,
    NewCategoryComponent,
    HomeComponent,
    AddSmsNotificationComponent,
    AddCashierComponent,
    AddUserComponent,
    AddUserBranchComponent,
    BusinessComponent,
    BranchItemsComponent,
    BranchCategoryComponent,
    BranchItemsManageComponent,
    BranchItemsPlaceComponent,
    BranchItemsPricingComponent,
    ModalComponent,
    AddBusinessFormComponent,
    RenameBusinessFormComponent,
    PosComponent,
    PosHistoryComponent,
    PrintingLayoutsComponent,
    TicketComponent,
    LogsComponent,
    LogComponent,
    RecentComponent,
    AddPosComponent,
    AddBranchPosComponent,
    DisconnectPosComponent,
    ReplacePosComponent,
    ParamsPosComponent,
    PosBranchComponent,
    PosBranchLogsComponent,
    RecentsComponent,
    RecentComponent,
    ImportExportComponent,
    ImportComponent,
    ExportComponent,
    NewBranchComponent,
    PrintingLayoutsComponent,
    GenerateCouponsComponent,
    TrxsBranchComponent,
    ClaimsBranchComponent,
    DeletedtxrsBranchComponent,
    NewLayoutComponent,
    EditLayoutComponent,
    GenerateCouponsComponent,
    TrxsBranchComponent,
    ClaimsBranchComponent,
    DeletedtxrsBranchComponent,
    NewLayoutComponent,
    EditLayoutComponent,
    GenerateCouponsComponent,
    TrxsBranchComponent,
    ClaimsBranchComponent,
    DeletedtxrsBranchComponent,
    NewLayoutComponent,
    EditLayoutComponent,
    DateAgoPipe,
    CouponBranchComponent,
    SortDirective,
    EditUserComponent,
    ResetPasswordUserComponent,
    DeleteUserComponent,
    NewItemComponent,
    NewCategoryComponent,
    DeleteCategoryComponent,
    DeleteItemComponent,
    ForgotPasswordComponent,
    EditCashierComponent,
    DeleteCashierComponent,
    ResetPasswordCashierComponent,
    EditUserBranchComponent,
    ResetPasswordUserBranchComponent,
    DeleteUserBranchComponent,
    ForgotPasswordBranchComponent,
    EditSmsNotificationComponent,
    DeleteSmsNotificationComponent,
    TrxDetailsComponent,
    AddSmsComponent,
    EditSmsComponent,
    SearchFormComponent,
    NewSubCategoryComponent,
    SubCategoryListComponent,
    BranchItemsSheetComponent,
    BranchCategoryLogoComponent,
    BranchItemsLogoComponent,
    SelectBranchLocationComponent,
    BranchCategoryIconsComponent,
    EditBranchComponent,
    BranchLogosComponent,
    DynamicFormComponent,
    DynamicInputComponent,
    DynamicToggleComponent,
    DynamicFieldComponent,
    LazyActivatableDirective,
    ViewLayoutComponent,
    OrdersComponent,
    CustomersComponent,
    OrderComponent,
    AddCustomerComponent,
    EditQtyComponent,
    WorkersComponent,
    AddWorkerComponent,
    PosTypesComponent,
    OperationsComponent,
    FeatureComponent,
    AddFeatureGroupComponent,
    AddFeatureComponent,
    AddPackageComponent,
    TableComponent,
    TableFormComponent,
    CouponBranchTabComponent,
    BranchTabComponent,
    BusinessTabComponent,
    MainBranchTabComponent,
    MainBusinessTabComponent,
    PosTabComponent,
    SearchComponent,
    TrxsBranchTabComponent,
    NewLayout2Component,
    DeliveryManagementComponent,
    AddAreaComponent,
    ReferenceComponent,
    BranchesComponent,
    DeleteAreaComponent,
    ImgCardComponent,
    EditCardComponent,
    BranchItemsImgManageComponent,
    EditBranchItemsImgComponent,
    ReferenceCategoryComponent,
    ReferenceSubcategoryComponent,
    ReferenceItemsComponent,
    ReferenceDataTableComponent,
    ReferenceUploadImgComponent,
    ExpensesListComponent,
    PostEditExpenseModalComponent,
    ConfirmExpenseDeleteModalComponent,
    PostExpenseTypeModalComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    DragDropModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',
          token: {
            class: NbAuthJWTToken,
            key: 'token',
          },
          baseEndpoint: `${environment.baseURL}/Users`,
          login: {
            endpoint: '/login',
            method: 'post',
            redirect: {
              success: 'home',
            },
          },
          register: {
            endpoint: '/register',
            method: 'post',
            requireValidToken: false,
            redirect: {
              success: '/auth/login',
            },
          },
          requestPass: {
            endpoint: `/forgot-password`,
            method: 'post',
            redirect: {
              success: 'auth/reset-password',
            },
          },
          resetPass: {
            endpoint: '/reset-password',
            method: 'post',
            resetPasswordTokenKey: '',
            redirect: {
              success: 'auth/login',
            },
          },
          validation: {
            password: {
              regexp: '^(?=.*[A-Za-z])(?=.*d)[A-Za-zd]{8,}$',
              minLength: 8,
              maxLength: 100,
            },
          },
        }),
      ],
      forms: {
        login: {
          rememberMe: false,
        },
        register: {
          terms: false,
        },
      },
    }),
    GoogleMapsModule,
    // LogMonitorModule,
    ReactiveFormsModule,
    GalleryModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbLayoutModule,
    NbEvaIconsModule,
    NbIconModule,
    NbTabsetModule,
    NbCardModule,
    NbProgressBarModule,
    NbSelectModule,
    NbToggleModule,
    NbButtonModule,
    NbTreeGridModule,
    NbAccordionModule,
    NgxSpinnerModule,
    NbInputModule,
    NbTagModule,
    NbButtonGroupModule,
    NbListModule,
    NbUserModule,
    NbActionsModule,
    NbPopoverModule,
    NbSearchModule,
    NbFormFieldModule,
    NbCheckboxModule,
    NbDialogModule.forRoot(),
    NbRadioModule,
    NbCheckboxModule,
    NbStepperModule,
    NbToastrModule.forRoot(),
    NbBadgeModule,
    NbWindowModule.forRoot(),
    NbRouteTabsetModule,
    NbSpinnerModule,
    NbContextMenuModule,
    NbAutocompleteModule,
    NbAlertModule,
    NgbModule,
    NbDatepickerModule.forRoot(),
    NbTooltipModule,
    NgxDropzoneModule,
    // CdkTableModule
    NgOptimizedImage
  ],
  providers: [
    DatePipe,
    { provide: OverlayContainer },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NbAuthJWTInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerInterceptor,
      multi: true,
    },
    { provide: OverlayContainer },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    NbActionsModule,
  ],
  bootstrap: [AppComponent],
  exports: [NewItemComponent],
})
export class AppModule { }
