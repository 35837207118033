<nb-card class="m-0">
  <nb-card-header>
    <h5>Create Feature</h5>
  </nb-card-header>
  <nb-card-body>
    <form>
      <div class="form-row">
        <div class="mb-3 col-md-6">
          <label class="mb-2">Feature Name EN</label>
          <input type="text" (input)="onNameChange($event)" [(ngModel)]="nameInLanguages[0].name" nbInput fullWidth>
          <!-- <span *ngIf="submitted && featureGroupForm.get('sn')?.errors?.['required']" class="text-danger">SN is required</span> -->
        </div>
        <div class="mb-3 col-md-6">
          <label class="mb-2" >Feature Name AR</label>
          <input type="text" [(ngModel)]="nameInLanguages[1].name" nbInput fullWidth>
          <!-- <span *ngIf="submitted && featureGroupForm.get('sn')?.errors?.['required']" class="text-danger">SN is required</span> -->
        </div>
        <div class="mb-3 col-md-6">
          <label class="mb-2">Feature Name TR</label>
          <input type="text" [(ngModel)]="nameInLanguages[2].name" nbInput fullWidth>
          <!-- <span *ngIf="submitted && featureGroupForm.get('sn')?.errors?.['required']" class="text-danger">SN is required</span> -->
        </div>
        <div class="mb-3 col-md-6">
          <label class="mb-2">Feature Name FR</label>
          <input type="text" [(ngModel)]="nameInLanguages[3].name" nbInput fullWidth>
          <!-- <span *ngIf="submitted && featureGroupForm.get('sn')?.errors?.['required']" class="text-danger">SN is required</span> -->
        </div>
        <div class="mb-3 col-md-6">
          <label class="mb-2">Feature Group</label>
          <select class="form-control custom-select" [(ngModel)]="selectedFeatureGroup" nbInput>
            <option selected disabled value=""> Select Feature Group</option>
            <option *ngFor="let group of featuregroups" value="{{group.featureGroupId}}">
              {{group.defaultName}}
            </option>
          </select>
        </div>
        <div class="mb-3 col-md-6">
          <label class="mb-2">Business type​</label>
          <select class="form-control custom-select" [(ngModel)]="selectedBusinessType"  nbInput>
            <option selected disabled value=""> Select Business type​</option>
            <option *ngFor="let type of businessTypes" value="{{type.id}}">
              {{type.name}}
            </option>
          </select>
        </div>
        <div class="mb-3 col-md-6">
          <label class="mb-2">Key</label>
          <input type="text" [(ngModel)]="key" nbInput fullWidth>
        </div>
        <div class="mb-3 col-md-12">
          <label class="mb-2">SVG Icon</label>
          <ngx-dropzone class="justify-content-center" (change)="onChange($event)" [multiple]="false"
                accept="image/svg+xml">
                <ngx-dropzone-label>Drop pos type image to upload</ngx-dropzone-label>
                <ngx-dropzone-preview *ngIf="icon" [removable]="true">
                  <ngx-dropzone-label>{{ icon.name }}</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
          <!-- <input type="file" (change)="onChange($event)" nbInput fullWidth> -->
          <!-- <span *ngIf="submitted && featureGroupForm.get('sn')?.errors?.['required']" class="text-danger">SN is required</span> -->
        </div>

        <div class="mb-3 col-md-6">
          <nb-checkbox [(ngModel)]="isDefault" status="success">Is Default​</nb-checkbox>
        </div>
        <div class="mb-3 col-12">
          <table class="table table-bordered table-sm">
            <tr>
              <th></th>
              <th>AED</th>
              <th>TRY</th>
              <th>USD</th>
              <!-- <th>Tr</th> -->
            </tr>
            <tbody>
              <tr>
                <th>
                  Price
                </th>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[0].price" nbInput fullWidth>
                </td>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[1].price" nbInput fullWidth>
                </td>
                <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[2].price" nbInput fullWidth>
                </td>
                <!-- <td>
                  <input type="number" [(ngModel)]="priceInCurrencies[3].price" nbInput fullWidth>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <span *ngIf="submitted && !valid" class="text-danger">*all fields are required</span>
    </form>
  </nb-card-body>
  <nb-card-footer class="text-right">
    <button nbButton status="basic" (click)="onCancel()">Cancel</button>
    <button nbButton status="success" (click)="onSave()">Save</button>
  </nb-card-footer>
</nb-card>
