import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-reference-category',
  templateUrl: './reference-category.component.html',
  styleUrl: './reference-category.component.scss'
})
export class ReferenceCategoryComponent implements OnInit,OnChanges {
  
  isLoading: boolean = false;
  searchQuery:any;

  @Input() businessTypeId: any;

  @Input() categories: any[] = [];
  @Output() updateDataEvent = new EventEmitter<void>();

  categoryId: any;

  
  constructor(

  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.categoryId=null;
  }
  ngOnInit(): void {
   
    
  }
  onCategoryChange(){}
  updateevent(){
    console.log('updateDataEvent from ReferenceCategoryComponent');
    
    this.updateDataEvent.emit();

  }
}
