<!-- generateCoupon -->
<nb-card>
  <nb-card-header>
    <h5>Generate Coupons</h5>
  </nb-card-header>
  <nb-card-body>
    <form>
      <div class="row form-row">
        <div class="col-12 mb-3">
          <label for="recipient-name" class="col-form-label">Count Of Coupons</label>
          <input type="number" name="countOfCoupons" [(ngModel)]="addCouponForm.countOfCoupons" nbInput fullWidth
            placeholder="Enter Count Of Coupons">
        </div>
        <div class="col-12 mb-3">
          <label for="recipient-name" class="col-form-label">Discount Type </label>
          <div class="row">
            <div class="col">
              <div class="row">
                <button nbButton status="basic" *ngFor="let discount of couponDiscountTypes"
                  [ngClass]="{'btn': couponTypeId == discount.id}" (click)="onSelect(discount.id, discount.type)"
                  [value]="discount.id" class="discount"> {{discount.type}}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-3">
          <label for="recipient-name" class="col-form-label">Value</label>
          <input type="number" nbInput fullWidth placeholder="Enter Discount Value" name="discountValue"
            [(ngModel)]="addCouponForm.discountValue">
        </div>
        <div class="col-12 mb-3">
          <label for="recipient-name" class="col-form-label">Expiry Date</label> <br>
          <div class="input-group-append">
            <input nbInput placeholder="Enter Expiry Date" name="dp" [(ngModel)]="model" ngbDatepicker
              #d="ngbDatepicker">
            <nb-icon icon="calendar-outline" (click)="d.toggle()" type="button" class="calendr"></nb-icon>
          </div>

        </div>
      </div>
    </form>


    <nb-alert status="success" *ngIf="success.isSucceeded">
      <nb-icon icon="checkmark-outline"></nb-icon>
      Generate Coupon successfully
    </nb-alert>
    <div class="alert alert-danger" *ngIf="alert.alert">{{ alert.message }} please try again.</div>
  </nb-card-body>
  <nb-card-footer class="text-end">
    <button nbButton status="success" (click)="generateCoupon()">
      <nb-icon icon="repeat-outline"></nb-icon>Generate
    </button>
    <button nbButton status="danger" (click)="cancelCoupon()">
      <nb-icon icon="close-outline"></nb-icon>Cancel
    </button>
  </nb-card-footer>
</nb-card>
<!--generateCoupon-->