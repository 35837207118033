import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { EditCardComponent } from './edit-card/edit-card.component';

@Component({
  selector: 'app-img-card',
  templateUrl: './img-card.component.html',
  styleUrl: './img-card.component.scss'
})
export class ImgCardComponent {

  @Input() categories:any;
  @Input() subCategories:any;
  @Input() isCategory:boolean=true;

  @Output() updateParentEvent = new EventEmitter<void>();
  constructor (
    private dialogService: NbDialogService
  ) { 
    console.log('from ImgCardComponent' ,this.categories);
    
  }
  editimg(item:any,isCategory:boolean){
    console.log(item);
    
    const modalRef = this.dialogService.open(EditCardComponent, {
      context: {
        category:item,
        subCategory:item,
        isCategory : isCategory
      },
    });
    modalRef.onClose.subscribe((res: any) => {
      console.log(res);
      if (res?.updated) {
        this.updateParentEvent.emit();
      }  
    });

  }
}
