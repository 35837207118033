import { Component, Input, OnInit } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { BranchService } from '../../services/branch.service';
import { BuisnessService } from '../../services/buisness.service';


@Component({
  selector: 'app-branch-logos',
  templateUrl: './branch-logos.component.html',
  styleUrls: ['./branch-logos.component.scss']
})
export class BranchLogosComponent implements OnInit {

  @Input() branchId: string = "";
  branchLogo: any;
  branchDefaultLogo: any;
  branchPrintLogo: any;
  branchFooterLogo: any;
  currentImage: any;
  logoPlaceholder: string = "https://socialimpact.com/wp-content/uploads/2021/03/logo-placeholder-300x210.jpg";

  constructor(private branchService: BranchService,
     private businessService: BuisnessService, 
     public toastrService: NbToastrService) { }

  ngOnInit(): void {
    this.onGetBranchLogo();
    this.onGetBranchDefaultLogo();
    this.onGetBranchPrintLogo();
    this.onGetBranchFooterLogo();
  }

  onGetBranchLogo() {
    this.branchService.getBranchLogo(this.branchId).subscribe(res => {
      //console.log('branch logo:', res)
      this.branchLogo = res;

    }, err => console.log(err))
  }

  onGetBranchDefaultLogo() {
    this.branchService.getBranchDefaultLogo(this.branchId).subscribe(res => {
      this.branchDefaultLogo = res;
    }, err => console.log(err))
  }

  onGetBranchPrintLogo() {
    this.branchService.getBranchPrintLogo(this.branchId).subscribe(res => {
      this.branchPrintLogo = res;
    }, err => console.log(err))
  }

  onGetBranchFooterLogo() {
    this.branchService.getBranchFooterLogo(this.branchId).subscribe(res => {
      //console.log({footer: res})
      this.branchFooterLogo = res;
    }, err => console.log(err))
  }

  updateLogo(event: any, imageInfo: { name: string, type: number }) {
    let formData: FormData = new FormData();

    formData.append("ImageName", imageInfo.name);
    formData.append("ImageTypeId", JSON.stringify(imageInfo.type));
    formData.append("BranchId", this.branchId);
    formData.append("BusinessTypeId", "1");
    formData.append("File", event.target.files[0]);

    this.businessService.uploadBranchLogos(formData).subscribe(res => {
      console.log(res)
      this.toastrService.show('Logo Uploaded successfully', 'success', {
        status: 'success',
        hasIcon: true,
        icon: {
          icon: 'checkmark-circle-outline',
          pack: 'eva'
        }
      });

      if (imageInfo.type == 1) {
        this.onGetBranchLogo();
      } else if (imageInfo.type == 2) {
        this.onGetBranchDefaultLogo();
      } else if (imageInfo.type == 3) {
        this.onGetBranchPrintLogo();
      } else if (imageInfo.type == 4) {
        this.onGetBranchFooterLogo();
      }
    }, err => {
      this.toastrService.show('failed to upload logo, please try again', 'error', {
        status: 'danger',
        hasIcon: true,
        icon: {
          icon: 'alert-circle-outline',
          pack: 'eva'
        }
      })
    });
  }

}
