import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { SmsnotificationsService } from '../../../services/smsnotifications.service';


@Component({
  selector: 'app-delete-sms-notification',
  templateUrl: './delete-sms-notification.component.html',
  styleUrls: ['./delete-sms-notification.component.scss']
})
export class DeleteSmsNotificationComponent implements OnInit {
  smsId:string = ""
  constructor(
    public smsService:SmsnotificationsService,
    private nbToastr: NbToastrService,
    protected dialogRef: NbDialogRef<DeleteSmsNotificationComponent>) { }

  ngOnInit(): void {
  }
  deleteSms() {
    this.smsService.deleteSmsNotification(this.smsId).subscribe((res:any)=>{     
      if(res.isSucceeded == true) {
        this.onClose('success');
      } else {
        this.onClose(res.message);
      }
      this.nbToastr.show('Sms Deleted successfully.', 'success', {
        status: 'success',
        hasIcon: false,
        icon: 'checkmark-outline'
      });
      this.smsService.refresh.emit();
      this.dialogRef.close();   
    },
    (err) => {
      console.log(err)
      this.nbToastr.show('Cannot Delete Sms', 'error', {
        status: 'danger',
        hasIcon: false,
        icon: 'close-outline'
      });
     })
    }
    onClose(msg: string) {
      this.dialogRef.close(msg);
    }
    cancel() {
      this.dialogRef.close()
    }
}
