import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { OperationsService } from '../../services/operations.service';


@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss'],
  // providers: [NbDialogRef]
})
export class OperationsComponent implements OnInit {
  @Input() branchId: any;
  type: string = '';
  // dialogRef: NbDialogRef
  constructor(private operationsService: OperationsService, private toaster: NbToastrService, private dialogService: NbDialogService) { }

  ngOnInit(): void {
  }

  onResetMenu(ref: NbDialogRef<any>) {
    this.operationsService.resetMenu(this.branchId).subscribe(res => {
      //console.log({res})
      this.toaster.show(res, 'SUCCESS', {
        status: 'success',
        hasIcon: false
      });

      ref.close();
    }, err => {
      console.log({ err })
      this.toaster.show(err.error.error, 'ERROR', {
        status: 'danger',
        hasIcon: false
      });

      ref.close();
    })
  }

  resetMenu(dialog: TemplateRef<any>) {
    this.type = 'menu';
    this.dialogService.open(dialog, { context: 'categories, subcategories and items', dialogClass: 'w-50', closeOnBackdropClick: false });
  }

  onResetBranch(ref: NbDialogRef<any>) {
    this.operationsService.resetBranches(this.branchId).subscribe(res => {
      //console.log({res})
      this.toaster.show(res, 'SUCCESS', {
        status: 'success',
        hasIcon: false
      });

      ref.close();
    }, err => {
      console.log({ err })
      this.toaster.show(err.error.error, 'ERROR', {
        status: 'danger',
        hasIcon: false
      });

      ref.close();
    })
  }

  resetBranch(dialog: TemplateRef<any>) {
    this.type = 'branch';
    this.dialogService.open(dialog, { context: 'categories, subcategories, items, orders, transactions, customers, users and stock', dialogClass: 'w-50', closeOnBackdropClick: false });
  }

  onResetOrderAndTransactions(ref: NbDialogRef<any>) {
    this.operationsService.resetOrderAndTransactions(this.branchId).subscribe(res => {
      //console.log({res})
      this.toaster.show(res, 'SUCCESS', {
        status: 'success',
        hasIcon: false
      });

      ref.close();
    }, err => {
      console.log({ err })
      this.toaster.show(err.error.error, 'ERROR', {
        status: 'danger',
        hasIcon: false
      });

      ref.close();
    })
  }

  restOrderandTrx(dialog: TemplateRef<any>) {
    this.type = 'orderandTrx';
    this.dialogService.open(dialog, { context: 'orders and transactions', dialogClass: 'w-50', closeOnBackdropClick: false });
  }

  onResetOrder(ref: NbDialogRef<any>) {
    this.operationsService.resetOrder(this.branchId).subscribe(res => {
      //console.log({res})
      this.toaster.show(res, 'SUCCESS', {
        status: 'success',
        hasIcon: false
      });

      ref.close();
    }, err => {
      console.log({ err })
      this.toaster.show(err.error.error, 'ERROR', {
        status: 'danger',
        hasIcon: false
      });

      ref.close();
    })
  }

  resetOrder(dialog: TemplateRef<any>) {
    this.type = 'order';
    this.dialogService.open(dialog, { context: 'orders', dialogClass: 'w-50', closeOnBackdropClick: false });
  }

  onResetTransaction(ref: NbDialogRef<any>) {
    this.operationsService.resetTransaction(this.branchId).subscribe(res => {
      //console.log({res})
      this.toaster.show(res, 'SUCCESS', {
        status: 'success',
        hasIcon: false
      });

      ref.close();
    }, err => {
      console.log({ err })
      this.toaster.show(err.error.error, 'ERROR', {
        status: 'danger',
        hasIcon: false
      });

      ref.close();
    })
  }


  resetTrx(dialog: TemplateRef<any>) {
    this.type = 'trx';
    this.dialogService.open(dialog, { context: 'transactions', dialogClass: 'w-50', closeOnBackdropClick: false });
  }

  download() {
    this.operationsService.downloadProducts(this.branchId).subscribe((res:any) => {
      console.log(res)
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = res;
      // the filename you want
      a.download = 'products';
      document.body.appendChild(a);
      a.click();
    }, err => console.log(err))
  }
}
