import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';


import { NbDialogService, NbToastrService } from '@nebular/theme';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Salesman } from '../../models/salesman.model';
import { City, Country, Tax } from '../../models/buisness.model';
import { User } from '../../models/user.model';
import { Cashier } from '../../models/cashier.model';
import { SmsNotification } from '../../models/smsnotification';
import { BuisnessService } from '../../services/buisness.service';
import { UtilityService } from '../../services/utility.service';
import { SalesmanService } from '../../services/salesman.service';
import { UserService } from '../../services/user.service';
import { CashierService } from '../../services/cashier.service';
import { SmsnotificationsService } from '../../services/smsnotifications.service';
import { AddSmsNotificationComponent } from '../../components/sms-notification/add-sms-notification/add-sms-notification.component';
import { EditSmsNotificationComponent } from '../../components/sms-notification/edit-sms-notification/edit-sms-notification.component';
import { DeleteSmsNotificationComponent } from '../../components/sms-notification/delete-sms-notification/delete-sms-notification.component';
import { EditCashierComponent } from '../../components/cashier/edit-cashier/edit-cashier.component';
import { DeleteCashierComponent } from '../../components/cashier/delete-cashier/delete-cashier.component';
import { AddCashierComponent } from '../../components/cashier/add-cashier.component';
import { ResetPasswordCashierComponent } from '../../components/cashier/reset-password-cashier/reset-password-cashier.component';
import { AddUserBranchComponent } from '../../components/user-branch/add-user.component';
import { ForgotPasswordBranchComponent } from '../../components/user-branch/forgot-password/forgot-password.component';
import { EditUserBranchComponent } from '../../components/user-branch/edit-user/edit-user.component';
import { DeleteUserBranchComponent } from '../../components/user-branch/delete-user/delete-user.component';
import { SelectBranchLocationComponent } from '../../components/select-branch-location/select-branch-location.component';


const posParamsTypes: any = {
  Boolean: 'bool',
  Int: 'number',
  String: 'text',
};
@Component({
  selector: 'app-main-branch-tab',
  templateUrl: './main-branch-tab.component.html',
  styleUrls: ['./main-branch-tab.component.scss'],
})
export class MainBranchTabComponent implements OnInit, AfterViewInit {
  selectedItem = '0';
  notifications: any = [];
  salesMen: Salesman[] = [];
  subscriptionTypes: any[] = [];
  taxes: Tax[] = [];
  cities: City[] = [];
  countries: Country[] = [];
  data: any;
  users: User[] = [];
  cashiers: Cashier[] = [];
  branchParams: any = [];
  smsNotifications: SmsNotification[] = [];
  @Input() branchId: any;
  branchName: any;
  branchID: any;
  status: any;
  merchantCopies = [
    { id: 0, status: 'false' },
    { id: 1, status: 'true' },
    { id: 2, status: 'ask' },
  ];
  taxTypes = [
    { id: 1, name: 'Exclusive' },
    { id: 2, name: 'inclusive' },
  ];
  businessName: any;
  businessId: any;
  loading: boolean = false;
  location: any = '';
  addressData: any = '';
  param: any = {};
  branchData: any = {};
  editBranchData: any = {};
  BranchData: any = {};
  AddressData: any = {};
  BranchParamData: any = {};
  ExtraParams: any = [];
  smsBalance: any;
  businesses: any = [];
  smsID: any;
  showLocation: boolean = true;
  p: number = 1;
  BusinessID: any;
  SubcriptionID: any;
  CityID: any;
  CountryID: any;
  Location: any;
  Address: any;
  SalesManId: any;
  decimalPoint: any;
  discountAllowed: any;
  claimAllowed: any;
  nfcProductSearch: any;
  customerAllowed: any;
  payment: any;
  printAllowed: any;
  smsAllowed: any;
  taxAllowed: any;
  footerMessage: any;
  rePrint: any;
  merchantCopy: any;
  taxTypeId: any;
  taxID: any;
  trn: any;
  taxValue: any;
  editbranchData: any;
  currentPlan: any;
  constructor(
    public businessService: BuisnessService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public utilService: UtilityService,
    public saleservice: SalesmanService,
    public userservice: UserService,
    public cashierservice: CashierService,
    private nbToaster: NbToastrService,
    private dialogService: NbDialogService,
    private smsService: SmsnotificationsService,
    private modalService: NgbModal
  ) {
    let val = localStorage.getItem('searchVal');
    this.param = JSON.parse(val!);
    this.branchId = this.param.id;
    if (this.param.type == 'business') {
      this.branchId = this.route.snapshot.paramMap.get('id');
    }

    if (this.param.type == 'branch') {
      this.getBusinessName(this.param.businessId);
    }
  }

  ngOnInit(): void {
    console.log('this.branchId');
  }
  ngAfterViewInit() {
    /** spinner starts on init */
    this.getBranchById();
    this.getSalesMan();
    this.getSubscriptionTypes();
    this.getBusinesses();
    this.getSmsNotification();
    this.getBranchParams();
    this.getCountries();
    this.getTaxes();
    this.getCashiers();
    this.getUserByBranchId();
    this.cashierservice.refresh.subscribe((res) => this.getCashiers());
    this.userservice.refresh.subscribe((res) => this.getUserByBranchId());
    this.smsService.refresh.subscribe((res) => this.getSmsNotification());
    this.businessService.refresh.subscribe((res) => this.getBranchParams());
    this.businessService.refresh.subscribe((res) => this.getBranchById());
    this.smsService.refresh.subscribe((res) => this.getCountries());
    this.spinner.show();
  }
  getBranchById() {
    this.businessService.getBranch(this.branchId).subscribe(
      (res: any) => {
        if(JSON.parse(localStorage.getItem('searchVal')!).businessId == "") {
          localStorage.setItem('businessId', res.branch.businessId);
        }

        this.branchData = res.branch;
        this.branchName = this.branchData?.name;
        this.BusinessID = this.branchData?.businessId;
        this.SalesManId = this.branchData?.salesManId;
        this.SubcriptionID = this.branchData?.subscriptionTypeId;
        this.currentPlan = this.branchData?.currentPlan;
        if (this.branchData != null && this.branchData?.address != null) {
          this.Location = this.branchData?.address?.location;
          this.CountryID = this.branchData.address?.countryId;
          this.CityID = this.branchData.address?.cityId;
        }
        if (
          this.branchData != null &&
          this.branchData?.branchOptionalParameters != null
        ) {
          //this.ExtraParams = this.branchData?.branchOptionalParameters;
          const branchExtraParams: any = {};
          this.branchData?.branchOptionalParameters.map((param: any) => {
            branchExtraParams[param.paramName] = {
              type: posParamsTypes[param.parameterDataType],
              value: param.value,
              label: param.paramName,
              id: param.optionalParameterId,
            };
          });

          this.ExtraParams = branchExtraParams;
        }
        let countryId;
        if (this.branchData?.address != null) {
          countryId = this.branchData.address?.countryId;
          this.getCities(countryId);
        } else {
          countryId = null;
        }
        this.Address = this.branchData?.address?.branchAddress;
        this.branchID = this.branchData?.id;
        this.getBusinessName(this.branchData?.businessId);
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        console.log(err);
      }
    );
  }

  getBusinesses() {
    this.loading = true;
    this.businessService.getBuisnesses(false).subscribe(
      (res: any) => {
        this.businesses = res.businesses;
        this.loading = false;
      },
      (err) => console.log(err)
    );
  }

  getBusinessName(business: string) {
    this.businessService.getBuisness(business).subscribe(
      (res: any) => {
        this.businessName = res.business;
        this.businessId = res.business?.id;
        // this.editBranchData.branch.businessId = res.business?.id
      },
      (err) => console.log(err)
    );
  }

  getSmsNotification() {
    this.loading = true;
    this.smsService.getSmsNotification().subscribe(
      (res: any) => {
        this.smsNotifications = res.notificationBases;
        this.loading = false;
      },
      (err) => console.log(err)
    );
  }

  addSms() {
    const modalRef = this.dialogService.open(AddSmsNotificationComponent, {
      context: {
        branchId: this.branchId,
      },
    });
    modalRef.onClose.subscribe((res: string) => {
      console.log(res);
      this.userservice.logs.emit('add sms in branch log');
    });
  }

  EditSms(smsId: string, text: string, notificationEventId: string) {
    const modalRef = this.dialogService.open(EditSmsNotificationComponent, {
      context: {
        smsId: smsId,
        text: text,
        notificationEventId: notificationEventId,
        branchId: this.branchId,
      },
    });
    modalRef.onClose.subscribe((res: string) => {
      console.log(res);
      this.userservice.logs.emit('edit sms in branch log');
    });
  }

  deleteSms(smsId: string) {
    const modalRef = this.dialogService.open(DeleteSmsNotificationComponent, {
      context: {
        smsId: smsId,
      },
    });
    modalRef.onClose.subscribe((res: string) => {
      console.log(res);
      this.userservice.logs.emit('delete sms in branch log');
    });
  }

  editCashier(
    cashierId: string,
    cashierName: string,
    cashierType: string,
    cashierPassword: string,
    cashierTypeId: any
  ) {
    const modalRef = this.dialogService.open(EditCashierComponent, {
      context: {
        cashierId: cashierId,
        cashierName: cashierName,
        cashierType: cashierType,
        cashierTypeId: cashierTypeId,
        // cashierPassword: cashierPassword,
        branchId: this.branchId,
      },
      dialogClass: 'model-full',
    });
    modalRef.onClose.subscribe((res: string) => {
      console.log(res);
      this.userservice.logs.emit('edit cashier in branch log');
    });
  }

  deleteCashier(cashierId: string) {
    const modalRef = this.dialogService.open(DeleteCashierComponent, {
      context: {
        cashierId: cashierId,
      },
    });
    modalRef.onClose.subscribe((res: string) => {
      console.log(res);
      this.userservice.logs.emit('delete cashier in branch log');
    });
  }

  addCashier() {
    const modalRef = this.dialogService.open(AddCashierComponent, {
      context: {
        branchId: this.branchId,
      },
      dialogClass: 'model-full',
    });
    modalRef.onClose.subscribe((res: string) => {
      console.log(res);
      this.userservice.logs.emit('add cashier in branch log');
    });
  }

  resetCashierPassword(
    cashierId: string,
    cashierName: string,
    password: string
  ) {
    const modalRef = this.dialogService.open(ResetPasswordCashierComponent, {
      context: {
        cashierId: cashierId,
        cashierName: cashierName,
        // password: password,
      },
      dialogClass: 'model-full',
    });
    modalRef.onClose.subscribe((res: string) => {
      console.log(res);
      this.userservice.logs.emit('rest cashier  password in branch log');
    });
  }

  addUser(id: number) {
    const modalRef = this.dialogService.open(AddUserBranchComponent, {
      context: {
        branchId: id,
      },
      dialogClass: 'model-full',
      hasScroll: true
    });
    modalRef.onClose.subscribe((res: string) => {
      console.log(res);
      this.userservice.logs.emit('add user in branch log');
    });
  }

  resetUserPassword(
    id: string,
    userName: string,
    userId: string,
    email: string,
    password: string,
    confirmpassword: string
  ) {
    const modalRef = this.dialogService.open(ForgotPasswordBranchComponent, {
      context: {
        branchId: id,
        userName: userName,
        userId: userId,
        email: email,
        // password:password,
        // confirmpassword:confirmpassword
      },
    });
    modalRef.onClose.subscribe((res: string) => {
      console.log(res);
      this.userservice.logs.emit('rest user password in branch log');
    });
  }

  editUser(
    id: string,
    userId: string,
    userName: string,
    email: string,
    role: string,
    password: string,
    confirmpassword: string
  ) {
    console.log(role);
    const modalRef = this.dialogService.open(EditUserBranchComponent, {
      context: {
        branchId: id,
        userId: userId,
        userName: userName,
        email: email,
        role: role,
        // password: password,
        // confirmpassword: confirmpassword,
      },
    });
    modalRef.onClose.subscribe((res: string) => {
      console.log(res);
      this.userservice.logs.emit('edit user  in branch log');
    });
  }

  deleteUser(id: string) {
    const modalRef = this.dialogService.open(DeleteUserBranchComponent, {
      context: {
        userId: id,
      },
    });
    modalRef.onClose.subscribe((res: string) => {
      console.log(res);
      this.userservice.logs.emit('delete user in branch log');
    });
  }

  getSubscriptionTypes() {
    this.businessService.getSubscriptionTypes().subscribe(
      (res: any) => {
        this.subscriptionTypes = res;
      },
      (err) => console.log(err)
    );
  }

  getCountries() {
    this.businessService.getCountries().subscribe(
      (res: any) => {
        this.countries = res.countries;
      },
      (err) => console.log(err)
    );
  }

  getTaxes() {
    this.businessService.getTaxes().subscribe(
      (res: any) => {
        this.taxes = res.taxes;
      },
      (err) => {
        console.error({ err });
      }
    );
  }

  getSalesMan() {
    this.saleservice.getSalesman().subscribe(
      (res: any) => {
        this.salesMen = res;
      },
      (err) => console.log(err)
    );
  }

  getUserByBranchId() {
    this.loading = true;
    this.userservice.getUsersBranch(this.branchId).subscribe(
      (res: any) => {
        this.users = res.users;
        this.loading = false;
      },
      (err) => console.log(err)
    );
  }

  getCashiers() {
    this.loading = true;
    this.cashierservice.getCashiers(this.branchId).subscribe(
      (res: any) => {
        this.cashiers = res.cashiers;
        this.loading = false;
      },
      (err) => console.log(err)
    );
  }

  getBranchParams() {
    this.businessService.getBranchParams(this.branchId).subscribe(
      (res: any) => {
        this.branchParams = res.branchParams;
        this.decimalPoint = this.branchParams?.decimalPoint;
        this.discountAllowed = this.branchParams?.discountAllowed;
        this.claimAllowed = this.branchParams?.claimAllowed;
        if (this.branchParams != null && this.branchParams?.payment != null) {
          this.payment = JSON.parse('[' + this.branchParams?.payment + ']');
        }
        this.printAllowed = this.branchParams?.printAllowed;
        this.footerMessage = this.branchParams?.footerMessage;
        this.rePrint = this.branchParams?.rePrint;
        this.merchantCopy = this.branchParams?.merchantCopy;
        this.nfcProductSearch = this.branchParams?.nfcProductSearch;
        this.customerAllowed = this.branchParams?.customerAllowed;
        this.taxAllowed = this.branchParams?.taxAllowed;
        this.smsAllowed = this.branchParams?.smsAllowed;
        if (this.param.type == 'branch' && this.branchParams == null) {
          this.branchParams = {};
          this.branchParams.branchID = this.param.id;
        }
        this.smsBalance = res?.smsBalnce;
        this.taxValue = res?.taxValue;
        this.taxID = this.branchParams?.taxID;
        // this.BranchParamData.taxID = this.branchParams?.taxID;
        //taxValue = 1
        this.taxTypeId = this.branchParams?.taxTypeId;
        this.trn = this.branchParams?.trn;
        this.spinner.hide();
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  openSelectLocation() {
    this.dialogService.open(SelectBranchLocationComponent, {
      context: {
        branchId: this.branchID,
        defaultLocation: this.Location,
        defaultbranchAddress: this.Address,
      },
      dialogClass: 'model-full',
    });
  }

  clearData() {
    this.editBranchData.branch = {};
    this.editBranchData.address = {};
    this.editBranchData.branchParam = {};
    this.editBranchData.extraParams = [];
  }

  editAddress(addressData: any, selectType: string) {
    this.editBranchData = {
      address: {},
    };
    this.editBranchData.address = addressData;

    if (selectType == 'country') {
      this.getCities(addressData.countryId);
    }
  }

  editBaiscInfo(branchData: any) {
    this.editBranchData = {
      branch: {},
    };
    this.editBranchData.branch = branchData;
  }

  editBranchParam(branchParamData: any) {
    this.editBranchData = {
      branchParam: {},
    };
    this.editBranchData.branchParam = branchParamData;
    if (this.editBranchData.branchParam.payment != null) {
      this.editBranchData.branchParam.payment =
        this.editBranchData.branchParam.payment.toString();
    }
  }

  SaveEditBasicInfo() {
    this.loading = true;
    this.businessService
      .editBranchParams(this.editBranchData, this.branchId)
      .subscribe(
        (res: any) => {
          this.nbToaster.show('Branch Edited successfully.', 'success', {
            status: 'success',
            hasIcon: false,
            icon: 'checkmark-outline',
          });
          this.loading = false;
          this.businessService.refresh.emit();
          this.userservice.logs.emit('edit in branch log');
          this.BranchData = {};
          this.branchName = this.branchData?.name;
          this.BusinessID = this.branchData?.businessId;
          this.SubcriptionID = this.branchData?.subscriptionTypeId;
          this.SalesManId = this.branchData?.salesManId;
          this.AddressData = {};
          this.CountryID = this.branchData.address?.countryId;
          this.CityID = this.branchData.address?.cityId;
          this.Location = this.branchData?.address?.location;
          this.Address = this.branchData?.address?.branchAddress;
          this.BranchParamData = {};
          this.decimalPoint = this.branchParams?.decimalPoint;
          this.discountAllowed = this.branchParams?.discountAllowed;
          this.claimAllowed = this.branchParams?.claimAllowed;
          if (this.branchParams !== null && this.branchParams?.payment !== null) {
            this.payment = JSON.parse('[' + this.branchParams?.payment + ']');
          }
          this.footerMessage = this.branchParams?.footerMessage;
          this.rePrint = this.branchParams?.rePrint;
          this.merchantCopy = this.branchParams?.merchantCopy;
          this.taxValue = res?.taxValue;
          this.taxID = this.branchParams?.taxID;
          this.taxTypeId = this.branchParams?.taxTypeId;
          this.trn = this.branchParams?.trn;
        },
        (errors) => {
          console.log(errors, 'errors');
          if (errors.error.StatusCode == 500) {
            this.nbToaster.show(errors.error.Message, 'error', {
              status: 'danger',
              hasIcon: false,
              icon: 'close-outline',
            });
          } else {
            this.nbToaster.show(errors.error?.errors[0], 'error', {
              status: 'danger',
              hasIcon: false,
              icon: 'close-outline',
            });
          }
          this.businessService.refresh.emit();
        }
      );
  }

  editextraParamss(extraparamData: any) {
    if (extraparamData.parameterDataType == true) {
      extraparamData.parameterDataType = 'True';
    } else if (extraparamData.parameterDataType == false) {
      extraparamData.parameterDataType = 'False';
    }
    this.editBranchData = {
      extraParams: [],
    };
    this.editBranchData.extraParams = [extraparamData];
  }

  SaveEditExtraParams() {
    console.log(this.ExtraParams)

    let extraParams = {
      extraParams: this.updatedExtraParams
    };

    console.log(extraParams)

    this.loading = true;
    this.businessService
      .editBranchParams(extraParams, this.branchId)
      .subscribe(
        (res: any) => {
          this.nbToaster.show('Branch Extra Params Edited successfully.', 'success', {
            status: 'success',
            hasIcon: false,
            icon: 'checkmark-outline',
          });
          // this.businessService.refresh.emit();
          this.loading = false;
          this.userservice.logs.emit('edit in branch log');
        },
        (errors) => {
          if (errors.error.StatusCode == 500) {
            this.nbToaster.show(errors.error.Message, 'error', {
              status: 'danger',
              hasIcon: false,
              icon: 'close-outline',
            });
          } else {
            this.nbToaster.show(errors.error?.errors[0], 'error', {
              status: 'danger',
              hasIcon: false,
              icon: 'close-outline',
            });
          }
          // this.businessService.refresh.emit();
        }
      );
  }

  updatedExtraParams: any[] = [];

  onExtraParamsFormChange(data: any) {
    // console.log({ data });
    for (const field of Object.keys(data)) {
      let val = {
        optionalParameterId: this.ExtraParams[field].id,
        paramName: field,
        parameterDataType: this.capitalizeFirstLetter(typeof (data[field])),
        value: String(data[field]),
      };

      this.updatedExtraParams.push(val);
    }
  }


  capitalizeFirstLetter(str: string) {
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalized;
  }

  getCities(id: number) {
    this.businessService.getCitiesByCountry(id).subscribe(
      (res: any) => {
        this.cities = res.cities;
      },
      (err) => console.log(err)
    );
  }
}
