import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { ItemsService } from '../../../services/items.service';

@Component({
  selector: 'app-edit-card',
  templateUrl: './edit-card.component.html',
  styleUrl: './edit-card.component.scss'
})
export class EditCardComponent implements OnInit {
  @Input() category:any;
  @Input() subCategory:any;
  @Input() isCategory:boolean=true;

  @Output() updateDataEvent = new EventEmitter<void>();

  imageUrl: string | ArrayBuffer | null = null;
  fileToUpload: File | null = null;

  constructor( 
    private nbToastr: NbToastrService,
    private itemService: ItemsService,
    protected dialogRef: NbDialogRef<EditCardComponent>

  ) { }

  ngOnInit() {
    if (this.category && this.category.imageUrl && this.isCategory) {
      console.log('edit category');
      
      this.imageUrl = this.category.imageUrl;
    }
    if (this.subCategory && this.subCategory.imageUrl && !this.isCategory) {
      console.log('edit SubCategory');
      this.imageUrl = this.subCategory.imageUrl;
    }
  }

   
    onDragOver(event: DragEvent) {
      event.preventDefault();
    }
  
    
    onDragLeave(event: DragEvent) {
      event.preventDefault();
    }
  
    
    onDrop(event: DragEvent) {
      event.preventDefault();
      if (event.dataTransfer && event.dataTransfer.files.length > 0) {
        this.fileToUpload = event.dataTransfer.files[0];
        this.previewImage(this.fileToUpload);
      }
    }
  
   
    onFileSelected(event: any) {
      if (event.target.files && event.target.files.length > 0) {
        this.fileToUpload = event.target.files[0];
        this.previewImage(this.fileToUpload!);
      }
    }
  
    
    previewImage(file: File) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result;
      };
      reader.readAsDataURL(file);
    }
  
    
    removeImage() {
      this.imageUrl = null;
      this.fileToUpload = null;
    }
  
    
  saveChanges() {
    if (this.fileToUpload) {
      let formData = new FormData();
      formData.append('logofile', this.fileToUpload!);
      console.log(this.fileToUpload);
      console.log(formData);
      if (this.isCategory) {
        this.itemService
        .uploadCategoryImage(formData, this.category.categoryId, this.category.categoryNameEN)
        .subscribe({
          next:(res: any) => {
          console.log(res);
  
          this.nbToastr.success('Logo uploaded successfully','Success');
          this.updateDataEvent.emit();
          this.dialogRef.close({ updated: true });
            this.imageUrl = res.url;
            // this.placeholder = res.url;
  
            // console.log(res);
          },
          error:(err: any) => {
            console.log(err);
            this.nbToastr.danger(err.message);
          }
        });
      }
      else {
        this.itemService
        .uploadSubCategoryImage(formData, this.subCategory.subCategoryId, this.subCategory.subCategoryNameEN)
        .subscribe({
          next:(res: any) => {
          console.log(res);
  
          this.nbToastr.success('Logo uploaded successfully','Success');
          this.updateDataEvent.emit();
          this.dialogRef.close({ updated: true });
          this.imageUrl = res.url;
            // this.placeholder = res.url;
  
            // console.log(res);
          },
          error:(err: any) => {
            console.log(err);
            this.nbToastr.danger(err.message);
          }
        });
      }


    } 
    else {
      this.nbToastr.warning('No image selected', 'Warning');
    }
    // this.dialogRef.close(this.imageUrl); // Close the dialog after saving
  }
}
