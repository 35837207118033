import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { BuisnessService } from '../../services/buisness.service';
import { transactionDetails } from '../../models/transaction.model';


@Component({
  selector: 'app-trx-details',
  templateUrl: './trx-details.component.html',
  styleUrls: ['./trx-details.component.scss']
})
export class TrxDetailsComponent implements OnInit {

  trxOperations :transactionDetails[]=[];
  branchID: any = "";
  loading: boolean = false;
  @Input() trxNo: any;

  date = new Date()
  constructor(
    private businessService: BuisnessService,
    private nbToastr: NbToastrService,
    private route: ActivatedRoute,) { 
      this.branchID = this.route.snapshot.queryParamMap.get('id');}

  ngOnInit(): void {
    console.log(this.trxOperations,"trxOperations")
    this.getTrxDetials()
  }

  getTrxDetials() {
    this.loading = true;
    this.businessService.getTrxDetials(this.trxNo).subscribe((res: any) => {
      this.trxOperations = res.transactionDetails;
      this.loading = false;
    }, err => console.log(err));
  }
  DisableTrx(id:any) {
    let status:boolean = true;
    this.loading = true;
    this.businessService.disableTrxDetial({ itemId: id, isDeleted: status }).subscribe(res => {
      this.nbToastr.show('disabled successfully!', 'disabled', {
        status: 'success'
      });

      this.getTrxDetials();
      this.loading = false;
    }, err => {
      console.log(err.errors[0])
      this.nbToastr.show(err.message, 'Erorr', {
        status: 'danger'
      });
    }
    )
  }
}
