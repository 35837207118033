import { Component, OnInit } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AddWorkerComponent } from './add-worker/add-worker.component';
import { WorkersService } from '../../services/workers.service';

@Component({
  selector: 'app-workers',
  templateUrl: './workers.component.html',
  styleUrls: ['./workers.component.scss']
})
export class WorkersComponent implements OnInit {
  workers: any[] = [];
  loading: boolean = false;
  branchId = JSON.parse(localStorage.getItem("searchVal")!).id;
  constructor(private workersService: WorkersService, public modalService: NgbModal, private nbToastr: NbToastrService) { }

  ngOnInit(): void {
    this.onGetBranchWorkers();
  }

  onGetBranchWorkers() {
    this.loading = true;
    this.workersService.getWorkers(this.branchId).subscribe(res => {
      if (res.statusCode == 200 && res.data) {
        this.workers = res.data;
        this.loading = false;
      }
    }, err => {
      console.log(err);
      this.loading = false;
    })
  }

  onSearch(e: any) {

  }

  onEditWorker(id: number) {

  }

  onDeleteWorker(id: string) {
    this.workersService.deleteWorker(id).subscribe(res => {
      this.nbToastr.show(res.message, 'success', {
        status: 'success',
        hasIcon: false,
        icon: 'checkmark-outline'
      });
    }, err => {
      this.nbToastr.show(err.message, 'error', {
        status: 'danger',
        hasIcon: false,
      });
    })
  }

  onAddEditWorker(worker?: {}) {
    const modalRef = this.modalService.open(AddWorkerComponent, { centered: true });
    modalRef.componentInstance.branch = this.branchId;
    if(worker) {
      modalRef.componentInstance.worker = worker;
    }
    modalRef.closed.subscribe(res => {
      if (res == 'success') {
        this.nbToastr.show(res.message, 'success', {
          status: 'success',
          hasIcon: false,
          icon: 'checkmark-outline'
        });

        this.onGetBranchWorkers();
      } else {
        this.nbToastr.show(res.message, 'error', {
          status: 'danger',
          hasIcon: false,
        });
      }
    })
  }
}
