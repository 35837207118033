import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UserService } from '../../services/user.service';
import { Observable } from 'rxjs';
import { Log } from '../../models/logs.model';

@Component({
  selector: 'app-branch-logs',
  templateUrl: './branch-logs.component.html',
  styleUrls: ['./branch-logs.component.scss'],
})
export class BranchLogsComponent implements OnInit {
  @Input() branchID: string; // branch id passed to component
  logObs$!: Observable<Log[]>;
  constructor(private userService: UserService, private route: ActivatedRoute) {
    let val = localStorage.getItem('searchVal');
    this.branchID = JSON.parse(val!)!.id;
  }
  ngOnInit(): void {
    this.logObs$ = this.userService.getbranchLogs(this.branchID, true);
  }
}
