import { Component, OnInit } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { NbToastrService } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


import { BuisnessService } from '../../../services/buisness.service';
import { FeatureService } from '../../../services/feature.service';

@Component({
  selector: 'app-add-feature',
  templateUrl: './add-feature.component.html',
  styleUrls: ['./add-feature.component.scss']
})
export class AddFeatureComponent implements OnInit {

  nameInLanguages: any[] = [
    { languageId: 1},
    { languageId: 2},
    { languageId: 3},
    { languageId: 4},
  ];

  priceInCurrencies: any[] = [
    { currencyId: 1},
    { currencyId: 2},
    { currencyId: 3},
    // { currencyId: 4},
  ];
  featuregroups:any[] = [];
  businessTypes:any[] = [];
  submitted: boolean = false;
  valid: boolean = true;
  isDefault: boolean = false;
  selectedFeatureGroup: any;
  selectedBusinessType: any;
  createdBy = '';
  icon: any;
  key: string = '';
  constructor(
    private authService: NbAuthService, 
    private businessService: BuisnessService,
     private nbToaster: NbToastrService, 
     private activeModal: NgbActiveModal, 
     private featureService: FeatureService) { }

  ngOnInit(): void {
    this.getFeatureGroups();
    this.getBusinessTypes();
    this.authService.onTokenChange().subscribe(res => {
      this.createdBy = res.getPayload().preferred_username;
    });
  }

  onSave() {
    this.submitted = true;
    //console.log(this.selectedBusinessType)
    if (this.key && this.nameInLanguages[0].name && this.nameInLanguages[1].name && this.nameInLanguages[2].name && this.nameInLanguages[3].name) {
      this.valid = false;
      let feature: any = {
        // nameInLanguages: this.nameInLanguages,
        // priceInCurrencies: this.priceInCurrencies,
        featureGroupId: Number(this.selectedFeatureGroup),
        businessTypeId: this.selectedBusinessType ? Number(this.selectedBusinessType) : '',
        isDefault: this.isDefault,
        createdBy: this.createdBy,
        Icon: this.icon,
        key: this.key.replace(/ /g,"_")
      }

      let featureFormdata = new FormData();
      for(const key in feature) {
        if(feature[key]) {
          featureFormdata.append(key, feature[key]);
        }
      };

      this.nameInLanguages.forEach((lang, i) => {
        featureFormdata.append(`NameInLanguages[${i}][languageId]`, lang.languageId)
        featureFormdata.append(`NameInLanguages[${i}][Name]`, lang.name)
      });

      this.priceInCurrencies.forEach((curr, i) => {
        featureFormdata.append(`priceInCurrencies[${i}][CurrencyId]`, curr.currencyId)
        featureFormdata.append(`priceInCurrencies[${i}][Name]`, curr.price)
      });

      // console.log(feature);
      this.featureService.createFeature(featureFormdata).subscribe(res => {
        // console.log({res});
        this.onClose(res);
      }, err => {
        console.log({ err })
        this.nbToaster.show(err.errors[0], 'ERROR', {
          status: 'danger',
          hasIcon: false
        });
      });

      this.submitted = false;
    } else {
      this.valid = false;
    }
  }

  onClose(msg: any) {
    this.activeModal.close(msg);
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  onChange($event: any) {
    this.icon = $event.addedFiles[0];
    console.log(this.icon)
  }

  getFeatureGroups() {
    this.featureService.getFeatureGroups().subscribe((res: any) => {
      console.log({ res })
      this.featuregroups = res;
    }, (err:any) => console.log(err))
  }

  getBusinessTypes() {
    this.businessService.getBusinessTypes().subscribe((res: any) => {
      console.log({ res })
      this.businessTypes = res;
    }, err => console.log(err))
  }

  onNameChange($event: any) {
    // console.log($event.target.value)
    //this.key = $event.target.value;
  }

}
