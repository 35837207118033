import { Component, OnInit } from '@angular/core';
import {ItemsService} from "../../services/items.service";

@Component({
  selector: 'app-sub-category-list',
  templateUrl: './sub-category-list.component.html',
  styleUrls: ['./sub-category-list.component.scss']
})
export class SubCategoryListComponent implements OnInit {

  categoryId!:number;
  branchId!:string;
  isLoading:boolean = false;

  constructor(private itemService:ItemsService) { }

  ngOnInit(): void {
    this.getSubCategories();
  }

  getSubCategories(){
    this.isLoading = true
    this.itemService.getSubCategories(this.categoryId,this.branchId).subscribe((subCategories) =>{
      console.log({subCategories})
      this.isLoading = false
    },(error) => {
      this.isLoading = false
    })
  }


}
