

<!--deleteSMS-->
<nb-card>
  <nb-card-header>
          <h5 class="modal-title">Delete Notification </h5></nb-card-header>
          <nb-card-body>
            Confirm Deleting Notification ?
          </nb-card-body>
        <nb-card-footer>  
            <button nbButton  status="success" (click)="deleteSms()"><nb-icon icon="save-outline"></nb-icon>Delete</button>
            <button nbButton  status="danger" (click)="cancel()"><nb-icon icon="close-outline"></nb-icon>Cancel</button>
        </nb-card-footer>
      </nb-card>
  <!--deleteSMS-->