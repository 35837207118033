import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {
  selected: string = "";
  constructor() { }

  ngOnInit(): void {
  }

  toggle(event: boolean, option: string) {
    console.log(`${option} is ${event}`)
    if(event && option == 'custom') {
      
    }
  }

}
