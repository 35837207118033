import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { map } from 'rxjs/operators';

export interface TranslationObj {
  q: string[] | string;
  target: string;
}

@Injectable({
  providedIn: 'root'
})
export class GoogletranslateService {

  url: string = "https://translation.googleapis.com/language/translate/v2?key="

  constructor(private http: HttpClient) { }

  translate(obj: TranslationObj) {
    return this.http.post(this.url + environment.googleApiKey, obj).pipe(
      map((res: any) => {
        return res.data.translations[0].translatedText;
        // or if you want to transform data
        // return transformRes(res);
      }))
  }

  translateToEnglish(value: string) {
    //this.isLoading = true;
    const translation: TranslationObj = { q: value, target: 'en' }
    this.translate(translation).subscribe((res: any) => {
      return res.data.translations[0].translatedText;
      //this.isLoading = false;
      // this.newItem.patchValue({
      //   ItemNameEN: res.data.translations[0].translatedText
      // })
    }, error => {
      return '';
    })
  }

  translateToArabic(value: string) {
    //this.isLoading = true;
    const translation: TranslationObj = { q: value, target: 'ar' }
    this.translate(translation).subscribe((res: any) => {
      return res.data.translations[0].translatedText;
      // this.isLoading = false;
      // this.newItem.patchValue({
      //   ItemNameAR: res.data.translations[0].translatedText
      // })
    }, (error: HttpErrorResponse) => {
      return '';
    })
  }

  translateToTurkish(value: string) {
    // this.isLoading = true;
    const translation: TranslationObj = { q: value, target: 'tr' }
    this.translate(translation).subscribe((res: any) => {
      return res.data.translations[0].translatedText;
      // this.isLoading = false
      // this.newItem.patchValue({
      //   ItemNameTR: res.data.translations[0].translatedText
      // })
    }, (error: any) => {
      return '';
    })
  }


  translateToFrench(value: string) {
    //this.isLoading = true;
    const translation: TranslationObj = { q: value, target: 'fr' }
    this.translate(translation).subscribe((res: any) => {
      return res.data.translations[0].translatedText;
      // console.log(res)
      // this.newItem.patchValue({
      //   ItemNameFR: res.data.translations[0].translatedText
      // })
    }, error => {
      return '';
    })
  }
}
