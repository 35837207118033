<div class="container py-4">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
  
        <div class="card shadow-sm p-4">
        <h4 *ngIf="isCategory" class="text-center mb-4"> Add image to Category <span class="span" >{{item.name}}</span></h4>
        <h4 *ngIf="isSubCategory" class="text-center mb-4">Add image to SubCategory <span class="span">{{item.name}}</span></h4>
        <h4 *ngIf="isitem" class="text-center mb-4">Add image to Item <span class="span">{{item.name}}</span></h4>

          <div class="card-body">
            <div *ngIf="imageUrl; else dragDropArea" class="text-center">
              <div class="image-preview">
                <img [src]="imageUrl" alt="Current Image" class="img-fluid mb-3 shadow-sm">
              </div>
              <button class="btn btn-outline-danger btn-sm mt-3" (click)="removeImage()">Remove Image</button>
            </div>
  
            <!-- Drag and Drop area -->
            <ng-template #dragDropArea>
              <div class="drag-drop-area text-center p-4" 
                   (dragover)="onDragOver($event)" 
                   (dragleave)="onDragLeave($event)" 
                   (drop)="onDrop($event)">
                <div class="upload-icon mb-3">
                  <i class="nb-upload-outline" style="font-size: 2rem;"></i>
                </div>
                <p class="text-muted">Drag & drop an image here, or <span class="select-file">click to browse</span></p>
                <input type="file" #fileInput class="d-none" (change)="onFileSelected($event)">
                <button class="btn btn-outline-primary mt-3" (click)="fileInput.click()">Select Image</button>
              </div>
            </ng-template>

                    

          </div>
          <div class="d-flex justify-content-center mt-4">
              <button class="btn btn-outline-secondary me-2" (click)="dialogRef.close()">Cancel</button>
            <button class="btn btn-primary " (click)="saveChanges()">Save Changes</button>
        </div>
        </div>
  

      </div>
    </div>
  </div>
  