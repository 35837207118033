<nb-card class="m-0">
  <nb-card-header>
    <h5>Disconnect POS from Branch</h5>
  </nb-card-header>
  <nb-card-body>
    <div class="alert fw-bold {{'alert-' + alert.status}}" *ngIf="alert.alert">{{ alert.message }}</div>
    <form [formGroup]="disconnectPosForm">
      <div class="row align-items-center">
        <div class="col-6">
          <div class="mb-3 col-12">
            <label class="mb-2">Branch Name</label>
            <input type="text" nbInput fullWidth [value]="name" disabled>
          </div>
          <div class="mb-3 col-12">
            <label class="mb-2">Branch Id</label>
            <input type="text" nbInput fullWidth [value]="id" disabled>
          </div>
          <div class="mb-3 col-12">
            <label class="mb-2">Pos</label>
            <input type="text" formControlName="posId" nbInput fullWidth>
          </div>
          <div class="mb-3 col-12">
            <label class="mb-2">Notes</label>
            <textarea type="text" formControlName="notes" nbInput fullWidth></textarea>
          </div>
        </div>
        <div class="col-6 d-flex align-items-center justify-content-center">
          <img src="{{pos.posTypeImageUrl? pos.posTypeImageUrl : 'https://placehold.co/100?text=image+unavailable'}}"
            class="img-fluid  pos-img" alt="pos">
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="text-right">
    <button nbButton status="basic" (click)="onCancel()">Cancel</button>
    <button nbButton status="success" (click)="onDisconnect()">Disconnect</button>
  </nb-card-footer>
</nb-card>
