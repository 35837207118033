import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';
import { Coupon,couponResponse } from '../models/coupon.model';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CouponService {
  @Output() refresh = new EventEmitter<null>();
  @Output() update = new EventEmitter<null>();

  constructor(private http: HttpClient) { 
  }

  //Coupon
  generateCoupon(coupon:Coupon) {
    return this.http.post<couponResponse>(`${environment.baseURL}/Coupons`, coupon);
  }
  getCouponByBranch(brancheId: string,IsPaginated: boolean) {
    return this.http.get<any>(
      `${environment.baseURL}/Coupons/?IsPaginated=${IsPaginated}&${brancheId}`
    ).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }
  getCouponById(couponId: string,IsPaginated: boolean) {
    return this.http.get<any>(
      `${environment.baseURL}/Coupons/${couponId}?IsPaginated=${IsPaginated}`
    ).pipe(
      map((data) => {
        return data.coupons;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }
  changeCouponStatus(body: {}) {
    return this.http.post<couponResponse>(
      `${environment.baseURL}/Coupons/change-status`,
      body
    );
  }
  pageChange(url: string) {
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }
  //Coupon
}
