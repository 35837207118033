import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SmsnotificationsService } from '../../../services/smsnotifications.service';


@Component({
  selector: 'app-add-sms',
  templateUrl: './add-sms.component.html',
  styleUrls: ['./add-sms.component.scss']
})
export class AddSmsComponent implements OnInit {
  //@ViewChild('eventType') eventType!: ElementRef;
  notificationEvents: any[] = [];
  addSMSForm: FormGroup = new FormGroup({
    text: new FormControl(''),
    notificationTypeId: new FormControl('5EE4D2E6-770A-429F-BB04-5004F096C87E'),
    notificationEventId: new FormControl('0')
  })

  constructor(private smsService: SmsnotificationsService, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.onGetNotificationBase();
  }

  onGetNotificationBase() {
    this.smsService.getNotificationEvents().subscribe(res => { console.log(res); this.notificationEvents = res}, err => console.log(err));
  }

  onAdd() {
    this.activeModal.dismiss(this.addSMSForm.value);
  }
}
