import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PosService } from '../../../services/pos.service';


@Component({
  selector: 'app-add-pos',
  templateUrl: './add-pos.component.html',
  styleUrls: ['./add-pos.component.scss']
})
export class AddPosComponent implements OnInit {
  addPosForm: FormGroup = new FormGroup({
    sn: new FormControl('', Validators.required),
    posTypeId: new FormControl('1', Validators.required),
    description: new FormControl(''),
    createdBy: new FormControl(''),
  });
  @Input() types: any[] = [];
  currentPOSImg: any = 'https://socialimpact.com/wp-content/uploads/2021/03/logo-placeholder-300x210.jpg';
  submitted: boolean = false;

  constructor(
    private nbToaster: NbToastrService,
     private activeModal: NgbActiveModal,
      private posService: PosService) { }

  ngOnInit(): void {
    // this.getPOSTypes();
  }

  onSave() {
    this.submitted = true;
    if(this.addPosForm.valid) {
      this.posService.addPos(this.addPosForm.value).subscribe(res => {
        this.nbToaster.show('POS ADDED', 'SUCCESS', {
          status: 'success',
          hasIcon: false
        });

        this.onClose('success');
      }, err => {
        this.nbToaster.show('error adding pos, please try again.', 'ERROR', {
          status: 'danger',
          hasIcon: false
        });
      })

      this.submitted = false;
    }
  }

  onClose(msg: string) {
    this.activeModal.close(msg);
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  onChange(event: any) {
    let type = this.types.find(type => type.posTypeId == event.target.value);
    this.currentPOSImg = type.imageUrl? type.imageUrl : 'https://socialimpact.com/wp-content/uploads/2021/03/logo-placeholder-300x210.jpg';
  }

}
