import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';

import { ResetPasswordUserBranchComponent } from '../reset-password-user/reset-password-user.component';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordBranchComponent implements OnInit {
  userId:any;
  branchId:any;
  email:string=''
  userName:string=''
  forgotUserForm: FormGroup = new FormGroup({
    email: new FormControl(''),
    userName: new FormControl(''),
  });
  constructor(public userService:UserService,
    private dialogService: NbDialogService,
    protected dialogRef: NbDialogRef<ForgotPasswordBranchComponent>, 
    private nbToaster: NbToastrService) { }

  ngOnInit(): void {
    this.forgotUserForm.setValue({
      email: this.email,
      userName: this.userName,
    });
    console.log(this.forgotUserForm.value)
  }
  goTo() {
    this.userService.forgotPassword(this.forgotUserForm.value).subscribe((res:any)=>{   
      if(res.isSucceeded == true) {
        this.onClose('success');
      } else {
        this.onClose(res.message);
      }
      this.nbToaster.show('We Sended Code To Your Email', 'success', {
        status: 'success',
        hasIcon: false,
        icon: 'checkmark-outline'
      });
      this.userService.refresh.emit();
      this.dialogRef.close();   
      this.dialogService.open(ResetPasswordUserBranchComponent,{context:{
          userId: this.userId,
          userName:this.userName,
          email:this.email
      }})
    },
    (err) => {
      console.error({ err });
      this.nbToaster.show(err.error.Message, 'error', {
        status: 'danger',
        hasIcon: false,
        icon: 'close-outline'
      });
    });
  }
  onClose(msg: string) {
    this.dialogRef.close(msg);
  }
  cancel() {
    this.dialogRef.close()
  }
}
