<nb-card [nbSpinner]="isLoading" >
  <button ngbTooltip="Download General Form" ghost nbButton status="info" (click)="downloadGeneralForm()"><nb-icon icon="archive-outline"  [options]="{ animation: { type: 'shake' } }"></nb-icon></button>
  <ngx-dropzone class="justify-content-center m-3" (change)="onSelect($event)" [multiple]="false" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
    <ngx-dropzone-label>Drop the item sheet to upload</ngx-dropzone-label>
    <ngx-dropzone-preview *ngIf="itemSheet" [removable]="true" >
      <ngx-dropzone-label>{{ itemSheet.name }}</ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ngx-dropzone>
  <button nbButton class="m-2 " (click)="uploadSheet()">Upload</button>
</nb-card>

