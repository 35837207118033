<nb-card [nbSpinner]="isLoading" >
  <nb-card-header>
    <div class="row">
      <div class="col-md-12">
        <h2>{{ item!.itemNameEN }}</h2>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body class="text-center">
    <input nbInput placeholder="logo name" [(ngModel)]="logoName" class="my-3">
  </nb-card-body>
  <nb-card-footer class="text-center">
    <div class="row justify-content-center">
      <div class="col-md-5 text-center" >
        <img [src]="item!.imageUrl" alt="Logo" (click)="onClick(drop)" style="width: 12vw; height: 20vh" *ngIf="item!.imageUrl" />
        <img [src]="placeholder" alt="Logo" (click)="onClick(drop)" style="width: 100%; height: 100%"   *ngIf="!item!.imageUrl"/>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-md-12">
        <button class="btn btn-primary" (click)="onClick(drop)">
          <i class="fa fa-edit"></i>
          <span>{{!item!.imageUrl ? " Set" : " Edit"}}</span>
        </button>
      </div>
    </div>
  </nb-card-footer>
  <ngx-dropzone (change)="onSelect($event)" #drop hidden accept="image/jpeg,image/jpg,image/png"></ngx-dropzone>
</nb-card>
