import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from '../../services/user.service';
import { couponResponse } from '../../models/coupon.model';
import { CouponService } from '../../services/coupon.service';

@Component({
  selector: 'app-generate-coupons',
  templateUrl: './generate-coupons.component.html',
  styleUrls: ['./generate-coupons.component.scss']
})
export class GenerateCouponsComponent implements OnInit {
  couponDiscountTypes:any=[
    {id:1,type:'value'},
    {id:0,type:'percentage'},
  ]  
  branchId:string="";
  addCouponForm :any = {}
  param: any = {};
  
  model!:NgbDateStruct;
  
  success: couponResponse = {
    isSucceeded: false,
    message: "",
    statusCode: 0,
  };
  alert: any = {
    alert: false,
    message: ""
  };
  exportedCouponCodes:any = []
  couponTypeId: any;
  constructor(
    public couponService:CouponService, 
    private router: Router,
    private route: ActivatedRoute,
    private nbToaster: NbToastrService, 
    public datePipe: DatePipe,
   public calendar: NgbCalendar,
   private modalService: NgbModal,
   public userservice:UserService,
    ) { 
      let val = localStorage.getItem('searchVal');
      this.param = JSON.parse(val!);
      this.branchId = this.param.id;
    }
    
    ngOnInit(): void {
  }
  onSelect(id: any, type: any) {
      this.couponTypeId = id;
  }
  generateCoupon() {
   this.addCouponForm.discountType = this.couponTypeId
   console.log(this.addCouponForm,"hey")
   this.addCouponForm.branchId = this.branchId
   this.addCouponForm.expiryDate  =  new Date(this.model.year,this.model.month-1,this.model.day + 1);
    // console
    this.couponService.generateCoupon(this.addCouponForm).subscribe((res: any) => {
      this.userservice.logs.emit('generate coupon in branch log'); 
      console.log(res,"addCoupon");
      setTimeout(() => {
        this.success = res
      }, 2000);
      this.modalService.dismissAll()
      this.couponService.refresh.emit();
    }, errors => {
      console.log(errors.error.errors);
      this.alert = {
        alert: true,
        message: errors.error.errors
      }
      setTimeout(() => {
        this.alert.alert = false;
      }, 2000);
      this.couponService.refresh.emit();
    });
  }
  cancelCoupon() {
    this.modalService.dismissAll()
    }
}
