import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';


@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserBranchComponent implements OnInit {
  branchId: string = ''
  userId: any;
  userName: string = ''
  email: string = ''
  role: string = ''
  password: string = ''
  confirmpassword: string = ''
  editUserForm: FormGroup = new FormGroup({
    userName: new FormControl(''),
    email: new FormControl(''),
    role: new FormControl(''),
    password: new FormControl(''),
    confirmpassword: new FormControl(''),
  });
  roles: any = []

  userList: User[] = []
  showPassword = true;
  showconfirmPassword = true;

  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    else {
      return 'password';
    }
  }
  getInputType1() {
    if (this.showconfirmPassword) {
      return 'text';
    }
    else {
      return 'password';
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  toggleShowPassword1() {
    this.showconfirmPassword = !this.showconfirmPassword;
  }
  constructor(public userService: UserService,
    protected dialogRef: NbDialogRef<EditUserBranchComponent>,
    private nbToaster: NbToastrService
  ) { }

  ngOnInit(): void {
    this.editUserForm.setValue({
      userName: this.userName,
      email: this.email,
      role: this.role,
      password: this.password,
      confirmpassword: this.confirmpassword
    });
    console.log(this.editUserForm.value, "hey")
    this.getUsers();
    this.userService.refresh.subscribe(res => this.getUsers());
    this.getUserRoles()
  }
  getUsers() {
    this.userService.getUsersBranch(this.branchId).subscribe((res: any) => {
      this.userList = res;
    },
      (err) => {
        console.error({ err });
      });
  }
  getUserRoles() {
    this.userService.getUserRoles().subscribe((res: any) => {
      this.roles = res.filter((r:any) => {
        return r.name == "branch"
      });
      this.role = this.role
    },
      (err) => {
        console.error({ err });
      });
  }
  editUser() {
        if (this.editUserForm.value.password === this.editUserForm.value.confirmpassword) {
          this.userService.editUser(this.editUserForm.value, this.userId).subscribe((res: any) => {
            console.log(this.editUserForm.value);
            if(res.isSucceeded == true) {
              this.onClose('success');
            } else {
              this.onClose(res.message);
            }
            this.nbToaster.show('User Edited successfully.', 'success', {
              status: 'success',
              hasIcon: false,
              icon: 'checkmark-outline'
            });
            this.userService.refresh.emit();
            this.dialogRef.close();
          },
            (err) => {
              console.error({ err });
              this.nbToaster.show(err.error.Message, 'error', {
                status: 'danger',
                hasIcon: false,
                icon: 'close-outline'
              });
            })
        } else if (this.editUserForm.value.password != this.editUserForm.value.confirmpassword) {
          this.nbToaster.show("password and confirm password  doesn't match", 'error', {
            status: 'danger',
            hasIcon: false,
            icon: 'close-outline'
          });
        }

  }
  onClose(msg: string) {
    this.dialogRef.close(msg);
  }
  cancel() {
    this.dialogRef.close()
  }
}
