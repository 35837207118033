import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BuisnessService } from '../../services/buisness.service';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  // options: string[] = [];
  // filteredOptions$!: Observable<any[]>;
  // businesses: any[] = [];
  // branches: any[] = [];
  // list: any[] = [];
  loading: boolean = false;
  onClose: Subject<boolean> = new Subject();
  @ViewChild('autoInput') input: any;

  constructor(
    private businessService: BuisnessService, 
    public router: Router) { }

  ngOnInit(): void {
    // this.filteredOptions$ = of(this.options);
  }

  // private filter(value: string): any[] {
  //   const filterValue = value.toLowerCase();
  //   return this.options.filter((optionValue: any) => optionValue.name.toLowerCase().includes(filterValue));
  // }

  // getFilteredOptions(value: string): Observable<string[]> {
  //   return of(value).pipe(
  //     map(filterString => this.filter(filterString)),
  //   );
  // }

  // onChange(input: any) {
  //   //console.log(input.value.length)
  //   if (input.value.length >= 1) {
  //     this.businessService.search(input.value).subscribe((res: any) => {
  //       //console.log(res)
  //       this.businesses = [];
  //       this.branches = [];
  //       this.options = [];

  //       this.businesses = res.businesses;
  //       this.branches = res.branches;

  //       this.list = res.businesses.concat(res.branches);
  //       this.list.forEach((item: any) => {
  //         this.options.push(item)
  //       });

  //       this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);
  //       this.loading = false;

  //     }, err => console.log('bussiness not found'));
  //   } else {
  //     this.loading = false;
  //     this.options = [];
  //     this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);
  //   }
  // }

  // onSelectionChange($event: any) {
  //   // console.log($event)
  //   let name: any = [];

  //   this.options.forEach((option: any) => {
  //     if (option.id == $event) {
  //       name.push(option.name)
  //     }
  //   })

  //   this.filteredOptions$ = this.getFilteredOptions(name[0]);
  //   let value: any = this.list.filter((item: any) => {
  //     return item.id == $event;
  //   });

  //   let id = value[0].id;
  //     this.businesses.forEach(business => {
  //       if (business.id == id) {
  //         business.type = 'business';
  //         return value = business;
  //       }
  //     });

  //     this.branches.forEach(branch => {
  //       if (branch.id == id) {
  //         branch.type = 'branch';
  //         return value = branch;
  //       }
  //     });

  //     localStorage.setItem('searchVal', JSON.stringify(value));
  //     this.router.navigateByUrl('home');
  // }

  closeMenu() {
    this.onClose.next(false);
  }

}
