<nb-card class="m-0">
  <nb-card-header>
    <h5>Add POS to Branch</h5>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="addPosForm" *ngIf="!success.isSucceeded">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="mb-3 col-12">
            <label class="mb-2">Branch Name</label>
            <input type="text" nbInput fullWidth [value]="name" disabled>
          </div>
          <div class="mb-3 col-12">
            <label class="mb-2">Branch Id</label>
            <input type="text" formControlName="branchId" nbInput fullWidth>
          </div>
          <div class="mb-3 col-12">
            <label class="mb-2">POS</label>
            <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r.sn + ' ' + r.posType + ' ' + r.posStatus" [term]="t"></ngb-highlight>
            </ng-template>
            <input id="typeahead-basic" type="text" class="form-control custom-input" [ngbTypeahead]="search"
              [resultTemplate]="rt" [inputFormatter]="formatter" (change)="onPosSelectionChange($event)"/>
          </div>
          <div class="mb-3 col-12">
            <label class="mb-2">Salesman</label>
            <select class="form-control custom-select" formControlName="salesManId" nbInput>
              <option value="0" disabled>Select salesMan</option>
              <option *ngFor="let saleman of salesMen" value="{{saleman.id}}">{{saleman.salesManName}}</option>
            </select>
          </div>
          <div class="mb-3 col-12">
            <nb-radio-group status="success" class="d-md-flex" [(value)]="selected">
              <nb-radio value="wifi">wifi</nb-radio>
              <nb-radio value="sim">Sim</nb-radio>
            </nb-radio-group>
          </div>
          <div class="mb-3 col-12" *ngIf="selected == 'sim'">
            <label class="mb-2">Sim serial number</label>
            <input type="text" formControlName="sim" nbInput fullWidth placeholder="Enter Sim Serial No.">
          </div>
          <div class="mb-3 col-12">
            <label class="mb-2">Notes</label>
            <textarea type="text" formControlName="notes" nbInput fullWidth placeholder="Enter Pos note"></textarea>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex align-items-center justify-content-center">
          <img
            src="{{posType == 'Mp3' ? '/assets/imgs/mp3.png': posType == 'Mp4' ? '/assets/imgs/mp4.png' : '/assets/imgs/sunmi.png'}}"
            class="img-fluid pos-img" alt="pos">
        </div>
      </div>
    </form>
    <div class="row" *ngIf="success.isSucceeded">
      <div class="col-12 col-md-6 border-right text-center">
        <h6 class="text-success">{{success.message}}</h6>
        <div class="my-3">
          <img src="{{'https://chart.googleapis.com/chart?cht=qr&chl=' + success.terminalId + '&chs=200'}}"
            class="img-fluid" alt="qr code">
          <h6>Terminal id: {{success.terminalId}}</h6>
        </div>
        <p>Branch Name: {{name}}</p>
        <p>Branch ID: {{id}}</p>
      </div>
      <div class="col-12 col-md-6  d-flex align-items-center justify-content-center">
        <img
          src="{{posType == 'Mp3' ? '/assets/imgs/mp3.png': posType == 'Mp4' ? '/assets/imgs/mp4.png' : '/assets/imgs/sunmi.png'}}"
          class="img-fluid" alt="pos">
      </div>
    </div>
    <div class="alert alert-danger font-weight-medium" *ngIf="alert.alert">{{ alert.message }}.</div>
  </nb-card-body>
  <nb-card-footer class="text-end">
    <button nbButton status="basic" (click)="onCancel()">Cancel</button>
    <button nbButton status="success" (click)="onAssign()" *ngIf="!success.isSucceeded">Assign</button>
  </nb-card-footer>
</nb-card>