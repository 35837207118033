<nb-card class="m-0">
    <nb-card-header>
        <h5>Edit Business</h5>
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]="renameBusinessForm">
            <div class="form-row">
                <div class="mb-3 col-12">
                    <label class="mb-2">Business Name</label>
                    <input type="text" formControlName="name" nbInput fullWidth placeholder="Enter Business Name">
                </div>
                <div class="mb-3 col-12">
                    <label class="mb-2">Business Email</label>
                    <input type="text" formControlName="email" nbInput fullWidth placeholder="Enter Business Email" [value]="email">
                </div>
                <div class="mb-3 col-12">
                    <label class="mb-2">Business Phone</label>
                    <input type="text" formControlName="phone" nbInput fullWidth placeholder="Enter Business Phone" [value]="phone">
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer class="text-right">
        <button nbButton status="basic" (click)="onCancel()">Cancel</button>
        <button nbButton status="success" (click)="onSave()">Save</button>
    </nb-card-footer>
</nb-card>