import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OperationsService {

  constructor(private http: HttpClient) { }

   resetMenu(id: string) {
    return this.http.put(environment.baseURL + '/Operation/ResetMenu?branchId=' + id, null, { responseType: 'text' })
   }
   resetBranches(id: string) {
    return this.http.put(environment.baseURL + '/Operation/ResetBranches?branchId=' + id, null, { responseType: 'text' })
   }
   resetOrderAndTransactions(id: string) {
    return this.http.put(environment.baseURL + '/Operation/ResetOrderAndTransactions?branchId=' + id, null, { responseType: 'text' })
   }
   resetOrder(id: string) {
    return this.http.put(environment.baseURL + '/Operation/ResetOrder?branchId=' + id, null, { responseType: 'text' })
   }
   resetTransaction(id: string) {
    return this.http.put(environment.baseURL + '/Operation/ResetTransaction?branchId=' + id, null, { responseType: 'text' })
   }

   downloadProducts(id: string) {
    return this.http.post(environment.baseURL + '/Operation/DownloadProducts?branchId=' + id, {}, { responseType: 'text' })
   }
}
