export class PrintingLayout {
  printingLayoutId!: string;
  layoutName!: string;
  layoutType!: number;
  layoutText!: string;
  copyType!: number;
}

export interface PrintingLayoutKey {
  id: number;
  uuid: string;
  name: string;
  label: string;
  initialValue: string;
  initValue?: string;
  dataType: string;
  sectionName: string;
  options: PrintingLayoutKeyOption;
  editable: boolean;
}

export interface PrintingLayoutKeyOption {
  isBold: boolean;
  size?: 1 | 2 | number; // 1: small, 2: medium
  align?: 0 | 1 | 2 | number; // 0: left, 1: center, 2: right
  value?: string;
  label?: string;
}

export enum PrintingSectinos {
  Title = 'Title',
  Header = 'Header',
  Body = 'Body',
  Footer = 'Footer',
}

export interface PrintingLayoutKeyOption {}
