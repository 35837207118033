<nb-card>
  <nb-card-body class="d-flex flex-column align-items-start">
    <button nbButton status="danger" class="mb-2" hero (click)="resetMenu(dialog)">Reset Menu</button>
    <button nbButton status="danger" class="mb-2" hero (click)="resetBranch(dialog)">Reset Branche</button>
    <button nbButton status="danger" class="mb-2" hero (click)="restOrderandTrx(dialog)">Reset Orders & Transactions</button>
    <button nbButton status="danger" class="mb-2" hero (click)="resetOrder(dialog)">Reset Orders</button>
    <button nbButton status="danger" class="mb-2" hero (click)="resetTrx(dialog)">Reset Transactions</button>
    <button nbButton status="info" class="mb-2" hero (click)="download()">Download Products</button>
  </nb-card-body>
</nb-card>
<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>
      <h2><nb-icon style="font-size: 30px;" class="text-danger" icon="alert-triangle-outline"></nb-icon></h2>
    </nb-card-header>
    <nb-card-body>
      <h5>This will remove {{data}}, are you sure you want to remove?</h5>
    </nb-card-body>
    <nb-card-footer class="text-right">
      <button nbButton status="success" (click)="ref.close()">No</button>
      <button nbButton status="danger" hero (click)="
      type == 'menu'? onResetMenu(ref) :
      type == 'branch'? onResetBranch(ref) :
      type == 'orderandTrx'? onResetOrderAndTransactions(ref) :
      type == 'order'? onResetOrder(ref) :
      onResetTransaction(ref)"
      >Yes</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
