import { HttpClient } from '@angular/common/http';
import {
  EventEmitter,
  Injectable,
  Output,
} from '@angular/core';

import { throwError } from 'rxjs';
import {
  catchError,
  map,
} from 'rxjs/operators';
import { environment } from '../../environments/environment';

import {
  Branch,
  BranchLogo,
} from '../models/branches.model';
import {
  Buisness,
  City,
  Country,
  Tax,
} from '../models/buisness.model';
import { Log } from '../models/logs.model';

@Injectable({
  providedIn: 'root',
})
export class BuisnessService {
  @Output() refresh = new EventEmitter<null>();
  @Output() searchValue = new EventEmitter<any>();

  constructor(private http: HttpClient) { }

  getBuisnesses(IsPaginated: boolean) {
    return this.http.get<any>(`${environment.baseURL}/Businesses?IsPaginated=${IsPaginated}`).pipe(
      map((data) => {
        //console.log(data)
        return data;
      }),
      catchError((error) => {
        return throwError(`Something Is Wrong With The Server: ${error}`);
      })
    );
  }

  getBuisnessesWithFilter(page?: number, searchKey?: string) {
    let filterInfos = [];

    if (searchKey) {
      filterInfos.push({
        "logical": "And",
        "propertyName": "Name",
        "value": searchKey,
        "operator": "Contains"
      })
    }

    let body = {
      "filterInfos": filterInfos,
      "orderInfos": [
        {
          "orderType": "Desc",
          "property": "Id"
        }
      ],
      "columns": [],
      "count": 10,
      "page": page ? page : 1
    }

    return this.http.post<any>(`${environment.baseURL}/Businesses/GetWithFilter`, body).pipe(
      map((data: any) => {
        if (data.value) {
          return data;
        } else {
          return [];
        }
      },
      catchError((error) => {
        return throwError(`Something Is Wrong With The Server: ${error}`);
      })
    ));
  }

  getBuisness(BusinessId: string) {
    return this.http
      .get<Buisness>(`${environment.baseURL}/Businesses/${BusinessId}`)
      .pipe(
        map((data: Buisness) => {
          return data;
        }),
        catchError((error) => {
          return throwError('Something Is Wrong');
        })
      );
  }
  getBranchesByBusinessId(BusinessId: string) {
    return this.http
      .get<Branch>(`${environment.baseURL}/Businesses/${BusinessId}/Branches`)
      .pipe(
        map((data: Branch) => {
          return data;
        }),
        catchError((error) => {
          return throwError('Something Is Wrong');
        })
      );
  }
  
  // to retrive all branches related to specific buisness
  getBuisnessBranches(BusinessId: string) {
    return this.http
      .get(`${environment.baseURL}/Businesses/${BusinessId}/Branches`);
  }

  addBuisness(buisness: Buisness) {
    return this.http.post(`${environment.baseURL}/Businesses`, buisness);
  }

  deleteBuisness(BusinessId: string) {
    return this.http.put(
      `${environment.baseURL}/Businesses/${BusinessId}/delete`,
      {}
    );
  }

  disableBuisness(body: {}) {
    return this.http.post(
      `${environment.baseURL}/Businesses/change-status`, body);
  }

  editBuissness(buisness: Buisness, id: string) {
    return this.http.post(
      `${environment.baseURL}/Businesses/${id}`,
      buisness
    );
  }

  pageChange(url: string) {
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }

  addNewBranch(body: {}) {
    return this.http.post(`${environment.baseURL}/Branches`, body);
  }

  getBranches() {
    return this.http.get<Branch[]>(`${environment.baseURL}/Branches`).pipe(
      map(
        (data: any) => {
          return data.branches;
        },
        catchError((error) => throwError('Something Went Wrong'))
      )
    );
  }

  getBranch(brancheId: string) {
    return this.http
      .get<Branch>(`${environment.baseURL}/Branches/${brancheId}/branch`)
      .pipe(
        map((data: Branch) => {
          return data;
        }),
        catchError((error) => throwError('Something Went Wrong'))
      );
  }

  getBranchParams(brancheId: string) {
    return this.http.get(
      `${environment.baseURL}/Branches/${brancheId}/BranchParams`
    );
  }

  getBranchLogs(brancheId: string) {
    return this.http
      .get<Log[]>(`${environment.baseURL}/Branches/RecentLogs/${brancheId}/`)
      .pipe(
        map((data: Log[]) => {
          return data;
        }),
        catchError((error) => throwError('Something Went Wrong'))
      );
  }

  copyBranch(brancheId: string) {
    return this.http.post(
      `${environment.baseURL}/Branches/${brancheId}/Copy`,
      {}
    );
  }

  search(input: string) {
    return this.http.get(
      `${environment.baseURL}/Branches/BusinessesBranches/${input}`
    ).pipe(
      map(
        (data) => {
          return data;
        },
        catchError((error) => throwError('Something Went Wrong'))
      )
    );
  }

  getLanguage() {
    return this.http.get(
      `${environment.baseURL}/Languages`
    ).pipe(
      map(
        (data: any) => {
          return data.languages;
        },
        catchError((error) => throwError('Something Went Wrong'))
      )
    );
  }

  getBusinessTypes() {
    return this.http.get(
      `${environment.baseURL}/BusinessTypes`
    ).pipe(
      map(
        (data: any) => {
          return data.businessTypes;
        },
        catchError((error) => throwError('Something Went Wrong'))
      )
    );
  }

  getSubscriptionTypes() {
    return this.http.get(
      `${environment.baseURL}/SubscriptionTypes`
    ).pipe(
      map(
        (data: any) => {
          return data.subscriptionTypes;
        },
        catchError((error) => throwError('Something Went Wrong'))
      )
    );
  }

  getCitiesByCountry(countryId: any) {
    return this.http.get<City[]>(`${environment.baseURL}/Cities/${countryId}/cities`).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => throwError('Something Went Wrong'))
    )
  }

  getCountries() {
    return this.http.get<Country[]>(`${environment.baseURL}/Countries`).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }

  getTaxs() {
    return this.http.get(`${environment.baseURL}/Taxes`).pipe(
      map((data: any) => {
        return data.taxes;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }

  changeTaxStatus(body: {}) {
    return this.http.post(
      `${environment.baseURL}/Branches/change-tax-status`, body);
  }

  changeParam1Status(body: {}) {
    return this.http.post(
      `${environment.baseURL}/Branches/change-param1-status`, body);
  }


  changeParam2Status(body: {}) {
    return this.http.post(
      `${environment.baseURL}/Branches/change-param2-status`, body);
  }


  changeParam3Status(body: {}) {
    return this.http.post(
      `${environment.baseURL}/Branches/change-param3-status`, body);
  }
  changeSmsStatus(body: {}) {
    return this.http.post(
      `${environment.baseURL}/Branches/change-sms-status`, body);
  }
  changePrintingStatus(body: {}) {
    return this.http.post(
      `${environment.baseURL}/Branches/change-printing-status`, body);
  }

  getTaxes() {
    return this.http.get<Tax[]>(`${environment.baseURL}/Taxes`).pipe(
      map((data: Tax[]) => {
        return data;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }

  changestatusBranch(body: {}) {
    return this.http.post(
      `${environment.baseURL}/Branches/change-status`, body);
  }

  getPamentMode() {
    return this.http.get<any[]>(`${environment.baseURL}/Transactions/GetPayments`).pipe(
      map((data: any[]) => {
        return data;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }

  getDevice() {
    return this.http.get<any[]>(`${environment.baseURL}/Transactions/GetDevices`).pipe(
      map((data: any[]) => {
        return data;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }

  getClaimTrxs(branchId: string, dateFrom: any, dateTo: any) {
    return this.http.get<any[]>(`${environment.baseURL}/Transactions/${branchId}/${dateFrom}/${dateTo}/claims`).pipe(
      map((data: any[]) => {
        return data;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }

  getDeletedTrxs(branchId: string, dateFrom: any, dateTo: any) {
    return this.http.get<any[]>(`${environment.baseURL}/Transactions/${branchId}/${dateFrom}/${dateTo}/deletedtransactions`).pipe(
      map((data: any[]) => {
        return data;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }

  getTrxs(branchId: string, dateFrom: any, dateTo: any) {
    return this.http.get<any>(
      `${environment.baseURL}/Transactions/${branchId}/${dateFrom}/${dateTo}/`
    ).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }
  getBusinessSales(businessId: string, dateFrom: any, dateTo: any) {
    return this.http.get<any>(
      `${environment.baseURL}/Transactions/businessSales/${businessId}/${dateFrom}/${dateTo}/`
    ).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }
  getItemSales(businessId: string, dateFrom: any, dateTo: any) {
    return this.http.get<any>(
      `${environment.baseURL}/Transactions/itemSales/${businessId}/${dateFrom}/${dateTo}/`
    ).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }
  getsubCategorySales(businessId: string, dateFrom: any, dateTo: any) {
    return this.http.get<any>(
      `${environment.baseURL}/Transactions/subCategorySales/${businessId}/${dateFrom}/${dateTo}/`
    ).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }
  getCashierSales(businessId: string, dateFrom: any, dateTo: any) {
    return this.http.get<any>(
      `${environment.baseURL}/Transactions/cashierSales/${businessId}/${dateFrom}/${dateTo}/`
    ).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }
  getTrxDetials(trxId: string) {
    return this.http.get<any>(
      `${environment.baseURL}/Transactions/${trxId}/details`
    ).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }

  disableTrx(body: {}) {
    return this.http.post(
      `${environment.baseURL}/Transactions/change-master-status`, body);
  }

  enableDeletedTrx(body: {}) {
    return this.http.post(
      `${environment.baseURL}/Transactions/change-master-status`, body);
  }

  changeClaimStatus(body: {}) {
    return this.http.post(
      `${environment.baseURL}/Transactions/set-claim-status`, body);
  }
  disableTrxDetial(body: {}) {
    return this.http.post(
      `${environment.baseURL}/Transactions/change-details-status`, body);
  }

  getCategoriesByTrx(branchId: string) {
    return this.http.get<any[]>(`${environment.baseURL}/Categories/${branchId}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => throwError('Something Went Wrong'))
    )
  }

  editBranchParams(body: {}, branchId: number) {
    return this.http.post(
      `${environment.baseURL}/Branches/${branchId}/edit`, body);
  }

  uploadBranchLogos(body: BranchLogo | FormData) {
    return this.http.post(`${environment.baseURL}/Branches/UploadLogo`, body)
  }

  getBranchLogos(branchId: string) {
    return this.http.get(`${environment.baseURL}/Branches/GetBranchLogos?BranchId=${branchId}`)
  }


  getPosInfo(id: string) {
    return this.http.get(environment.baseURL + `/Branches/${id}/pos-info`).pipe(
      map((data: any) => {
        if (data) {
          return data;
        }
        return [];
      }),
      catchError(error => throwError('Something Went Wrong'))
    );
  }
}


