import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { OrderComponent } from './order/order.component';
import { NbToastrService } from '@nebular/theme';
import { CashierService } from '../../services/cashier.service';
import { Cashier } from '../../models/cashier.model';
import { OrdersService } from '../../services/orders.service';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  branchId: number = JSON.parse(localStorage.getItem('searchVal')!).id;
  orders: any[] = [];
  loading: boolean = false;
  pages: number[] = [];
  pageNumber: number = 1;
  nextPage: boolean = true;
  prevPage: boolean = false;
  statuses: any[] = [];
  selectedStatus: any;
  selectedOrder: any;
  cashiers!: Cashier[];
  selectedCashier!: Cashier | undefined;
  constructor(private cashierService: CashierService, private nbToastr: NbToastrService, private ordersService: OrdersService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getOrders(1);
    this.getCashiers();
    //this.getOrderStatuesTypes();
  }

  getOrders(page: number, searchKey?: string) {
    this.loading = true;
    this.ordersService.getOrders(this.branchId, page, searchKey ? searchKey : '').subscribe((res: any) => {
      if (res?.value) {
        this.orders = res?.value;
        this.orders.forEach(order => {
          order.selectedStatus = order.currentStatusType;
        })
        console.log(this.orders)

        let pagination = Array.from({ length: res.totalPageCount }, (_, i) => i + 1);
        let start = res.currentPageNumber >= 4 ? res.currentPageNumber - 3 : 0;
        let end = res.currentPageNumber < res.totalPageCount ? res.currentPageNumber + 3 : res.currentPageNumber;

        let pagesToShow = pagination.slice(start, end);
        this.pages = pagesToShow;
        this.pageNumber = res.currentPageNumber;
        //  console.log({pagesToShow})
        if (res.currentPageNumber == pagination.length) {
          this.nextPage = false;
        }

        if (res.currentPageNumber > 1) {
          this.prevPage = true;
        } else {
          this.prevPage = false;
        }
      }


      this.loading = false;
    }, err => console.log(err))
  }

  getOrderStatuesTypes() {
    this.ordersService.getOrderStatuesTypes(this.branchId).subscribe((res: any) => {
      console.log(res)
      this.statuses = res;
    })
  }

  onChangeOrderStatues(order: any, template?: TemplateRef<any>) {
    console.log(order)
    this.selectedOrder = order;
    if (order.selectedStatus.title == "Completed") {
      this.modalService.open(template, { centered: true, size: 'sm' });
    } else {
      this.changeOrderStatues();
    }
  }

  changeOrderStatues() {
    let body:any = {
      orderId: this.selectedOrder.id,
      statusId: this.selectedOrder.selectedStatus.id,
      operatedOn: new Date().toISOString(),
      paymentInfos: [
        { amount: this.selectedOrder.totalFinalPrice, paymentMode: 1 }
      ]
    }

    if(this.selectedCashier) {
      body.cashierId = this.selectedCashier.userId
    }

    this.ordersService.changeOrderStatus(body).subscribe(res => {
      console.log(res);
      this.nbToastr.show('status changed', 'success', {
        status: 'success',
        hasIcon: true,
        icon: 'checkmark-outline'
      });

      if(this.selectedCashier) {
        this.modalService.dismissAll();
        this.selectedCashier = undefined;
      }

      this.getOrders(1);
    }, err => {
      this.nbToastr.show(err.error.message, 'Error', {
        status: 'danger',
        hasIcon: true,
        icon: 'close-outline'
      });
    })
  }

  onNext() {
    //console.log(this.pageNumber)
    this.getOrders(this.pageNumber + 1);
  }

  onPrevious() {
    //console.log(this.pageNumber)
    this.getOrders(this.pageNumber - 1);
  }

  onSearch(input: any) {
    if (input.target.value.length > 2) {
      this.getOrders(1, input.target.value);
    } else if (input.target.value == '') {
      this.getOrders(1);
    }
  }

  onPageChange(page: number) {
    this.pageNumber = page;
    this.getOrders(page);
  }

  onShowDetails(id: number) {
    this.ordersService.getOrderDetails(id).subscribe(res => {
      const modalRef = this.modalService.open(OrderComponent, { centered: true, size: 'lg' });
      modalRef.componentInstance.order = res;
    })

  }

  getCashiers() {
    this.cashierService.getCashiers(this.branchId).subscribe(res => {
      this.cashiers = res.cashiers;
    })
  }
}
