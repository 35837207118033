import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Cashier } from '../models/cashier.model';
@Injectable({
  providedIn: 'root'
})
export class CashierService {

  @Output() refresh = new EventEmitter<null>();
  constructor(private http: HttpClient) { }
  getCashiers(branchId:any) {
    return this.http.get<any>(`${environment.baseURL}/Cashiers/${branchId}/cashiers`).pipe(
      map(
        (data: any) => {
          return data;
        },
        catchError((error) => throwError('Something Went Wrong'))
      )
    );
  }
  getCashierTypes() {
    return this.http.get<Cashier[]>(`${environment.baseURL}/POSRoles`).pipe(
      map(
        (data: Cashier[]) => {
          return data;
        },
        catchError((error) => throwError('Something Went Wrong'))
      )
    );
  }
  getCashierById(cashierId:string){
    return this.http
    .get<Cashier>(`${environment.baseURL}/Cashiers/${cashierId}`)
    .pipe(
      map((data: Cashier) => {
        return data;
      }),
      catchError((error) => {
        return throwError('Something Is Wrong');
      })
    );
  }
  addCashier(cashier: Cashier) {
    return this.http.post(`${environment.baseURL}/Cashiers`, cashier);
  }
  resetPassword(cashier: Cashier,cashierId:string) {
    return this.http.post(`${environment.baseURL}/Cashiers/reset-password/${cashierId}`, cashier);
  }
  editCashier(cashier: Cashier, cashierId: string){
    return this.http.post(
      `${environment.baseURL}/Cashiers/${cashierId}/edit`,
      cashier
    );
  }
  deleteCashier(cashierId:string){
    return this.http.post(
      `${environment.baseURL}/Cashiers/${cashierId}/delete`,
      {}
    );
  }

}
