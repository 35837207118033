import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Category, SubCategory } from '../models/category.model';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Item } from '../models/item.model';

@Injectable({
  providedIn: 'root',
})
export class ItemsService {
  shouldReload = new EventEmitter<boolean>();
  constructor(private http: HttpClient) { }

  // Categories API

  addCategory(category: any) {
    return this.http.post(`${environment.baseURL}/Categories`, category);
  }

  setCategoryPlace() {

  }

  editSubCategory(subCategory: SubCategory | any) {
    return this.http.post(`${environment.baseURL}/SubCategories/edit`, subCategory);
  }

  addSubCategory(subCategory: SubCategory) {
    return this.http.post(`${environment.baseURL}/SubCategories`, subCategory);
  }

  editCategory(category: Category | any) {
    return this.http.post(`${environment.baseURL}/Categories/edit`, category);
  }

  uploadCategoryImage(formData: FormData, categoryId: number, logoName: string) {
    return this.http.post(
      `${environment.baseURL}/Categories/${categoryId}/logos?logoName=${logoName}`,
      formData,
    );
  }

  uploadSubCategoryImage(formData: FormData, subcategoryId: number, logoName: string) {
    return this.http.post(
      `${environment.baseURL}/SubCategories/${subcategoryId}/logoupload?logoName=${logoName}`,
      formData,
    );
  }

  getCategories(branchId: string) {
    return this.http
      .get<Category[]>(`${environment.baseURL}/Categories/${branchId}`)
      .pipe(
        map((data: any) => {
          if (data.categories) {
            return data.categories as Category[];
          } else {
            return [];
          }
        }),
        catchError((error) => throwError('Something Went Wrong')),
        catchError((error) => {
          return [];
        })
      );
  }

  getCategoriesWithPagination(branchId: string, page: number, searchKey?: string) {
    let filterInfos = [];

    if (searchKey) {
      filterInfos.push({
        "logical": "And",
        "propertyName": "categoryNameEN",
        "value": searchKey,
        "operator": "Contains"
      })
    }

    let body = {
      "filterInfos": filterInfos,
      "orderInfos": [
        {
          "orderType": "Asc",
          "property": "CategoryId"
        }
      ],
      "columns": [],
      "count": 5,
      "page": page
    }
    return this.http
      .post<any[]>(`${environment.baseURL}/Categories/GetCategories-Filtering-Pagination?branchId=${branchId}`, body)
      .pipe(
        map((data: any) => {
          if (data.value) {
            return data;
          } else {
            return [];
          }
        }),
        catchError((error) => throwError('Something Went Wrong')),
        catchError((error) => {
          return [];
        })
      );
  }

  getCategory(categoryId: string) {
    return this.http.get(`${environment.baseURL}/Categories/${categoryId}`);
  }

  deleteCategory(categoryId: string) {
    return this.http.post(`${environment.baseURL}/Categories/Delete/${categoryId}`, null);
  }

  findSupCategories(searchTerm: string, branchID: string) {
    return this.http
      .get(`${environment.baseURL}/SubCategories/${branchID}/${searchTerm}`)
      .pipe(
        map((response: any) => {
          if (response.subCategories) return response.subCategories;
          return [];
        }),
        catchError((error) => throwError('something went wrong'))
      );
  }

  deleteSubCategory(subcategoryId: string) {
    return this.http.post(`${environment.baseURL}/api/SubCategories/${subcategoryId}/delete`, {});
  }

  getImage(url: any) {
    return this.http.get(`${environment.baseURL}/Branches/GetImage/${url}`, { responseType: 'blob' });
  }

  // Items API

  getItems(branchId: string) {
    return this.http
      .get<Item[]>(`${environment.baseURL}/Items/${branchId}`)
      .pipe(
        map((data: any) => {
          if (data.items) {
            return data.items as Item[];
          } else {
            return [];
          }
        }),
        catchError((error) => throwError('Something Went Wrong')),
        catchError((error) => {
          return [];
        })
      );
  }

  getItemsWithPagination(branchId: string, page?: number, searchKey?: string) {
    let filterInfos = [];

    if (searchKey) {
      filterInfos.push({
        "logical": "And",
        "propertyName": "itemNameEN",
        "value": searchKey,
        "operator": "Contains"
      })
    }

    let body = {
      "filterInfos": filterInfos,
      "orderInfos": [
        {
          "orderType": "Asc",
          "property": "ItemId"
        }
      ],
      "columns": [],
      "count": 10,
      "page": page ? page : 1
    }
    return this.http
      .post<any[]>(`${environment.baseURL}/Items/GetItems-Filtering-Pagination?branchId=${branchId}`, body)
      .pipe(
        map((data: any) => {
          if (data.value) {
            return data;
          } else {
            return [];
          }
        }),
        catchError((error) => throwError('Something Went Wrong')),
        catchError((error) => {
          return [];
        })
      );
  }

  getItemsAddOnTypes(branchId: string, page?: number, searchKey?: string) {
    let filterInfos = [];

    if (searchKey) {
      filterInfos.push({
        "logical": "And",
        "propertyName": "itemNameEN",
        "value": searchKey,
        "operator": "Contains"
      })
    }

    let body = {
      "filterInfos": filterInfos,
      "orderInfos": [
        {
          "orderType": "Asc",
          "property": "ItemId"
        }
      ],
      "columns": [],
      "count": 10,
      "page": page ? page : 1
    }
    return this.http
      .post<any[]>(`${environment.baseURL}/Items/GetItemNamesByType?branchId=${branchId}&typeId=3`, body)
      .pipe(
        map((data: any) => {
          if (data.value) {
            return data;
          } else {
            return [];
          }
        }),
        catchError((error) => throwError('Something Went Wrong')),
        catchError((error) => {
          return [];
        })
      );
  }

  getSubCategories(categoryId: number, branchID: string) {
    return this.http
      .get(`${environment.baseURL}/SubCategories/${branchID}/${categoryId}/SubCategories`)
      .pipe(
        map((response: any) => {
          if (response.subCategories) return response.subCategories;
          return [];
        }),
        catchError((error) => throwError('something went wrong'))
      );
  }

  addItem(item: any) {
    return this.http.post(`${environment.baseURL}/Items`, item);
  }

  editItem(item: Item) {
    return this.http.put(`${environment.baseURL}/Items`, item);
  }

  addItemBySheet(data: FormData, branchID: string) {
    return this.http.post(
      `${environment.baseURL}/Items/excel/${branchID}`,
      data
    );
  }

  uploadItemLogo(data: FormData, itemId: number, logoName: string) {
    let queryParam = { itemId, logoName };
    return this.http.post(`${environment.baseURL}/Items/logos`, data, {
      params: queryParam,
    });
  }

  setItemLogo(itemId: string, imageId: string) {
    let body = { itemId, imageId };
    return this.http.post(`${environment.baseURL}/Items/set-logo`, body);
  }

  getItemLogo(itemId: string, imageId: string) {
    let body = { itemId, imageId };
    return this.http.post(`${environment.baseURL}/Items/set-logo`, body);
  }

  deleteItem(itemID: string) {
    return this.http.post(`${environment.baseURL}/Items/Delete/${itemID}`, null);
  }

  setItemPlace(itemId: number, itemPlace: number) {
    return this.http.post(`${environment.baseURL}/Items/set-item-place`, { itemId, itemPlace });
  }

  changeItemVisibilty(id:number, visible: boolean) {
    return this.http.post(`${environment.baseURL}/Items/UpdateItemForSupportDashboard?itemId=${id}&isVisible=${visible}`, null);
  }
}
