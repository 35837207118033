import { Component } from '@angular/core';
import { PrintingLayoutKey, PrintingSectinos } from '../../../models/printing-layout.model';
import { PrintingLayoutService } from '../../../services/printing-layout.service';
import { NbToastrService } from '@nebular/theme';
import { UserService } from '../../../services/user.service';
import * as uuid from 'uuid';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-new-layout2',
  templateUrl: './new-layout2.component.html',
  styleUrl: './new-layout2.component.scss'
})
export class NewLayout2Component {
  addLayout: any = {};
  layoutJson: any = { transactions: {}, sort: [], sort_header: [], sort_body: [], sort_footer: [] };
  newLayouts: PrintingLayoutKey[] = [];
  sortedKeys: any[] = [];
  sortHeader: any[] = [];
  sortBody: any[] = [];
  sortFooter: any[] = [];
  addedKeys: PrintingLayoutKey[] = [];
  transactionKeys: any = {};
  previewLayout: string = '';
  draggingIndex?: string;
  filterKeyTerm: string = '';
  keysCount: any = { header: 0, body: 0, footer: 0 };
  imgurlPlaceholder : string = 'https://www.altkamul.net/content/merchants/50112101/50112101/printing.bmp';

  constructor(
    private printingLayoutService: PrintingLayoutService,
    private toastrService: NbToastrService,
    public userservice: UserService,
    private router : Router
  ) { }
  
  ngOnInit(): void {
    this.addLayout.layoutName = '';
    this.addLayout.layoutType = 1;
    this.addLayout.layoutText = '';
    this.getLayoutKeys();
  }
  
  filterKeys(): PrintingLayoutKey[] {
    // console.log('filterKeys');
    return this.newLayouts.filter((el) =>
      el.name.toLowerCase().includes(this.filterKeyTerm.toLowerCase())
    );
  }
  getAddedKeys(sectionName?: string): PrintingLayoutKey[] {
    // console.log('getAddedKeys');
    
    return this.addedKeys.filter((el) => el.sectionName == sectionName);
  }
  addToLayout(data: PrintingLayoutKey) {
    console.log('addToLayout');
    console.log('data',data);
    
    
    if(!data.uuid)  {
      data.options = { isBold: false, size: 1, align: 0 };
      data.editable = false;
      data = { ...data, uuid: uuid.v4() };
      console.log('data after',data);

      if (data.sectionName == PrintingSectinos.Body) {
        this.addedKeys.splice(
          this.keysCount.header + this.keysCount.body,
          0,
          data
        );
        this.keysCount.body++;
      }

      if (data.sectionName == PrintingSectinos.Header) {
        this.addedKeys.splice(this.keysCount.header, 0, data);
        this.keysCount.header++;
      }

      if (data.sectionName == PrintingSectinos.Footer) {
        this.addedKeys.push(data);
        this.keysCount.footer++;
      }

    }

    this.updateJson(data);

  }
  getLayoutKeys() {
    console.log('getLayoutKeys');
    
    this.printingLayoutService.getPrinitingLayoutKeys().subscribe({
      next: (data) => this.onGetSuccess(data),
      error: (err) => this.onGetError(err),
    });
  }
  onGetSuccess(keys: PrintingLayoutKey[]) {
    console.log('access keys',keys);
    
    // keys = keys.sort((a, b) => (a.sectionName > b.sectionName ? 1 : -1));
    // console.log('keys',keys);
    const customOrder = ['Header', 'Body', 'Footer'];

    keys = keys.sort((a, b) => {
      return customOrder.indexOf(a.sectionName) - customOrder.indexOf(b.sectionName);
    });

    this.newLayouts = keys;
  }
  onClose() {
    // this.activeModal.close();
    this.router.navigate(['home/printing-layouts']);

  }
  onGetError(error: any) { }
  onSave() {
    this.updateJson();
    this.layoutJson.transactions = this.transactionKeys;
    this.layoutJson.sort = this.sortedKeys;
    this.layoutJson.sort_header = this.sortHeader;
    this.layoutJson.sort_body = this.sortBody;
    this.layoutJson.sort_footer = this.sortFooter;
    this.addLayout.layoutText = JSON.stringify(this.layoutJson);
    this.addLayout.CopyType = this.addLayout.layoutType;

    console.log(this.layoutJson)
    console.log(this.addLayout)

    this.printingLayoutService.addPrintingLayout(this.addLayout).subscribe(
      (res) => {
        this.userservice.logs.emit('add printing layout in branch log');
        this.toastrService.show('layout added successfully', 'success', {
          status: 'success',
          hasIcon: true,
          icon: {
            icon: 'checkmark-outline',
            pack: 'eva',
          },
        });
        this.router.navigate(['home/printing-layouts']);

        // this.activeModal.close('success');
      },
      (err) => {
        // this.activeModal.close('error');
        this.toastrService.show(
          'failed to add new layout, please try again',
          'error',
          {
            status: 'danger',
            hasIcon: true,
            icon: {
              icon: 'alert-circle-outline',
              pack: 'eva',
            },
          }
        );
      }
    );
  }
  updateJson(data?: PrintingLayoutKey) {
    // console.log({data})
    // console.log('updateJson');
    
    if (data) {
      // console.log(data.label);
       
      this.sortedKeys.push(data.uuid);
      if (data.sectionName == PrintingSectinos.Body) {
        let isfound = this.sortBody.find(body => {
          return body == data.uuid
        });

        if(!isfound) {
          this.sortBody.push(data.uuid)
        }

        this.transactionKeys[data.uuid] = data.label;

      } else if (data.sectionName == PrintingSectinos.Header) {
        let isfound = this.sortHeader.find(header => {
          return header == data.uuid
        });

        if(!isfound) {
          this.sortHeader.push(data.uuid);
        }

      } else if (data.sectionName == PrintingSectinos.Footer) {
        let isfound = this.sortBody.find(footer => {
          return footer == data.uuid
        });

        if(!isfound) {
          this.sortFooter.push(data.uuid)
        }

      }
      this.layoutJson[data.uuid] = data;
    } else {
      this.sortedKeys = [];
      this.addedKeys.forEach((el) => {
        this.sortedKeys.push(el.uuid);
      });

      Object.keys(this.transactionKeys).forEach((id: string) => {
        const index = this.addedKeys.findIndex((el2) => el2.uuid == id);
        this.transactionKeys[id] = this.addedKeys[index].initialValue;
      });
    }
  }
  getLayoutPreview(): string {
    // console.log('getLayoutPreview');

    return this.addedKeys.reduce(
      (acc, curr) => acc + this.renderKeysOptions(curr) + '','');
      // (acc, curr) => acc + this.renderKeysOptions(curr) + '<br>','');

  }
  renderKeysOptions(key: PrintingLayoutKey): string {
    // console.log('renderKeysOptions');

    let para = "<p class='";
    let value = key.label ? key.label : key.initialValue;

    if (key.name=='logo') {
      key.initialValue = `<img src="${this.imgurlPlaceholder}" width="200" >`;
      key.options!.align=1;
    }

    if (key.options!.size == 2) {
      para += 'fw-bold ';
    }

    if (key.options!.align == 0) {
      para += 'text-left ';
    }

    if (key.options!.align == 1) {
      para += 'text-center ';
    }

    if (key.options!.align == 2) {
      para += 'text-end ';
    }

    para += "'>" + value + '</p>';
    return para;
  }
  allowLabelEdit(uuid: string) {
    // console.log('allowLabelEdit')
    const index = this.addedKeys.findIndex((el) => el.uuid == uuid);
    this.addedKeys[index].editable = true;
  }
  onContentChanged(data: any) {
    let historyStyle = data.event.source.history._operations.map((el: any) => {
      return el;
    });
    historyStyle = historyStyle.map((el: any) => {
      return el;
    });
    JSON.stringify(historyStyle);
  }
  isEditable(index: number): boolean {
    // console.log('isEditable');
    return this.addedKeys[index].editable;
  }

  toggleEdit(uuid: string) {
    // console.log('toggleEdit');

    // console.log('toggleEdit')
    const index = this.addedKeys.findIndex((el) => el.uuid == uuid);
    //console.log(this.addedKeys[index])
    this.addedKeys[index].editable = !this.addedKeys[index].editable;
    // console.log(this.addedKeys)
    this.addToLayout(this.addedKeys[index]);
  }
  allowDrop(ev: any): void {
    // console.log('allowDrop');

    ev.preventDefault();
    ev.stopPropagation(); // Stops some browsers from redirecting.
  }

  drag(ev: any, isSort: boolean = false): void {
    // console.log('drag');

    ev.dataTransfer.setData('dragElement', ev.target.id);
    ev.dataTransfer.setData('isSort', isSort);
  }

  drop(ev: any): void {
    console.log('drop');
    ev.preventDefault();
    
  }

  onDragEnter(toIndex: string, type: string): void {
    console.log('onDragEnter to index',toIndex);

    if (this.draggingIndex !== toIndex) {
      this._reorderItem(this.draggingIndex!, toIndex, type);
    console.log("toIndex from dragstart" , toIndex);

    }
  }

  onDragStart(fromIndex: string): void {
    // console.log('onDragStart');

    this.draggingIndex = fromIndex;
    console.log("draggingIndex from dragstart" , fromIndex);
    console.log("draggingIndex from dragstart" , this.draggingIndex);
    
  }

  deleteKey(uuid: string) {
    const index = this.addedKeys.findIndex((el) => el.uuid == uuid);
    const key = this.addedKeys.splice(index, 1)[0];
    const sortedIdx = this.sortedKeys.findIndex((el) => el == key.uuid);
    this.sortedKeys.splice(sortedIdx, 1);

    //console.log(key)

    delete this.transactionKeys[key.uuid];
    delete this.layoutJson[key.uuid];

    if (key.sectionName == PrintingSectinos.Header) {
      this.keysCount.header--;
      this.sortHeader = this.sortHeader.filter(header => {
        return header !== key.uuid;
      });

      //console.log(this.sortHeader)
    }

    if (key.sectionName == PrintingSectinos.Body) {
      this.keysCount.body--;
      this.sortBody = this.sortBody.filter(body => {
        return body !== key.uuid;
      });

      //console.log(this.sortBody)
    }

    if (key.sectionName == PrintingSectinos.Footer) {
      this.keysCount.footer--;
      this.sortFooter = this.sortFooter.filter(footer => {
        return footer !== key.uuid;
      });

      //console.log(this.sortFooter)
    }
  }

  private _reorderItem(fromUUID: string, toUUID: string, type: string): void {
    console.log({fromUUID}, {toUUID})
    console.log('_reorderItem');

    const fromIndex = this.addedKeys.findIndex((el) => el.uuid == fromUUID);
    const itemToBeReordered = this.addedKeys.splice(fromIndex, 1)[0];
    const toIndex = this.addedKeys.findIndex((el) => el.uuid == toUUID);
    this.addedKeys.splice(toIndex, 0, itemToBeReordered);

    // this.draggingIndex = toUUID;
    // console.log("draggingIndex from _reorderItem" , toUUID);



    //////////
    if (type == 'body') {
      const fromBodyIndex = this.sortBody.findIndex((el) => el == fromUUID);
      const itemBodyToBeReordered = this.sortBody.splice(fromBodyIndex, 1)[0];
      const toBodyIndex = this.sortBody.findIndex((el) => el == toUUID);
      this.sortBody.splice(toBodyIndex, 0, itemBodyToBeReordered);
    } else if (type == 'header') {
      const fromHeaderIndex = this.sortHeader.findIndex((el) => el == fromUUID);
      const itemHeaderToBeReordered = this.sortHeader.splice(fromHeaderIndex, 1)[0];
      const toHeaderIndex = this.sortHeader.findIndex((el) => el == toUUID);
      this.sortHeader.splice(toHeaderIndex, 0, itemHeaderToBeReordered);
    } else if (type == 'footer') {
      const fromFooterIndex = this.sortFooter.findIndex((el) => el == fromUUID);
      const itemFooterToBeReordered = this.sortFooter.splice(fromFooterIndex, 1)[0];
      const toFooterIndex = this.sortFooter.findIndex((el) => el == toUUID);
      this.sortFooter.splice(toFooterIndex, 0, itemFooterToBeReordered);
    }
    console.log(this.sortBody)

  }

  onDragEnd(): void {
    console.log('onDragEnd');
    
    this.draggingIndex = undefined;
  }

  toggleBold(index: number) {
    // console.log('toggleBold');

    if (this.addedKeys[index].options!.size == 1) {
      this.addedKeys[index].options!.size = 2;
    } else {
      this.addedKeys[index].options!.size = 1;
    }
  }
  items = ['Item 1', 'Item 2', 'Item 3', 'Item 4'];

  dropcdk(event: CdkDragDrop<string[]>) {
    
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    console.log(this.items);
  }
}
