<nb-card [nbSpinner]="isLoading">
  <nb-card-body>
    <div class="row">
      <div class="col-md-6">
        <input nbInput placeholder="search by item name.." id="search" class="search-input" (input)="onSearch($event)" />
      </div>
      <div class="col-md-6">

        <div class="btn-container">
          <button nbButton status="primary" (click)="newItem()">
            <nb-icon icon="plus" [options]="{ animation: { type: 'shake' } }"></nb-icon>
            New Item
          </button>
        </div>
      </div>
    </div>
    <!-- <input nbInput [nbFilterInput]="dataSource" id="search" class="search-input" /> -->
    <div class="text-center" *ngIf="itemLength > 0">
      <label>press Enter to Confirm</label>
    </div>


    <table [nbTreeGrid]="dataSource" [nbSort]="dataSource" (sort)="updateSort($event)">
      <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns"></tr>
      <tr nbTreeGridRow [clickToToggle]="false" *nbTreeGridRowDef="let row; columns: allColumns"></tr>

      <ng-container [nbTreeGridColumnDef]="customColumn">
        <th nbTreeGridHeaderCell [nbSortHeader]="getSortDirection('name')" *nbTreeGridHeaderCellDef>
          Name
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">
          {{row.data[customColumn] }}
          <nb-icon icon="arrow-forward-outline"></nb-icon>
          {{ row.data["category"] }}
        </td>
      </ng-container>


      <ng-container nbTreeGridColumnDef="faceValue">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>Face Value</th>
        <td nbTreeGridCell *nbCellDef="let row">
          <input type="number" [(ngModel)]="row.data['faceValue']" nbInput shape="semi-round"
            (keyup.enter)="onEnter($event, row, 'facePrice')" />

        </td>
      </ng-container>

      <ng-container nbTreeGridColumnDef="buyingPrice">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>Cost Price</th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">
          <input type="number" [(ngModel)]="row.data['buyingPrice']" nbInput (keyup.enter)="onEnter($event, row, 'buyingPrice')"
            shape="semi-round" />
        </td>
      </ng-container>

      <ng-container nbTreeGridColumnDef="icon">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>Logo</th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">
          <ng-icons imgUrl="{{row.data['icon']}}"></ng-icons>
        </td>
      </ng-container>

      <ng-container nbTreeGridColumnDef="discount">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>Discount</th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">
          <input type="number" nbInput [(ngModel)]="row.data['discount']" shape="semi-round" (keyup.enter)="onEnter($event, row, 'discount')"/>
        </td>
      </ng-container>

      <ng-container nbTreeGridColumnDef="minPrice">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>Min Price</th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">
          <input type="number" nbInput [(ngModel)]="row.data['minPrice']" shape="semi-round"
            (keyup.enter)="onEnter($event, row,'minimumPrice')" />
        </td>
      </ng-container>

      <ng-container nbTreeGridColumnDef="maxPrice">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>Max Price</th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">
          <input type="number" nbInput [(ngModel)]="row.data['maxPrice']" shape="semi-round"
            (keyup.enter)="onEnter($event, row,'maximumPrice')" />
        </td>
      </ng-container>

      <ng-container nbTreeGridColumnDef="openPrice">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>Open Price</th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">
          <nb-toggle [(checked)]="row.data['openPrice']" (change)="onEnter($event, row,'isOpenPrice')"></nb-toggle>
        </td>
      </ng-container>

      <ng-container nbTreeGridColumnDef="openQuantity">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>Open Quantity</th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">
          <nb-toggle [(checked)]="row.data['openQuantity']" (change)="onEnter($event, row,'isOpenQuantity')"></nb-toggle>
        </td>
      </ng-container>

    </table>
    <nav aria-label="table pagination">
      <ul class="pagination justify-content-end mt-3">
        <li class="page-item" [ngClass]="{ 'disabled': !prevPage }" (click)="onPrevious()">
          <span class="page-link">Prev</span>
        </li>
        <li class="page-item" [ngClass]="{ 'active': pageNumber == page }" *ngFor="let page of pages"
          (click)="onPageChange(page)"><span class="page-link">{{ page }}</span></li>
        <li class="page-item" [ngClass]="{ 'disabled': !nextPage }" (click)="onNext()">
          <span class="page-link">Next</span>
        </li>
      </ul>
    </nav>
  </nb-card-body>
</nb-card>
