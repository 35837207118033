<nb-card>
  <nb-tabset class="px-3">
    <nb-tab tabTitle="Feature">
      <nb-card class="border-0">
        <nb-card-header class="px-0 d-flex justify-content-between align-items-center">
          <div class="search">
            <!-- <nb-form-field>
              <nb-icon nbPrefix status="warning" icon="search-outline" pack="eva"></nb-icon>
              <input type="text" nbInput status="basic" placeholder="Search by SN, Type or Status"
                (input)="onSearch($event)" autofocus>
            </nb-form-field> -->
          </div>
          <div class="report d-flex align-items-center">
            <button nbButton hero status="success" (click)="onCreateFeature()">
              <nb-icon icon="plus-outline"></nb-icon> Create Feature
            </button>
            <button nbButton status="success" hero (click)="onCreateFeatureGroup()">
              <nb-icon icon="plus-outline"></nb-icon>
              Create Feature Group
            </button>
          </div>
        </nb-card-header>
        <nb-card-body>
          <div class="table-responsive" *ngIf="features.length > 0">
            <table class="table table-bordered" [nbSpinner]="loading" nbSpinnerStatus="basic">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Price in AED</th>
                  <th scope="col">Price in TRY</th>
                  <th scope="col">Price in USD</th>
                  <th scope="col">creator</th>
                  <!-- <th scope="col">Options</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let feature of features; let i = index ">
                  <th scope="row">{{ i + 1 }}</th>
                  <td>{{feature.defaultName}}</td>
                  <td>{{feature.featurePrices[0]?.price}}</td>
                  <td>{{feature.featurePrices[1]?.price}}</td>
                  <td>{{feature.featurePrices[2]?.price}}</td>
                  <td>{{feature.creator}}</td>
                  <!-- <td>
                    <button placement="right" ngbTooltip="History" tooltipClass="custom-tooltip" nbButton status="warning"
                      shape="round" hero (click)="onShowHistory(pos.posId, pos.sn)">
                      <nb-icon icon="clock-outline"></nb-icon>
                    </button>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <div class="py-3 text-center" *ngIf="features.length == 0">
            <p>No available features to show</p>
          </div>
        </nb-card-body>
      </nb-card>
    </nb-tab>
    <nb-tab tabTitle="Package">
      <nb-card class="border-0">
        <nb-card-header class="px-0 text-right">
          <button nbButton hero status="success" (click)="onCreatePackage()">
            <nb-icon icon="plus-outline"></nb-icon> create Package
          </button>
        </nb-card-header>
        <nb-card-body>
          <div class="table-responsive" *ngIf="packages.length > 0">
            <table class="table table-bordered" [nbSpinner]="loading" nbSpinnerStatus="basic">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">creator</th>
                        <!-- <th scope="col">Actions</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let package of packages; let i = index ">
                        <th scope="row">{{ i + 1 }}</th>
                        <td>{{package.defaultName}}</td>
                        <td>
                          {{package.creator}}
                        </td>
                        <!-- <td>
                            <button placement="right" ngbTooltip="delete" tooltipClass="custom-tooltip" nbButton status="danger" size="small" shape="round" hero (click)="deletePOSType()">
                                <nb-icon icon="trash-2-outline"></nb-icon>
                            </button>
                            <button placement="right" ngbTooltip="edit" tooltipClass="custom-tooltip" nbButton status="success" size="small" shape="round" hero (click)="onEditPOSType(type)">
                              <nb-icon icon="edit-outline"></nb-icon>
                          </button>
                        </td> -->
                    </tr>
                </tbody>
            </table>
          </div>
          <div class="py-3 text-center" *ngIf="packages.length == 0">
            <p>No available POS packages to show</p>
          </div>
        </nb-card-body>
      </nb-card>
    </nb-tab>
  </nb-tabset>
</nb-card>
