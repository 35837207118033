import {Component, Input, OnInit} from '@angular/core';
import {SubCategory} from "../../models/category.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {ItemsService} from "../../services/items.service";
import {NbDialogRef, NbToastrService} from "@nebular/theme";
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { GoogletranslateService } from '../../services/googletranslate.service';


@Component({
  selector: 'app-new-sub-category',
  templateUrl: './new-sub-category.component.html',
  styleUrls: ['./new-sub-category.component.scss']
})
export class NewSubCategoryComponent implements OnInit {
  categoryId?:number;
  branchID: string = '';
  subCategory?: SubCategory;
  newSubCategory: FormGroup;
  isLoading: boolean = false;
  id: any = '';
  itemImage?: File;
  subCategoryImage: any;
  autoTranslate: boolean = true;

  constructor( private fb: FormBuilder,
               public route: ActivatedRoute,
               private ItemService: ItemsService,
               private nbToastr: NbToastrService,
               private dialogRef: NbDialogRef<NewSubCategoryComponent>,
               private googleTranslate: GoogletranslateService,
              ) {

    this.newSubCategory = this.fb.group({
      subCategoryNameEN: ['', Validators.required],
      subCategoryNameAR: ['', Validators.required],
      subCategoryNameFR: [''],
      subCategoryNameTR: [''],
    });
  }

  ngOnInit(): void {
    console.log(this.subCategory,    'subCategory');
    if (this.subCategory) {
      this.newSubCategory.patchValue({
        subCategoryNameEN: this.subCategory.subCategoryNameEN,
        subCategoryNameAR: this.subCategory.subCategoryNameAR,
        subCategoryNameFR: this.subCategory.subCategoryNameFR,
        subCategoryNameTR: this.subCategory.subCategoryNameTR
      });

      this.subCategoryImage = this.subCategoryImage.img

      // this.ItemService.getImage(this.subCategory?.imageId).subscribe(res => {
      //   // this.categoryImage = res;
      //   var reader = new FileReader();
      //   reader.readAsDataURL(res);
      //   reader.onloadend = () => {
      //     this.subCategoryImage = reader.result;
      //   }
      // })
    }
    //console.log(this.categoryId)
  }

  close() {
    this.dialogRef.close();
  }

  onSelect(event: NgxDropzoneChangeEvent) {
    this.itemImage = event.addedFiles[0];
  }

  save() {
    if (this.subCategory) console.log(this.subCategory, 'Updating...');
    if (this.newSubCategory.valid) {
      this.isLoading = true;
      if (this.subCategory) {
        // updating category
        this.updateSubCategory();
      } else {
        // new category
        this.createSubCategory();
      }
    }
  }

  updateSubCategory() {
    let updatedSubcategory = this.newSubCategory.value;
    updatedSubcategory.subCategoryPlace = this.subCategory?.subCategoryPlace;
    updatedSubcategory.subCategoryId = this.subCategory?.subCategoryId;

    this.ItemService.editSubCategory(updatedSubcategory).subscribe(
      (res: any) => {
        console.log(res);
        if (this.itemImage) {
          this.uploadSubCategoryImage(updatedSubcategory.subCategoryNameEN, this.subCategory!.subCategoryId)
        } else {
          this.isLoading = false;
          this.nbToastr.success(res.message);
          this.dialogRef.close();
        }
      },
      (err) => {
        console.log(err);
        if (err.error.errors) this.nbToastr.danger(err.error.errors[0]);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }
  createSubCategory() {
    this.newSubCategory.value.categoryId = this.categoryId;
    this.ItemService.addSubCategory(this.newSubCategory.value).subscribe(
      (res: any) => {
        console.log(res);
        this.isLoading = false;
        this.nbToastr.success(res.message);
        this.dialogRef.close();
      },
      (err) => {
        console.log(err);
        this.nbToastr.danger(err.error.Message);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  uploadSubCategoryImage(name: string, id: number) {
    let img = new FormData();
    img.append('logofile', this.itemImage!);

    this.ItemService.uploadSubCategoryImage(img, id, name).subscribe(res => {
      this.isLoading = false;
      let msg = this.subCategory? 'subCategory updated successfully.': 'subCategory added successfully.';
      this.nbToastr.success(msg);
      this.dialogRef.close();
    }, err => {
      this.nbToastr.danger(err.message);
      this.isLoading = false;
    })
  }

  detectChange(lang: string, $e: any) {
    let value = $e.target.value;

    if(lang !== 'ar') {
      this.googleTranslate.translate({ q: value, target: 'ar' }).subscribe(res => {
        this.newSubCategory.get('subCategoryNameAR')?.setValue(res)
      });
    }

    if(lang !== 'en') {
      this.googleTranslate.translate({ q: value, target: 'en' }).subscribe(res => {
        this.newSubCategory.get('subCategoryNameEN')?.setValue(res)
      });
    }

    if(lang !== 'tr') {
      this.googleTranslate.translate({ q: value, target: 'tr' }).subscribe(res => {
        this.newSubCategory.get('subCategoryNameTR')?.setValue(res)
      });
    }

    if(lang != 'fr') {
      this.googleTranslate.translate({ q: value, target: 'fr' }).subscribe(res => {
        this.newSubCategory.get('subCategoryNameFR')?.setValue(res)
      });
    }
  }

}
