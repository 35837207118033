<div class="p-3">
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Item</th>
          <th>Price</th>
          <th>Quantity</th>
          <th>Tax</th>
          <th>Discount</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of order.orderDetails">
          <td>{{item.item.itemNameEN}} - {{item.item.itemNameAR}}</td>
          <td>{{item.originalPrice}}</td>
          <td>{{item.quantity}}</td>
          <td>{{item.taxPrice}}</td>
          <td>{{item.discountPrice}}</td>
          <td>{{item.finalPrice}}</td>
        </tr>
        <tr class="table-active table-borderless">
          <td>Total</td>
          <td class="fw-bold">{{order.totalPrice}}</td>
          <td class="fw-bold">{{order.totalQuantity}}</td>
          <td class="fw-bold">{{order.totalTax}}</td>
          <td class="fw-bold">{{order.totalDiscoun}}</td>
          <td class="fw-bold">{{order.totalFinalPrice}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
