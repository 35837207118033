<nb-card>
  <nb-card-header>
    <div class="d-flex align-items-center justify-content-end">
      <button nbButton hero status="success" (click)="onCreateTable()">
        <nb-icon icon="plus-outline"></nb-icon> Create Table
      </button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <!-- <div class="table-responsive" *ngIf="tables.length > 0"> -->
    <div class="table-responsive">
      <table class="table table-bordered" [nbSpinner]="loading" nbSpinnerStatus="basic">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Number</th>
            <th scope="col">Capacity</th>
            <th scope="col">Status</th>
            <th scope="col">Options</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let table of tables; let i = index ">
            <th scope="row">{{ i + 1 }}</th>
            <td>{{table.tableName}}</td>
            <td>{{table.tableNumber}}</td>
            <td>{{table.tableCapacity}}</td>
            <td>{{table.tableStatus}}</td>
            <td>
              <button placement="right" ngbTooltip="edit" tooltipClass="custom-tooltip" nbButton status="success"
                shape="round" hero (click)="onUpdateTable(table)">
                <nb-icon icon="edit-2-outline"></nb-icon>
              </button>
              <button placement="right" ngbTooltip="delete" tooltipClass="custom-tooltip" nbButton status="danger"
                shape="round" hero (click)="delete(table.id)">
                <nb-icon icon="trash-2-outline"></nb-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="py-3 text-center" *ngIf="tables.length == 0">
      <p>No available tables to show</p>
    </div>
  </nb-card-body>
</nb-card>
