import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { ItemsService } from '../../services/items.service';


@Component({
  selector: 'app-delete-category',
  templateUrl: './delete-category.component.html',
  styleUrls: ['./delete-category.component.scss'],
})
export class DeleteCategoryComponent implements OnInit {
  category?: any;
  isLoading: boolean = false;
  constructor(
    private dialogRef: NbDialogRef<DeleteCategoryComponent>,
    private itemService: ItemsService,
    private nbToastr: NbToastrService
  ) {}

  close() {
    this.dialogRef.close();
  }

  deleteCategory() {
    this.isLoading = true;
    this.itemService.deleteCategory(this.category.id).subscribe(
      (res) => {
        this.nbToastr.success('Deleted Successfully', 'Success');
        this.isLoading = false;
        this.close();
      },
      (e) => {
        this.nbToastr.danger('Something Went Wrong', 'Failure');
        this.isLoading = false;
        this.close();
      }
    );
    console.log(this.category);
  }

  deleteSubCategory(){
    this.isLoading = true; 
    console.log('from sub',this.category);
    
    this.itemService.deleteSubCategory(this.category.subCategoryId).subscribe(
      (_) => {
        this.nbToastr.success('Deleted Successfully', 'Success');
        this.isLoading = false;
        this.close();
      },
      (e) => {
        this.nbToastr.danger('Something Went Wrong', 'Failure');
        this.isLoading = false;
        this.close();
      }
    );

  }

  submit(){
    if(this.category.subCategoryId){
      this.deleteSubCategory();
    }else{
      this.deleteCategory();
    }
  }

  ngOnInit(): void {
    console.log(this.category);
  }
}
