<nb-card>
  <nb-card-body>
    <nb-stepper status="success" orientation="horizontal" disableStepNavigation [linear]="true">
      <!-- step one -->
      <nb-step>
        <div class="row">
          <!-- (ngModelChange)="selectionChanged()" -->
          <nb-radio-group name="branch-radio" status="success" class="d-md-flex" [(ngModel)]="selectedValue">
            <nb-radio value="old business">Select Old Business</nb-radio>
            <nb-radio value="new business">Add New Business</nb-radio>
          </nb-radio-group>
          <div class="col-12 my-3" *ngIf="selectedValue == 'old business'">
            <nb-form-field>
              <nb-icon nbPrefix status="warning" icon="search-outline" pack="eva"></nb-icon>
              <input fullWidth (input)="onBusinessInput($event)" nbInput type="text" placeholder="Search Business Name"
                [nbAutocomplete]="autoControl" autofocus autocomplete="off" />
              <button nbSuffix nbButton ghost [nbSpinner]="loading" nbSpinnerStatus="warning"
                nbSpinnerSize="tiny"></button>
            </nb-form-field>

            <nb-autocomplete #autoControl (selectedChange)="onBusinessSelect($event)">
              <nb-option *ngFor="let business of filteredBusinessOptions$ | async" value="{{ business.id }}">
                {{ business.name }}
              </nb-option>
            </nb-autocomplete>
          </div>
          <div class="col-12 my-3" *ngIf="selectedValue == 'new business'">
            <form [formGroup]="addBusinessForm">
              <div class="form-row">
                <div class="mb-3 col-12">
                  <label class="mb-2">Business Name</label>
                  <input type="text" formControlName="name" nbInput fullWidth placeholder="Enter Business Name" />
                </div>
                <div class="mb-3 col-12">
                  <label class="mb-2">Business Phone</label>
                  <input type="text" formControlName="phone" nbInput fullWidth placeholder="Enter Business Phone" />
                </div>
                <div class="mb-3 col-12">
                  <label class="mb-2">Business Email</label>
                  <input type="email" formControlName="email" nbInput fullWidth placeholder="Enter Business Email" />
                </div>
                <div class="mb-3 col-12">
                  <label class="mb-2">Sales Man Name</label>
                  <select class="form-control custom-select" formControlName="salesManId" nbInput>
                    <option *ngFor="let salesMan of salesMen" value="{{ salesMan.id }}">
                      {{ salesMan.salesManName }}
                    </option>
                  </select>
                  <!-- <nb-select formControlName="salesManId" fullWidth placeholder="Select Sales Man">
                                    <nb-option *ngFor="let salesMan of salesMen" value="{{salesMan.id}}">{{salesMan.salesManName}}</nb-option>
                                </nb-select> -->
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="text-right">
          <!-- <button nbButton disabled nbStepperNext>prev</button> -->
          <button nbButton nbStepperNext [disabled]="
              (selectedValue == 'new business' && !addBusinessForm.valid) ||
              (selectedValue == 'old business' && isDisbaled)
            " (click)="selectedValue == 'new business' ? onBusinessAdd() : null">
            next
          </button>
        </div>
      </nb-step>
      <!-- step two -->
      <nb-step>
        <h4>New Branch</h4>
        <form [formGroup]="branchInfoForm">
          <div class="row my-3">
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Business Name</label>
              <input type="text" #businessName nbInput fullWidth placeholder="Enter Business Name"
                [value]="selectedBusinessName" [disabled]="true" fullWidth />
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Branch Name</label>
              <input type="text" formControlName="Name" placeholder="Enter Branch Name" nbInput fullWidth autofocus />
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Branch Domain Address</label>
              <input type="text" formControlName="DomainAddress"
                placeholder="Enter Branch Domain Address, EX: test.catalogak.info" nbInput fullWidth autofocus />
              <small class="text-danger"
                *ngIf="branchInfoForm.get('DomainAddress')!.value && !branchInfoForm.get('DomainAddress')!.valid && branchInfoForm.get('DomainAddress')!.errors?.['pattern']">domain
                address must end with .catalogak.info</small>
              <small class="text-danger"
                *ngIf="branchInfoForm.get('DomainAddress')!.value && !branchInfoForm.get('DomainAddress')!.valid && branchInfoForm.get('DomainAddress')!.errors?.['required']">domain
                address is required</small>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Branch Phone</label>
              <input type="text" formControlName="FirstPhoneNumber" placeholder="Enter Branch Phone" nbInput
                fullWidth />
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Another Phone</label>
              <input type="text" formControlName="SecondPhoneNumber" placeholder="Enter Another Phone" nbInput
                fullWidth />
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Branch Email</label>
              <input type="text" formControlName="Email" placeholder="Enter Branch Email" nbInput fullWidth />
            </div>
            <!-- <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Subscription Type</label>
              <nb-select class="w-100" fullWidth formControlName="SubscriptionTypeId"
                placeholder="Select Subscription Type">
                <nb-option *ngFor="let type of subscriptionTypes" [value]="convertString(type.id)">
                  {{ type.name }}
                </nb-option>
              </nb-select>
            </div> -->
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Business Type</label>
              <nb-select class="w-100" fullWidth formControlName="BusinessTypeId" placeholder="Select Business Type">
                <nb-option *ngFor="let type of businessTypes" [value]="convertString(type.id)">
                  {{ type.name }}</nb-option>
              </nb-select>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Language</label>
              <nb-select class="w-100" fullWidth formControlName="LanguageId" placeholder="Select Language">
                <nb-option *ngFor="let Language of languages" [value]="convertString(Language.id)">
                  {{ Language.name }}
                </nb-option>
              </nb-select>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Sales Man</label>
              <nb-select class="w-100" fullWidth formControlName="SalesManId" placeholder="Select SalesMan">
                <nb-option *ngFor="let saleman of salesMen" value="{{ saleman.id }}">
                  {{ saleman.salesManName }}</nb-option>
              </nb-select>
            </div>
          </div>
        </form>
        <hr>
        <form [formGroup]="packgeInfoForm">
          <div class="row">
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Subscription Package</label>
              <nb-select class="w-100" fullWidth formControlName="PackageId" placeholder="Select Package Type">
                <nb-option *ngFor="let package of packages" [value]="convertString(package.packageId)">
                  {{ package.defaultName }}
                </nb-option>
              </nb-select>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Subscription Period</label>
              <nb-select class="w-100" fullWidth formControlName="Interval" placeholder="Select period">
                <nb-option [value]="2">Monthly</nb-option>
                <nb-option [value]="0">Year</nb-option>
              </nb-select>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Subscription Duration</label>
              <input min="1" type="number" nbInput fullWidth placeholder="Enter subscription duration"
                formControlName="Duration" />
            </div>
          </div>
        </form>
        <div class="text-right">
          <!-- <button *ngIf="selectedValue == 'old business'" nbButton nbStepperPrevious>prev</button> -->
          <button nbButton nbStepperNext (click)="onBranchAdd()" [disabled]="!branchInfoForm.valid">next</button>
        </div>
      </nb-step>
      <!-- step three -->
      <nb-step>
        <h4>Branch Address</h4>
        <form [formGroup]="branchAddressForm">
          <div class="row my-3">
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Country</label>
              <nb-select class="w-100" fullWidth placeholder="Select Country" (selectedChange)="onGetCities($event)">
                <nb-option *ngFor="let country of countries" [value]="country">{{country.name}}
                </nb-option>
              </nb-select>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">City</label>
              <nb-select fullWidth class="w-100" placeholder="Select City" (selectedChange)="onSelectCities($event)">
                <nb-option *ngFor="let city of cities" [value]="city">{{city.name}}</nb-option>
              </nb-select>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Location</label>
              <input type="text" formControlName="Location" placeholder="Select Location from map" nbInput fullWidth
                [value]="location" />
            </div>
            <div class="col-12 mb-3">
              <label class="label mb-2">Address</label>
              <textarea type="text" formControlName="BranchAddress" placeholder="Address" nbInput fullWidth
                [value]="branchAddress"></textarea>
            </div>
            <div class="col-12 mb-3">
              <input #mapSearchField nbInput class="map-input my-4" type="text" placeholder="Enter Location" />
              <google-map [options]="mapConfig" [center]="initialCoordinates" [zoom]="mapZoom"
                (mapClick)="onMapClick($event)">
                <map-marker [position]="marker.position" [title]="marker.title"></map-marker>
              </google-map>
            </div>
          </div>
        </form>
        <div class="text-right">
          <button nbButton nbStepperPrevious>prev</button>
          <button nbButton nbStepperNext (click)="onAddressAdd()">next</button>
        </div>
      </nb-step>
      <!-- step four -->
      <nb-step>
        <form [formGroup]="branchParamsForm">
          <h4>Params</h4>
          <div class="row">
            <div class="col-12 col-md-4 mb-3 d-flex justify-content-around align-items-center">
              <label class="label mb-2">Customer Allowed</label>
              <nb-toggle status="success" formControlName="CustomerAllowed"></nb-toggle>
            </div>
            <div class="col-12 col-md-4 mb-3 d-flex justify-content-around align-items-center">
              <label class="label mb-2">Queue Allowed</label>
              <nb-select formControlName="QueueAllowed" fullWidth placeholder="Select Queue Allowed">
                <nb-option [value]="0">False</nb-option>
                <nb-option [value]="1">True</nb-option>
                <!-- <nb-option value="True with printing"
                  >True with printing</nb-option
                > -->
              </nb-select>
            </div>
            <div class="col-12 col-md-4 mb-3 d-flex justify-content-around align-items-center">
              <label class="label mb-2">Discount</label>
              <nb-select placeholder="Select Discount" formControlName="DiscountAllowed" fullWidth>
                <nb-option [value]="0">false</nb-option>
                <nb-option [value]="1">value</nb-option>
                <nb-option [value]="2">percentage</nb-option>
                <nb-option [value]="3">Both</nb-option>
              </nb-select>
              <!-- <nb-toggle status="success" formControlName="DiscountAllowed"></nb-toggle> -->
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Claim</label>
              <nb-select formControlName="ClaimAllowed" fullWidth placeholder="Select Claim">
                <nb-option [value]="0">False</nb-option>
                <nb-option value="1">Auto</nb-option>
                <nb-option value="manual">Manual</nb-option>
              </nb-select>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Payment</label>
              <nb-select multiple formControlName="Payment" fullWidth placeholder="Select Payment type">
                <nb-option value="1">Cash</nb-option>
                <nb-option value="2">Visa</nb-option>
                <nb-option value="3">Bank</nb-option>
              </nb-select>
            </div>
          </div>
          <hr />
          <h4>Printing</h4>
          <div class="row">
            <div class="col-12 col-md-4 mb-3 d-flex justify-content-around align-items-center">
              <label class="label mb-2">Printing Allowe</label>
              <nb-toggle status="success" formControlName="PrintAllowed"></nb-toggle>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Footer Message</label>
              <input formControlName="FooterMessage" type="text" nbInput fullWidth placeholder="Enter Footer Message"
                [value]="" />
            </div>
            <div class="col-12 col-md-4 mb-3 d-flex justify-content-around align-items-center">
              <label class="label mb-2">Reprint</label>
              <nb-toggle status="success" formControlName="RePrint"></nb-toggle>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Merchant copy</label>
              <nb-select formControlName="MerchantCopy" fullWidth placeholder="Select Merchant copy">
                <nb-option [value]="0">False</nb-option>
                <nb-option [value]="1">True</nb-option>
                <nb-option value="ask">Ask</nb-option>
              </nb-select>
            </div>
          </div>
          <hr />
          <h4>Tax</h4>
          <div class="row">
            <div class="col-12 col-md-4 mb-3 d-flex justify-content-around align-items-center">
              <label class="label mb-2">Tax Allowed</label>
              <nb-toggle status="success" formControlName="TaxAllowed"></nb-toggle>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Tax Type</label>
              <nb-select formControlName="TaxTypeId" fullWidth placeholder="Select Tax Type">
                <nb-option [value]="2">Include</nb-option>
                <nb-option [value]="1">Exclude</nb-option>
              </nb-select>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">Tax Id</label>
              <nb-select formControlName="TaxID" fullWidth placeholder="Select Tax Id">
                <nb-option *ngFor="let tax of taxs" [value]="convertString(tax.id)">{{ tax.id }} - {{ tax.name
                  }}</nb-option>
              </nb-select>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="label mb-2">TRN</label>
              <input formControlName="TRN" type="number" nbInput fullWidth placeholder="Enter TRN" />
            </div>
          </div>
        </form>
        <div class="text-right">
          <button nbButton nbStepperPrevious>prev</button>
          <button nbButton nbStepperNext (click)="onParamsAdd()">next</button>
        </div>
      </nb-step>
      <!-- step five -->
      <nb-step>
        <div class="d-flex align-items-center justify-content-between mb-5">
          <h4>SMS Notifictaion</h4>
          <nb-toggle status="success" class="m-0" (checkedChange)="onAllowSMS($event)"></nb-toggle>
        </div>
        <div *ngIf="allowSMS">
          <div class="row justify-content-between align-items-center">
            <div class="col-12 col-md-4 mb-3">
              <label class="mb-3">SMS Balance</label>
              <input type="text" nbInput fullWidth placeholder="SMS Balance" [value]="balance" disabled />
            </div>
            <div class="col-12 col-md-6 mb-3 text-right">
              <button nbButton hero (click)="onAddSms()">
                <nb-icon icon="plus-outline"></nb-icon> Add SMS
              </button>
            </div>
          </div>
          <!-- <div class="row">
                        <div class="col-12 table-responsive" *ngIf="NotificationBases.length > 0">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Event</th>
                                        <th scope="col">Text</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let base of NotificationBases; let i = index">
                                        <td>{{ i }}</td>
                                        <td>{{ base.eventName }}</td>
                                        <td>{{ base.text }}</td>
                                        <td>
                                            <button nbPopover="Edit" nbPopoverTrigger="hint" nbPopoverPlacement="bottom" class="mx-2" nbButton status="warning" size="small" shape="round" hero (click)="onEditSMS(base)">
                                                <nb-icon icon="edit-outline"></nb-icon>
                                            </button>
                                            <button nbPopover="Delete" nbPopoverTrigger="hint" nbPopoverPlacement="bottom" class="mx-2" nbButton status="danger" size="small" shape="round" hero (click)="onDeleteSMS(base.notificationBaseID)">
                                                <nb-icon icon="trash-outline"></nb-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-12 text-center" *ngIf="NotificationBases.length == 0">
                            <p class="my-3 font-weight-bold">no data found..</p>
                        </div>
                    </div> -->
        </div>
        <div *ngIf="!allowSMS">
          <p class="text-center">enable SMS Notifictaion to start editing 🤳🏻</p>
        </div>
        <div class="text-right">
          <button nbButton nbStepperPrevious>prev</button>
          <button nbButton nbStepperNext (click)="onAddNotification()">
            next
          </button>
        </div>
      </nb-step>
      <nb-step>
        <h4>Upload Items / Products</h4>
        <div class="my-3">
          <app-import text="Drop items sheet here or browse files" (uploaded)="onUploaded($event)"
            (removed)="onRemoved()"></app-import>
        </div>
        <div class="text-right">
          <button nbButton nbStepperPrevious>prev</button>
          <button nbButton status="success" hero (click)="onFinish()">
            Create Branch
          </button>
        </div>
      </nb-step>
    </nb-stepper>
  </nb-card-body>
</nb-card>
