<!--edit -->

<nb-card>
  <nb-card-header>
    <h5 class="modal-title">Edit User</h5>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="editUserForm">
      <div class="col">
        <div class="mb-3">
          <label for="recipient-name" class="col-form-label">User Name</label>
          <input type="text" fullWidth nbInput formControlName="userName" class="input-style" [value]="userName">
        </div>
        <div class="mb-3">
          <label for="recipient-name" class="col-form-label">Email</label>
          <input type="email" fullWidth nbInput formControlName="email" class="input-style" [value]="email">
        </div>
        <div class="mb-3">
          <label for="recipient-name" class="col-form-label">Role</label>
          <nb-select placeholder="Select User Rold" fullWidth formControlName="role">
            <nb-option *ngFor="let role of roles" [value]="role.name">
              {{role.name}}</nb-option>
          </nb-select>
        </div>
        <!-- <div class="mb-3">
          <label for="recipient-name" class="col-form-label"> Password </label>
          <nb-form-field>
            <input [type]="getInputType()" class="input-style" nbInput fullWidth placeholder="Enter User Password"
              formControlName="password">
            <button nbSuffix nbButton ghost (click)="toggleShowPassword()">
              <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'" pack="eva"
                [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
              </nb-icon>
            </button>
          </nb-form-field>
        </div>
        <div class="mb-3">
          <label for="recipient-name" class="col-form-label">Retype - Password </label>
            <nb-form-field>
              <input [type]="getInputType1()" class="input-style" nbInput fullWidth placeholder="Retype User Password" formControlName="confirmpassword" >
              <button nbSuffix nbButton ghost (click)="toggleShowPassword1()">
                <nb-icon [icon]="showconfirmPassword ? 'eye-outline' : 'eye-off-2-outline'"
                        pack="eva"
                        [attr.aria-label]="showconfirmPassword ? 'hide password' : 'show password'">
                </nb-icon>
              </button>
            </nb-form-field>
        </div> -->
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="text-end">
    <button nbButton status="success" (click)="editUser()">
      <nb-icon icon="save-outline"></nb-icon>Save
    </button>
    <button nbButton status="danger" (click)="cancel()">
      <nb-icon icon="close-outline"></nb-icon>Cancel
    </button>
  </nb-card-footer>
</nb-card>
<!--edit-->
