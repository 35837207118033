<div class="recent">
  <div class="d-flex align-items-center p-2 rounded-2">
    <img
      class="img-fluid rounded-circle d-none d-md-inline-block"
      width="70"
      src="{{ log.branchPhoto }}"
      alt="user"
    />
    <div class="ms-1">
      <p class="mb-2 fw-bold">
        {{ log.branchName == null ? "branch name" : log.branchName }}
      </p>
      <span class="action font-weight-medium">{{ log.action }}</span>
    </div>
  </div>
</div>
