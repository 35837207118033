import { DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import * as XLSX from 'xlsx';

import {
  NgbDateStruct,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';

import {
  CouponBranchComponent,
} from '../../components/coupon-branch/coupon-branch.component';
import { Coupon, couponResponse } from '../../models/coupon.model';
import { CouponService } from '../../services/coupon.service';
import { GenerateCouponsComponent } from '../../components/generate-coupons/generate-coupons.component';

@Component({
  selector: 'app-coupon-branch-tab',
  templateUrl: './coupon-branch-tab.component.html',
  styleUrls: ['./coupon-branch-tab.component.scss'],
})
export class CouponBranchTabComponent implements OnInit {
  coupon: any;
  generateDates: any;
  expiryDates: any
  checked = false;
  coupounStatuses = [
    { id: '1', name: 'Active', value: true },
    { id: '2', name: 'Deactive', value: false }
  ]
  showgenerateDate: boolean = false;
  showexpiryDate: boolean = false;

  couponList: Coupon[] = []
  exportedCoupons: any = []
  couponsListTwo: Coupon[] = []
  filterCoupons: Coupon[] = [];
  downloadedCoupons:any =[]
  addedCoupons: any;
  searchData: any = {
    generateDate: null,
    expiryDate: null,
    status: ''
  };
  searchTerm: any;
  fileName = 'CouponSheet.xlsx';
  date = new Date()

  p: number = 1;
  loading: boolean = false;
  branchID: any = "";
  param: any = {};
  couponMasterId: any;
  selectedModal: string = "";
  coupons: any = []
  page = 1;
  pageSize = 10;
  generate!: any | NgbDateStruct;
  expire!: any | NgbDateStruct;
  success: couponResponse = {
    isSucceeded: false,
    message: "",
    statusCode: 0,
  };

  alert: any = {
    alert: false,
    message: ""
  };
  constructor(
    public couponService: CouponService,
    public datePipe: DatePipe,
    public router: Router,
    private modalService: NgbModal,
  ) {
    let val = localStorage.getItem('searchVal');
    this.param = JSON.parse(val!);
    this.branchID = this.param.id;
  }

  ngOnInit(): void {
    this.getCouponByBranch()
    this.couponService.refresh.subscribe(res => this.getCouponByBranch())
  }

  onSearch(event: any) {
    this.searchTerm = event.target.value;
    if (this.searchTerm == "") {
      this.couponList = this.couponsListTwo;
    } else {
      this.couponList = this.couponsListTwo.filter((coupon) =>
      coupon.amountDeducted == this.searchTerm || coupon.createdOn == this.searchTerm
        || coupon.expiryDate == this.searchTerm || coupon.discountType == this.searchTerm
        || coupon.discountValue == this.searchTerm || coupon.isActive == this.searchTerm
      );
    };
  }
  getCouponByBranch() {
    this.loading = true;
    let branchId = `branchid=` + this.branchID;
    this.couponService.getCouponByBranch(branchId, false).subscribe(res => {
      this.couponsListTwo = res?.coupons;
      this.couponList = res?.coupons;
      this.filterCoupons = res?.coupons;
      this.loading = false;
      if (this.couponList == null) {
        this.couponList = []
      }
      //pagination
      for (let i = 1; i <= this.couponList?.length; i++) {
        this.couponList.push();
      }
    }, err => console.log(err));

  }

  checkGenerateDate(checked: boolean) {
    this.checked = checked;
    this.showgenerateDate = !this.showgenerateDate
  }
  checkExpiryDate(checked: boolean) {
    this.checked = checked;
    this.showexpiryDate = !this.showexpiryDate
  }
  getBoolean(value: any) {
    switch (value) {
      case true:
      case "true":
      case 1:
      case "1":
      case "on":
      case "yes":
        return true;
      default:
        return '';
    }
  }
  filterCoupon() {
    if (this.showgenerateDate == true) {
      if(this.generate != undefined || typeof(this.generate) == 'object') {
        let generateDate = new Date(this.generate?.year, this.generate?.month - 1, this.generate?.day);
        this.searchData.generateDate = this.datePipe.transform(generateDate, 'yyyy-MM-dd');
      }
      console.log(this.generate,"generateDate")
    }
    if (this.showexpiryDate == true) {
      if(this.expire != undefined || typeof(this.expire) == 'object') {
        let expiryDate = new Date(this.expire?.year, this.expire?.month - 1, this.expire?.day);
        this.searchData.expiryDate = this.datePipe.transform(expiryDate, 'yyyy-MM-dd');
      }
      console.log(this.expire,"expiryDate")
    }
    if (this.searchData.generateDate == "" && this.searchData.expiryDate == "" && this.searchData.status == "") {
      console.log('search is NULL')
      this.couponList = this.couponsListTwo;
    } else {
      if (this.searchData.generateDate != null && this.searchData.expiryDate == null && this.searchData.status == "") {
        this.couponList = this.couponsListTwo.filter((coupon) => {
          let coupongDate = this.datePipe.transform(coupon.createdOn, 'yyyy-MM-dd')
          return coupongDate == this.searchData.generateDate
        });
      }
      if (this.searchData.expiryDate != null && this.searchData.generateDate == null && this.searchData.status == "") {
        this.couponList = this.couponsListTwo.filter((coupon) => {
          let couponeDate = this.datePipe.transform(coupon.expiryDate, 'yyyy-MM-dd')
          return couponeDate == this.searchData.expiryDate
        });
      }
      if (this.searchData.status != "" && this.searchData.generateDate == null && this.searchData.expiryDate == null) {
        this.couponList = this.couponsListTwo.filter((coupon) => {
          return coupon.isActive == this.getBoolean(this.searchData.status)
        });
      }
     if(this.searchData.generateDate != null && this.searchData.expiryDate != null && this.searchData.status == "") {
      this.couponList = this.couponsListTwo.filter((coupon) => {
        let coupongDate = this.datePipe.transform(coupon.createdOn, 'yyyy-MM-dd')
        let couponeDate = this.datePipe.transform(coupon.expiryDate, 'yyyy-MM-dd')
        return coupongDate == this.searchData.generateDate
          && couponeDate == this.searchData.expiryDate
      });
     }
     if(this.searchData.status != "" && this.searchData.generateDate != null) {
      this.couponList = this.couponsListTwo.filter((coupon) => {
        let coupongDate = this.datePipe.transform(coupon.createdOn, 'yyyy-MM-dd')
        return coupongDate == this.searchData.generateDate
          && coupon.isActive == this.getBoolean(this.searchData.status)
      });
     }
     if(this.searchData.status != "" && this.searchData.expiryDate != null) {
      this.couponList = this.couponsListTwo.filter((coupon) => {
        let couponeDate = this.datePipe.transform(coupon.expiryDate, 'yyyy-MM-dd')
        return couponeDate == this.searchData.expiryDate
          && coupon.isActive == this.getBoolean(this.searchData.status)
      });
     }
      if (this.searchData.generateDate != null && this.searchData.expiryDate != null && this.searchData.status != "") {
        this.couponList = this.couponsListTwo.filter((coupon) => {
          let coupongDate = this.datePipe.transform(coupon.createdOn, 'yyyy-MM-dd')
          let couponeDate = this.datePipe.transform(coupon.expiryDate, 'yyyy-MM-dd')
          return coupongDate == this.searchData.generateDate
            && couponeDate == this.searchData.expiryDate
            && coupon.isActive == this.getBoolean(this.searchData.status)
        });
      }
    };
  }
  clearFilterData() {
    this.searchData.generateDate = null
    // this.generate = "Select Generate Date";
    // this.expire = "Select Expiry Date";
    this.searchData.expiryDate = null
    this.showgenerateDate = false;
    this.showexpiryDate = false;
    this.searchData.status = ""
    this.couponList = this.couponsListTwo;
  }
  changeCouponStatus(id: number, status: boolean) {
    this.couponService.changeCouponStatus({ couponMasterId: id, status: !status }).subscribe(res => {

      setTimeout(() => {
        this.success = res
      }, 2000);
      this.couponService.refresh.emit();

    }, errors => {
      console.log(errors.error.errors);
      this.alert = {
        alert: true,
        message: errors.error.errors
      }
      setTimeout(() => {
        this.alert.alert = false;
      }, 2000);
      this.couponService.refresh.emit();
    });

  }
  onClose() {

  }
  goToCouponDetails(id: any) {
    const modalRef = this.modalService.open(CouponBranchComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.couponMasterId = id;
  }
  generateCoupon() {
    this.modalService.open(GenerateCouponsComponent)
  }
  getFileName(name: string) {
    let timeSpan = new Date().toISOString();
    let sheetName = name || "ExportResult";
    let fileName = `${sheetName}-${timeSpan}`;
    return {
      sheetName,
      fileName
    };
  };
  exportArrayToExcel(arr: any[], name?: any) {
    let { sheetName, fileName } = this.getFileName(name);
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(arr);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }
  exportExcel(): void {
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.couponList);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName,);
  }
  head = [
    { title: "No", dataKey: "couponMasterId" },
    { title: "Generated date", dataKey: "createdOn" },
    { title: "Expiry Date", dataKey: "expiryDate" },
    { title: "Type", dataKey: "discountType" },
    { title: "Value", dataKey: "discountValue" },
    { title: "Amount deducted", dataKey: "amountDeducted" },
    { title: "Status", dataKey: "isActive" },
  ]

  exportPdf() {
    this.exportedCoupons = this.couponList;
    let doc = new jsPDF();
    autoTable(doc, {
      columns: this.head,
      body: this.exportedCoupons
    });
    doc.save('Coupon.pdf');
  }
  downloadCoupon(id:any) {
    this.couponService.getCouponById(id,false).subscribe(res => {
      this.downloadedCoupons = res;
      console.log(this.downloadedCoupons, "downloaded=")
      if(this.downloadedCoupons != null || this.downloadedCoupons.length != 0) {
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.downloadedCoupons);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'CouponCodes.xlsx',);
      }
    }, err => console.log(err));
  }
}