import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeLast,
} from 'rxjs/operators';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, OnChanges {
  dynamicFormGroup!: FormGroup;
  @Input() model: any = {};
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  fields: any = [];

  constructor() { }

  ngOnInit(): void {

    this.buildForm();
  }

  ngOnChanges(changes: any) { // SimpleChanges
    if (!changes.model.firstChange) {
      console.log(this.model)
      this.buildForm();
    }
  }

  buildForm() {
    const formGroupFields = this.getFormControlsFields();
    this.dynamicFormGroup = new FormGroup(formGroupFields);
    this.dynamicFormGroup.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((value) => {
        this.onChange.emit(this.getDirtyValues(this.dynamicFormGroup));
      });
  }

  getDirtyValues(form: any) {
    let dirtyValues: any = {};
    Object.keys(form.controls).forEach((key) => {
      let currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls)
          dirtyValues[key] = this.getDirtyValues(currentControl);
        else {
          dirtyValues[key] = currentControl.value;
          currentControl.markAsPristine();
        }
      }
    });

    return dirtyValues;
  }

  getFormControlsFields() {
    let formGroupFields: any = {};
    for (const field of Object.keys(this.model)) {
      const fieldProps = this.model[field];

      if(fieldProps.label !== 'TransactionLayoutId' && fieldProps.label !== 'OrderLayoutId') {
        formGroupFields[field] = new FormControl(fieldProps.value);
        this.fields.push({ ...fieldProps, fieldName: field });
      }

    }
    return formGroupFields;
  }
}
