import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { PosService } from '../../services/pos.service';
import { SalesmanService } from '../../services/salesman.service';


@Component({
  selector: 'app-replace-pos',
  templateUrl: './replace-pos.component.html',
  styleUrls: ['./replace-pos.component.scss']
})
export class ReplacePosComponent implements OnInit {
  newSelected = 'wifi';
  oldSelected = 'wifi';
  @Input() name: string = "";
  @Input() id: string = "";
  @Input() pos: any;
  posType: string = "Mp3";
  replacePosForm: FormGroup = new FormGroup({
    posId: new FormControl(0),
    salesManId: new FormControl(0),
    sim: new FormControl(''),
    oldPosId: new FormControl({ value: '', disabled: true }),
    replaceNotes: new FormControl(''),
    notes: new FormControl('')
  });

  salesmen: any[] = [];
  POSs: any[] = [];
  alert: any = {
    alert: false,
    message: ""
  };

  options: any[] = [];

  constructor(private posService: PosService,
     private salesmanService: SalesmanService, private activeModal: NgbActiveModal) { }


  ngOnInit(): void {
    this.onGetAllPos();
    this.getSalesMan();
    this.replacePosForm.patchValue({
      oldPosId: this.pos.sn
    });

    if (this.pos.sim) {
      this.oldSelected = 'sim';
    }
  }

  search: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term => term === '' ? []
        : this.options.filter((v: any) => v.sn.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  formatter = (x: { sn: string }) => x.sn;

  onReplace() {
    //onsole.log(this.replacePosForm.getRawValue());
    this.posService.replacePos(this.replacePosForm.getRawValue()).subscribe(res => {
      // this.activeModal.close(res.message)
      if (res.isSucceeded == true) {
        this.onClose('success');
      } else {
        this.onClose(res.message);
      }
      this.replacePosForm.reset();
    }, err => {
      if (err.status === 0) {
        console.log(err.status)
        this.alert = {
          alert: true,
          message: "please check your connection and try again."
        }
      } else {
        let errors: any = [];
        let msg = "invalid ";

        Object.keys(err.error.errors).forEach((key) => {
          errors.push(key)
        });

        if (errors.length > 1) {
          errors.forEach((err: any, index: any) => {
            if (index == errors.length - 1) {
              msg += ' ' + err;
            }
            else {
              msg += err + ', ';
            }

          })
        } else {
          msg += errors[0]
        }

        this.alert = {
          alert: true,
          message: msg
        }
      }

      setTimeout(() => {
        this.alert.alert = false;
      }, 2000);
    })
  }

  onClose(msg: string) {
    this.activeModal.close(msg);
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  onGetAllPos() {
    this.posService.getAllPos(false).subscribe(res => {
      //console.log(res)
      this.POSs = res.pos;
      if (this.POSs !== null) {
        this.POSs.forEach(pos => {
          this.options.push({ sn: pos.sn, posType: pos.posType, posStatus: pos.posStatus })
        });
      }

    }, err => {
      console.log(err)
    })
  }

  onPosSelectionChange(event: any) {
    let sn = event.target.value;
    let pos = this.POSs.filter(pos => {
      return pos.sn == sn;
    });

    this.posType = pos[0].posType;
    this.replacePosForm.patchValue({
      posId: pos[0].posId
    });
  }

  getSalesMan() {
    this.salesmanService.getSalesman().subscribe(res => {
      //console.log(res)
      this.salesmen = res;
    }, err => console.log(err))
  }

}
