import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BusinessComponent } from '../business.component';
import { BuisnessService } from '../../../services/buisness.service';

@Component({
  selector: 'app-rename-business-form',
  templateUrl: './rename-business-form.component.html',
  styleUrls: ['./rename-business-form.component.scss']
})
export class RenameBusinessFormComponent implements OnInit {
  @Input() name: string = "";
  @Input() email: string = "";
  @Input() phone: string = "";
  @Input() id: string = "";
  renameBusinessForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
  });

  constructor(private businessService: BuisnessService, public activeModal: NgbActiveModal, private nbToaster: NbToastrService) { }

  ngOnInit(): void {
    this.renameBusinessForm.setValue({
      name: this.name,
      email: this.email,
      phone: this.phone
    });
  }

  onSave() {
    console.log(this.renameBusinessForm.value);
    this.businessService.editBuissness(this.renameBusinessForm.value, this.id).subscribe((res: any) => {
      console.log(res)
      if (res.isSucceeded == true) {
        this.nbToaster.show('business edited successfully', 'success', {
          status: 'success',
          hasIcon: true,
          icon: 'checkmark-outline'
        });
        this.renameBusinessForm.reset();
        this.onClose('success');
      } else {
        this.onClose(res.message);
      }
    }, err => {
      if (err.status == 0) {
        this.nbToaster.show('please check your connection and try again.', 'no internt connection', {
          status: 'danger',
          hasIcon: false,
          icon: 'info-checkmark'
        });
      } else {
        //validation
        console.log(err)
      }

      this.onClose('err');
    })
  }

  onClose(msg: string) {
    this.activeModal.close(msg);
  }

  onCancel() {
    this.activeModal.dismiss();
  }
}
