<nb-card class="m-0">
  <nb-card-header>
    <h5>Replace POS</h5>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="replacePosForm">
      <div class="row">
        <div class="col-6 border-right">
          <h5 class="bg-light p-2 rounded mb-4">Old device</h5>
          <div class="row align-items-center">
            <div class="col-6">
              <div class="mb-3 col-12">
                <label class="mb-2">Branch Name</label>
                <input type="text" nbInput fullWidth [value]="name" disabled>
              </div>
              <div class="mb-3 col-12">
                <label class="mb-2">Branch Id</label>
                <input type="text" nbInput fullWidth [value]="id" disabled>
              </div>
              <div class="mb-3 col-12">
                <label class="mb-2">Pos</label>
                <input type="text" formControlName="oldPosId" nbInput fullWidth>
              </div>
              <div class="mb-3 col-12">
                <nb-radio-group name="old" status="success" class="d-md-flex" [(value)]="oldSelected" disabled>
                  <nb-radio value="wifi">wifi</nb-radio>
                  <nb-radio value="sim">Sim</nb-radio>
                </nb-radio-group>
              </div>
              <div class="mb-3 col-12" *ngIf="oldSelected == 'sim'">
                <label class="mb-2">Sim serial number</label>
                <input type="text" nbInput fullWidth [value]="pos.sim" disabled>
              </div>
              <div class="mb-3 col-12">
                <label class="mb-2">Notes</label>
                <textarea type="text" [value]="pos.notes" nbInput fullWidth disabled></textarea>
              </div>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <img
                src="{{pos.posTypeImageUrl? pos.posTypeImageUrl : 'https://placehold.co/100?text=image+unavailable'}}"
                class="img-fluid pos-img" alt="pos">
            </div>
          </div>
        </div>
        <div class="col-6">
          <h5 class="bg-light p-2 rounded mb-4">Replacement device</h5>
          <div class="row align-items-center">
            <div class="col-6">
              <div class="mb-3 col-12">
                <label class="mb-2">Branch Name</label>
                <input type="text" nbInput fullWidth [value]="name" disabled>
              </div>
              <div class="mb-3 col-12">
                <label class="mb-2">Branch Id</label>
                <input type="text" nbInput fullWidth [value]="id" disabled>
              </div>
              <div class="mb-3 col-12">
                <label class="mb-2">Pos</label>
                <ng-template #rt let-r="result" let-t="term">
                  <ngb-highlight [result]="r.sn + ' ' + r.posType + ' ' + r.posStatus" [term]="t"></ngb-highlight>
                </ng-template>
                <input id="typeahead-basic" type="text" class="form-control custom-input" [ngbTypeahead]="search"
                  [resultTemplate]="rt" [inputFormatter]="formatter" (change)="onPosSelectionChange($event)" />
              </div>
              <div class="mb-3 col-12">
                <nb-radio-group name="new" status="success" class="d-md-flex" [(value)]="newSelected">
                  <nb-radio value="wifi">wifi</nb-radio>
                  <nb-radio value="sim">Sim</nb-radio>
                </nb-radio-group>
              </div>
              <div class="mb-3 col-12" *ngIf="newSelected == 'sim'">
                <label class="mb-2">Sim serial number</label>
                <input type="text" formControlName="sim" nbInput fullWidth placeholder="Enter Sim Serial No.">
              </div>
              <div class="mb-3 col-12">
                <label class="mb-2">Salesman</label>
                <select class="form-control custom-select" formControlName="salesManId">
                  <option value="0" disabled>Select salesMan</option>
                  <option *ngFor="let saleman of salesmen" value="{{saleman.id}}">{{saleman.salesManName}}</option>
                </select>
              </div>
              <div class="mb-3 col-12">
                <label class="mb-2">Notes</label>
                <textarea type="text" formControlName="notes" nbInput fullWidth placeholder="Enter Pos Note"></textarea>
              </div>
              <div class="mb-3 col-12">
                <label class="mb-2">Replacement Reason</label>
                <textarea type="text" formControlName="replaceNotes" nbInput fullWidth
                  placeholder="Enter Pos Replacement Reason"></textarea>
              </div>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-cente">
              <img
                src="{{posType == 'Mp3' ? '/assets/imgs/mp3.png': posType == 'Mp4' ? '/assets/imgs/mp4.png' : '/assets/imgs/sunmi.png'}}"
                class="img-fluid pos-img" alt="pos">
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="alert alert-danger" *ngIf="alert.alert">{{ alert.message }}</div>
  </nb-card-body>
  <nb-card-footer class="text-right">
    <button nbButton status="basic" (click)="onCancel()">Cancel</button>
    <button nbButton status="success" hero (click)="onReplace()">Replace</button>
  </nb-card-footer>
</nb-card>
