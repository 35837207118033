import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-reference-items',
  templateUrl: './reference-items.component.html',
  styleUrl: './reference-items.component.scss'
})
export class ReferenceItemsComponent implements OnInit,OnChanges {
  
  isLoading: boolean = false;
  searchQuery:any;


  @Input() businessTypeId: any;
  @Input() items: any[] = [];
  itemId: any;
  @Output() updateDataEvent = new EventEmitter<void>();

  constructor (

  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('changed');
    this.itemId=null;
    
  }
  ngOnInit(): void {
 
  }
  onitemsChange(){
  }
  updateevent(){
    console.log('updateDataEvent from ReferenceItemsComponent');
    
    this.updateDataEvent.emit();

  }
}
