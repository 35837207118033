import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WorkersService {

  constructor(private http: HttpClient) { }

  getWorkers(id: string) {
    return this.http
      .get(`${environment.baseURL}/Worker/GetBranchWorkers?branchId=${id}`)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          return throwError('something went wrong');
        })
      );
  }

  addWorker(worker: {}) {
    return this.http
      .post(`${environment.baseURL}/Worker/Create`, worker)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          return throwError('something went wrong');
        })
      );
  }

  editWorker(worker: {}) {
    return this.http
      .patch(`${environment.baseURL}/Worker/Edit`, worker)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          return throwError('something went wrong');
        })
      );
  }

  deleteWorker(id: string) {
    return this.http
      .delete(`${environment.baseURL}/Delete?workerId=${id}`)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          return throwError('something went wrong');
        })
      );
  }
}
