<div class="table-responsive" *ngIf="orders.length > 0">
  <div class="my-3">
    <input nbInput placeholder="search by customer name.." class="search-input" (input)="onSearch($event)" />
  </div>
  <table class="table table-bordered" [nbSpinner]="loading" nbSpinnerStatus="basic">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th>Date</th>
        <th scope="col">Customer</th>
        <th scope="col">Phone No.</th>
        <th scope="col">Customer Address</th>
        <!-- <th scope="col">Order Queue</th> -->
        <th scope="col">Order Status</th>
        <!-- <th scope="col">Quantity</th> -->
        <!-- <th scope="col">Discount</th> -->
        <!-- <th scope="col">Tax</th> -->
        <!-- <th scope="col">Price</th> -->
        <th>Order Details</th>
        <th>Change Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let order of orders; let i = index ">
        <th scope="row">{{ i + 1 }}</th>
        <td>{{order?.createdAt | date : 'short'}}</td>
        <td>{{order?.customer?.fullname? order.customer?.fullname : ''}}</td>
        <td>{{order?.customer?.phoneNumber? order?.customer?.phoneNumber : ''}}</td>
        <td>{{order?.customerAddress?.fullAddress? order.customerAddress.fullAddress : '-'}}</td>
        <!-- <td>{{order.orderQueue.queueNumber}}</td> -->
        <td>{{order.currentStatusType.title}}</td>
        <!-- <td>{{order.totalQuantity}}</td>
              <td>{{order.totalDiscount}}</td>
              <td>{{order.totalTax}}</td>
              <td>{{order.totalPrice}}</td> -->
        <td>
          <button placement="right" ngbTooltip="Order Details" tooltipClass="custom-tooltip" nbButton status="info"
            size="small" shape="round" hero (click)="onShowDetails(order.id)">
            <nb-icon icon="alert-circle-outline"></nb-icon>
          </button>
        </td>
        <td>
          <nb-select [disabled]="order.selectedStatus.title == 'Completed'" placeholder="Select Statues" fullWidth [(ngModel)]="order.selectedStatus"
            (selectedChange)="onChangeOrderStatues(order, cashier)">
            <nb-option *ngFor="let status of order.possibleStatusTypes" [value]="status">
              {{ status.title }}</nb-option>
          </nb-select>
        </td>
      </tr>
    </tbody>
  </table>
  <nav aria-label="table pagination">
    <ul class="pagination justify-content-end mt-3">
      <li class="page-item" [ngClass]="{ 'disabled': !prevPage }" (click)="onPrevious()">
        <span class="page-link">Prev</span>
      </li>
      <li class="page-item" [ngClass]="{ 'active': pageNumber == page }" *ngFor="let page of pages"
        (click)="onPageChange(page)"><span class="page-link">{{ page }}</span></li>
      <li class="page-item" [ngClass]="{ 'disabled': !nextPage }" (click)="onNext()">
        <span class="page-link">Next</span>
      </li>
    </ul>
  </nav>
</div>
<div class="py-3 text-center" *ngIf="orders.length == 0">
  <p>nothing to show</p>
</div>
<ng-template #cashier>
  <div class="modal-body">
    <nb-select placeholder="Select Cashier" fullWidth [(ngModel)]="selectedCashier">
      <nb-option *ngFor="let cashier of cashiers" [value]="cashier">
        {{ cashier.userName }}</nb-option>
    </nb-select>
    <div class="text-right mt-3">
      <button nbButton hero status="success" (click)="changeOrderStatues()">
        Compelete Order
      </button>
    </div>
  </div>
</ng-template>
