<nb-card>
  <nb-card-header>
    <h5 class="modal-title">Delete Branch Area </h5>
  </nb-card-header>
  <nb-card-body>
    Confirm Deleting Branch Area ?
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="success" (click)="deleteArea()"><nb-icon icon="save-outline"></nb-icon>Delete</button>
    <button nbButton status="danger" (click)="cancel()"><nb-icon icon="close-outline"></nb-icon>Cancel</button>
  </nb-card-footer>
</nb-card>