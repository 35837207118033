<nb-card accent="primary" [nbSpinner]="isLoading">
  <nb-card-header> {{ subCategory ? "Update" : "New" }} Sub Category</nb-card-header>

  <nb-card-body>
    <form [formGroup]="newSubCategory">
      <img *ngIf="subCategory" class="img-fluid" width="200" [src]="subCategoryImage" alt="">

      <input
        type="text"
        (input)="autoTranslate && detectChange('en', $event)"
        nbInput
        shape="rectangle"
        placeholder="Sub Category English Name"
        formControlName="subCategoryNameEN"
      />

      <input
      (input)="autoTranslate && detectChange('ar', $event)"
        type="text"
        nbInput
        shape="rectangle"
        placeholder="Sub Category Arabic Name"
        formControlName="subCategoryNameAR"
      />

      <input
      (input)="autoTranslate && detectChange('fr', $event)"
        type="text"
        nbInput
        shape="rectangle"
        placeholder="Sub Category French Name"
        formControlName="subCategoryNameFR"
      />

      <input
      (input)="autoTranslate && detectChange('tr', $event)"
        type="text"
        nbInput
        shape="rectangle"
        placeholder="Sub Category Turkish Name"
        formControlName="subCategoryNameTR"
      />

      <div class="mb-3 col-12">
        <nb-checkbox [(ngModel)]="autoTranslate" [ngModelOptions]="{'standalone': true}">Auto translate item name</nb-checkbox>
      </div>

      <ngx-dropzone  class="justify-content-center m-3"
        (change)="onSelect($event)" [multiple]="false" accept="image/*">
        <ngx-dropzone-label>Drop the item logo to {{subCategory? 'change current logo':'upload'}}</ngx-dropzone-label>
        <ngx-dropzone-preview *ngIf="itemImage" [removable]="true">
          <ngx-dropzone-label>{{ itemImage.name }}</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="success" (click)="save()">OK</button>
    <button nbButton status="danger" (click)="close()">Cancel</button>
  </nb-card-footer>
</nb-card>
