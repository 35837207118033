import {Component, Input, OnInit} from '@angular/core';
import {NgxDropzoneChangeEvent} from "ngx-dropzone";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'ng-icons',
  templateUrl: './branch-category-icons.component.html',
  styleUrls: ['./branch-category-icons.component.scss']
})
export class BranchCategoryIconsComponent implements OnInit {
  @Input() imgUrl?: string;
  baseImgUrl:string;

  constructor() {
    this.baseImgUrl = environment.baseImageUrl
  }

  ngOnInit(): void {
  }



}
