import { Component, OnInit } from '@angular/core';
import {NgxDropzoneChangeEvent} from "ngx-dropzone";
import {ItemsService} from "../../services/items.service";
import {NbToastrService} from "@nebular/theme";

@Component({
  selector: 'app-branch-items-sheet',
  templateUrl: './branch-items-sheet.component.html',
  styleUrls: ['./branch-items-sheet.component.scss']
})
export class BranchItemsSheetComponent implements OnInit {

  itemSheet!:File
  isLoading:boolean = false;
  branchID:string;

  constructor(private itemService:ItemsService, private toastrService:NbToastrService) {
    let val = localStorage.getItem('searchVal');
    this.branchID = JSON.parse(val!)!.id;
  }

  ngOnInit(): void {
  }

  onSelect(event:NgxDropzoneChangeEvent) {
    console.log(event);
    this.itemSheet = event.addedFiles[0];
  }

downloadGeneralForm() {
  window.open("./assets/files/general-fom.xlsx", "_blank");
}

  uploadSheet():any{
    if(!this.itemSheet) return this.toastrService.warning("Please Specify File To Upload")
    let fromData = new FormData()
    fromData.append("sheet", this.itemSheet)
    this.isLoading = true;
    this.itemService.addItemBySheet(fromData, this.branchID).subscribe((res) =>{
      console.log({res})
      this.isLoading = false;
      this.toastrService.success("Sheet Uploaded Successfully")
    },(error => {
      console.error({error})
       this.toastrService.danger("Error Has Occurred Please Retry")
      this.isLoading = false
    }),() =>{
      this.isLoading = false
    })
  }

}
