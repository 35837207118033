<nb-tabset>
  <nb-tab tabTitle="Place" style="padding-left: 0; padding-right: 0">
    <app-branch-items-place></app-branch-items-place>
  </nb-tab>
  <nb-tab tabTitle="Pricing">
    <app-branch-items-pricing></app-branch-items-pricing>
  </nb-tab>
  <nb-tab tabTitle="Upload Items">
    <app-branch-items-sheet></app-branch-items-sheet>
  </nb-tab>
</nb-tabset>
