import { Component, OnInit } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
  userId: string = "";
  logs: any[] = [];

  constructor(private authService: NbAuthService, private userService: UserService) { }

  ngOnInit(): void {
    this.authService.onTokenChange().subscribe(res => {
      this.userId = res.getPayload().userId;
    });

    this.userService.logs.subscribe(res => {
      console.log(res)
      this.onGetUserLogs(this.userId);
    })

    this.onGetUserLogs(this.userId);
  }

  onGetUserLogs(id: string) {
    this.userService.getUserLogs().subscribe( res => {
      if(res !== null) {
        this.logs = res;
      }
      // console.log(this.logs)
    }, err => console.log(err))
  }
}
