<nb-tabset>
  <nb-tab tabTitle="Category and Subcategory">
    <app-branch-category [branchID]="branchID"></app-branch-category>
  </nb-tab>
  <nb-tab tabTitle="Items">
    <div class="mt-4">
      <app-branch-items-manage [branchID]="branchID"></app-branch-items-manage>
    </div>
  </nb-tab>
</nb-tabset>
