<nb-card class="m-0">
  <nb-card-header>
    <h5>Create Feature Group</h5>
  </nb-card-header>
  <nb-card-body>
    <form>
      <div class="form-row">
        <div class="mb-3 col-12 col-md-6">
          <label class="mb-2">Feature Group Name EN</label>
          <input type="text" (input)="onNameChange($event)" [(ngModel)]="nameInLanguages[0].name" nbInput fullWidth>
          <!-- <span *ngIf="submitted && featureGroupForm.get('sn')?.errors?.['required']" class="text-danger">SN is required</span> -->
        </div>
        <div class="mb-3 col-12 col-md-6">
          <label class="mb-2">Feature Group Name AR</label>
          <input type="text" [(ngModel)]="nameInLanguages[1].name" nbInput fullWidth>
          <!-- <span *ngIf="submitted && featureGroupForm.get('sn')?.errors?.['required']" class="text-danger">SN is required</span> -->
        </div>
        <div class="mb-3 col-12 col-md-6">
          <label class="mb-2">Feature Group Name TR</label>
          <input type="text" [(ngModel)]="nameInLanguages[2].name" nbInput fullWidth>
          <!-- <span *ngIf="submitted && featureGroupForm.get('sn')?.errors?.['required']" class="text-danger">SN is required</span> -->
        </div>
        <div class="mb-3 col-12 col-md-6">
          <label class="mb-2">Feature Group Name FR</label>
          <input type="text" [(ngModel)]="nameInLanguages[3].name" nbInput fullWidth>
          <!-- <span *ngIf="submitted && featureGroupForm.get('sn')?.errors?.['required']" class="text-danger">SN is required</span> -->
        </div>
        <div class="mb-3 col-12 col-md-6">
          <label class="mb-2">Key</label>
          <input type="text" [(ngModel)]="key" nbInput fullWidth>
          <!-- <span *ngIf="submitted && featureGroupForm.get('sn')?.errors?.['required']" class="text-danger">SN is required</span> -->
        </div>
      </div>
      <span *ngIf="submitted && !valid" class="text-danger">*all fields are required</span>
    </form>
  </nb-card-body>
  <nb-card-footer class="text-right">
    <button nbButton status="basic" (click)="onCancel()">Cancel</button>
    <button nbButton status="success" (click)="onSave()">Save</button>
  </nb-card-footer>
</nb-card>
