import { Component, OnInit } from '@angular/core';

import { AddFeatureGroupComponent } from './add-feature-group/add-feature-group.component';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NbToastrService } from '@nebular/theme';
import { AddFeatureComponent } from './add-feature/add-feature.component';
import { AddPackageComponent } from './add-package/add-package.component';
import { BuisnessService } from '../../services/buisness.service';
import { FeatureService } from '../../services/feature.service';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss']
})
export class FeatureComponent implements OnInit {
  packages: any[] = [];
  features: any[] = [];
  loading: boolean = false;
  constructor(
    private featuresService: FeatureService, 
    private nbToaster: NbToastrService, 
    private modalService: NgbModal, 
    private businessService: BuisnessService) { }

  ngOnInit(): void {
    this.getPackages();
    this.getFeatures();
  }

  onCreateFeatureGroup() {
    const modalRef = this.modalService.open(AddFeatureGroupComponent, { centered: true });
    modalRef.closed.subscribe(res => {
      // console.log(res)
      if (res.isSucceeded) {
        //this.getPOSTypes();
        this.nbToaster.show(res.message, 'SUCCESS', {
          status: 'success',
          hasIcon: false
        });
      };
    })
  }

  onCreateFeature() {
    const modalRef = this.modalService.open(AddFeatureComponent, { centered: true });
    modalRef.closed.subscribe(res => {
      console.log(res)
      if (res.isSucceeded) {
        //this.getPOSTypes();
        this.nbToaster.show(res.message, 'SUCCESS', {
          status: 'success',
          hasIcon: false
        });
      };
    })
  }

  onCreatePackage() {
    const modalRef = this.modalService.open(AddPackageComponent, { centered: true, size: 'xl' });
    modalRef.closed.subscribe(res => {
      console.log(res)
      if (res.isSucceeded) {
        //this.getPOSTypes();
        this.nbToaster.show(res.message, 'SUCCESS', {
          status: 'success',
          hasIcon: false
        });
      };
    })
  }

  getPackages() {
    this.loading = true;
    this.featuresService.getPackages().subscribe({
      next: (res: any) => {
        this.packages = res;
        this.loading = false;
      },
      error: (err) => {
        console.log(err.error);
        this.loading = false;
      }
    })
  }

  getFeatures() {
    this.loading = true;
    this.featuresService.getAllFeatures().subscribe({
      next: (res: any) => {
        this.features = res;
        this.loading = false;
      },
      error: (err) => {
        console.log(err.error);
        this.loading = false;
      }
    })
  }

}
