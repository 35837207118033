<nb-card class="m-0">
    <nb-card-header>
        <h5>Add New Business</h5>
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]="addBusinessForm">
            <div class="form-row">
                <div class="mb-3 col-12">
                    <label class="mb-2">Business Name</label>
                    <input type="text" formControlName="name" nbInput fullWidth placeholder="Enter Business Name">
                </div>
                <div class="mb-3 col-12">
                    <label class="mb-2">Business Phone</label>
                    <input type="text" formControlName="phone" nbInput fullWidth placeholder="Enter Business Phone">
                </div>
                <div class="mb-3 col-12">
                    <label class="mb-2">Business Email</label>
                    <input type="email" formControlName="email" nbInput fullWidth placeholder="Enter Business Email">
                </div>
                <div class="mb-3 col-12">
                    <label class="mb-2">Sales Man Name</label>
                    <select class="form-control custom-select" formControlName="salesManId" nbInput>
                        <option *ngFor="let salesMan of salesMen" value="{{salesMan.id}}">{{salesMan.salesManName}}
                        </option>
                    </select>
                    <!-- <nb-select formControlName="salesManId" fullWidth placeholder="Select Sales Man">
                        <nb-option *ngFor="let salesMan of salesMen" value="{{salesMan.id}}">{{salesMan.salesManName}}</nb-option>
                    </nb-select> -->
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer class="text-right">
        <button nbButton status="basic" (click)="onCancel()">Cancel</button>
        <button nbButton status="success" (click)="useCase == 'business'? onSave() : onStep()">Save</button>
    </nb-card-footer>
</nb-card>
