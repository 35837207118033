<div class="row">
  <div class="col">
  <div class="row d-flex">
    <div class="col-12 col-md-6 mb-3">
      <!-- <strong class="title">Report</strong> -->
      <span class="form-group" *ngIf="showFromDate">
        From Date
        <div class="inline">
          <input nbInput placeholder="Select From Date" name="fromDate" [(ngModel)]="fromDate" size="small"
            ngbDatepicker #d="ngbDatepicker">
          <nb-icon icon="calendar-outline" (click)="d.toggle()" class="calendr">
          </nb-icon>
        </div>
      </span>
    </div>
    <div class="col-12 col-md-6  mb-3">
      <span class="form-group" *ngIf="showToDate">
        To Date
        <div class="inline">
          <input nbInput placeholder="Select To Date" name="toDate" [(ngModel)]="toDate" size="small" ngbDatepicker
            #d="ngbDatepicker">
          <nb-icon icon="calendar-outline" (click)="d.toggle()" class="calendr"></nb-icon>
        </div>
      </span>
    </div>
  </div>
  <div class="padding"></div>
  <div class="padding"></div>

  <div class="row  d-flex">
    <div class="col-12 col-md-6 col-lg-4 mb-3">
      Category
      <nb-select placeholder="Select Category" [(ngModel)]="trx.category" (selectedChange)="selectCategory($event)">
        <nb-option *ngFor="let category of categories" value="{{category.categoryId}}">{{category.categoryNameEN}}</nb-option>
        <nb-option *ngIf="categories==null">No Category Found!</nb-option>
      </nb-select>
    </div>
    <div class="col-12 col-md-6 col-lg-4 mb-3">
      Sub-Category
      <nb-select placeholder="Select SubCategory" [(ngModel)]="trx.subcategory">
        <nb-option *ngFor="let subcategory of subcategories" value="{{subcategory.subCategoryId}}">{{subcategory.subCategoryNameEN}}</nb-option>
        <nb-option *ngIf="subcategories==null">No SubCategory Found!</nb-option>
      </nb-select>
    </div>
    <div class="col-12 col-md-12 col-lg-4 mb-3">
      Item
      <nb-select placeholder="Select Item">
        <nb-option *ngFor="let item of items" value="{{item.itemId}}" >
          {{item.itemNameEN}}</nb-option>
        <nb-option *ngIf="items==null">No Items Found!</nb-option>
      </nb-select>
    </div>
  </div>


  <div class="row d-flex">
    <div class="col-12 col-md-6 col-lg-4 mb-3">
      Payment Mode
      <nb-select placeholder="Select Payment Mode" [(ngModel)]="trx.payment">
        <nb-option *ngFor="let payment of payments" value="{{payment.id}}">{{payment.name}}</nb-option>
      </nb-select>
    </div>

    <div class="col-12 col-md-6 col-lg-4 mb-3">
      Device
      <nb-select placeholder="Select Device" [(ngModel)]="trx.device">
        <nb-option *ngFor="let device of devices" value="{{device.id}}">{{device.name}}</nb-option>
      </nb-select>
    </div>

    <div class="col-12 col-md-6 col-lg-4 mb-3">
      <button nbButton size="medium" hero status="primary" (click)="getDeletedTrx()">
        <nb-icon icon="archive-outline"></nb-icon>
        Show Report
      </button>
    </div>

  </div>

    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <input class="input-style" fullWidth type="text" nbInput placeholder="Search" name="search"
          (input)="onSearch($event)">
      </div>
      <div class="col-12  col-md-6 wrapper-img">
        <img src="../assets/imgs/excel.png" class="img-fluid excel-img" (click)="exportExcel()">
        <img src="../assets/imgs/pdf.png" class="img-fluid pdf-img" (click)="exportPdf()">
      </div>
    </div>

        <div class="row" *ngIf="trxOperations.length == 0" [nbSpinner]="loading"
        nbSpinnerStatus="basic">
              <strong style="text-align: center;"> No Deleted Transactions Found!</strong>
          </div>
        <div class="row" *ngIf="trxOperations.length != 0" [nbSpinner]="loading"
        nbSpinnerStatus="basic">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>
                    Transaction No</th>
                  <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="voucherNO">Receipt Number</th>
                  <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="transactionDateTime">Date and
                    Time</th>
                  <!-- <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="comments">Comments</th> -->
                  <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="cashier">Cashier</th>
                  <!-- <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="service">Service</th> -->
                  <!-- <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="quantity">Quantity</th> -->
                  <!-- <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="amount">Amount</th> -->
                  <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="totalAmount">Total</th>
                  <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="totalTaxAmount">Tax Amount</th>
                  <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="couponCode">Coupon Code</th>
                  <!-- <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="coupon">Coupon %</th> -->
                  <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="discountValue">Discount Amount
                  </th>
                  <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="totalAfterDiscount">Total After
                    Discount</th>
                  <th scope="col" [appSort]="trxOperations" data-order="desc" data-name="totalAfterTaxAmount">Total
                    After Tax</th>
                  <th scope="col">Options</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let trxOperation of trxOperations; let i=index">
                  <td>{{i + 1}}</td>
                  <td>{{trxOperation.voucherNO}}</td>
                  <td>{{trxOperation.transactionDateTime | date: 'yyyy-MM-dd'}}</td>
                  <!-- <td>{{trxOperation.comments}}</td> -->
                  <td>{{trxOperation.cashier}}</td>
                  <!-- <td>{{trxOperation.service}}</td>
          <td>{{trxOperation.quantity}}</td> -->
                  <!-- <td>{{trxOperation.amount}}</td> -->
                  <td>{{trxOperation.totalAmount}}</td>
                  <td>{{trxOperation.totalTaxAmount}}</td>
                  <td>{{trxOperation.couponCode}}</td>
                  <!-- <td>{{trxOperation.coupon}}</td> -->
                  <td>{{trxOperation.discountValue}}</td>
                  <td>{{trxOperation.totalAfterDiscount}}</td>
                  <td>{{trxOperation.totalAfterTaxAmount}}</td>
                  <td>
                    <button nbButton hero shape="round" size="small" status="warning" placement="top"
                    (click)="EnableTrx(trxOperation.transactionMasterId)" ngbTooltip="Enable Trx" tooltipClass="custom-tooltip">
                      <nb-icon icon="checkmark-square-2-outline"></nb-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div></div>
  </div>