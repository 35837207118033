<!--Coupon Detials-->
<nb-card >
  <nb-card-header>
         <h5 class="modal-title">Coupon {{couponMasterId}} 
        
            <button placement="top" ngbTooltip="Download Coupon" tooltipClass="custom-tooltip"
            nbButton class="mx-2" (click)="downloadCoupon()"
            status="primary" shape="round" hero size="small">
            <nb-icon icon="file-text-outline"></nb-icon>
        </button>     
        </h5>
        </nb-card-header>
        <nb-card-body>
            
            <div class="row" *ngIf="coupons.length == 0" >
                <strong style="text-align: center;"> No Coupons Found!</strong>                
            </div>
                <div class="table-responsive col-12" *ngIf="coupons.length != 0" >
                    <table class="table table-bordered" [nbSpinner]="loading" nbSpinnerStatus="basic">
                    <thead>
                        <tr>
                        <th>No</th>
                        <th [appSort]="coupons" data-order="desc" data-name="couponCode" scope="col">Coupon</th>
                        <th [appSort]="coupons" data-order="desc" data-name="isRedeemed" scope="col">Status</th>
                        <!-- <th scope="col">Trx</th> -->
                        <th [appSort]="coupons" data-order="desc" data-name="redeemDate"  scope="col">Redeem On</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let coupon of coupons  | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i = index">

                        <th scope="row">{{i + 1}}</th>
                        <td>{{coupon.couponCode}}</td>
                        <td *ngIf="coupon.isRedeemed==true"> isRedeemed</td>
                        <td *ngIf="coupon.isRedeemed==false"> Not Redeemed</td>
                        <!-- <td>{{coupon.trx}}</td> -->
                        <td>{{coupon.redeemDate  | date: 'yyyy-MM-dd'}}</td>
                        </tr>
                    </tbody>
                    </table>
                    <div *ngIf="coupons != null">
                        <ngb-pagination [collectionSize]="coupons.length" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                    </div>

                  </div>
                  </nb-card-body>
          </nb-card>
  <!--Coupon Detials-->